import { Col, Row } from "react-bootstrap";
import Table from "../../../../../../../components/Table";
import "./CategoryTasks.scss";
import { Category as CategoryType } from "../../../../../../../utils/Task";
import List from "../../../../../../../components/List";
import Task from "../Task/Task";

type CategoryTasksProps = {
  category: CategoryType;
  className?: string;
};
export default function CategoryTasks({ category, className }: CategoryTasksProps) {
  return (
    <div className={`CategoryTasks ${className}`}>
      <List>
        {category.tasks.map((task) => (
          <List.Item key={task.id}>
            <Task task={task} />
          </List.Item>
        ))}
      </List>
    </div>
  );
}
