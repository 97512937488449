import { Card as CardBS, Col, Row } from "react-bootstrap";
import DatePicker from "../../../../../components/DatePicker";
import { useYearRecordsTable } from "../../context/YearRecordsTableContext";

export function Header() {
  const { year, setYear, availableYears } = useYearRecordsTable();
  return (
    <div className="Header">
      <Row>
        <Col>
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-flex px-2 me-1 justify-content-center justify-content-c-sm-start align-items-center">
              <div>
                {/* <DatePicker selected={month.date} dateFormat="MM.yyyy" showMonthYearPicker className="mx-1" readOnly={!setMonth} onChange={(month) => month && setMonth && setMonth(new DateTime(month))} /> */}
                <DatePicker
                  date={year}
                  setDate={setYear}
                  format={"yearOnly"}
                  minDate={availableYears.minYear}
                  maxDate={availableYears.maxYear}
                  leftArrow={true}
                  rightArrow={true}
                  // leftArrowClassName="d-none d-sm-inline-block"
                  // rightArrowClassName="d-none d-sm-inline-block"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
