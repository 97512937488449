import { DateTime } from "./DateTime";
import { Task } from "./Task";
import { TaskRecord, TaskRecordGroups } from "./TaskRecord"
import _ from "lodash";


type RespTaskData = {
    recordId: string;
    task: {
        id: string,
        category: string,
        name: string
    };
    period: {
      startedAt: string;
      endedAt: string | null;
    };
    durationMin: number;
    isManual: boolean;
  };
  
  type RespTaskTrackingData = {[group: string]: RespTaskData[]}

export class TaskRecords {
    // _taskRecords: Record<string, Record<string,TaskRecord>>;
    _taskRecords: {[group: string]: {[id: string]: TaskRecord}};

    constructor(taskRecords: TaskRecord[]) {
      this._taskRecords = {}
        this._taskRecords = {billable: {}, unverified: {}}
        taskRecords.map((taskRecord) => {
          if (!this._taskRecords.hasOwnProperty(taskRecord.group)) {
            this._taskRecords[taskRecord.group] = {}
          }
            this._taskRecords[taskRecord.group][taskRecord.id] = taskRecord;
        })
    }

    static fromResp(respTrackingData: RespTaskTrackingData): TaskRecords {
        const taskRecords: TaskRecord[] = [];
    
        for (const group in respTrackingData) {
          if (respTrackingData.hasOwnProperty(group)) {

            const respTasks = respTrackingData[group];
            respTasks.forEach((respTaskData) => {
              const task = new Task(
                respTaskData.task.id,
                respTaskData.task.name,
                respTaskData.task.category
              );
              const period = {
                startedAt: new DateTime(respTaskData.period.startedAt),
                endedAt: respTaskData.period.endedAt ? new DateTime(respTaskData.period.endedAt) : null
              };
              const taskRecord: TaskRecord = {
                id: respTaskData.recordId,
                period: period,
                task: task,
                durationMin: respTaskData.durationMin,
                isManual: respTaskData.isManual,
                group: group as TaskRecordGroups
              };
              
              // new TaskRecord(
              //   respTaskData.recordId,
              //   task,
              //   period,
              //   respTaskData.durationMin,
              //   respTaskData.isManual,
              //   group as TaskRecordGroups
              // );
              taskRecords.push(taskRecord);
            });
          }
        }
        // console.log(taskRecords);

    
        return new TaskRecords(taskRecords);
      }

      // Operator [] implementation
      findGroup(group: string | TaskRecordGroups): {[id: string]: TaskRecord} | undefined {
        if (this._taskRecords.hasOwnProperty(group)) {
            return this._taskRecords[group];
        }
        return undefined
      }

      groupToArray(group: string | TaskRecordGroups){
        var groupRecords = this.findGroup(group);
        if (!groupRecords) return [];
        return Object.values(groupRecords);
      }
      
      sumDurationForGroup(group: string | TaskRecordGroups): number {
        var groupRecordsArray = this.groupToArray(group)
        if (!this.groupToArray) return 0;
        return _.sumBy(groupRecordsArray, (taskRecord) => {
          return taskRecord.durationMin
        })
      }
}