import { ReactNode } from "react";
import { OverlayTrigger, Tooltip as TooltipBS } from "react-bootstrap";

type TooltipProps = {
    children: React.ReactElement;
    tooltipContent: ReactNode | string;
    delay?: {
        show: number,
        hide: number
    },
    className?: string 
  };

export function Tooltip({children, tooltipContent, delay, className }:TooltipProps){
    if (!delay){
        delay = {show: 600, hide: 150};
    }
    const tooltip = (
        <TooltipBS id="tooltip" style={{position:"fixed"}} className={className}>
          {tooltipContent}
        </TooltipBS>
      );
    return (
        <OverlayTrigger placement="auto" overlay={tooltip}  delay={delay}>
            {children}
        </OverlayTrigger>
    )
}