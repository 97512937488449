import { useEffect, useState } from "react";
import {
  ErrorLog,
  FormErrors as FormErrorsGeneralType,
  useFormErrors,
} from "./useFormErrors";

type FormStatus = {
  isSubmitting: boolean;
  isDeleting: boolean;
};
function useFormStatus() {
  const [formStatus, _setStatusForm] = useState<FormStatus>({
    isSubmitting: false,
    isDeleting: false,
  });
  const startSubmitting = () => {
    _setStatusForm({ ...formStatus, isSubmitting: true });
  };
  const stopSubmitting = () => {
    _setStatusForm({ ...formStatus, isSubmitting: false });
  };
  const startDeleting = () => {
    _setStatusForm({ ...formStatus, isDeleting: true });
  };
  const stopDeleting = () => {
    _setStatusForm({ ...formStatus, isDeleting: false });
  };
  return [
    formStatus,
    startSubmitting,
    stopSubmitting,
    startDeleting,
    stopDeleting,
  ] as const;
}

function useFormSubmitDeleteSimple(
  validateSubmitData: () => boolean,
  validateDeleteData: () => boolean,
  sendSubmitData: (doAlways: () => void) => void,
  sendDeleteData: (doAlways: () => void) => void
) {
  const [
    formStatus,
    startSubmitting,
    stopSubmitting,
    startDeleting,
    stopDeleting,
  ] = useFormStatus();
  const submitForm = () => {
    if (!validateSubmitData()) {
      stopSubmitting();
      return;
    }
    const doAlways = stopSubmitting;
    sendSubmitData(doAlways);
  };
  const deleteRecord = () => {
    if (!validateDeleteData()) {
      stopDeleting();
      return;
    }
    const doAlways = stopDeleting;
    sendDeleteData(doAlways);
  };

  useEffect(() => {
    if (formStatus.isSubmitting && !formStatus.isDeleting) {
      submitForm();
    }
    if (!formStatus.isSubmitting && formStatus.isDeleting) {
      deleteRecord();
    }
  }, [formStatus]);

  const handleSubmit = () => {
    if (formStatus.isSubmitting || formStatus.isDeleting) {
      return;
    }
    startSubmitting();
  };
  const handleDelete = () => {
    if (formStatus.isSubmitting || formStatus.isDeleting) {
      return;
    }
    startDeleting();
  };

  return [formStatus, handleSubmit, handleDelete] as const;
}

export type ValidationResult = {
  isValid: boolean;
  message: ErrorLog;
};

export function useFormSubmitDelete<FormErrorsType extends FormErrorsGeneralType, DataType>(
  defaultFormErrorsValues: FormErrorsType,
  validateSubmitData: () => boolean,
  validateDeleteData: () => boolean,
  sendSubmitData: (doIfSuccess: () => void, doAlways: () => void) => void,
  sendDeleteData: (doIfSuccess: () => void, doAlways: () => void) => void,
  getUpdatedFormErrors: (data: DataType | undefined) => FormErrorsType
) {
  const [
    formErrors,
    setFormErrors,
    resetFormErrorsToNull,
    formErrorsHasNoError,
  ] = useFormErrors<FormErrorsType>(defaultFormErrorsValues);

  const _sendSubmitData = (doAlways: () => void) => {
    sendSubmitData(() => {
      resetFormErrorsToNull();
    }, doAlways);
  };
  const _sendDeleteData = (doAlways: () => void) => {
    sendDeleteData(() => {
      resetFormErrorsToNull();
    }, doAlways);
  };
  const [formStatus, _handleSubmit, _handleDelete] = useFormSubmitDeleteSimple(validateSubmitData, validateDeleteData, _sendSubmitData, _sendDeleteData);

  const submitIsPossible =
    !formStatus.isSubmitting && !formStatus.isDeleting && formErrorsHasNoError() && validateSubmitData();
  
  const deleteIsPossible =
    !formStatus.isSubmitting && !formStatus.isDeleting && formErrorsHasNoError() && validateDeleteData();

  const updateFormErrors = (data: DataType | undefined = undefined) => {
      setFormErrors(getUpdatedFormErrors(data));
  }
  const handleSubmit = () => {
    updateFormErrors();
    _handleSubmit();
  }
  const handleDelete = () => {
    updateFormErrors();
    _handleDelete();
  }
  return [
    formStatus,
    formErrors,
    handleSubmit,
    handleDelete,
    submitIsPossible,
    deleteIsPossible,
    updateFormErrors
  ] as const;
}
