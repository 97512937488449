import React, { useEffect, useState } from "react";
import { Offcanvas, Stack } from "react-bootstrap";
import { useSidebar } from "../../contexts/SidebarContext/SidebarContext";
// import sidebarItems from "../../data/sidebarInfo.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faGear,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import apiUrls from "../../data/api.json";
import "./Sidebar.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import alertMessages from "../../data/alertMessages.json";
import { useApp } from "../../contexts/AppContext";
import { useLogout } from "../../hooks/useLogout";
import { AuthRoles, getAuthRoleIndex } from "../../utils/AuthRoles";
import AuthRoleSelect from "../AuthRoleSelect";
import { detectRoleByPath, getApiLinksForRole, getIndexApiLinkForRole } from "../../utils/defaultPathForRoles";
import CookiesMenager from "../../utils/cookiesMenager";

export function Sidebar() {
  const { auth, roleIsAuthorized } = useAuth();
  const { showAlert } = useApp();
  const { isOpen, closeSidebar, sidebarLinks } = useSidebar();
  const navigate = useNavigate();
  const logout = useLogout();
  
  const viewedRole = (() => {
    let role = detectRoleByPath(window.location.pathname);
    return role && roleIsAuthorized(role) ? role : undefined
  })();
  const viewedRoleApiAuthLinks = viewedRole ? getApiLinksForRole(viewedRole) : undefined;
  
  const settingsLink = viewedRoleApiAuthLinks?.children.settings; 
  // roleIsAuthorized(AuthRoles.member)
  //   ? apiUrls.account.children.member.children.settings
  //   : roleIsAuthorized(AuthRoles.admin)
  //   ? apiUrls.account.children.admin.children.settings
  //   : undefined;

  // const helpLink = undefined;
  const helpLink = viewedRoleApiAuthLinks?.children.help;
  //  roleIsAuthorized(AuthRoles.member)
  //   ? apiUrls.account.children.member.children.help
  //   : roleIsAuthorized(AuthRoles.admin)
  //   ? apiUrls.account.children.admin.children.help
  //   : undefined;
  const [mainPaddingTop, setMainPaddingTop] = useState<number>(60);
  useEffect(()=>{
    if(isOpen){
      if(window.scrollY < 60) {
        setMainPaddingTop(60- window.scrollY);
      } else {
        setMainPaddingTop(0);
      }
    }
  }, [isOpen]);

  return (
    <Offcanvas
      show={isOpen}
      onHide={closeSidebar}
      className="Sidebar shadow-sm"
      style={{paddingTop: mainPaddingTop + "px"}}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="d-flex flex-column">
        <div className="flex-grow-1 content mb-4">
          {sidebarLinks.categories.map((category) => (
            <div className="mb-3" key={category.id}>
              <Offcanvas.Title className="small text-uppercase mb-1 category ps-3">
                {category.name.toUpperCase()}
              </Offcanvas.Title>

              {category.links.map((link) => (
                <NavLink
                  key={link.id}
                  to={link.url}
                  end={true}
                  className={({ isActive, isPending }) => {
                    return "no-st-lk" + (isActive ? " active" : "");
                  }}
                >
                  <Offcanvas.Title
                    className="fs-6 text-capitalize ps-5 link"
                    onClick={closeSidebar}
                  >
                    {link.name}
                  </Offcanvas.Title>
                </NavLink>
              ))}
            </div>
          ))}
        </div>
        {auth && (
          <div className="footer">
            {auth.roles.length > 1 &&  (<div>
            <AuthRoleSelect roles={auth.roles} 
            value={viewedRole
            //   (()=>{
            //   let role= detectRoleByPath(window.location.pathname);
            //   // console.log(window.location.pathname, detectRoleByPath(window.location.pathname), role && roleIsAuthorized(role) ? role : undefined);
            //   return role && roleIsAuthorized(role) ? role : undefined
            // })()
          } 
            onSelect={(role) => {
                navigate({ pathname: ((p) => p ? p : apiUrls.index)(getIndexApiLinkForRole(role))}, 
                  // { replace: true }
                  );
                CookiesMenager.setCookie("lastr", getAuthRoleIndex(role).toString(), {...CookiesMenager.defaultOptions, expires: 30});
            }}/>
            </div>)}
            {settingsLink && (
              <div className="">
                {/* <a
                  onClick={() =>
                    navigate(
                      settingsLink
                      // { replace: true }
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faGear} />
                  </span>
                  <span>Settings</span>
                </a> */}
                <NavLink
                  to={settingsLink}
                  className={({ isActive, isPending }) => {
                    return "w-100 no-st-lk" + (isActive ? " active" : "");
                  }}
                >
                  <div className="link ps-3" onClick={closeSidebar}>
                    <div className="px-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faGear} />
                    </span>
                    <span>Settings</span>

                    </div>
                  </div>
                </NavLink>
              </div>
            )}
            {helpLink && (
              <div className="">
                <NavLink
                  to={helpLink}
                  className={({ isActive, isPending }) => {
                    return "w-100 no-st-lk" + (isActive ? " active" : "");
                  }}
                >
                  <div className="link ps-3" onClick={closeSidebar}>
                  <div className="px-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                    <span>Help</span>
                    </div>
                  </div>
                </NavLink>
              </div>
            )}
            <div className="mb-3">
              <a
                onClick={logout}
                style={{ cursor: "pointer" }}
                className="w-100 no-st-lk"
              >
                <div className="link ps-3" onClick={closeSidebar}>
                <div className="px-2">
                  <span className="me-2">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </span>
                  <span>Log out</span>
                  </div>
                </div>
              </a>
            </div>
            <div className="small">
              <span className="small lighter">Logged in as: </span>
              <span className="small">{auth.firstName + ' ' + auth.lastName}</span>
            </div>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
