import { useEffect, useState } from "react";
import "./Clock.scss";
import { TimeHMS } from "../../utils/TimeTypes";
import { DateTime } from "../../utils";
import { Formatter } from "../../utils/Formatter";

type ClockProps = {
  variant?: "slim" | "wide";
  className?: string;
};
export function Clock({ variant = "wide", className = "" }: ClockProps) {
  const city = "Warsaw";
  const [time, setTime] = useState<DateTime>(new DateTime());

  useEffect(() => {
    const clockTimeout = setTimeout(() => {
      setTime(new DateTime());
    }, 1000);
  }, [time]);

  // return (
  //   <div className="clock_wrapper">
  //     <div id="clock">
  //       <div className="info_container">
  //         <div>
  //           <p id="date" className="date">{Formatter.formatDate(time)}</p>
  //         </div>
  //         <div>
  //           <p className="text">{city}</p>
  //         </div>
  //       </div>
  //       <div className="time_container">
  //         <p id="time" className="time">{Formatter.formatTime(time)}</p>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <div className={"Clock " + variant + " " + className}>
      <div className="wrapper">
        <div className="info date">{Formatter.formatDate(time)}</div>
        {variant === "slim" && (
          <div className="clock-time">{Formatter.formatTime(time)}</div>
        )}
        <div className="info city">{city}</div>
      </div>
      {variant === "wide" && (
        <div className="clock-time">{Formatter.formatTime(time)}</div>
      )}
    </div>
  );
}
