import { useEffect } from "react";
import { useUsers } from "../../../../contexts/UsersContext";
import { AuthRoles } from "../../../../utils/AuthRoles";
import { User } from "../../../../utils/Users";
import { useUrlParam } from "../../../../hooks/useUrlParams";

type UseUrlParamMemberReturnVal = {
  member: User;
  setMember: (newUser: User) => void;
};
export function useUrlParamMember(
  pathTemplate: string
): UseUrlParamMemberReturnVal {
  const { allUsersByRole } = useUsers();
  const urlParamName = "userId";
  const defaultValue = allUsersByRole(AuthRoles.member)[0];
  const parseUrlParam = (urlParam: string): User => {
    const member = allUsersByRole(AuthRoles.member).find(
      (member) => member.id === urlParam
    );
    if (!member) {
      throw new Error();
    }
    return member;
  };
  const formatUrlParam = (member: User): string => {
    return member.id;
  };
  const validateValue = (user: User): boolean => {
    return user.roles.includes(AuthRoles.member);
  };
  const areEqual = (member1: User, member2: User): boolean => {
    return member1.id === member2.id;
  };
  const [member, setMember] = useUrlParam(
    pathTemplate,
    urlParamName,
    defaultValue,
    parseUrlParam,
    formatUrlParam,
    validateValue,
    areEqual
  );

  return { member, setMember };
}
