import { Dropdown } from "react-bootstrap";
import { User } from "../../../utils/Users";
import alertMessages from "../../../data/alertMessages.json";
import { useApp } from "../../../contexts/AppContext";
import "./UserSelector.scss";

type UserSelectorProps = {
  value: User;
  users: User[];
  onSelect?: (user: User) => void;
  variant?: "select" | "dropdown";
  toggleMaxWidth?: string;
  disabled?: boolean;
  readonly?: boolean;
};
export function UserSelector({
  value,
  users,
  onSelect,
  variant = "select",
  toggleMaxWidth = "unset",
  disabled = false,
  readonly = false,
}: UserSelectorProps) {
  // console.log(users.length)
  const { showAlert } = useApp();
  const selectedUser = value;
  const handleSelect = (userId: string | null) => {
    if (!userId) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const findUserById = (userId: string): User | null | undefined => {
      if (!users) {
        showAlert(alertMessages.somethingWentWrong);
        return undefined;
      }
      const user = users.filter((user) => user.id == userId);
      if (!user || user.length < 1) {
        showAlert(alertMessages.somethingWentWrong);
        return null;
      }
      return user[0];
    };

    const user = findUserById(userId);
    if (!user) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    onSelect && onSelect(user);
  };
  return (
    <div className={"UserSelector " + variant}>
      <Dropdown
        drop={variant == "select" ? "down" : "down-centered"}
        className={`h-100`}
        onSelect={handleSelect}
      >
        <Dropdown.Toggle
          id={`dropdown-task-selector`}
          variant="light"
          style={{ maxWidth: toggleMaxWidth, whiteSpace: "unset" }}
          disabled={disabled || readonly}
        >
          {selectedUser && <div>{selectedUser.lastName + ' ' + selectedUser.firstName}</div>}
        </Dropdown.Toggle>
        <Dropdown.Menu className={variant == "select" ? "w-100" : ""}>
          {users.map((user) => (
            <Dropdown.Item eventKey={user.id} key={user.id}>
              <div className="ps-2">{user.lastName + ' ' + user.firstName}</div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
