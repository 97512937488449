export enum DayStatus {
    editable = "editable",
    submitted = "submitted",
    completed = "completed"
}

export function renderDayStatus(dayStatus: DayStatus): string {
    switch (dayStatus) {
        case DayStatus.completed: 
            return "approved";
        case DayStatus.submitted:
            return "submitted";
        case DayStatus.editable:
            return "draft";
        default:
            throw Error();
    }
}
