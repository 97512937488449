import React, { useEffect } from 'react';
import './App.scss';
import RoutingManager from './RoutingManager';
import AppProvider, { useApp } from './contexts/AppContext';
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';
import Test from './pages/Test';
import AuthProvider from './contexts/AuthContext';

function App() {
  return (
    <AppProvider>
      <AuthProvider>
      {/* <ErrorBoundary fallbackRender={ErrorBoundaryFallback} onError={(error: Error, info: { componentStack: string })=>{console.error(error);}}> */}
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <RoutingManager />
        {/* <Test/> */}
      </ErrorBoundary>
      </AuthProvider>
    </AppProvider>
  )
};

export default App;
