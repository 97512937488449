import "./TimeMinutesInHours.scss";

type TimeMinutesInHoursProps = {
  totalMinutes: number;
  className?: string;
  inParens?: boolean;
  larger?: boolean;
};
export function TimeMinutesInHours({
  totalMinutes,
  className = "",
  inParens = false,
  larger = false
}: TimeMinutesInHoursProps) {
  return (
    <div
      className={
        "TimeMinutesInHours d-inline" +
        (inParens ? " in-parens" : "") +
        (larger ? " larger" : "") + 
        " " + className
      }
    >
      <span className="parens">(</span>
      <span className="number">{totalMinutes / 60}</span>
      <span className="tit">h</span>
      <span className="parens">)</span>
    </div>
  );
}
