import { Nav } from "react-bootstrap";
import { useSidebar } from "../../contexts/SidebarContext";
import _ from "lodash";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "./TabBar.scss";

export type TabBarLinksType = {
  id: string;
  name: string;
  shortName?: string;
  url: string;
  tab: {
    id: string;
    name?: string;
    main?: boolean;
    icon?: IconDefinition;
  };
}[];

type TabBarProps = {
  className?:string;
  variant?: "slim" | "big"
}
export function TabBar({className = "", variant="big"}: TabBarProps) {
  const { sidebarLinks } = useSidebar();
  const tabLinks: TabBarLinksType = _.orderBy(
    sidebarLinks.categories.flatMap((category) =>
      category.links.filter((link) => "tab" in link)
    ) as TabBarLinksType,
    "tab.id",
    "asc"
  );
  const mainTabLink = tabLinks.find((tabLink) => tabLink.tab.main);
  return (
    <>
      {tabLinks && (
        <div className={"TabBar w-100 " + variant + " " + className }>
          <Nav
            fill
            justify
            className="Tabs"
            variant="underline"
            defaultActiveKey={mainTabLink ? mainTabLink.url : tabLinks[0].url}
            onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
          >
            {tabLinks.map((tabLink) => (
              <Nav.Item key={tabLink.id}>
                <NavLink
                  key={tabLink.id}
                  to={tabLink.url}
                  end={true}
                  className={({ isActive, isPending }) => {
                    return (
                      "nav-link" +
                      (tabLink.tab.main ? " main" : "") +
                      (isActive ? " active" : "")
                    );
                  }}
                >
                  {tabLink.tab.icon ? (<FontAwesomeIcon icon={tabLink.tab.icon} size="lg" />) : tabLink.tab.name ? tabLink.tab.name : tabLink.shortName ? tabLink.shortName : tabLink.name }
                </NavLink>
                {/* <Nav.Link href={tabLink.url} className={tabLink.tab.main ? "main": ""}>{tabLink.tab.name ? tabLink.tab.name : tabLink.name}</Nav.Link> */}
              </Nav.Item>
            ))}
          </Nav>
        </div>
      )}
    </>
  );

  //     {tabLinks && (
  //
  //     )}
  //   );
}
