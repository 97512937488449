import "./InputPassword.scss"
import { Form } from "react-bootstrap";
type InputPasswordProps = {
    value?: string,
    defaultValue?: string,
    onChange?: (value: string) => void;
    onInput?: () => void;
    isInvalid?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    maxLength?: number;
    placeholder?: string;
    className?: string;
    id?: string;
}
export function InputPassword({value, defaultValue, onChange, onInput, isInvalid, readOnly, disabled, maxLength, placeholder="", className="", id=undefined}: InputPasswordProps){
    return (
        <Form.Control className={`InputPassword ${className}`} type="password" value={value} defaultValue={defaultValue} maxLength={maxLength} onChange={onChange ? (e) => onChange(e.target.value): undefined} onInput={onInput} isInvalid={isInvalid} readOnly={readOnly} disabled={disabled} placeholder={placeholder} id={id} spellCheck={false}/>
    )
}