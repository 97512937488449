import TooltipButton from "../../../../../components/TooltipButton";
import { usePendingMembersRequests } from "../../context/PendingMembersRequestsProvider";
import "./Header.scss";

export function Header() {
  const { pendingRequests, resolveRequests } = usePendingMembersRequests();
  return (
    <div className="Header d-flex">
      <div className="d-none d-sm-block">Members' Requests</div>
      <div className="d-flex justify-content-end flex-fill">
        <div style={{ marginRight: "0px" }}>
          <TooltipButton
            tooltipContent="Grant All Changes"
            buttonVariant="outline-primary"
            size="sm"
            className="mx-1 px-1"
            style={{ fontWeight: "bold", marginRight: "-8px" }}
            onClick={() => {
              const requests = pendingRequests
                .flatMap((month) => month.members)
                .flatMap((member) => member.requests)
                .map((request) => request.id);
              resolveRequests(requests, true);
            }}
          >
            Grant All
          </TooltipButton>
        </div>
        <div style={{ marginRight: "0px" }}>
          <TooltipButton
            tooltipContent="Reject All Changes"
            buttonVariant="outline-primary"
            size="sm"
            className="mx-1 px-1"
            style={{ fontWeight: "bold", marginRight: "-8px" }}
            onClick={() => {
              const requests = pendingRequests
                .flatMap((month) => month.members)
                .flatMap((member) => member.requests)
                .map((request) => request.id);
              resolveRequests(requests, true);
            }}
          >
            Reject All
          </TooltipButton>
        </div>
      </div>
    </div>
  );
}
