import {
  ReactNode,
} from "react";
import { AuthRoles } from "../../utils/AuthRoles";
import { useAuth } from "../AuthContext";
import { Auth } from "../AuthContext/AuthContext";
import { createContext } from "../../hooks/useCreateContext";

type RoleAuthContextVal = {
  auth: Auth;
};
const [RoleAuthContext, useRoleAuth] =
  createContext<RoleAuthContextVal>();
export { useRoleAuth };

type RoleAuthProviderProps = {
  children: ReactNode;
  role: AuthRoles;
};
export function RoleAuthProvider({ children, role }: RoleAuthProviderProps) {
  const { auth, roleIsAuthorized } = useAuth();
  return (
    <>
      {auth && roleIsAuthorized(role) && (
        <RoleAuthContext.Provider value={{ auth }}>
          {children}
        </RoleAuthContext.Provider>
      )}
    </>
  );
}
