import { useApp } from "../contexts/AppContext";
import alertMessages from "../data/alertMessages.json";
import { fetchPost } from "../utils";
import { useNavigate } from "react-router";
import apiUrls from "../data/api.json";
import { useAuth } from "../contexts/AuthContext";
import { fetchers } from "../serverApi/fetchers";
import CookiesMenager from "../utils/cookiesMenager";

export function useLogout(): () => void {
  const { auth, setAuth } = useAuth();
  const { showAlert } = useApp();
  const navigate = useNavigate();

  const logout = () => {
    if (!auth) {
      navigate(apiUrls.login, 
        // { replace: true }
        );
      return;
    }
    fetchers.general.logout.fetch({}, {
      success: () => {
        setAuth(undefined);
        CookiesMenager.clearAllSiteCookies()
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      }
    })
    // const endpointLogout = serverApi.logout;
    // const dataToSend = {};
    // const doIfSuccess = () => {
    //   setAuth(undefined);
    // };
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // const doAlways = undefined;
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchPost<any>(endpointLogout, dataToSend, {
    //   success: doIfSuccess,
    //   fail: doIfFailed,
    //   always: doAlways,
    //   error: doIfError,
    // });
  };
  return logout;
}
