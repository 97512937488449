import React, { useEffect, useState } from "react";
// import "./TimeCount.scss";
import { DateTime } from "../../../../../../../utils";
// import { calcElapsedTime } from "../../../../../../../hooks/useTimer";
// import { useTimerContext } from "../../../../../contexts/TimerContext";
// import { useApp } from "../../../contexts/AppContext";
// import { Formatter2 } from "../../../DayRecordsTable/utils/Formatter2";
// import { ElapsedTimeType } from "../../../../hooks/useTimer";
export function calcElapsedTime(startedAt: DateTime, ended_at: DateTime) {
  var elapsedTime = {
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
    update: function (
      days: number,
      hours: number,
      minutes: number,
      seconds: number
    ) {
      this.days = "" + days;
      this.hours = hours < 10 ? "0" + hours : "" + hours;
      this.minutes = minutes < 10 ? "0" + minutes : "" + minutes;
      this.seconds = seconds < 10 ? "0" + seconds : "" + seconds;
    },
  };

  var now = ended_at.date.getTime(); //Date.now();
  if (now < startedAt.date.getTime()) {
    // throw new Error();

    return {
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
    };
  }
  var duration = now - startedAt.date.getTime();

  var seconds = Math.floor((duration / 1000) % 60);
  var minutes = Math.floor((duration / (1000 * 60)) % 60);
  var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  var days = Math.floor(duration / (1000 * 60 * 60 * 24));
  elapsedTime.update(
    (days = days),
    (hours = hours),
    (minutes = minutes),
    (seconds = seconds)
  );

  return {
    days: elapsedTime.days,
    hours: elapsedTime.hours,
    minutes: elapsedTime.minutes,
    seconds: elapsedTime.seconds,
  };
}

type TimeCountProps = {
  started_at: DateTime;
  date: DateTime;
  style?: React.CSSProperties;
};
export function TimeCount({ started_at, date, style }: TimeCountProps) {
  const elapsedTime = calcElapsedTime(started_at, date);

  return (
    <div className="TimeCount" style={{ height: "65%", fontSize: "1.1rem" }}>
      {elapsedTime.days != "0" && (
        <span className="days">
          +{elapsedTime.days}
          <span className="day-sign">d</span>
        </span>
      )}
      <span>
        {elapsedTime.hours}:{elapsedTime.minutes}:{elapsedTime.seconds}
      </span>
    </div>
  );
}
