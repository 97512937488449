export const logError = (errorName: string, errorText: string, onlyDev: boolean | undefined = true) => {
  const condition = onlyDev ? (process.env.NODE_ENV == "development") : true;
  if (condition) {
    console.log("%c " + errorName + ": ", "color: #B81104;", errorText);
  }
};

export const logResponseError = (errorText: string, onlyDev: boolean | undefined = true) => {
    logError("Response Error", errorText, onlyDev);
}

export const logValidationError = (errorText: string, onlyDev: boolean | undefined = true) => {
  logError("Validation Error", errorText, onlyDev);
}