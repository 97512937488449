import _ from "lodash";
import { DateTime } from "./DateTime";

export class Formatter {
  static addZeroInFront(num: number): string {
    return num < 10 ? "0" + num : num.toString();
  }
  static formatMonth(dateTime: DateTime, addYear: boolean=true): string {
    // const twoDigits = Formatter.addZeroInFront;
    return ( addYear 
      ? dateTime.getLocMonthName() +
      ", " +
      dateTime.getLocYear()
      : dateTime.getLocMonthName()
    );
  }
  static formatDate(dateTime: DateTime): string {
    const twoDigits = Formatter.addZeroInFront;
    return (
      twoDigits(dateTime.getLocDay()) +
      "." +
      twoDigits(dateTime.getLocMonth()) +
      "." +
      dateTime.getLocYear()
    );
  }
  static formatTime(dateTime: DateTime): string {
    const twoDigits = Formatter.addZeroInFront;
    return (
      twoDigits(dateTime.getLocHours()) +
      ":" +
      twoDigits(dateTime.getLocMinutes()) +
      ":" +
      twoDigits(dateTime.getLocSeconds())
    );
  }
  static formatPeriod(startedAt: DateTime, endedAt: DateTime) {
    const formatTime = Formatter.formatTime;
    var start = formatTime(startedAt);
    var end = formatTime(endedAt);
    return start + "-" + end;
  }

  static formatDuration(durationMin: number) {
    var hours = _.floor(_.divide(durationMin, 60));
    var minutes = durationMin % 60;
    return hours + "h" + " " + minutes + "m";
  }
  static formatDurationRaw(durationMin: number) {
    var hours = _.floor(_.divide(durationMin, 60));
    var minutes = durationMin % 60;
    return [hours.toString(), minutes.toString()];
  }

  static formatDurationInHoursRaw(durationMin: number) {
    var hours = _.divide(durationMin, 60);
    return hours;
  }
  static formatDurationInHours(durationMin: number) {
    return Formatter.formatDurationInHoursRaw(durationMin) + "h";
  }
}
