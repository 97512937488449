import { generatePath, useNavigate, useParams } from "react-router";
import { useUsers } from "../../../contexts/UsersContext";
import apiUrls from "../../../data/api.json";
import { AuthRoles } from "../../../utils/AuthRoles";
import { useEffect, useRef } from "react";
import { User } from "../../../utils/Users";
import { DateTime } from "../../../utils";
import MemberMonthTimeRecordTable from "../../../widgets/admin/MemberMonthTimeRecordTable";
import { useQueryMonth } from "./hooks/useQueryMonth";
import { useQueryParams } from "../../../hooks/useQuery";
import { useUrlParamMember } from "./hooks/useUrlParamMember";
import usePageTitle from "../../../hooks/usePageTitle";

type MemberMonthRecordsProps = {
  member: User;
  setMember: (newMember: User) => void;
};

export function MemberMonthRecords() {
  const pageTitle = { full: "Member's Monthly Records", short: "Month" };
  usePageTitle(pageTitle);
 
  const { member, setMember } = useUrlParamMember(
    apiUrls.account.children.admin.children.memberMonth
  );

  const { month, setMonth } = useQueryMonth({
    minMonth: member.created.getStartOfMonth(),
    maxMonth: new DateTime().getEndOfMonth(),
  });

  return (
    <>
      <MemberMonthTimeRecordTable
        member={member}
        setMember={setMember}
        month={month}
        setMonth={setMonth}
      />
    </>
  );
}
