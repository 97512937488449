import { useEffect, useState } from "react";
import { useMonthlyTimesheetsContext } from "../../context/MonthlyTimesheetsContext";
import { Formatter } from "../../../../../utils/Formatter";
import { Button, Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./ExportModal.scss";

export function ExportModal() {
  const { month, selectedRecords } = useMonthlyTimesheetsContext();
  const genTimeInfoText = () => {
    let recordsTimeInfo = "";
    selectedRecords.forEach((record) => {
      recordsTimeInfo +=
        record.member.firstName + ' ' + record.member.lastName +
        " - " +
        (record.month
          ? Formatter.formatDurationInHoursRaw(
              record.month.documentedMonthTotalMinutes
            )
          : "no data") +
        " godz." +
        "\n";
    });
    return recordsTimeInfo;
  };
  const [timeInfoText, setTimeInfoText] = useState<string>(genTimeInfoText());

  useEffect(() => {
    setTimeInfoText(genTimeInfoText());
  }, [month, selectedRecords]);
  return (
    <>
      <Button
        variant="light"
        size="sm"
        className="copy-btn ms-3"
        style={{ position: "absolute" }}
        onClick={() => {
          navigator.clipboard.writeText(timeInfoText);
          const copyBtn = document.querySelector(".card .copy-btn");
          const checkMark = document.querySelector(
            ".card .copy-btn .copy-check-mark"
          );
          copyBtn?.classList.add("highlighted");
          checkMark?.classList.add("quick-show");
          setTimeout(() => {
            copyBtn?.classList.remove("highlighted");
            checkMark?.classList.remove("quick-show");
          }, 1200);
        }}
      >
        Copy to clipboard
        <span className="copy-check-mark">
          <FontAwesomeIcon icon={faCheck} className="ms-1" size="xs" />
        </span>
      </Button>
      <Container fluid className="p-4">
        <div className="p-4 border" style={{ borderRadius: "5px" }}>
          <div className="ps-2 pt-2" style={{ whiteSpace: "pre-wrap" }}>
            {timeInfoText}
          </div>
        </div>
      </Container>
    </>
  );
}
