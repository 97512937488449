import "./TimerContent.scss";
import {
  Button,
  Card as CardBS,
  CloseButton,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import Player from "../Player";
import TimeCount from "../TimeCount";
import { useTimerContext } from "../../../../../contexts/TimerContext";
import TaskSelector from "../../../../../components/TaskSelector";
import { useApp } from "../../../../../contexts/AppContext";
import { Formatter2 } from "../../../DayRecordsTable/utils/Formatter2";

export function TimerContent() {
  const {
    isLocked,
    isDisabled,
    allTasks,
    findTaskById,
    selectedTask,
    setSelectedTask,
    timerStatus,
  } = useTimerContext();
  // return (
  //   <Container className="h-100 TimerContent">
  //     <Row className="h-100">
  //       <Player disabled={isDisabled} />
  //       <Col xs={8} className="h-100">
  //         <Row className="h-100" style={{ textAlign: "center", position: "relative" }}>
  //           {selectedTask && (
  //             <TaskSelector
  //               tasks={allTasks}
  //               value={selectedTask}
  //               onSelect={(selectedTask) => {
  //                 setSelectedTask(selectedTask);
  //               }}
  //               variant="dropdown"
  //               toggleMaxWidth={"70%"}
  //               disabled={isDisabled}
  //             ></TaskSelector>
  //           )}
  //           <TimeCount />
  //           {timerStatus && (
  //             <div className="started" style={{ height: "auto", fontSize: "0.65em", position: "absolute", bottom:"-10px" }}>
  //               {"Started: " + Formatter2.formatDate(timerStatus.getStartedAt()) + " " + Formatter2.formatTime(timerStatus.getStartedAt())}
  //             </div>
  //           )}
  //         </Row>
  //       </Col>
  //     </Row>
  //   </Container>
  // );

  // console.log(isDisabled)

  // You need editing rights for today to use the&nbsp;timer.
  return (
    <Container className="h-100 TimerContent d-flex align-items-center">
      <Player disabled={isDisabled} locked={isLocked} />
      {/* <div
        className="d-flex flex-column flex-fill"
        style={{ textAlign: "center", position: "relative" }}
      ></div> */}
      <div
        className="d-flex flex-column flex-fill ms-3"
        style={{ textAlign: "center", position: "relative" }}
      >
        {isLocked && !timerStatus && (
          <div className="mx-4 h-100 disabled-info d-flex flex-column align-items-center justify-content-center">
              {/* The timer is currently disabled. */}
              <div className="additional-info small">
                Current day is already submitted.
              </div>
          </div>
        )}
        {(!isLocked || !!timerStatus) && (
          <>
            {selectedTask && (
              <TaskSelector
                tasks={allTasks}
                value={selectedTask}
                onSelect={(selectedTask) => {
                  setSelectedTask(selectedTask);
                }}
                variant="dropdown"
                // toggleMaxWidth={"70%"}
                disabled={isDisabled}
                readonly={!!timerStatus}
              ></TaskSelector>
            )}
            {!selectedTask && (
              <>No Tasks Available</>
            )}
            <TimeCount />
            {timerStatus && (
              <div
                className="started"
                style={{
                  height: "auto",
                  fontSize: "0.65em",
                  // position: "absolute",
                  // bottom: "-20px",
                }}
              >
                {"Started at: " +
                  Formatter2.formatDate(timerStatus.getStartedAt()) +
                  " " +
                  Formatter2.formatTime(timerStatus.getStartedAt())}
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
}
