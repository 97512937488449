import _ from "lodash";
import { useState } from "react";

type Set<ItemType> = {
  items: readonly ItemType[];
  includes: (item: ItemType) => boolean;
  add: (item: ItemType) => void;
  remove: (item: ItemType) => void;
};
export function useSetCollection<ItemType>(
  defaultItems: ItemType[],
  isEqual: (item1: ItemType, item2: ItemType) => boolean = _.isEqual
): Set<ItemType> {
  if (
    isEqual === undefined ||
    _.uniqWith(defaultItems, isEqual).length !== defaultItems.length
  ) {
    throw new Error();
  }
  const [collection, setCollection] = useState<ItemType[]>(defaultItems);
  const includes = (item: ItemType): boolean =>
    !!collection.find((collectionItem) => isEqual(collectionItem, item));
  const add = (item: ItemType): void => {
    if (!includes(item)) {
      setCollection([...collection, item]);
    }
  };
  const remove = (item: ItemType): void => {
    if (includes(item)) {
      setCollection(collection.filter((collectionItem) => !isEqual(collectionItem, item)));
    }
  };
  const set: Set<ItemType> = {
    items: collection,
    includes: includes,
    add: add,
    remove: remove,
  };
  return set;
}
