import { generatePath, useLocation, useNavigate, useParams } from "react-router";
import apiUrls from "../data/api.json";
import { useEffect, useState } from "react";
import { useQueryParams } from "./useQuery";


const parseUrlParamsFromPath = (pathTemplate: string, path: string) => {
  let pathTemplateSegments = pathTemplate.split("/");
  let pathSegments = path.split("/");
  if (pathTemplateSegments.length !== pathSegments.length) {
      throw new Error();
  }
  const urlParams: { [k: string]: string } = {};
  for (let i = 0; i < pathTemplateSegments.length; i++) {
    if (pathTemplateSegments[i] !== pathSegments[i]) {
      if (!pathTemplateSegments[i].startsWith(":")) {
          throw new Error();
      }
      urlParams[pathTemplateSegments[i].slice(1)] = pathSegments[i];
    }
  }
  return urlParams;
};
const changedUrlParams = (pathTemplate: string, path: string, urlParamName: string, urlParamNewValue: string) => {
  const urlParams = parseUrlParamsFromPath(pathTemplate, path);
  if(!Object.keys(urlParams).includes(urlParamName)) {
      throw new Error();
  }
  const newUrlParams = {...urlParams};
  newUrlParams[urlParamName] = urlParamNewValue;
  return newUrlParams;
}

type UseParamParamReturn<T> = [
  value: T,
  setUrlParam: (value: T) => void
];
export function useUrlParam<T>(
  pathTemplate: string,
  urlParamName: string,
  defaultValue: T,
  parseUrlParam: (urlParam: string) => T,
  formatUrlParam: (value: T) => string,
  validateValue: (value: T) => boolean,
  areEqual: (value1: T, value2: T) => boolean,
): UseParamParamReturn<T> {
   const hash = (() => {
    const match = window.location.href.match(/#([^?]+)/);
    if (match) {
      return match[1];
    } else {
      return "";
    }
  })();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useQueryParams();

  const tryGetUrlParamValue = (): T | null => {
    if (!Object.keys(params).includes(urlParamName)) {
      return null;
    }
    const urlParam = params[urlParamName];
    if (!urlParam) {
      return null;
    }
    try {
      return parseUrlParam(urlParam);
    } catch (error) {
      return null;
    }
  };

  const getUrlParamValue = () => {
    const urlParam = tryGetUrlParamValue();
    if (urlParam) {
      return urlParam;
    }
    navigate(apiUrls.lost, { replace: true });
    return null;
  };

  const loadVal = () => {
    const loadedVal = getUrlParamValue();
    if (loadedVal) {
        return loadedVal
    }
    navigate(apiUrls.lost, { replace: true });
    return defaultValue;
  };
  const [value, setValue] = useState<T>(loadVal());

  const setUrlParam = (newValue: T) => {
    if ((!value || !areEqual(value, newValue)) && validateValue(newValue)) {
      setValue(newValue);
    }
  };


  useEffect(() => {
    if (value) {
      navigate(
        {
          pathname: generatePath(
            pathTemplate,
            changedUrlParams(pathTemplate, location.pathname, urlParamName, formatUrlParam(value))
          ),
          hash: hash,
          search: queryParams.toString(),
        },
        { replace: true }
      );
    }
  }, [value]);

  return [value, setUrlParam];
}
