import TimerContent from "./components/TimerContent";
import "./Timer.scss";
import WidgetCard from "../../../components/WidgetCard";

// export function Timer() {

//   return (
//     <Card className="Timer">
//       <CardBS.Body>
//         <TimerContent/>
//       </CardBS.Body>
//     </Card>
//   );
// }

export function Timer() {
  return (
    <WidgetCard className="Timer">
      {/* <WidgetCard.Header>
          <div className="AccountSettingsHeader w-100 d-flex justify-content-center align-items-center border-bottom mb-1">
          <div className="title">Worktime Recorder</div>
        </div>
      </WidgetCard.Header> */}
      <WidgetCard.Body>
        <TimerContent />
      </WidgetCard.Body>
    </WidgetCard>
  );
}
