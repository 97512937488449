import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom"

const Layout = () => {
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      if (location.pathname !== '/' && location.pathname.slice(-1)[0] === '/') {
        navigate(`${location.pathname.slice(0, -1)}${location.search}${location.hash}`, {state: location.state, replace: true});
      }
    }, [location]);
    return (
        <Outlet />
    )
}

export default Layout