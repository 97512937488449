import { AuthRoles, isAuthRole } from "./AuthRoles"
import apiUrls from "../data/api.json";

export const detectRoleByPath = (path: string): AuthRoles | null => {
    const roles = Object.values(AuthRoles);
    for (let role of roles) {
        const roleIndexPath = getIndexApiLinkForRole(role);
        if(roleIndexPath && path.startsWith(roleIndexPath) && isAuthRole(role)){
            return role;
        }
    }
    return null;
}

export const getApiLinksForRole = (role: AuthRoles) => {
    return apiUrls.account.children ? apiUrls.account.children[role] : undefined
}

export const getIndexApiLinkForRole = (role: AuthRoles) => {
    const apiLinks = getApiLinksForRole(role)
    return apiLinks ? apiLinks.route : undefined;
}