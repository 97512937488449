import { useEffect, useRef } from "react";
import "./DayModal.scss";
import { useMonthRecordsTable } from "../../context/MonthRecordsTableContext";
import MemberDayTimeRecordTable from "../../../MemberDayTimeRecordTable";

export function DayModal() {
  const ref = useRef<null | HTMLDivElement>(null);
  const {month, modalStatus, member,
    setMember, changeModalDate} = useMonthRecordsTable();
  useEffect(() => {
    if (modalStatus.show) {
      ref.current?.parentElement?.parentElement?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [modalStatus]);
  return (
    <div className="DayModal" ref={ref}>
      {modalStatus.status && <MemberDayTimeRecordTable member={member} setMember={setMember} date={modalStatus.status?.date} setDate={changeModalDate} onlyMonth={month} embeded={true} />}
    </div>
  );
}
