import { Col, Container, Row } from "react-bootstrap";
import Base from "../../components/pageBases/Base";
import ForgotPasswordForm from "../../widgets/general/ForgotPasswordForm";
import "./ForgotPassword.scss";
import { useAuth } from "../../contexts/AuthContext";
import { Navigate, useLocation } from "react-router";
import apiUrls from "../../data/api.json";


export function ForgotPassword() {
  const { auth } = useAuth();
  const location = useLocation();

  return (
    <>
      {!auth && (
        <Base>
          <div className="ForgotPassword">
            <Container fluid="sm" className="Container">
              <Row className="h-100 d-flex justify-content-center align-items-start form-container">
                <Col>
                  {/* <Row className="d-flex justify-content-center align-items-end logo-continer">
                  <Col>
                    <Image src={logo} className="logo w-100" />
                  </Col>
                </Row> */}
                  <ForgotPasswordForm />
                </Col>
              </Row>
            </Container>
          </div>
        </Base>
      )}
      {!!auth && (
        <Navigate
          to={apiUrls.login}
          state={{ from: location }}
          replace
        />
      )}
    </>
  );
}
