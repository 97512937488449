import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useApp } from "../../../contexts/AppContext";
import { DayStatus, renderDayStatus } from "../../../utils/DayStatus";


type DayStatusDropdown = {
  status: DayStatus;
  onChange: (status: DayStatus) => void;
  variant?: "small" | "normal";
  className?: string;
  disabled?: boolean;
};

export function DayStatusDropdown({
  status,
  onChange,
  variant = "normal",
  className,
  disabled = false,
}: DayStatusDropdown) {
  const handleChange = (strStatus: string) => {
    if (!Object.values(DayStatus).includes(strStatus as DayStatus)) {
      throw new Error();
    }
    onChange(strStatus as DayStatus);
  };

  return (
    <DropdownButton
      title={renderDayStatus(status)}
      as={ButtonGroup}
      size="sm"
      variant="outline-secondary"
      className={`DayStatusDropdown ${variant} ${className}  ${status}`}
      drop="down-centered"
      onSelect={(evk: string | null) => evk && handleChange(evk)}
      disabled={status == DayStatus.editable}
    >
      {/* {status == DayStatus.editable && (
        <Dropdown.Item eventKey={DayStatus.submitted}>submit</Dropdown.Item>
      )} */}
      {status == DayStatus.submitted && (
        <>
          {/* <Dropdown.Item eventKey={DayStatus.completed}>approved</Dropdown.Item> */}
          <Dropdown.Item eventKey={DayStatus.completed}>
            approve
          </Dropdown.Item>
          <Dropdown.Item eventKey={DayStatus.editable}>
            return edition
          </Dropdown.Item>
        </>
      )}
      {status == DayStatus.completed && (
        <>
        <Dropdown.Item eventKey={DayStatus.submitted}>
          return submission
        </Dropdown.Item>
        <Dropdown.Item eventKey={DayStatus.editable}>
            return edition
        </Dropdown.Item>
        </>
      )}
    </DropdownButton>
  );
}
