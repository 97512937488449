import { Card } from "react-bootstrap";
import { useUsersList } from "../../context/UsersListContext";
import { UserModalModes } from "../../hooks/useUserModal";
import { ModalUserCreation } from "./components/ModalUserCreation/ModalUserCreation";
import ModalUserEdition from "./components/ModalUserEdition";
import { useEffect, useRef } from "react";
import { usePrevious } from "../../../../../hooks/usePreviousValue";
import { ModalDeletedUsers } from "./components/ModalDeletedUsers/ModalDeletedUsers";

export function Modal() {
  const ref = useRef<null | HTMLDivElement>(null);
  const { modalStatus } = useUsersList();
  useEffect(() => {
    if (modalStatus.show) {
      // console.log("Hell")
      ref.current?.parentElement?.parentElement?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [modalStatus]);
  // const prevModalStatus = usePrevious(modalStatus)

  return (
    <Card.Body ref={ref}>
      {modalStatus.mode === UserModalModes.UserCreation && (
        <ModalUserCreation defaultRoles={modalStatus.status.roles} defaultFirstName={modalStatus.status.firstName} defaultLastName={modalStatus.status.lastName} defaultEmail={modalStatus.status.email} />
      )}
      {modalStatus.mode === UserModalModes.UserEdition && (
        <ModalUserEdition defaultUser={modalStatus.status.user} />
      )}
      {modalStatus.mode === UserModalModes.DeletedUsers && (
        <ModalDeletedUsers/>
      )}
    </Card.Body>
  );
}
