import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  faList
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, To } from "react-router-dom";

export function ViewSummaryButton({ summaryLink }: { summaryLink: To }){
    return (
        <NavLink
        to={summaryLink}
        className={({ isActive, isPending }) => {
          return "ViewSummaryButton d-inline-block w-100 no-st-lk" + (isActive ? " active" : "");
        }}
      >
        <Button variant="outline-primary">
        <FontAwesomeIcon icon={faList} size="xs" 
        // flip="horizontal"
        />
      </Button>
      </NavLink>
    )
}