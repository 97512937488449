import "./RoleUsersList.scss";
import { AuthRoles } from "../../../../../utils/AuthRoles";
import List from "../../../../../components/List";
import User from "../User";
import { Formatter2 } from "../../../../member/DayRecordsTable/utils/Formatter2";
import { useSetCollection } from "../../../../../hooks/useSetCollection";
import { ListOfAccordions } from "../../../../../components/ListOfAccordions/ListOfAccordions";
import { useUsersList } from "../../context/UsersListContext";
import { User as UserObj } from "../../../../../utils/Users";
import { User as UserType } from "../../../../../utils/Users";

type RoleUsersListProps = {
  allUsersByRole: (role: AuthRoles) => UserObj[];
  role: AuthRoles;
};
export function RoleUsersList({ role, allUsersByRole }: RoleUsersListProps) {
  const {disabledUsers} = useUsersList();
  const activeKeysSet = useSetCollection<string>([]);
  const userInfo = (user: UserType): { label: string; value: string }[] => {
    return [
      { label: "user id", value: user.id },
      { label: "email", value: user.email },
      { label: "created", value: Formatter2.formatDate(user.created) },
      { label: "updated", value: Formatter2.formatDate(user.updated) },
    ];
  };
  return (
    <div className="RoleUsersList">
      <ListOfAccordions
        list={allUsersByRole(role)}
        accordionHeader={(user, active) => (
          <>
            <User
              user={user}
              active={active}
              className={(active ? "active" : "") + (!!disabledUsers.find(disabledUser => disabledUser.id === user.id) ? " disabled" : "")}
              available={true}
            />
          </>
        )}
        accordionBody={(user) => (
          <List className="UserInfo">
            {userInfo(user).map((info) => (
              <List.Item key={info.label}>
                <div className="list-row-styled d-flex">
                  <div className="label me-2 d-inline">{info.label}:</div> <div className="value d-inline">{info.value}</div>
                </div>
              </List.Item>
            ))}
          </List>
        )}
      />
    </div>
  );
}
