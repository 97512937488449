import { Tabs } from "../../../../../components/TabsAdv";
import "./RoleTabs.scss";
import { AuthRoles } from "../../../../../utils/AuthRoles";
import _ from "lodash";
import RoleUsersList from "../RoleUsersList";
import TooltipButton from "../../../../../components/TooltipButton";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { RoleTabs as RS } from "./RoleTabs_";
import { useEffect, useRef, useState } from "react";
import AuthRoleSelect from "../../../../../components/AuthRoleSelect";
import TaskSelector from "../../../../../components/TaskSelector";
import { useUsersList } from "../../context/UsersListContext";
import { useApp } from "../../../../../contexts/AppContext";
import alertMessages from "../../../../../data/alertMessages.json";
import { User } from "../../../../../utils/Users";

type RoleTabsProps = {
  allUsersByRole: (role: AuthRoles) => User[];
  createUser?: (role?: AuthRoles | undefined) => void | undefined;
}
export function RoleTabs({allUsersByRole, createUser}: RoleTabsProps) {
  const { showAlert } = useApp();
  const {lastOpenedTab, setLastOpenedTab} = useUsersList();
  const authRoles = Object.values(AuthRoles);
  const selectedRole = useRef<AuthRoles>(lastOpenedTab ? lastOpenedTab : authRoles[0]);
  const handleSelect = (role: string | null) => {
    if (!role || !Object.values(AuthRoles).includes(role as AuthRoles)) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    selectedRole.current = role as AuthRoles
    setLastOpenedTab(selectedRole.current);
  };
  return (
    <div className="RoleTabs position-relative">
      {authRoles.length > 0 && (
        <Tabs  defaultActiveKey={selectedRole.current} onSelect={handleSelect}>
          <Tabs.Nav className={`justify-content-between `} variant={"tabs"}>
            {/* <div className="nav-tabs-wrapper"> */}
            <Col className={`d-none d-sm-flex nav-tabs-wrapper`}>
              {authRoles.map((authRole) => (
                <Tabs.Nav.Tab key={authRole} eventKey={authRole}>
                  {_.capitalize(authRole + "s")}
                </Tabs.Nav.Tab>
              ))}
            </Col>
            <Col className=" d-sm-none pb-1">
              <AuthRoleSelect />
            </Col>
            {createUser && (
              <Col
              xs={3}
              className="ps-1 d-flex justify-content-end align-items-center"
              style={{ width: "100px" }}
            >
              <div>
                <TooltipButton
                  tooltipContent="Create New User"
                  buttonVariant="outline-primary"
                  className="create list-row-btn mx-1 px-1  end-0"
                  size="sm"
                  style={{ fontWeight: "bold", marginRight: "-8px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    createUser(selectedRole.current);
                  }}
                >
                  New User
                </TooltipButton>
              </div>
            </Col>
            )}
            
          </Tabs.Nav>
          <Tabs.Content>
            {authRoles.map((authRole) => (
              <Tabs.Content.Tab key={authRole} eventKey={authRole}>
                <RoleUsersList role={authRole} allUsersByRole={allUsersByRole}/>
              </Tabs.Content.Tab>
            ))}
          </Tabs.Content>
        </Tabs>
      )}
    </div>
  );
}
