import {
  useQuerySearchParamNoUpdate,
} from "../../../hooks/useQuerySearchParam";

type UseQueryTokenReturnVal = {
  token: string | undefined;
};
export function useQueryToken(): UseQueryTokenReturnVal {
  const queryTokenName = "code";
  const parseQueryToken = (token: string) => token;

  const [token] = useQuerySearchParamNoUpdate<string | undefined>(
    queryTokenName,
    undefined,
    parseQueryToken
  );

  return { token } as const;
}
