export enum MonthStatus {
    editable = "editable",
    submitted = "submitted",
    completed = "completed"
}

export function renderMonthStatus(dayStatus: MonthStatus): string {
    switch (dayStatus) {
        case MonthStatus.completed: 
            return "approved";
        case MonthStatus.submitted:
            return "submitted";
        case MonthStatus.editable:
            return "draft";
        default:
            throw Error();
    }
}
