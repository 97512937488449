import { ReactNode, useEffect, useState } from "react";
import { createContext } from "../../hooks/useCreateContext";
import { usePWAInstall } from "../../hooks/usePWAInstall";

type AlertMessageStatus = {
    alertMessage: string | undefined;
    success: boolean | undefined;
  }
type AppContextVal = {
  alertMessageStatus: AlertMessageStatus;
  showAlert: (message: string, success?: boolean) => void;
  hideAlert: () => void;
  supportsPWA: boolean;
  triggerPWAInstall: () => Promise<void>;
};
const [AppContext, useApp] = createContext<AppContextVal>();
export { useApp };

type AppProviderProps = {
  children: ReactNode;
};
export function AppProvider({ children }: AppProviderProps) {
  const {supportsPWA, triggerPWAInstall} = usePWAInstall();

  const [alertMessageStatus, setAlertMessageStatus] = useState<AlertMessageStatus>(
    {
        alertMessage: undefined,
        success: undefined,
      }
  );
  const showAlert = (message: string, success: boolean = false) => {
    // alert("wait |" + alertMessageStatus.alertMessage + " === " +  message + ' > ' + (alertMessageStatus.alertMessage === message).toString() + '|, |' + success + " === " + alertMessageStatus.success + "> " + (success === alertMessageStatus.success).toString())
    if (alertMessageStatus.alertMessage === message || success === alertMessageStatus.success) {
      return;
    }
    setAlertMessageStatus({alertMessage: message, success: success});
  };
  const hideAlert = () => {
    setAlertMessageStatus({...alertMessageStatus, alertMessage: undefined, success: undefined});
  };

  return (
    <AppContext.Provider value={{ alertMessageStatus, showAlert, hideAlert, supportsPWA, triggerPWAInstall }}>
      {children}
    </AppContext.Provider>
  );
}
