import _ from "lodash";
import { DateTimeFormatter } from "./DateTimeFormatter";
import {
  Day,
  Hours,
  Minutes,
  Month,
  Seconds,
  Weekday,
  Year,
  isDay,
  isHours,
  isMinutes,
  isMonth,
  isSeconds,
  isYear,
} from "./TimeTypes";

export class DateTime {
  date: Date;

  constructor(date?: Date | string) {
    if (typeof date === "string") {
      let parsedDate = Date.parse(date);
      if (isNaN(parsedDate)) {
        throw new Error();
      }
      this.date = new Date(parsedDate);
      return;
    }
    this.date = date ? new Date(date.getTime()) : new Date();
  }
  static createLocDateTime(
    locYear: Year,
    locMonth: Month | undefined = 1,
    locDay: Day | undefined = 1,
    locHour: Hours | undefined = 0,
    locMinute: Minutes | undefined = 0,
    locSecond: Seconds | undefined = 0
  ): DateTime {
    const dateTime = new DateTime();
    dateTime.setLocDateTime(
      locYear,
      locMonth,
      locDay,
      locHour,
      locMinute,
      locSecond
    );
    return dateTime;
  }
  clone(): DateTime {
    return new DateTime(this.date);
  }
  toJSON() {
    return this.date.toISOString();
  }
  toString() {
    const year = this.getLocYear().toString()
    const month = this.getLocMonth().toString().padStart(2, "0");
    const day = this.getLocDay().toString().padStart(2, "0");
    const hour = this.getLocHours().toString().padStart(2, "0");
    const minute = this.getLocMinutes().toString().padStart(2, "0");
    const second = this.getLocSeconds().toString().padStart(2, "0");
    return day + "." + month + "." + year + " " + hour + ":" + minute + ":" + second;
    // return this.date.toISOString();
  }
  toLocIsoString() {
    const year = this.getLocYear().toString()
    const month = this.getLocMonth().toString().padStart(2, "0");
    const day = this.getLocDay().toString().padStart(2, "0");
    const hour = this.getLocHours().toString().padStart(2, "0");
    const minute = this.getLocMinutes().toString().padStart(2, "0");
    const second = this.getLocSeconds().toString().padStart(2, "0");
    return year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second;
    // return this.date.toISOString();
  }

  toLocIsoShortString() {
    const year = this.getLocYear().toString()
    const month = this.getLocMonth().toString().padStart(2, "0");
    const day = this.getLocDay().toString().padStart(2, "0");
    const hour = this.getLocHours().toString().padStart(2, "0");
    const minute = this.getLocMinutes().toString().padStart(2, "0");
    const second = this.getLocSeconds().toString().padStart(2, "0");
    return year + month + day + "T" + hour +  minute + second;
    // return this.date.toISOString();
  }
  getLocHours(): Hours {
    return DateTimeFormatter.getWarsawHour(this.date);
  }
  getLocMinutes(): Minutes {
    return DateTimeFormatter.getWarsawMinute(this.date);
  }
  getLocSeconds(): Seconds {
    return DateTimeFormatter.getWarsawSecond(this.date);
  }
  getLocDay(): Day {
    return DateTimeFormatter.getWarsawDay(this.date);
  }
  getLocMonth(): Month {
    return DateTimeFormatter.getWarsawMonth(this.date);
  }
  getLocMonthName(): string {
    return DateTimeFormatter.getWarsawMonthName(this.date);
  }
  getLocYear(): Year {
    return DateTimeFormatter.getWarsawYear(this.date);
  }
  getLocWeekday(): Weekday {
    return DateTimeFormatter.getWarsawWeekday(this.date);
  }
  
  setLocHours(locHour: Hours): void {
    const utcHour = DateTimeFormatter.convertWarsawHourToUTCHour(
      this.date,
      locHour
    );
    this.date.setUTCHours(utcHour);
  }
  setLocMinutes(locMinute: Minutes): void {
    const utcMinute = DateTimeFormatter.convertWarsawMinuteToUTCMinute(
      this.date,
      locMinute
    );
    this.date.setUTCMinutes(utcMinute);
  }
  setLocSeconds(locSecond: Seconds): void {
    const utcSecond = DateTimeFormatter.convertWarsawSecondToUTCSecond(
      this.date,
      locSecond
    );
    this.date.setUTCSeconds(utcSecond);
  }
  setLocDay(locDay: Day): void {
    const utcDay = DateTimeFormatter.convertWarsawDayToUTCDay(
      this.date,
      locDay
    );
    this.date.setUTCDate(utcDay);
  }
  setLocMonth(locMonth: Month): void {
    const utcMonth = DateTimeFormatter.convertWarsawMonthToUTCMonth(
      this.date,
      locMonth
    );
    this.date.setUTCMonth(utcMonth - 1);
  }
  setLocYear(locYear: Year): void {
    const utcYear = DateTimeFormatter.convertWarsawYearToUTCYear(
      this.date,
      locYear
    );
    const utcYearInt = parseInt(utcYear);
    if (isNaN(utcYearInt)) throw new Error();
    this.date.setUTCFullYear(utcYearInt);
  }

  setLocDateTime(
    locYear: Year,
    locMonth: Month,
    locDay: Day,
    locHour: Hours,
    locMinute: Minutes,
    locSecond: Seconds
  ): DateTime {
    this.setLocYear(locYear);
    this.setLocMonth(locMonth);
    this.setLocDay(locDay);
    this.setLocHours(locHour);
    this.setLocMinutes(locMinute);
    this.setLocSeconds(locSecond);
    this.zeroLocMilliseconds();
    return this;
  }

  zeroLocMilliseconds(): void {
    this.date.setUTCMilliseconds(0);
  }

  getUTCHours(): Hours {
    var hoursUTC = this.date.getUTCHours();
    if (!isHours(hoursUTC)) throw new Error();
    return hoursUTC;
  }
  getUTCMinutes(): Minutes {
    var minutesUTC = this.date.getUTCMinutes();
    if (!isMinutes(minutesUTC)) throw new Error();
    return minutesUTC;
  }
  getUTCSeconds(): Seconds {
    var secondsUTC = this.date.getUTCSeconds();
    if (!isSeconds(secondsUTC)) throw new Error();
    return secondsUTC;
  }
  getUTCDay(): Day {
    var dayUTC = this.date.getUTCDate();
    if (!isDay(dayUTC)) throw new Error();
    return dayUTC;
  }
  getUTCMonth(): Month {
    var monyhUTC = this.date.getUTCMonth();
    if (!isMonth(monyhUTC)) throw new Error();
    return monyhUTC;
  }
  getUTCYear(): Year {
    var yearUTC = this.date.getUTCFullYear().toString();
    if (!isYear(yearUTC)) throw new Error();
    return yearUTC;
  }

  lessThan(dateTime: DateTime): boolean {
    return this.date < dateTime.date;
  }
  graterThan(dateTime: DateTime): boolean {
    return this.date > dateTime.date;
  }
  equalTo(dateTime: DateTime): boolean {
    return this.date.getTime() == dateTime.date.getTime();
  }
  notEqualTo(dateTime: DateTime): boolean {
    return this.date.getTime() != dateTime.date.getTime();
  }
  lessThanOrEqual(dateTime: DateTime): boolean {
    return this.date <= dateTime.date;
  }
  graterThanOrEqual(dateTime: DateTime): boolean {
    return this.date >= dateTime.date;
  }

  substractInSec(dateTime: DateTime): number {
    return _.divide(
      _.subtract(this.date.getTime(), dateTime.date.getTime()),
      1000
    );
  }

  dateIsTheSameAs(dateTime: DateTime): boolean {
    return (
      this.getLocDay() === dateTime.getLocDay() &&
      this.getLocMonth() === dateTime.getLocMonth() &&
      this.getLocYear() === dateTime.getLocYear()
    );
  }
  addDays_old(days: number): DateTime {
    // counts from start of the month not date ??
    return new DateTime(new Date(new Date(this.date).setDate(days+1)))
  }
  addDays(days: number): DateTime {
    return new DateTime(new Date(new Date(this.date).setDate(this.date.getDate() + days)))
  }
  

  getStartOfMonth(): DateTime {
    return DateTime.createLocDateTime(
      this.getLocYear(),
      this.getLocMonth()
    )
  }
  getStartOfNextMonth(): DateTime {
    const nextMonth = this.getLocMonth() %12 + 1 as Month;
    return DateTime.createLocDateTime(
      nextMonth ==1 ? (parseInt(this.getLocYear())+1).toString() as Year : this.getLocYear(),
      nextMonth
      )
    }
  getStartOfPrevMonth(): DateTime {
    const getPrevMonth = (month: Month): Month =>  12 - ((13-month)%12) as Month;
    const prevMonth = getPrevMonth(this.getLocMonth());
    return DateTime.createLocDateTime(
      prevMonth ==12 ? (parseInt(this.getLocYear())-1).toString() as Year :  this.getLocYear(),
      prevMonth
      )
    }
  getEndOfMonth(): DateTime {
    return this.getStartOfNextMonth().addDays(-1)
  }

  getStartOfYear(): DateTime {
    return DateTime.createLocDateTime(
      this.getLocYear()
    )
  }
  getStartOfNextYear(): DateTime {
    const nextYear = (parseInt(this.getLocYear())+1).toString();
    if (!isYear(nextYear)){
      throw new Error();
    }
    return DateTime.createLocDateTime(
      nextYear
    );
    }
  getStartOfPrevYear(): DateTime {
    const prevYear = (parseInt(this.getLocYear())-1).toString();
    return DateTime.createLocDateTime(
      prevYear as Year
      );
    }
  getEndOfYear(): DateTime {
    return this.getStartOfNextYear().addDays(-1)
  }

  getDate(): DateTime {
    return DateTime.createLocDateTime(this.getLocYear(), this.getLocMonth(), this.getLocDay());
  }
}
