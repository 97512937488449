import { Col, Row } from "react-bootstrap";
import { useTable } from "../../context/TableContext/TableContext";
import { ReactNode } from "react";
import "./Head.scss"

type HeadProps = {
    children: ReactNode,
    className?: string,
    style?: React.CSSProperties
}
export function Head({children, className, style}: HeadProps) {
    const tmp = useTable();
    return (
        <div className={`Head ${className ? className : ""}`} style={style}>
            {children}
        </div>
    )
}