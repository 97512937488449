import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useTasksList } from "../../context/TasksListContext";
import Table from "../../../../../components/Table";
import Accordion from "../../../../../components/Accordion";
import "./CategoriesList.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import TooltipButton from "../../../../../components/TooltipButton";
import List from "../../../../../components/List";
import Category from "./components/Category/Category";
import CategoryTasks from "./components/CategoryTasks/CategoryTasks";
import { useSetCollection } from "../../../../../hooks/useSetCollection";
import { ListOfAccordions } from "../../../../../components/ListOfAccordions/ListOfAccordions";

export function CategoriesList() {
  const {
    allTasks,
    openTaskCreationModal,
    openTaskEditionModal,
    openCategoryEditionModal,
  } = useTasksList();
  const activeKeysSet = useSetCollection<string>([]);
  return (
    <div className="CategoriesList">
      <ListOfAccordions
        list={allTasks.getSortedByCategory().categories}
        accordionHeader={(category, active) => (
          <Category
            category={category}
            className={`${active ? "active" : ""} ${
              category.isPublic ? "public" : ""
            }`}
          />
        )}
        accordionBody={(category) => <CategoryTasks category={category} />}
      />
    </div>
  );
}
