import { ReactNode, useState } from "react";
import { createContext } from "../../../../hooks/useCreateContext";

type TableContextVal = {};
const [TableContext, useTable] =
createContext<TableContextVal>();
export { useTable };

type TableProviderProps = {
  children: ReactNode;
};

export function TableProvider({ children}: TableProviderProps) {
    return (
      <TableContext.Provider value={{}}>
        {children}
      </TableContext.Provider>
    );
}
