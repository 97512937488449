import { useApp } from "../../../../../contexts/AppContext";
import { useMonthRecordsTable } from "../../context/MonthRecordsTableContext";
import Table from "../../../../../components/Table";
import { Col, Row } from "react-bootstrap";
import { Formatter } from "../../../../../utils/Formatter";
import { roundMinutesToHours } from "../../../../../utils/TimeMathFormules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import TooltipButton from "../../../../../components/TooltipButton";
import DayStatusDropdown from "../../../../../components/admin/DayStatusDropdown";
import DayRequestedStatusDropdown from "../../../../../components/admin/DayRequestedStatusDropdown";
import { DayStatus } from "../../../../../utils/DayStatus";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import { DateTime } from "../../../../../utils";
import "./DaysTable.scss";

export function DaysTable() {
  const { showAlert } = useApp();
  const {
    monthRecordedData,
    selectedDay,
    setSelectedDay,
    changeDayStatus,
    openDayTimeRecordsModal,
    rejectChangeStatusRequest,
  } = useMonthRecordsTable();

  return (
    <div className="MemberMonthTimeRecordTable">
      <Table>
        <Table.Head>
          <Row className="justify-content-between">
            <Col xs={2} sm={3} className="text-center align-self-center date">
              Day
            </Col>
            <Col xs={6} sm={5} className="text-center align-self-center hours">
              <span className="d-none d-sm-inline">Working </span>Time
            </Col>
            {/* <Col xs={1} className="text-end"></Col> */}
            <Col
              xs={4}
              sm={4}
              className="text-center d-sm-block align-self-center status"
            >
              Status
            </Col>
            {/* <Col xs={3} className="text-center">Action</Col> */}
          </Row>
        </Table.Head>
        <Table.Body>
          {monthRecordedData.monthDaysInfo.map((day) => (
            <Row
              className={`justify-content-between align-items-center ${
                selectedDay && selectedDay.date.date === day.date.date
                  ? "active"
                  : ""
              } ${
                day.date.equalTo(
                  DateTime.createLocDateTime(
                    new DateTime().getLocYear(),
                    new DateTime().getLocMonth(),
                    new DateTime().getLocDay()
                  )
                )
                  ? "today"
                  : ""
              }`}
              key={day.date.date.getTime()}
              onClick={() =>
                selectedDay === day ? setSelectedDay(null) : setSelectedDay(day)
              }
              id={day.date.getLocDay().toString()}
            >
              {/* Date */}
              <Col xs={2} sm={3} className="text-center date">
                <div className="d-sm-none small-screan">
                  <span className="full">
                    {day.date.getLocDay().toString().padStart(2, "0")}
                    <span className="month">
                      {"." + day.date.getLocMonth().toString().padStart(2, "0")}
                    </span>
                  </span>
                  <span className="short">{day.date.getLocDay()}</span>
                </div>
                <div className="d-none d-sm-block large-screan">
                  {Formatter.formatDate(day.date)}
                </div>
              </Col>

              {/* Working Time */}
              <Col xs={6} sm={4} className="text-center d-flex justify-content-center hours">
                  <div className="text-center" style={{ minWidth: "70px" }}>
                  <TimeHoursMinutes
                    totalMinutes={day.totalMinutes}
                    lightLetters={true}
                  />
                </div>
                <div className="ps-0 ps-md-2 more">
                  <TooltipButton
                    tooltipContent="Details"
                    buttonVariant="light"
                    className="table-row-btn openDay"
                    onClick={() => openDayTimeRecordsModal(day.date)}
                    disabled={day.isDisabled}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </TooltipButton>
                </div>
              </Col>
              {/* <Col
                      xs={1}
                      className="text-center"
                      onClick={() => openModal(day)}
                    >
                      <Button variant="light" className="openDay">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                    </Col> */}
              <Col xs={4} sm={4} className="text-center d-sm-block status">
                {/* {day.status} */}
                {/* <Tooltip tooltipContent="Change status" className="openDay"> */}
                {day.requestedStatus === null && (
                  <span>
                    <DayStatusDropdown
                      status={day.status}
                      onChange={(newStatus) => {
                        changeDayStatus(day.date, day.status, newStatus);
                      }}
                      disabled={day.isDisabled}
                    />
                   
                  </span>
                )}
                {day.requestedStatus !== null && (
                  <div>
                    <DayRequestedStatusDropdown
                      currentStatus={day.status}
                      requestedStatus={day.requestedStatus}
                      onReject={() => {
                        rejectChangeStatusRequest(day.date);
                      }}
                      onApprove={() => {
                        changeDayStatus(
                          day.date,
                          day.status,
                          day.requestedStatus as DayStatus
                        );
                      }}
                    />
                  </div>
                )}
                {/* </Tooltip> */}
              </Col>
              {/* <Col xs={3} className="text-center">
                      <DayStatusDropdown status={day.status} onChange={(status) => {console.log(status)}}/>
                    </Col> */}
              {/* <Col
                      xs={1}
                      className="text-end"
                      onClick={() => openModal(day)}
                    >
                      <Button variant="light" className="openDay w-100">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                    </Col> */}
            </Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
