import { AuthRoles, areAuthRoles } from "../../utils/AuthRoles";
import { useApp } from "../../contexts/AppContext";
import alertMessages from "../../data/alertMessages.json";
import React from "react";
import "./AuthRolesSelect.scss";
import MultiSelect from "../MultiSelect";
import { MultiSelectOption } from "../MultiSelect/MultiSelect";
import _ from "lodash";


type AuthRolesSelectProps = {
  value?: AuthRoles[];
  defaultValue?: AuthRoles[];
  onSelect?: (selectedRoles: AuthRoles[]) => void;
  // variant?: "select" | "dropdown";
  toggleMaxWidth?: string;
  disabled?: boolean;
  invalid?: boolean;
};
export function AuthRolesSelect({
  value,
  defaultValue,
  onSelect,
  toggleMaxWidth = "unset",
  disabled = false,
  invalid = false
}: AuthRolesSelectProps) {

  const options_to_roles = (options: MultiSelectOption[]): AuthRoles[] => {
    const roles = options.map((val) => val.key);
    if (!areAuthRoles(roles)) {
      throw new Error();
    }
    return roles
  }

  const rolest_to_options = (roles:  AuthRoles[]): MultiSelectOption[] => {
    return roles.map((role) => ({
      key:  role,
      label: _.capitalize(role),
    }));
  }
  return (
    // <MultiSelect options={Object.values(AuthRoles).map(role => ({key: role, label: role}))} onChange={(value)=> console.log(value)}/>
    <MultiSelect
      options={rolest_to_options(Object.values(AuthRoles))}
      value={value ? rolest_to_options(value) : undefined}
      defaultValue={defaultValue ? rolest_to_options(defaultValue) : undefined}
      onChange={
        onSelect
          ? (value) => onSelect(options_to_roles(value))
          : undefined
      }
      disabled={disabled}
      isInvaid={invalid}
      maxSelected={Object.values(AuthRoles).length}
    />
  );
}
