import { generatePath, useNavigate, useParams } from "react-router";
import { useUsers } from "../../../contexts/UsersContext";
import apiUrls from "../../../data/api.json";
import { AuthRoles } from "../../../utils/AuthRoles";
import { useEffect, useRef } from "react";
import { User } from "../../../utils/Users";
import { useQueryDate } from "./hooks/useQueryDate";
import { DateTime } from "../../../utils";
import MemberDayTimeRecordTable from "../../../widgets/admin/MemberDayTimeRecordTable";
import { useUrlParamMember } from "./hooks/useUrlParamMember";
import usePageTitle from "../../../hooks/usePageTitle";
import { Month } from "../../../utils/TimeTypes";

// type MemberDayRecordsProps = {
//   member: User;
//   setMember: (newMember: User) => void;
// };

export function MemberDayRecords() {
  const pageTitle = {full: "Member's Daily Records", short: "Day"};
  usePageTitle(pageTitle);
  const { member, setMember } = useUrlParamMember(
    apiUrls.account.children.admin.children.memberDay
  );
  const { date, setDate } = useQueryDate({
    minDate: member.created.getStartOfMonth(),
    maxDate: new DateTime().getEndOfMonth(),
  });

  return (
    <MemberDayTimeRecordTable
      member={member}
      setMember={setMember}
      date={date}
      setDate={setDate}
    />
  );
}
