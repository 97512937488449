import { ReactNode } from "react";
import { createContext } from "../../../hooks/useCreateContext";

type GridContextVal = {};
const [GridContext, useGrid] =
createContext<GridContextVal>();
export { useGrid };

type GridProviderProps = {
  children: ReactNode;
  modal?: boolean;
};

export function GridProvider({ children, modal = false }: GridProviderProps) {
  if (modal) {
    return <GridContext.Provider value={{}}>{children}</GridContext.Provider>;
  }
  return <GridContext.Provider value={{}}>{children}</GridContext.Provider>;
}
