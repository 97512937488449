import { DateTime } from "../../utils";
import { default as ReactDatePicker } from "react-datepicker";
import "./DatePicker.scss";
import { LegacyRef, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCaretLeft, faCaretRight, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

type DatePickerProps = {
  date: DateTime;
  setDate?: (newDate: DateTime) => void;
  format?: "yearOnly" | "monthOnly" | "dayMonthYear";
  minDate?: DateTime;
  maxDate?: DateTime;
  excludedDays?: DateTime[];
  leftArrow?: boolean;
  rightArrow?: boolean;
  leftArrowClassName?: string;
  rightArrowClassName?: string;
};

export function DatePicker({
  date,
  setDate,
  format = "dayMonthYear",
  minDate,
  maxDate,
  excludedDays,
  leftArrow = false,
  rightArrow = false,
  leftArrowClassName = "",
  rightArrowClassName = ""
}: DatePickerProps) {
  var showOnlyMonth = false;
  var showOnlyYear = false;
  var dateFormat = "dd.MM.yyyy";
  let prevDate = date.addDays(-1);
  let nextDate = date.addDays(1);
  let dateIsAvailabe = (date: DateTime): boolean => {
    const minDateValid = minDate ? date.graterThanOrEqual(minDate) : true;
    const maxDateValid = maxDate ? date.lessThanOrEqual(maxDate) : true;
    const excludedDaysValid = excludedDays ? !excludedDays.find(day => day.equalTo(date)) : true;
    return minDateValid && maxDateValid && excludedDaysValid;
  }
  switch (format) {
    case "monthOnly":
      dateFormat = "MMMM, yyyy";
      showOnlyMonth = true;
      prevDate = date.getStartOfPrevMonth();
      nextDate = date.getStartOfNextMonth();
      dateIsAvailabe = (date: DateTime): boolean => {
        const minDateValid = minDate ? date.getStartOfMonth().graterThanOrEqual(minDate.getStartOfMonth()) : true;
        const maxDateValid = maxDate ? date.getStartOfMonth().lessThanOrEqual(maxDate.getStartOfMonth()) : true;
        const excludedDaysValid = excludedDays ? !excludedDays.find(day => day.getStartOfMonth().equalTo(date.getStartOfMonth())) : true;
        return minDateValid && maxDateValid && excludedDaysValid;
      }
      break;
    case "yearOnly":
      dateFormat = "yyyy";
      showOnlyYear = true;
      prevDate = date.getStartOfPrevYear();
      nextDate = date.getStartOfNextYear();
      dateIsAvailabe = (date: DateTime): boolean => {
        const minDateValid = minDate ? date.getStartOfYear().graterThanOrEqual(minDate.getStartOfYear()) : true;
        const maxDateValid = maxDate ? date.getStartOfYear().lessThanOrEqual(maxDate.getStartOfYear()) : true;
        const excludedDaysValid = excludedDays ? !excludedDays.find(day => day.getStartOfYear().equalTo(date.getStartOfYear())) : true;
        return minDateValid && maxDateValid && excludedDaysValid;
      }
      break;
  }

  // const  ReactDatePickerSpan = ({ value = "", onClick = undefined }: {value?: string, onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined}) => (
  //   <span onClick={onClick}>
  //     {value}
  //   </span>
  // );
  const ReactDatePickerSpan = forwardRef(
    (
      {
        value = "",
        onClick = undefined,
      }: {
        value?: string;
        onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
      },
      ref: LegacyRef<HTMLButtonElement> | undefined
    ) => (
      <span onClick={onClick} ref={ref}>
        <span>
          {!!setDate && <Button>
        <FontAwesomeIcon icon={faCalendar} size="sm" className="react-datepicker__calendar-icon" />
        </Button>}
        </span>
        <span className="react-datepicker__calendar-text">
          {value}
        </span>
      </span>
    )
  );

  return (
    <div className="DatePicker">
      <div className="d-flex align-items-center DatePicker-container">
      {leftArrow && setDate && (
        <Button variant="outline-light" size="sm" className={"arrow arrow-left " + leftArrowClassName} onClick={() => {setDate(prevDate)}} disabled={!dateIsAvailabe(prevDate)}><FontAwesomeIcon icon={faCaretLeft} size="sm" className="" /></Button>
      )}
      
      <ReactDatePicker
        // showIcon={!!setDate}
        closeOnScroll={true}
        selected={date.date}
        dateFormat={dateFormat}
        readOnly={!setDate}
        onChange={(newDate) => 
          newDate && setDate && setDate(new DateTime(newDate))
        }
        customInput={<ReactDatePickerSpan />}
        showMonthYearPicker={showOnlyMonth}
        showFullMonthYearPicker={showOnlyMonth}
        showYearPicker={showOnlyYear}
        minDate={minDate?.date}
        maxDate={maxDate?.date}
        excludeDates={excludedDays ? excludedDays.map(date => date.date) : undefined}
      />
      {rightArrow && setDate && (
        <Button variant="outline-light" size="sm" className={"arrow arrow-right " + rightArrowClassName} onClick={() => {setDate(nextDate)}} disabled={!dateIsAvailabe(nextDate)}><FontAwesomeIcon icon={faCaretRight} size="sm" className=""/></Button>
      )}
      </div>
    </div>
  );
}
