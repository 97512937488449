import { TaskRecord, TaskRecordGroups } from "../../../../utils/TaskRecord";
import { DateTime, fetchPost } from "../../../../utils";
import alertMessages from "../../../../data/alertMessages.json";
import { fetchers } from "../../../../serverApi/fetchers";

export function moveSelectedTaskToUnverifiedTimerecords(
  date: DateTime,
  selectedRecord: TaskRecord | null,
  refreshDay: () => void,
  showAlert: (message: string) => void
) {
  if (
    !selectedRecord ||
    selectedRecord.group !== TaskRecordGroups.billableTimerecords
  ) {
    return;
  }
  // Move
  // const endpointMoveToUnverifiedTimerecords =
  //   serverApi.moveTaskRecordToUnverifiedTimerecords;
  // const dataToSend = {
  //   date: date.toJSON(),
  //   recordId: selectedRecord.id,
  // };
  // const doIfSuccess = undefined;
  // const doIfFailed = () => {
  //   showAlert(alertMessages.somethingWentWrong);
  // };
  // const doAlways = () => {
  //   refreshDay();
  // };
  // const doIfError = () => {
  //   showAlert(alertMessages.somethingWentWrong);
  // };
  // fetchPost<{}>(endpointMoveToUnverifiedTimerecords, dataToSend, {
  //   success: doIfSuccess,
  //   fail: doIfFailed,
  //   always: doAlways,
  //   error: doIfError,
  // });
  const dataToSend = {
    date: date,
    recordId: selectedRecord.id,
  };
  fetchers.account.member.moveTimerecordToUnverified.fetch(dataToSend, {
    fail:() => {
      showAlert(alertMessages.somethingWentWrong);
    },
    always: () => {
      refreshDay();
    },
    error: () => {
      showAlert(alertMessages.somethingWentWrong);
    }
  })
}
