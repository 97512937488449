import React, { ReactNode } from "react";
import { Container as ContainerBS } from "react-bootstrap";
import "./Content.scss";

type ContentProps = {
  children: ReactNode;
};

export function Content({ children }: ContentProps) {
  return (
    <ContainerBS fluid="lg" className="Content">
      <div className="content-extra-padding px-2 px-md-auto">
      {children}
      </div>
    </ContainerBS>
  );
}
