import { Col, Container, Form, Row } from "react-bootstrap";
// import { TasksSelect } from "../../../../../TasksSelect/TasksSelect";
import {
  TimeInputHMS,
  InputTimeHMS,
} from "../../../../../../../../../components/TimeInput";
import TooltipButton from "../../../../../../../../../components/TooltipButton";
import { Task, Tasks } from "../../../../../../../../../utils/Task";
import {
  TaskRecord,
  TaskRecordGroups,
} from "../../../../../../../../../utils/TaskRecord";
import {
  Seconds,
  Hours,
  Minutes,
  TimeHMS,
} from "../../../../../../../../../utils/TimeTypes";
import { useDayRecords } from "../../../../../../context/DayRecordsContext";
import { useEffect, useRef, useState } from "react";
import "./ModalEditClockedTimeRecord.scss";
import _ from "lodash";
import { DateTime } from "../../../../../../../../../utils";
import alertMessages from "../../../../../../../../../data/alertMessages.json";
import { useApp } from "../../../../../../../../../contexts/AppContext";
import { TaskSelector } from "../../../../../../../../../components/TaskSelector/TaskSelector";
import { logValidationError } from "../../../../../../../../../utils/logError";
// import { useFormErrors } from "../../../../hooks/useFormErrors";
import ValidationFeedback from "../../../../../../../../../components/ValidationFeedback";
import {
  ErrorLog,
  useFormErrors,
} from "../../../../../../../../../hooks/useFormErrors";
import { fetchers } from "../../../../../../../../../serverApi/fetchers";
import { ValidationResult } from "../../../../../../../../../hooks/useFormSubmit";
import { useFormSubmitDelete } from "../../../../../../../../../hooks/useFormSubmitDeleteAdv";
// import ValidationFeedback from "../../../ValidationFeedback";
import restrictions from "../../../../../../../../../data/restrictions.json";

type TimeRecordInfo = {
  id: string;
  startedAt: DateTime | undefined;
  endedAt: DateTime | undefined;
  task: Task;
  group: TaskRecordGroups;
};
type FormInputs = {
  task: Task;
  startedAt: TimeHMS | undefined;
  endedAt: TimeHMS | undefined;
};

type FormErrors = {
  generalError: ErrorLog;
  taskError: ErrorLog;
  periodError: ErrorLog;
};

function extractTimeHMSfromDateTime(dateTime: DateTime) {
  return {
    hours: dateTime.getLocHours(),
    minutes: dateTime.getLocMinutes(),
    seconds: dateTime.getLocSeconds(),
  };
}
function updatedDateTimeByTimeHMS(dateTime: DateTime, time: TimeHMS) {
  const newDateTime = new DateTime(dateTime.date);
  newDateTime.setLocHours(time.hours);
  newDateTime.setLocMinutes(time.minutes);
  newDateTime.setLocSeconds(time.seconds);
  return newDateTime;
}

type ModalEditClockedTimeRecordProps = {
  timeRecord: TaskRecord & {
    period: { startedAt: DateTime; endedAt: DateTime };
  };
  deleteRecord: (doIfSuccess: () => void, doAlways: () => void) => void;
  tasks: Tasks;
};
export function ModalEditClockedTimeRecord({
  timeRecord,
  deleteRecord,
  tasks,
}: ModalEditClockedTimeRecordProps) {
  const { showAlert } = useApp();
  const { date, closeModal, refreshDay, dayTimeRecordsInfo } = useDayRecords();

  const newTimeRecordInfo = useRef<TimeRecordInfo>({
    id: timeRecord.id,
    task: timeRecord.task,
    startedAt: timeRecord.period.startedAt,
    endedAt: timeRecord.period.endedAt,
    group: timeRecord.group,
  });

  const [formInputs, _setFormInputs] = useState<FormInputs>({
    task: timeRecord.task,
    startedAt: extractTimeHMSfromDateTime(timeRecord.period.startedAt),
    endedAt: extractTimeHMSfromDateTime(timeRecord.period.endedAt),
  });
  const ceilMinute = (time: TimeHMS): TimeHMS => {
    let hours = time.hours;
    let minutes = time.minutes;
    if (time.seconds !== 0) {
      minutes += _.ceil(_.divide(time.seconds, 60));
      time.seconds = 0;
    }
    if (minutes >= 60) {
      hours += _.floor(_.divide(minutes, 60)) % 24;
      minutes = (minutes % 60) as Minutes;
    }
    return {
      hours: hours as Hours,
      minutes: minutes as Minutes,
      seconds: 0,
    };
  };
  const floorMinute = (time: TimeHMS): TimeHMS => {
    return { ...time, seconds: 0 };
  };
  const setFormInputs = (newFormInput: FormInputs) => {
    if (
      _.isEqual(
        extractTimeHMSfromDateTime(timeRecord.period.startedAt),
        newFormInput.startedAt
      ) &&
      _.isEqual(
        extractTimeHMSfromDateTime(timeRecord.period.endedAt),
        newFormInput.endedAt
      )
    ) {
      newTimeRecordInfo.current = {
        ...newTimeRecordInfo.current,
        task: newFormInput.task,
        startedAt: timeRecord.period.startedAt,
        endedAt: timeRecord.period.endedAt,
      };
      _setFormInputs(newFormInput);
      return;
    }
    newFormInput.startedAt = newFormInput.startedAt
      ? floorMinute(newFormInput.startedAt)
      : newFormInput.startedAt;
    newFormInput.endedAt = newFormInput.endedAt
      ? ceilMinute(newFormInput.endedAt)
      : newFormInput.endedAt;
    const startedAt =
      newFormInput.startedAt != undefined
        ? updatedDateTimeByTimeHMS(date, newFormInput.startedAt)
        : undefined;
    const endedAt =
      newFormInput.endedAt !== undefined
        ? updatedDateTimeByTimeHMS(date, newFormInput.endedAt)
        : undefined;
    newTimeRecordInfo.current = {
      ...newTimeRecordInfo.current,
      task: newFormInput.task,
      startedAt: startedAt,
      endedAt: endedAt,
    };
    _setFormInputs(newFormInput);
  };

  const timeRecordUnchanged = (timeRecordInfo: TimeRecordInfo) => {
    return _.isEqual(
      [
        timeRecordInfo.task.id,
        timeRecordInfo.startedAt?.date,
        timeRecordInfo.endedAt?.date,
      ],
      [
        timeRecord.task.id,
        timeRecord.period.startedAt.date,
        timeRecord.period.endedAt.date,
      ]
    );
  };

  const overlaps = (
    timeRecordInfo: TimeRecordInfo & {
      startedAt: DateTime;
      endedAt: DateTime;
      group: TaskRecordGroups.billableTimerecords;
    }
  ): boolean => {
    if (
     ( (!timeRecordInfo.startedAt.dateIsTheSameAs(timeRecordInfo.endedAt) && !_.isEqual([timeRecordInfo.endedAt.getLocHours(), timeRecordInfo.endedAt.getLocMinutes(), timeRecordInfo.endedAt.getLocSeconds()], [0,0,0]))) ||
      timeRecordInfo.endedAt.lessThan(timeRecordInfo.startedAt)
    ) {
      // << TO DO
      // showAlert(alertMessages.somethingWentWrong);
      return false;
    }
    const allBillableTimerecordForDate = dayTimeRecordsInfo.timeRecords
      .groupToArray(TaskRecordGroups.billableTimerecords)
      .filter((timeRecord) => timeRecord.period.endedAt !== null);
    if (allBillableTimerecordForDate.length < 1) {
      return false;
    }
    const overlapedTimerRecords = allBillableTimerecordForDate.filter(
      (timeRecord) => {
        if (timeRecord.period.endedAt === null) return false;
        let startedAt = new DateTime(timeRecord.period.startedAt.date);
        // startedAt.setLocSeconds(0);
        let endedAt = new DateTime(timeRecord.period.endedAt.date);
        // endedAt.setLocSeconds(0);
        // console.log(timeRecord.id, startedAt, endedAt,
        //   // before
        //   startedAt.lessThanOrEqual(timeRecordInfo.startedAt),
        //   endedAt.lessThanOrEqual(timeRecordInfo.startedAt),

        //   // after
        //   startedAt.graterThanOrEqual(timeRecordInfo.endedAt),
        //   endedAt.graterThanOrEqual(timeRecordInfo.endedAt)
        // )

        return (
          !(
            startedAt.lessThanOrEqual(timeRecordInfo.startedAt) &&
            endedAt.lessThanOrEqual(timeRecordInfo.startedAt)
          ) &&
          !(
            startedAt.graterThanOrEqual(timeRecordInfo.endedAt) &&
            endedAt.graterThanOrEqual(timeRecordInfo.endedAt)
          )
        );
      }
    );
    // console.log("ov: ", overlapedTimerRecords)

    if (overlapedTimerRecords.length < 1) {
      return false;
    }
    if (
      overlapedTimerRecords.length === 1 &&
      overlapedTimerRecords[0].id === timeRecord.id
    ) {
      return false;
    }
    return true;
  };

  const submitValidator = (
    timeRecordInfo: TimeRecordInfo,
    formErrors: FormErrors
  ) => {
    if (timeRecordUnchanged(timeRecordInfo)) {
      formErrors.generalError = "Time record was not changed.";
      return false;
    }
    // console.log(timeRecordInfo.startedAt, timeRecordInfo.endedAt);
    if (
      timeRecordInfo.startedAt === undefined ||
      timeRecordInfo.endedAt === undefined
    ) {
      formErrors.periodError = "Wrong duration format.";
      return false;
    }
    if (timeRecordInfo.endedAt.lessThan(timeRecordInfo.startedAt)) {
      formErrors.periodError = "Wrong time period format.";
      return false;
    }
    if (!timeRecordInfo.startedAt.dateIsTheSameAs(timeRecordInfo.endedAt) && !_.isEqual([timeRecordInfo.endedAt.getLocHours(), timeRecordInfo.endedAt.getLocMinutes(), timeRecordInfo.endedAt.getLocSeconds()], [0,0,0])) {
      // showAlert(alertMessages.somethingWentWrong); << TO DO
      return false;
    }
    const durationMin = _.ceil(
      _.divide(
        timeRecordInfo.endedAt.substractInSec(timeRecordInfo.startedAt),
        60
      )
    );
    const periodUnchanged = _.isEqual(
      [timeRecordInfo.startedAt.date, timeRecordInfo.endedAt.date],
      [timeRecord.period.startedAt.date, timeRecord.period.endedAt.date]
    );
    if (!periodUnchanged && durationMin < 1) {
      formErrors.periodError = "Time period should be at least 1 minute long.";
      return false;
    }
    if (durationMin > restrictions.impossibleWorkingDurationHours * 60) {
      formErrors.periodError =
        "Time period exceeds " +
        restrictions.impossibleWorkingDurationHours +
        " hours.";
      return false;
    }
    if (timeRecordInfo.group === TaskRecordGroups.billableTimerecords) {
      if (
        durationMin >
        restrictions.maxPossibleWorkingDurationPerDayHours * 60
      ) {
        formErrors.periodError =
          "Time period should bee no longer than " +
          restrictions.maxPossibleWorkingDurationPerDayHours +
          " hours.";
        return false;
      }
      const timeRecordOverlaps = overlaps({
        ...timeRecordInfo,
        startedAt: timeRecordInfo.startedAt,
        endedAt: timeRecordInfo.endedAt,
        group: timeRecordInfo.group,
      });
      if (timeRecordOverlaps) {
        formErrors.generalError =
          "Time record overlaps with the existing time records for this date.";
        return false;
      }
      const expectedTotalMin =
        dayTimeRecordsInfo.timeRecords.sumDurationForGroup(timeRecord.group) -
        timeRecord.durationMin +
        durationMin;
      if (
        expectedTotalMin >
        restrictions.maxPossibleWorkingDurationPerDayHours * 60
      ) {
        formErrors.generalError =
          "Total working duration for the date should ne no longer than " +
          restrictions.maxPossibleWorkingDurationPerDayHours +
          " hours.";
        return false;
      }
    }
    return true;
  };
  const defaultFormErrorsValues: FormErrors = {
    generalError: null,
    periodError: null,
    taskError: null,
  };
  Object.freeze(defaultFormErrorsValues);

  const validateSubmitData = (timeRecordInfo: TimeRecordInfo) => {
    let formErrors = { ...defaultFormErrorsValues };
    const isValid = submitValidator(timeRecordInfo, formErrors);
    return { isValid: isValid, formErrors: formErrors };
  };
  const deleteValidator = (
    timeRecordInfo: TimeRecordInfo,
    formErrors: FormErrors
  ) => {
    const unchanged = timeRecordUnchanged(timeRecordInfo);
    if (!unchanged) {
      formErrors.generalError = "Time record was changed.";
    }
    return unchanged;
  };
  const validateDeleteData = (timeRecordInfo: TimeRecordInfo) => {
    let formErrors = { ...defaultFormErrorsValues };
    const isValid = deleteValidator(timeRecordInfo, formErrors);
    return { isValid: isValid, formErrors: formErrors };
  };

  const sendSubmitData = (
    timeRecordInfo: TimeRecordInfo,
    _doIfSuccess: () => void,
    _doAlways: () => void
  ): void => {
    if (
      timeRecordInfo.startedAt === undefined ||
      timeRecordInfo.endedAt === undefined
    ) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const dataToSend = {
      date: date,
      recordId: timeRecord.id,
      taskId: timeRecordInfo.task.id,
      stratedAt: timeRecordInfo.startedAt,
      endedAt: timeRecordInfo.endedAt,
      group: timeRecord.group,
    };

    fetchers.account.member.editClockedTimerecord.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        refreshDay();
      },
      fail: (data, status) => {
        if (status === 443) {
          showAlert(
            (alertMessages.changesNotSaved,
            alertMessages.timeRecordDurationNoLongerThan.replace(
              "{{maxTimerecordDuration}}",
              restrictions.impossibleWorkingDurationHours + " hours"
            ))
          );
          return;
        }
        if (status === 445) {
          showAlert(
            (alertMessages.changesNotSaved,
            alertMessages.timeRecordDurationIsLongerThan.replace(
              "{{maxTimerecordDuration}}",
              restrictions.impossibleWorkingDurationHours + " hours"
            ))
          );
          return;
        }
        if (status === 446) {
          showAlert(
            (alertMessages.changesNotSaved, alertMessages.timeRecordOverlaps)
          );
          return;
        }
        if (status === 447) {
          showAlert(
            (alertMessages.changesNotSaved,
            alertMessages.totalWorkingDurationIsLongerThan.replace(
              "{{maxTotalWorkingDuration}}",
              restrictions.maxPossibleWorkingDurationPerDayHours + " hours"
            ))
          );
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  const sendDeleteData = (
    timeRecordInfo: TimeRecordInfo,
    _doIfSuccess: () => void,
    _doAlways: () => void
  ) => {
    deleteRecord(_doIfSuccess, _doAlways);
  };

  const [
    formStatus,
    formErrors,
    handleSubmit,
    handleDelete,
    submitIsPossible,
    deleteIsPossible,
  ] = useFormSubmitDelete<FormErrors, TimeRecordInfo>(
    defaultFormErrorsValues,
    newTimeRecordInfo.current,
    validateSubmitData,
    validateDeleteData,
    sendSubmitData,
    sendDeleteData
  );

  const handleTaskChange = (newTask: Task) => {
    const newFormInputs = { ...formInputs, task: newTask };
    setFormInputs(newFormInputs);
  };
  const handleStartedAtChange = (newStartedAt: TimeHMS | undefined) => {
    const newFormInputs = {
      ...formInputs,
      startedAt: newStartedAt,
    };
    setFormInputs(newFormInputs);
  };
  const handleEndedAtChange = (newEndedAt: TimeHMS | undefined) => {
    const newFormInputs = {
      ...formInputs,
      endedAt: newEndedAt,
    };
    setFormInputs(newFormInputs);
  };

  const submitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && submitIsPossible) {
      handleSubmit();
    }
  }
  return (
    <>
      <Container
        className={`w-auto mx-md-3 ModalEditClockedTimeRecord ${
          timeRecord.group === TaskRecordGroups.unverifiedTimerecords
            ? "Unverified"
            : ""
        }`}
        onKeyDown={submitOnEnter}
      >
        <Form.Group className="mb-3">
          <Form.Label>Task:</Form.Label>
          <TaskSelector
            variant="select"
            tasks={tasks}
            value={formInputs.task}
            onSelect={handleTaskChange}
          ></TaskSelector>
          <ValidationFeedback message={formErrors.taskError} />
        </Form.Group>
        <Form.Group className="mb-3 startedAt">
          <Form.Label>Started at:</Form.Label>
          <InputTimeHMS
            defaultValue={formInputs.startedAt}
            secondsReadonly={true}
            onChange={handleStartedAtChange}
            // onInput={()=>{if(!changedOriginal) setChangedOriginal(true)}}
            isInvalid={!!formErrors.periodError}
            allowEmpty={false}
          ></InputTimeHMS>
        </Form.Group>
        <Form.Group className="mb-3 finishedAt">
          <Form.Label>Finished at:</Form.Label>
          <InputTimeHMS
            defaultValue={formInputs.endedAt}
            secondsReadonly={true}
            onChange={handleEndedAtChange}
            // onInput={()=>{if(!changedOriginal) setChangedOriginal(true)}}
            isInvalid={!!formErrors.periodError}
            allowEmpty={false}
          ></InputTimeHMS>
          <ValidationFeedback message={formErrors.periodError} />
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <ValidationFeedback
            message={formErrors.generalError}
            className="me-3"
          />
        </Form.Group>
        <Form.Group as={Row} className="mt-5">
          <Col className="d-flex justify-content-start">
            <TooltipButton
              tooltipContent={`Delete this record`}
              buttonVariant="outline-primary"
              className="px-4 delete"
              onClick={handleDelete}
              disabled={!deleteIsPossible}
            >
              {formStatus.isDeleting ? "Deleting" : "Delete"}
            </TooltipButton>
          </Col>
          <Col className="d-flex justify-content-end">
            <TooltipButton
              tooltipContent={`Save changes`}
              className="px-4 submit"
              onClick={handleSubmit}
              disabled={!submitIsPossible}
            >
              {formStatus.isSubmitting ? "Saving" : "Save"}
            </TooltipButton>
          </Col>
        </Form.Group>
      </Container>
    </>
  );
}
