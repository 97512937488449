import { ReactNode, useState } from "react";
import { createContext } from "../../../hooks/useCreateContext";

type CardContextVal = {};
const [CardContext, useCard] =
createContext<CardContextVal>();
export { useCard };

type CardProviderProps = {
  children: ReactNode;
  modal?: boolean;
};

export function CardProvider({ children, modal = false }: CardProviderProps) {
  if (modal) {
    return (
      // <div className="Marker">
      <CardContext.Provider value={{}}>
        {children}

      </CardContext.Provider>
      // </div>
    );
  }
  return <CardContext.Provider value={{}}>{children}</CardContext.Provider>;
}
