import { createContext as createReactContext, useContext as useReactContext } from "react";

type CreateContextReturn<T> = [React.Context<T | undefined>, () => T];

export function createContext<T>(): CreateContextReturn<T> {
  const Context = createReactContext(undefined as T | undefined);

  const useCurrentContext = (): T => {
    const currentContext = useReactContext(Context);

    if (!currentContext) {
      throw new Error(
        "Child components of the component cannot be rendered outside the parent component!"
      );
    }
    return currentContext;
  };
  return [Context, useCurrentContext];
}
