import { DateTime } from "../../../utils";
import MonthRecordsTable from "../../../widgets/member/MonthRecordsTable";
import { useRoleAuth } from "../../../contexts/RoleAuthContext/RoleAuthContext";
import usePageTitle from "../../../hooks/usePageTitle";
import { useQueryMonth } from "./hooks/useQueryMonth";

export function MonthRecords() {
  const pageTitle = {full: "Monthly Summary", short: "Month"};
  usePageTitle(pageTitle);
  const { auth } = useRoleAuth();
  const { month, setMonth } = useQueryMonth({
    minMonth: auth.created.getStartOfMonth(),
    maxMonth: new DateTime().getEndOfMonth(),
  });


  return (
    <>
      {month && (
        <MonthRecordsTable
          month={month}
          setMonth={setMonth}
        ></MonthRecordsTable>
      )}
    </>
  );
}
