import React, { ReactNode } from "react";
import {
  SidebarLinksType,
  SidebarProvider,
} from "../../../contexts/SidebarContext/SidebarContext";
import "./Base.scss";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";
import Content from "../../Content";
import { ThemeProvider } from "../../../contexts/ThemeContext/ThemeContext";
import { BaseProvider } from "./context/BaseContext";
import Alert from "../../Alert";
import { useApp } from "../../../contexts/AppContext/AppContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "../../ErrorBoundaryFallback";

type BaseProps = {
  children: ReactNode;
  className?: string;
};

function BaseInner({ children, className = "" }: BaseProps) {
  const {alertMessageStatus, hideAlert} = useApp()
  return (
    <div className={`Base h-100 ${className}`}>
      <Alert message={alertMessageStatus.alertMessage} onClose={hideAlert} variant={alertMessageStatus.success ? "success" : "danger"} />
      {children}
    </div>
  );
}

export function Base({ children, className }: BaseProps) {
  return (
    <ThemeProvider>
      <BaseProvider>
        <BaseInner className={className}>
          {children}
        </BaseInner>
      </BaseProvider>
    </ThemeProvider>
  );
}
