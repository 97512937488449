import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Row,
} from "react-bootstrap";
import Table from "../../../../../components/Table";
import "./WorkTimeSummaryTable.scss";
import { DateTime } from "../../../../../utils";
import { Formatter } from "../../../../../utils/Formatter";
import _ from "lodash";
import {
  DayRecordedData,
  useMonthRecords,
} from "../../context/MonthRecordsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import DayStatusDropdown from "../../../../../components/member/DayStatusDropdown";
import TooltipButton from "../../../../../components/TooltipButton";
import Tooltip from "../../../../../components/Tooltip";
import Placeholder from "../../../../../components/Placeholder";
import alertMessages from "../../../../../data/alertMessages.json";
import { DayStatus, renderDayStatus } from "../../../../../utils/DayStatus";
import { roundMinutesToHours } from "../../../../../utils/TimeMathFormules";
import DayStatusRequested from "../../../../../components/DayStatusRequested";
import { useApp } from "../../../../../contexts/AppContext";
import { fetchers } from "../../../../../serverApi/fetchers";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";

export function WorkTimeSummaryTable() {
  const { showAlert } = useApp();
  const {
    month,
    setMonth,
    monthTimeRecordsInfo,
    changeMonthStatus,
    selectedDay,
    setSelectedDay,
    modalStatus,
    openModal,
    closeModal,
    changeDayStatus,
    refreshMonth,
  } = useMonthRecords();

  const cancelDayChangeStatusRequest = (date: DateTime) => {
    // const endpointCancelRequest = serverApi.cancelChangeDayStatusReuest;
    // const dataToSend = {
    //   date: date.toJSON(),
    // };
    // const doIfSuccess = () => {
    //   refreshMonth();
    // }
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // }
    // const doAlways = undefined;
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchPost<{}>(
    //   endpointCancelRequest,
    //   dataToSend,
    //   doIfSuccess,
    //   doIfFailed,
    //   doAlways,
    //   doIfError
    // );

    const dataToSend = { date: date };
    fetchers.account.member.cancelDateStatusRequest.fetch(dataToSend, {
      success: () => {
        refreshMonth();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  return (
    <div className="WorkTimeSummaryTable">
      <Table>
        <Table.Head>
          <Row className="justify-content-between">
            <Col xs={2} sm={3} className="text-center align-self-center date">
              Day
            </Col>
            <Col
              xs={6}
              sm={5}
              className="text-center 
            align-self-center hours"
            >
              <span className="d-none d-sm-inline">Working </span>Time
            </Col>
            {/* <Col xs={1} className="text-end"></Col> */}
            <Col
              xs={4}
              sm={4}
              className="text-center  d-sm-block align-self-center status"
            >
              Status
            </Col>
            {/* <Col xs={3} className="text-center">Action</Col> */}
          </Row>
        </Table.Head>
        <Table.Body>
          {monthTimeRecordsInfo.monthDaysInfo.map((day) => (
            <Row
              className={`justify-content-between align-items-center ${
                selectedDay && selectedDay.date.date === day.date.date
                  ? "active"
                  : ""
              } ${
                day.date.equalTo(
                  DateTime.createLocDateTime(
                    new DateTime().getLocYear(),
                    new DateTime().getLocMonth(),
                    new DateTime().getLocDay()
                  )
                )
                  ? "today"
                  : ""
              }`}
              key={day.date.date.getTime()}
              onClick={() =>
                selectedDay === day ? setSelectedDay(null) : setSelectedDay(day)
              }
              id={day.date.getLocDay().toString()}
            >
              {/* Date */}
              <Col xs={2} sm={3} className="text-center date">
                <div className="d-sm-none small-screan">
                  <span className="me-2 weekday weekday-mobile">
                    {day.date.getLocWeekday()}
                  </span>

                  <span className="full">
                    {day.date.getLocDay().toString().padStart(2, "0")}
                    <span className="month">
                      {"." + day.date.getLocMonth().toString().padStart(2, "0")}
                    </span>
                  </span>
                  <span className="short">{day.date.getLocDay()}</span>
                </div>
                <div className="d-none d-sm-flex justify-content-center align-items-center large-screan">
                  <span className="me-2 weekday d-none d-md-inline-block self-align-start">
                    {day.date.getLocWeekday()}
                  </span>
                  {Formatter.formatDate(day.date)}
                </div>
              </Col>

              {/*  Working Time */}
              <Col
                xs={6}
                sm={4}
                className="text-center d-flex justify-content-center hours"
              >
                <div className="text-center" style={{ minWidth: "70px" }}>
                  <TimeHoursMinutes
                    totalMinutes={day.totalMinutes}
                    lightLetters={true}
                  />
                </div>
                <div className="ps-0 ps-md-2 more">
                  <TooltipButton
                    tooltipContent="Details"
                    buttonVariant="light"
                    className="table-row-btn openDay"
                    onClick={() => openModal(day)}
                    disabled={day.isDisabled}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </TooltipButton>
                </div>
              </Col>
              {/* <Col
                  xs={1}
                  className="text-center"
                  onClick={() => openModal(day)}
                >
                  <Button variant="light" className="openDay">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </Button>
                </Col> */}
              <Col xs={4} sm={4} className="text-center  d-sm-block status">
                {/* {day.status} */}
                {/* <Tooltip tooltipContent="Change status" className="openDay"> */}
                {day.requestedStatus === null && (
                  <span>
                    <DayStatusDropdown
                      status={day.status}
                      onChange={(newStatus) => {
                        changeDayStatus(day.date, day.status, newStatus);
                      }}
                      disabled={day.isDisabled}
                    />
                    
                  </span>
                )}
                {day.requestedStatus !== null && (
                  <div>
                    <DayStatusRequested
                      requestedStatus={day.requestedStatus}
                      onCancel={() => cancelDayChangeStatusRequest(day.date)}
                    />
                  </div>
                )}
                {/* </Tooltip> */}
              </Col>
              {/* <Col xs={3} className="text-center">
                  <DayStatusDropdown status={day.status} onChange={(status) => {console.log(status)}}/>
                </Col> */}
              {/* <Col
                  xs={1}
                  className="text-end"
                  onClick={() => openModal(day)}
                >
                  <Button variant="light" className="openDay w-100">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </Button>
                </Col> */}
            </Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
