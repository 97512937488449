import Card from "../../../components/Card";
import { Card as CardBS } from "react-bootstrap";

import "./DayRecordsTable.scss";
import Header from "./components/Header";
import Placeholder from "../../../components/Placeholder";
import { DayRecordsProvider, useDayRecords } from "./context/DayRecordsContext";
import TaskTrackingDataTable from "./components/TaskTrackingDataTable";
import Modal from "./components/Modal";
import { TaskRecordGroups } from "../../../utils/TaskRecord";
import { DateTime } from "../../../utils";
import CardFooter from "./components/CardFooter";
import { ReactNode } from "react";
import WidgetCard from "../../../components/WidgetCard";
import { To, createSearchParams, useNavigate } from "react-router-dom";
import apiUrls from "../../../data/api.json";
import ViewSummaryButton from "../../../components/ViewSummaryButton";
import ExpandButton from "../../../components/ExpandButton";

type DayRecordsTableProps = {
  date: DateTime;
  setDate?: (date: DateTime) => void;
  simplified?: boolean;
  onlyMonth?: DateTime;
  embeded?: boolean;
}
export function DayRecordsTable({date, setDate, onlyMonth, simplified = false, embeded = false}: DayRecordsTableProps) {
  const DRProvider = ({ children }: { children: ReactNode }) => (
    <DayRecordsProvider
    date={date} setDate={setDate}
    onlyMonth={onlyMonth}
      children={children}
    />
  );

  const getDayRecordsLink = (date: DateTime): To => {
    const dayValNum = date.getLocDay();
    const dayVal = dayValNum < 10 ? "0" + dayValNum : "" + dayValNum;
    const monthValNum = date.getLocMonth();
    const monthVal = monthValNum < 10 ? "0" + monthValNum : "" + monthValNum;
    const queryParams = createSearchParams({
      date: dayVal + "." + monthVal + "." + date.getLocYear()
    }).toString()
    return {
      pathname: apiUrls.account.children.member.children.dayRecords,
      search: queryParams
    };
  }

  const getMonthSummaryLink = (date: DateTime): To => {
    const month = date.getStartOfMonth();
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0");
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();
    return {
        pathname: apiUrls.account.children.member.children.monthRecords,
        search: queryParams,
      };
  };

  return (
    <WidgetCard
      className="DayRecordsTable"
      ContextProvider={DRProvider}
      useContext={useDayRecords}
    >
      {(!setDate || embeded) && (<WidgetCard.TopExtras><ExpandButton expandLink={getDayRecordsLink(date)}/></WidgetCard.TopExtras>)}
      {!!setDate && !embeded  &&(<WidgetCard.TopExtras><ViewSummaryButton summaryLink={getMonthSummaryLink(date)}/></WidgetCard.TopExtras>)}
      <WidgetCard.Header>
          <Header simplified={simplified}/>
      </WidgetCard.Header>
      <WidgetCard.Body>
        <TaskTrackingDataTable />
      </WidgetCard.Body>
      <WidgetCard.Footer>
        <CardFooter/>
      </WidgetCard.Footer>
      <WidgetCard.Modal>
        <Modal />
      </WidgetCard.Modal>
    </WidgetCard>
  );
}