import { AuthRoles, areAuthRoles } from "../../../../../../../utils/AuthRoles";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useFormSubmitDelete } from "../../../../../../../hooks/useFormSubmitDeleteAdv";
import { User } from "../../../../../../../utils/Users";
import TooltipButton from "../../../../../../../components/TooltipButton";
import ValidationFeedback from "../../../../../../../components/ValidationFeedback";
import { ErrorLog } from "../../../../../../../hooks/useFormErrors";
import { useUsersList } from "../../../../context/UsersListContext";
import { useApp } from "../../../../../../../contexts/AppContext";
import { useRef, useState } from "react";
import restrictions from "../../../../../../../data/restrictions.json";
import _ from "lodash";
import InputText from "../../../../../../../components/InputText";
import InputEmail from "../../../../../../../components/InputEmail";
import AuthRoleSelect from "../../../../../../../components/AuthRoleSelect";
import { fetchPost } from "../../../../../../../utils";
import alertMessages from "../../../../../../../data/alertMessages.json";
import { useFormSubmit } from "../../../../../../../hooks/useFormSubmitAdv";
import { fetchers } from "../../../../../../../serverApi/fetchers";
import AuthRolesSelect from "../../../../../../../components/AuthRolesSelect";

type FormErrors = {
  firstNameError: ErrorLog;
  lastNameError: ErrorLog;
  emailError: ErrorLog;
  rolesError: ErrorLog;
  generalError: ErrorLog;
};
type UserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  roles: AuthRoles[];
};
type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  roles: AuthRoles[];
};
type ModalUserCreationProps = {
  defaultRoles: AuthRoles[];
  defaultFirstName: string;
  defaultLastName: string;
  defaultEmail: string;
};
export function ModalUserCreation({
  defaultRoles,
  defaultFirstName,
  defaultLastName,
  defaultEmail,
}: ModalUserCreationProps) {
  const { showAlert } = useApp();
  const { closeModal, usersRefresh, allUsers } = useUsersList();
  const defaultFormErrorsValues = {
    firstNameError: null,
    lastNameError: null,
    emailError: null,
    rolesError: null,
    generalError: null,
  };
  Object.freeze(defaultFormErrorsValues);

  const defaultUserInfo = {
    firstName: defaultFirstName,
    lastName: defaultLastName,
    email: defaultEmail,
    roles: defaultRoles,
  };
  const newUserInfo = useRef<UserInfo>(defaultUserInfo);
  const userInfoComparator = (userInfo1: UserInfo, userInfo2: UserInfo) => {
    return _.isEqual(
      [userInfo1.firstName, userInfo1.lastName, userInfo1.email, userInfo1.roles],
      [userInfo2.firstName, userInfo2.lastName, userInfo2.email, userInfo2.roles]
    );
  };

  const [formInputs, _setFormInputs] = useState<FormInputs>({
    firstName: defaultFirstName,
    lastName: defaultLastName,
    email: defaultEmail,
    roles: defaultRoles,
  });

  const setFormInputs = (newFormInput: FormInputs) => {
    newUserInfo.current = {
      ...newUserInfo.current,
      firstName: newFormInput.firstName,
      lastName: newFormInput.lastName,
      email: newFormInput.email,
      roles: newFormInput.roles,
    };
    _setFormInputs(newFormInput);
  };

  const submitValidator = (userInfo: UserInfo, formErrors: FormErrors) => {
    if (userInfo.firstName.trim().length < 1) {
      formErrors.firstNameError = "First name should not be empty.";
      return false;
    }
    if (userInfo.lastName.trim().length < 1) {
      formErrors.lastNameError = "Last name should not be empty.";
      return false;
    }
    if (userInfo.firstName.trim().length > restrictions.maxUserNameLength) {
      formErrors.firstNameError =
        "First name should be no longer than " +
        restrictions.maxUserNameLength +
        " characters.";
      return false;
    }
    if (userInfo.lastName.trim().length > restrictions.maxUserNameLength) {
      formErrors.lastNameError =
        "Last name should be no longer than " +
        restrictions.maxUserNameLength +
        " characters.";
      return false;
    }
    if (userInfo.email.trim().length < 1) {
      formErrors.emailError = "Email should not be empty.";
      return false;
    }
    if (userInfo.email.trim().length > restrictions.maxEmailLength) {
      formErrors.emailError =
        "Email should be no longer than " +
        restrictions.maxEmailLength +
        " characters.";
      return false;
    }
    const emailPattern =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
    if (!emailPattern.test(userInfo.email)) {
      formErrors.emailError = "Invalid email format.";
      return false;
    }
    if (restrictions.emailAllowedDomains.length > 0) {
      const domainPattern = /^.*?@(.*?\..*)$/;
      const domainMatches = userInfo.email.match(domainPattern);
      if (
        !domainMatches ||
        domainMatches.length < 2 ||
        !domainMatches[1] ||
        !restrictions.emailAllowedDomains.includes(domainMatches[1])
      ) {
        formErrors.emailError =
          'Invalid domain. Only following domains are allowed: "' +
          restrictions.emailAllowedDomains.join('", "') +
          '".';
        return false;
      }
    }
    if (allUsers.filter((user) => user.email === userInfo.email).length > 0) {
      formErrors.emailError =
        "User is already registered with this email address.";
      return false;
    }
    if (userInfo.roles.length < 1) {
      formErrors.rolesError = "User's roles cannot be empty.";
      return false;
    }
    if (
      !areAuthRoles(userInfo.roles)
    ) {
      formErrors.rolesError = "Wrong User's roles format.";
      return false;
    }
    return true;
  };

  const validateSubmitData = (userInfo: UserInfo) => {
    let formErrors = { ...defaultFormErrorsValues };
    const isValid = submitValidator(userInfo, formErrors);
    return { isValid: isValid, formErrors: formErrors };
  };
  const sendSubmitData = (
    userInfo: UserInfo,
    _doIfSuccess: () => void,
    _doAlways: () => void
  ): void => {
    // console.log(newUserInfo.current, "sent");
    const dataToSend = {
      firstName: userInfo.firstName.trim(),
      lastName: userInfo.lastName.trim(),
      email: userInfo.email.trim(),
      roles: userInfo.roles,
    };
    fetchers.account.admin.createUser.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        // usersRefresh();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // fetchPost("/admin/users/create-user", dataToSend, {
    //   success: () => {
    //     _doIfSuccess();
    //     closeModal();
    //     usersRefresh();
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     _doAlways();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };
  const [formStatus, formErrors, handleSubmit, submitIsPossible] =
    useFormSubmit<FormErrors, UserInfo>(
      defaultFormErrorsValues,
      newUserInfo.current,
      userInfoComparator,
      validateSubmitData,
      sendSubmitData
    );

  const handleFirstNameChange = (newFirstName: string) => {
    const newFormInputs = { ...formInputs, firstName: newFirstName };
    setFormInputs(newFormInputs);
  };
  const handleLastNameChange = (newLastName: string) => {
    const newFormInputs = { ...formInputs, lastName: newLastName };
    setFormInputs(newFormInputs);
  };
  const handleEmailChange = (newEmail: string) => {
    const newFormInputs = { ...formInputs, email: newEmail };
    setFormInputs(newFormInputs);
  };
  const handleRolesChange = (newRoles: AuthRoles[]) => {
    const newFormInputs = { ...formInputs, roles: newRoles };
    setFormInputs(newFormInputs);
  };

  const submitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && submitIsPossible) {
      handleSubmit();
    }
  }
  return (
    <Container className={`w-auto mx-md-3 ModalUserCreation`} onKeyDown={submitOnEnter}>
      <Form.Group className="mb-3">
        <Form.Label>Last Name:</Form.Label>
        <InputText
          defaultValue={formInputs.lastName}
          onChange={handleLastNameChange}
          maxLength={restrictions.maxUserNameLength}
        />
        <ValidationFeedback message={formErrors.lastNameError} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>First Name:</Form.Label>
        <InputText
          defaultValue={formInputs.firstName}
          onChange={handleFirstNameChange}
          maxLength={restrictions.maxUserNameLength}
        />
        <ValidationFeedback message={formErrors.firstNameError} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Email:</Form.Label>
        <InputEmail
          defaultValue={formInputs.email}
          onChange={handleEmailChange}
          maxLength={restrictions.maxEmailLength}
        />
        <ValidationFeedback message={formErrors.emailError} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Roles:</Form.Label>
        {/* <AuthRoleSelect
          defaultValue={formInputs.role}
          onSelect={handleRoleChange}
        /> */}
        <AuthRolesSelect
          defaultValue={formInputs.roles}
          onSelect={handleRolesChange}
        />
        <ValidationFeedback message={formErrors.rolesError} />
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <ValidationFeedback
          message={formErrors.generalError}
          className="me-3"
        />
      </Form.Group>
      <Form.Group as={Row} className="mt-5">
        <Col className="d-flex justify-content-end">
          <TooltipButton
            tooltipContent={`Save changes`}
            className="px-4 submit"
            onClick={handleSubmit}
            disabled={!submitIsPossible}
          >
            {formStatus.isSubmitting ? "Saving" : "Save"}
          </TooltipButton>
        </Col>
      </Form.Group>
    </Container>
  );
}
