import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "../../ErrorBoundaryFallback";
import { useAuth } from "../../../contexts/AuthContext";
import { ReactNode } from "react";

type AccountBaseProps = {
  children: ReactNode;
};
export function AccountBase({ children }: AccountBaseProps) {
  const { auth } = useAuth();
  return (
    <ErrorBoundary
      fallbackRender={ErrorBoundaryFallback}
      onError={(error: Error, info: { componentStack: string }) => {
        console.error(error);
      }}
      onReset={() => {}}
    >
      {auth && <>{children}</>}
    </ErrorBoundary>
  );
}
