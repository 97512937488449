import { useEffect } from "react";
import { useBase } from "../components/pageBases/Base";
import { PageTitle } from "../components/pageBases/Base/context/BaseContext";

export default function usePageTitle(pageTitle: PageTitle) {
  const { setPageTitle } = useBase();
  useEffect(() => {
    setPageTitle(pageTitle);
  }, []);
}
