import { Tabs as TabsBS, Tab as TabBS, Row, Col, Nav } from "react-bootstrap";
import "./Tabs.scss";
import React, { ReactNode } from "react";

type TabNavProp = {
  // key: React.Key;
  eventKey: string | number;
  children: ReactNode;
};
function TabNav({ children, eventKey }: TabNavProp) {
  return (
    <Nav.Item>
      <Nav.Link className="btn btn-outline-primary" eventKey={eventKey}>{children}</Nav.Link>
    </Nav.Item>
  );
}
type TabsNavContainerProps = {
  children: ReactNode;
  variant?: "tabs" | "pills" | "underline";
  className?: string;
};
function TabsNavContainer({ children, variant="tabs", className="" }: TabsNavContainerProps) {
  return (
    <Nav variant={variant} className={`${className}`}>
      {children}
    </Nav>
  );
}
TabsNavContainer.Tab = TabNav;

// ----

function TabsContentContainer({ children }: { children: ReactNode }) {
  return <TabBS.Content>{children}</TabBS.Content>;
}
type TabContentProps = {
  // key?: React.Key;
  eventKey: string | number;
  children: ReactNode;
};
function TabContent({ children, eventKey }: TabContentProps) {
  return (
    <TabBS.Pane eventKey={eventKey}>
      {children}
    </TabBS.Pane>
  );
}
TabsContentContainer.Tab = TabContent;

// ----

type TabsProps = {
  children: ReactNode;
  defaultActiveKey?: string | number;
  activeKey?: string | number;
  onSelect?: (selected: string | null) => void
  className?: string;
};

export function Tabs({
  defaultActiveKey,
  activeKey,
  onSelect,
  className = "",
  children,
}: TabsProps) {
  return (
    <div className={`Tabs mb-3 ${className}`}>
      <TabBS.Container defaultActiveKey={defaultActiveKey} activeKey={activeKey} onSelect={onSelect}>
        {children}
      </TabBS.Container>
    </div>
  );
}
Tabs.Nav = TabsNavContainer;
Tabs.Content = TabsContentContainer;
