import {
  ErrorBoundary,
  FallbackProps,
  useErrorBoundary,
} from "react-error-boundary";
import "./ErrorBoundaryFallback.scss";
import pageDeadImg from "../../assets/images/pageDead.webp";
import { Image } from "react-bootstrap";
import lockImage from "../../assets/images/lock2.png";
import { SnapError } from "./SnapError";


export function ErrorBoundaryFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  return (<SnapError reloadPage={resetErrorBoundary} />)
}
