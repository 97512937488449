import { Image as ImageBS } from "react-bootstrap";

type ImageProps = {
  src: string;
  className?: string;
  width?: number;
  height?: number;
};
export function TextImage({
  src,
  className = "",
  width = 22,
  height = 22,
}: ImageProps) {
  return (
    <ImageBS
      className={"mb-1 " + className}
      src={src}
      width={width}
      height={height}
    />
  );
}
