import React, { ReactNode } from "react";
import SimpleCard from "../SimpleCard";
import "./Card.scss";
import { Placeholder } from "react-bootstrap";
import Modal from "./components/Modal";
import Grid from "../Grid";
import { CardProvider, useCard } from "./context/CardContext";
import Expand from "./components/Expand";
import TopExtras from "./components/TopExtras";


// type MProps = {
//   children: ReactNode;
// };
// export function  MContent({ children }: MProps) {
//     const lol = useCard()
//     return (
//         <div className="Modal">
//             {children}
//         </div>
//       );
// }

type CardProps = {
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
};
export function Card({ children, style, className }: CardProps) {
  const arrayChildren = React.Children.toArray(children);
  const cardModal =
    React.Children.count(arrayChildren) > 0 &&
    arrayChildren.find(
      (child) => React.isValidElement(child) && child.type === Modal
    );
  const notcardModal =
    React.Children.count(arrayChildren) > 0 &&
    arrayChildren.filter(
      (child) => !(React.isValidElement(child) && child.type === Modal)
    );
  const modal = React.Children.count(cardModal) ? true : false;
  return (
    <div className={`Card ${className ? className : ""}`} style={style}>
      <CardProvider modal={modal}>
        <SimpleCard>
           <Grid>
            <Grid.Node>
           {notcardModal}
           </Grid.Node>
           {modal && (<>{cardModal}</>)}
           </Grid>

        </SimpleCard>
      </CardProvider>
    </div>
  );
}
Card.Modal = Modal;
Card.Expand = Expand;
Card.TopExtras = TopExtras;
