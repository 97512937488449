import { Badge, Row } from "react-bootstrap";
import { ListOfAccordions } from "../../../../../components/ListOfAccordions/ListOfAccordions";
import { usePendingMembersRequests } from "../../context/PendingMembersRequestsProvider";
import "./RequestsList.scss";
import DatePicker from "../../../../../components/DatePicker";
import { Month } from "./components/Month/Month";
import { Members } from "./components/Members/Members";
import React, { useEffect, useState } from "react";
import { createContext } from "../../../../../hooks/useCreateContext";

type MonthRequestsItemContextVal = {
  isMonthRequestsItemDisabled: boolean;
  setIsMonthRequestsItemDisabled: (isMonthRequestsItemDisabled: boolean) => void;
};
const [MonthRequestsItemContext, useMonthRequestsItem] =
  createContext<MonthRequestsItemContextVal>();
export { useMonthRequestsItem };

type MonthRequestsItemProviderProps = {
  children: React.ReactNode;
};

export function MonthRequestsItemProvider({
  children,
}: MonthRequestsItemProviderProps) {
  const { clearDisabled} = usePendingMembersRequests();
  const [isMonthRequestsItemDisabled, setIsMonthRequestsItemDisabled] = useState<boolean>(false);

  useEffect(()=>{
    setIsMonthRequestsItemDisabled(false);
  }, [clearDisabled]);
  return (
    <MonthRequestsItemContext.Provider value={{isMonthRequestsItemDisabled, setIsMonthRequestsItemDisabled}}>
      <div className={"MonthRequestsItemWrapper " + (isMonthRequestsItemDisabled ? "disabled": "")}>
      {children}
      </div>
    </MonthRequestsItemContext.Provider>
  );
}

export function RequestsList() {
  const { pendingRequests} =
    usePendingMembersRequests();

  return (
    <div className={"RequestsList"}>
      {pendingRequests.length >= 1 && (
        <ListOfAccordions
          list={pendingRequests}
          accordionHeader={(monthInfo, active) => (
            <Month monthInfo={monthInfo} active={active} />
          )}
          accordionBody={(monthInfo) => (
            <Members members={monthInfo.members} month={monthInfo.month} />
          )}
          ListItemContentWrapper={MonthRequestsItemProvider}
        />
      )}
      {pendingRequests.length < 1 && (
        <div className="info d-flex justify-content-center mt-5 mb-2 small">
          Currently No Requests
        </div>
      )}
    </div>
  );
}
