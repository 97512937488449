import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext"
import { AuthRoles } from "../../utils/AuthRoles";
import Home from "../../pages/Home";
import apiUrls from "../../data/api.json";



type RequireAuthProps = {
    allowedRoles: AuthRoles[]
}
export function RequireAuth({allowedRoles}: RequireAuthProps) {
    const { auth } = useAuth();
    const location = useLocation();
    return (
        auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.lastName
                ? <Navigate to={apiUrls.unauthorized} state={{ from: location }} replace />
                : <Navigate to={apiUrls.login} state={{ from: location }} replace />
    );
}