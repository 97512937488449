import {
  ReactNode,
  useEffect,
  useState,
} from "react";
import { AuthRoles } from "../../utils/AuthRoles";
import { DateTime, fetchGet, fetchPost } from "../../utils";
import { fetchers } from "../../serverApi/fetchers";
import { createContext } from "../../hooks/useCreateContext";
import { User } from "../../utils/Users";
import { isStandalone } from "../../utils/StandAlone";

export type Auth = User;

type AuthContextVal = {
  auth: Auth | undefined;
  setAuth: (newAuth: Auth | undefined) => void;
  roleIsAuthorized: (allowedRole: AuthRoles) => boolean;
};
const [AuthContext, useAuth] =
  createContext<AuthContextVal>();
export { useAuth };

type AuthProviderProps = {
  children: ReactNode;
};
export function AuthProvider({ children }: AuthProviderProps) {
  const [authIsLoaded, setAuthIsLoaded] = useState<boolean>(false);
  const [auth, setAuth] = useState<Auth | undefined>(undefined);

  const loadAuth = () => {
    fetchers.general.authInfo.fetch({}, {
      success: (data, status) => {
          const newAuth = {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            roles: data.roles ,
            email: data.email,
            created: data.created,
            updated: data.updated,
            deleted: data.deleted
          };
          setAuth(newAuth);
          if(isStandalone()){
            fetchGet("/standalone", {
              query: {
                u: newAuth.id,
                w: window.innerWidth.toString()
              }
            })
          }
        },
        always: () => {
          setAuthIsLoaded(true);
        }
    })
    // type ResponceData = {
    //   name: string;
    //   role: string;
    //   created: string;
    //   email: string;
    // };
    // const endpointAuthInfo = serverApi.authorizationInfo;
    // const dataToSend = {};
    // const doIfSuccess = (data: ResponceData) => {
    //   if (!Object.values(AuthRoles).includes(data.role as AuthRoles)) {
    //     throw new Error();
    //   }
    //   const role: AuthRoles = data.role as AuthRoles;
    //   const newAuth = {
    //     username: data.name,
    //     roles: [data.role as AuthRoles],
    //     created: new DateTime(data.created),
    //     email: data.email,
    //   };
    //   setAuth(newAuth);
    // };
    // const doIfFailed = undefined;
    // const doAlways = () => {
    //   setAuthIsLoaded(true);
    // };
    // const doIfError = undefined;
    // fetchPost<ResponceData>(endpointAuthInfo, dataToSend, {
    //   success: doIfSuccess,
    //   fail: doIfFailed,
    //   always: doAlways,
    //   error: doIfError,
    // });
  };

  useEffect(() => {
    loadAuth();
  }, []);

  const roleIsAuthorized = (allowedRole: AuthRoles) => {
    return !!(auth && auth.roles.find((role) => [allowedRole].includes(role)));
  };

  return (
    <>
      {authIsLoaded && (
        <AuthContext.Provider value={{ auth, setAuth, roleIsAuthorized }}>
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
}
