import { Card as CardBS } from "react-bootstrap";
import "./WidgetCard.scss";
import Card from "../Card";
import React, { ReactElement, ReactNode } from "react";

type WidgetCardSimpleType = {
  expand?: ReactNode;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  modal?: ReactNode;
  className?: string;
};
function WidgetCardSimple({
  expand,
  header,
  body,
  footer,
  modal,
  className,
}: WidgetCardSimpleType) {
  return (
    <Card className={`WidgetCardSimple ${className ? className : ""}`}>
      {expand && (expand)}
      {header && (header)}
      {body && (body)}
      {footer && (footer)}
      {modal && (modal)}
    </Card>
  );
}

type WidgetContextType = {
  modalStatus: {
    show: boolean;
    [key: string]: any;
  };
  closeModal: () => void;
  [key: string]: any;
};

const WidgetCardHeader = ({ children }: { children: ReactNode }) => (
  <CardBS.Header className="WidgetCardHeader">{children}</CardBS.Header>
);
const WidgetCardBody = ({ children }: { children: ReactNode }) => (
  <CardBS.Body className="WidgetCardBody">{children}</CardBS.Body>
);
const WidgetCardFooter = ({ children }: { children: ReactNode }) => (
  <CardBS.Footer className="WidgetCardFooter">{children}</CardBS.Footer>
);
const WidgetCardModal = ({ children }: { children: ReactNode }) => (
  <>{children}</>
);
const WidgetCardExpand = ({ onClick }: { onClick: ()=>void }) => (
  <Card.Expand onClick={onClick}/>
);
const WidgetCardTopExtras = ({ 
  children,
  style,
  className 
}: { 
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) => (
  <Card.TopExtras children={children} className={className} style={style}/>
);

type ContextProviderArgsType = {
  children: ReactNode;
  [k: string]: any;
};

type WidgetCardProps = {
  children: ReactNode;
  ContextProvider?: ({
    children,
  }: ContextProviderArgsType) => React.JSX.Element;
  useContext?: () => WidgetContextType;
  className?: string;
};

export function WidgetCard({
  children,
  ContextProvider,
  useContext,
  className,
}: WidgetCardProps) {
  let headerChild: ReactNode = undefined;
  let bodyChild: ReactNode = undefined;
  let footerChild: ReactNode = undefined;
  let modalChild: ReactNode = undefined;
  let expandChild: ReactNode = undefined;

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type.toString() === WidgetCardHeader.toString()) {
        headerChild = child;
      }
      if (child.type.toString() === WidgetCardBody.toString()) {
        bodyChild = child;
      }
      if (child.type.toString() === WidgetCardFooter.toString()) {
        footerChild = child;
      }
      if (child.type.toString() === WidgetCardModal.toString()) {
        modalChild = child;
      }
      if (child.type.toString() === WidgetCardExpand.toString()) {
        expandChild = child;
      }
      if (child.type.toString() === WidgetCardTopExtras.toString()) {
        expandChild = child;
      }
    }
  });

  if (!ContextProvider) {
    return (
      <WidgetCardSimple
        expand={expandChild}
        header={headerChild}
        body={bodyChild}
        footer={footerChild}
        className={`WidgetCard ${className}`}
      />
    );
  }
  if (!useContext || !modalChild) {
    return (
      <ContextProvider>
        <WidgetCardSimple
          expand={expandChild}
          header={headerChild}
          body={bodyChild}
          footer={footerChild}
          className={`WidgetCard ${className}`}
        />
      </ContextProvider>
    );
  }
  const WidgetCardInner = () => {
    const { modalStatus, closeModal } = useContext();
    const renderModal = (isShown: boolean, onClose: () => void) => (
      <Card.Modal show={isShown} onClose={onClose}>
        {modalChild}
      </Card.Modal>
    );
    return (
      <WidgetCardSimple
        expand={expandChild}
        header={headerChild}
        body={bodyChild}
        footer={footerChild}
        modal={renderModal(modalStatus.show, closeModal)}
        className={`WidgetCard ${className}`}
      />
    );
  };

  return (
    <ContextProvider>
      <WidgetCardInner />
    </ContextProvider>
  );
}

WidgetCard.Header = WidgetCardHeader;
WidgetCard.Body = WidgetCardBody;
WidgetCard.Footer = WidgetCardFooter;
WidgetCard.Modal = WidgetCardModal;
WidgetCard.Expand = WidgetCardExpand;
WidgetCard.TopExtras = WidgetCardTopExtras;
