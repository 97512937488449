import { useState } from "react";
import { Task, Tasks } from "../utils/Task";
import { DateTime } from "../utils";


type RunningTimerStatus = {
  startedAt: DateTime;
  task: Task;
};
type TimerStatus = RunningTimerStatus | null;

export type ElapsedTime = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

export type TimerStatusType =
  | {
      getElapsedTime: () => ElapsedTime;
      getRunningTask: () => Task;
      getStartedAt: () => DateTime
    }
  | null;

type UseTimerStatus = [
  timerStatus: TimerStatusType,
  setTimerStatus: (newTimerStatus: TimerStatus) => void,
  // startTimer: (selectedTask: TimerType) => void,
  // stopTimer: () => void
];


export function calcElapsedTime(startedAt: DateTime) : ElapsedTime {
    var elapsedTime = {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
      update: function (days: number, hours: number, minutes: number, seconds: number) {
        this.days = "" + days;
        this.hours = hours < 10 ? "0" + hours : "" + hours;
        this.minutes = minutes < 10 ? "0" + minutes : "" + minutes;
        this.seconds = seconds < 10 ? "0" + seconds : "" + seconds;
      }
    }
    
    var now = Date.now();
    if (now < startedAt.date.getTime()) 
      return {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
      };
    var duration = now - startedAt.date.getTime();
    var seconds = Math.floor((duration / 1000) % 60);
    var minutes = Math.floor((duration / (1000 * 60)) % 60);
    var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    var days = Math.floor((duration / (1000 * 60 * 60 * 24)));
    elapsedTime.update(
      (days = days),
      (hours = hours),
      (minutes = minutes),
      (seconds = seconds),
    );
    
    return {
      days: elapsedTime.days,
      hours: elapsedTime.hours,
      minutes: elapsedTime.minutes,
      seconds: elapsedTime.seconds,
    };

}


export function useTimerStatus(initTimerStatus?: TimerStatus | undefined): UseTimerStatus {
  initTimerStatus = !initTimerStatus || (initTimerStatus && initTimerStatus.startedAt.date.getTime() < Date.now()) ? initTimerStatus : undefined; 
  const [timerStatus, setTimerStatus] = useState<TimerStatus| undefined>(initTimerStatus);
 
  var returnTimerStatus: TimerStatusType = timerStatus ? {
    getRunningTask: () => {
      return timerStatus.task;
    },
    getElapsedTime: () => {
      return calcElapsedTime(timerStatus.startedAt)
    },
    getStartedAt: () =>  {
      return new DateTime(timerStatus.startedAt.date);
    },
  } : null;

  const retunSetTimerStatus = (newTimerStatus: TimerStatus) => {
    if (!newTimerStatus) {
      // if (newTimerStatus && newTimerStatus.startedAt.date.getTime() > Date.now()) {
      setTimerStatus(undefined);
      return;
    }
    setTimerStatus(newTimerStatus);
  }

  return [returnTimerStatus, retunSetTimerStatus];
}
