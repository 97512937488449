import usePageTitle from "../../../hooks/usePageTitle";
import { DateTime } from "../../../utils";
import { MembersMonthlyTimesheetsTable } from "../../../widgets/admin/MembersMonthlyTimesheetsTable/MembersMonthlyTimesheetsTable";
import { useQueryMonth } from "./hooks/useQueryMonth";

export function MembersMonthlyTimesheets() {
  const pageTitle = {
    full: "Member's Monthly Timesheets",
    short: "Timesheets",
  };
  usePageTitle(pageTitle);

  const { month, setMonth } = useQueryMonth({
    minMonth: DateTime.createLocDateTime("2024", 3, 1),
    maxMonth: new DateTime().getEndOfMonth(),
  });
  return (<MembersMonthlyTimesheetsTable month={month} setMonth={setMonth} />)
}
