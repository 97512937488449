import { ReactNode, useState, useEffect, useRef } from "react";
import { DateTime } from "../../../../utils";
import { Modal, useModal } from "../hooks/useModal";
import { DayStatus } from "../../../../utils/DayStatus";
import { MonthStatus } from "../../../../utils/MonthStatus";
import alertMessages from "../../../../data/alertMessages.json";
import { useApp } from "../../../../contexts/AppContext";
import { logValidationError } from "../../../../utils/logError";
import Placeholder from "../../../../components/Placeholder";
import { useRoleAuth } from "../../../../contexts/RoleAuthContext/RoleAuthContext";
import { fetchers } from "../../../../serverApi/fetchers";
import { createContext } from "../../../../hooks/useCreateContext";
import apiUrls from "../../../../data/api.json";
import { Month } from "../../../../utils/TimeTypes";

export type DayRecordedData = {
  date: DateTime;
  totalMinutes: number;
  status: DayStatus;
  isDisabled: boolean;
  requestedStatus: DayStatus | null;
};
type MonthRecordedData = DayRecordedData[];

type MonthTimeRecordsInfo = {
  status: MonthStatus;
  totalMinutes: number;
  documentedTotalMinutes: number;
  monthDaysInfo: DayRecordedData[];
};

type AvailableMonths = {
  minMonth: DateTime;
  maxMonth: DateTime;
};

type MonthRecordsContextVal = {
  month: DateTime;
  setMonth: (month: DateTime) => void;
  availableMonths: AvailableMonths;
  monthTimeRecordsInfo: MonthTimeRecordsInfo;
  // monthStatus: MonthStatus;
  // monthRecordedData: MonthRecordedData;
  selectedDay: DayRecordedData | null;
  setSelectedDay: (selectedDay: DayRecordedData | null) => void;
  modalStatus: Modal;
  openModal: (day?: DayRecordedData | undefined) => void;
  closeModal: () => void;
  changeModalDate: (date: DateTime) => void;
  changeMonthStatus: (newStatus: MonthStatus) => void;
  changeDayStatus: (
    date: DateTime,
    oldStatus: DayStatus,
    newStatus: DayStatus
  ) => void;
  refreshMonth: () => void;
  downloadTimesheet: () => void;
  fileAnchorRef: React.MutableRefObject<HTMLAnchorElement | null>;
};
const [MonthRecordsContext, useMonthRecords] =
  createContext<MonthRecordsContextVal>();
export { useMonthRecords };

type MonthRecordsProviderProps = {
  children: ReactNode;
  month: DateTime;
  setMonth: (month: DateTime) => void;
};
export function MonthRecordsProvider({
  children,
  month,
  setMonth,
}: MonthRecordsProviderProps) {
  const fileAnchorRef = useRef<HTMLAnchorElement | null>(null);
  const { showAlert } = useApp();
  const { auth } = useRoleAuth();
  const [monthTimeRecordsInfo, setMonthTimeRecordsInfo] = useState<
    MonthTimeRecordsInfo | undefined
  >(undefined);
  // const [monthRecordedData, setMonthRecordedData] = useState<
  //   MonthRecordedData | undefined
  // >(undefined);
  // const [monthStatus, setMonthStatus] = useState<MonthStatus | undefined>(
  //   undefined
  // );
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [selectedDay, setSelectedDay] = useState<DayRecordedData | null>(null);

  const availableMonths: AvailableMonths = {
    minMonth: DateTime.createLocDateTime(
      auth.created.getLocYear(),
      auth.created.getLocMonth()
    ),
    maxMonth: new DateTime(),
  };

  const checkDateIsAvailable = (date: DateTime, dateStatus: DayStatus) => {
    const lastMonthDay = new DateTime().getEndOfMonth();
    const startOfLastMonthWeek = lastMonthDay.addDays(-14);
    if (new DateTime().graterThan(startOfLastMonthWeek)) {
      return true;
    }
    const afterToday = date.graterThan(new DateTime());
    if (!afterToday) {
      return true;
    }
    // after today
    if (
      dateStatus === DayStatus.submitted ||
      dateStatus === DayStatus.completed
    ) {
      return true;
    }
    return false;
  };

  const monthRecordedDataRefresh = () => {
    contentRef.current?.classList.add("loading");

    const dataToSend = {
      date: month,
    };

    fetchers.account.member.monthInfo.fetch(dataToSend, {
      success: (data, status) => {
        const newMonthRecordedData = data.monthDays.map((month_day) => {
          return {
            ...month_day,
            isDisabled: !checkDateIsAvailable(month_day.date, month_day.status),
          };
        });
        // setMonthRecordedData(newMonthRecordedData);
        // setMonthStatus(data.status);
        setMonthTimeRecordsInfo({
          status: data.status,
          totalMinutes: data.totalMinutes,
          documentedTotalMinutes: data.documentedTotalMinutes,
          monthDaysInfo: newMonthRecordedData,
        });
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        contentRef.current?.classList.remove("loading");
        
      }
    });

    // type RespMonthRecordedData = {
    //   status: string;
    //   dates: {
    //     date: string;
    //     totalWorkTimeMin: number;
    //     status: string;
    //     requestedStatus: string | null;
    //   }[];
    // };
    // const endpointRecordedDataForMonth = serverApi.getRecordedDataForMonth;
    // const dataToSend = {
    //   date: month.toJSON(),
    // };
    // const doIfSuccess = (respMonthRecordedData: RespMonthRecordedData) => {
    //   if (!respMonthRecordedData) return;
    //   const status = respMonthRecordedData.status;
    //   const newMonthRecordedData: MonthRecordedData =
    //     respMonthRecordedData.dates.map((dayInfo) => {
    //       if (!Object.values(DayStatus).includes(dayInfo.status as DayStatus)) {
    //         throw new Error();
    //       }
    //       const date = new DateTime(dayInfo.date);
    //       let requestedStatus: DayStatus | null = null;
    //       if (dayInfo.requestedStatus !== null) {
    //         if (
    //           !Object.values(DayStatus).includes(
    //             dayInfo.requestedStatus as DayStatus
    //           )
    //         ) {
    //           throw new Error();
    //         }
    //         requestedStatus = dayInfo.requestedStatus as DayStatus;
    //       }
    //       return {
    //         date: date,
    //         totalWorkTimeMin: dayInfo.totalWorkTimeMin,
    //         status: dayInfo.status as DayStatus,
    //         isDisabled: date.graterThan(new DateTime()),
    //         requestedStatus: requestedStatus,
    //       };
    //     });
    //   if (!Object.values(MonthStatus).includes(status as MonthStatus)) {
    //     throw new Error();
    //   }
    //   // console.log(">>",newMonthRecordedData)
    //   setMonthRecordedData(newMonthRecordedData);
    //   setMonthStatus(status as MonthStatus);
    // };
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // const doAlways = undefined;
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchPost<RespMonthRecordedData>(
    //   endpointRecordedDataForMonth,
    //   dataToSend,
    //   doIfSuccess,
    //   doIfFailed,
    //   doAlways,
    //   doIfError
    // );
  };
  // useEffect(() => monthRecordedDataRefresh(), [month]);
  const refreshMonth = () => {
    // setMonthTimeRecordsInfo(undefined);
    // setMonthRecordedData(undefined);
    // setMonthStatus(undefined);
    monthRecordedDataRefresh();
  };
  const [modalStatus, openModal, closeModal, changeModalDate] = useModal(
    month,
    selectedDay,
    refreshMonth
  );
  useEffect(() => {
    refreshMonth();
  }, [month]);

  const changeMonthStatus = (newStatus: MonthStatus) => {
    if (
      !monthTimeRecordsInfo?.status ||
      monthTimeRecordsInfo.status === newStatus
    ) {
      return;
    }
    var valid =
      (monthTimeRecordsInfo.status === MonthStatus.completed &&
        newStatus === MonthStatus.submitted) ||
      (monthTimeRecordsInfo.status === MonthStatus.submitted &&
        // newStatus == MonthStatus.completed ||
        newStatus === MonthStatus.editable) ||
      (monthTimeRecordsInfo.status === MonthStatus.editable &&
        newStatus === MonthStatus.submitted);
    if (!valid) {
      showAlert(alertMessages.invalidData);
      logValidationError("Wrong status.");
      return;
    }

    if (
      monthTimeRecordsInfo.status === MonthStatus.editable &&
      newStatus === MonthStatus.submitted
    ) {
      if (
        window.confirm(
          'Are you sure you want to change the month\'s status to "submitted"? This action means you will no longer be able to make changes to the time records for this month.'
        ) !== true
      ) {
        return;
      }
    }

    // setMonthTimeRecordsInfo(undefined);
    contentRef.current?.classList.add("loading");

    // setMonthRecordedData(undefined);
    // setMonthStatus(undefined);

    // const endpointChangeMonthStatus = serverApi.changeMonthStatus;
    // const dataToSend = {
    //   date: month.toJSON(),
    //   newStatus: newStatus,
    // };
    // const doIfSuccess = undefined;
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // const doAlways = () => {
    //   monthRecordedDataRefresh();
    // };
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchPost<{}>(
    //   endpointChangeMonthStatus,
    //   dataToSend,
    //   doIfSuccess,
    //   doIfFailed,
    //   doAlways,
    //   doIfError
    // );
    const dataToSend = {
      date: month,
      newStatus: newStatus,
    };
    fetchers.account.member.changeMonthStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        monthRecordedDataRefresh();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  const changeDayStatus = (
    date: DateTime,
    oldStatus: DayStatus,
    newStatus: DayStatus
  ) => {
    if (date.graterThan(month.getEndOfMonth())) {
      return;
    }
    if (newStatus === oldStatus) {
      return;
    }

    var valid =
      (oldStatus === DayStatus.completed && 
        (newStatus === DayStatus.submitted || newStatus === DayStatus.editable)) ||
      (oldStatus === DayStatus.submitted &&
        // newStatus == DayStatus.completed ||
        newStatus === DayStatus.editable) ||
      (oldStatus === DayStatus.editable && newStatus === DayStatus.submitted);
    if (!valid) {
      showAlert(alertMessages.invalidData);
      logValidationError("Wrong status.");
      return;
    }
    // setMonthTimeRecordsInfo(undefined);
    contentRef.current?.classList.add("loading");

    // setMonthRecordedData(undefined);
    // setMonthStatus(undefined);

    // const endpointChangeDayStatus = serverApi.changeDayStatus;
    // var dataToSend = {
    //   date: date.toJSON(),
    //   newStatus: newStatus,
    // };
    // const doIfSuccess = undefined;
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // const doAlways = () => {
    //   monthRecordedDataRefresh();
    // };
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchPost<{}>(
    //   endpointChangeDayStatus,
    //   dataToSend,
    //   doIfSuccess,
    //   doIfFailed,
    //   doAlways,
    //   doIfError
    // );

    const dataToSend = {
      date: date,
      newStatus: newStatus,
    };
    fetchers.account.member.changeDateStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        monthRecordedDataRefresh();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  const downloadTimesheet = () => {
    const dataToSend = {
      date: month,
    };
    fetchers.account.member.generateMonthTimesheetFile.fetch(dataToSend, {
      success: (data, status) => {
        let url = URL.createObjectURL(data.file);
        const defaultFilename =
          "whitefly_ewidencja_" +
          auth.firstName.replace(/\s/g, "-") +
          "-" +
          auth.lastName.replace(/\s/g, "-") +
          "_" +
          month.getLocDay().toString().padStart(2, "0") +
          "-" +
          month.getLocYear().toString() +
          "-" +
          new DateTime().toLocIsoShortString() +
          ".pdf";
        const filename = data.filename ? data.filename : defaultFilename;
        // console.log(data.filename);
        if (fileAnchorRef.current) {
          fileAnchorRef.current.href = url;
          fileAnchorRef.current.download = filename;
          fileAnchorRef.current.click();
        }
        URL.revokeObjectURL(url);
        // alert(alertMessages.downloaded + "\n" + "File name: " + filename + "");
        // showAlert(alertMessages.done, true);
      },
      fail: (status) => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: (error) => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  return (
    <>
      {monthTimeRecordsInfo && (
        // monthStatus && monthRecordedData &&
        <MonthRecordsContext.Provider
          value={{
            month,
            setMonth,
            availableMonths,
            monthTimeRecordsInfo,
            // monthStatus,
            // monthRecordedData,
            selectedDay,
            setSelectedDay,
            modalStatus,
            openModal,
            closeModal,
            changeModalDate,
            changeMonthStatus,
            changeDayStatus,
            refreshMonth,
            downloadTimesheet,
            fileAnchorRef,
          }}
        >
          <div ref={contentRef} className="MonthTimeRecordProvider">
            {children}
          </div>
        </MonthRecordsContext.Provider>
      )}
      {!monthTimeRecordsInfo && (
        // || !monthStatus || !monthRecordedData
        <Placeholder />
      )}
    </>
  );
}
