import Base from "../../components/pageBases/Base";
import apiUrls from "../../data/api.json";
import "./Unauthorized.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import lockImage from "../../assets/images/lock2.png";
import { Image } from "react-bootstrap";

export function Unauthorized() {
  return (
    <Base>
      <div className="Unauthorized">
        <div className="wrapper">
            <div></div>
          <div className="locked">
            <Image src={lockImage} />
          </div>
          <div>
          <h1>Hold up!</h1>
          <p className="mb-4">
            Unauthorized
            <span className="extra"></span>. <br /> Let us help guide you out
            and get you back home.
          </p>
          </div>
          <div className="buttons">
            <a href={apiUrls.index}>Home</a>
            <br />
          </div>
        </div>
        {/* <div className="side">
          <div className="lock">
            <Image src={lockImage}/>
          </div>
        </div> */}
      </div>
      {/* <FontAwesomeIcon icon={faLock} size="lg" /> */}
    </Base>
  );
}
