import "./DayStatusRequested.scss";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { DayStatus, renderDayStatus } from "../../utils/DayStatus";

type DayStatusRequestedProps = {
  requestedStatus: DayStatus;
  onCancel: () => void;
  variant?: 'small'|'normal';
  className?: string;
  disabled?: boolean;
};
export function DayStatusRequested({
  requestedStatus,
  onCancel,
  variant,
  className,
  disabled,
}: DayStatusRequestedProps) {
    const title = (_requestedStatus: DayStatus) => {
        return 'Requested status "' + renderDayStatus(_requestedStatus) + '"'
    }
    const handleChange = (evk: string) => {
        onCancel && onCancel();
    }
  return (
    <>
      <DropdownButton title={title(requestedStatus)} as={ButtonGroup} size="sm"
      variant="outline-secondary" className={`DayStatusRequested ${variant} ${className}  ${requestedStatus}`}
      drop="down-centered" onSelect={(evk: string | null) => evk && handleChange(evk)}
      disabled={disabled}>
        <Dropdown.Item eventKey={"cancel"}>Cancel request</Dropdown.Item>
      </DropdownButton>
    </>
  );
}
