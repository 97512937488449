import { ReactNode, useContext } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import "./CardHeaderToggle.scss";

type ContextAwareToggleProps = {
  children: (isActive: boolean) => ReactNode;
  eventKey: string;
  onClick?: (eventKey: string) => void;
  className?: string;
};
export function CardHeaderToggle({
  children,
  eventKey,
  onClick,
  className
}: ContextAwareToggleProps) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => onClick && onClick(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;
  const isActive = !!isCurrentEventKey;
  return (
    <Card.Header
      className={`${className} ${isActive ? "active" : ""}`}
      onClick={decoratedOnClick}
    >
      {children(isActive)}
    </Card.Header>
  );
}
