import { Placeholder as PlaceholderBS } from "react-bootstrap";

export function Placeholder() {
  return (
    <PlaceholderBS animation="glow">
      <PlaceholderBS xs={6} />
      <PlaceholderBS xs={8} />
      <PlaceholderBS xs={7} />
      <PlaceholderBS xs={6} />
      <PlaceholderBS xs={7} />
      <PlaceholderBS xs={8} />
    </PlaceholderBS>
  );
}
