import { Col, Row } from "react-bootstrap";
import Table from "../../../../../components/Table";
import { useMemberWorktimeSummary } from "../../context/SummaryContext";
import TooltipButton from "../../../../../components/TooltipButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import "./SummaryTable.scss";
import { useUsers } from "../../../../../contexts/UsersContext";
import { AuthRoles } from "../../../../../utils/AuthRoles";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import Slide from "../../../../../components/Slide";
import { useState } from "react";

export function SummaryTable() {
  const {
    memberWorktimeSummary,
    navigateToMemberDayTimeRecords,
    navigateToMonthMemberTimeRecords,
  } = useMemberWorktimeSummary();
  // const { allUsersByRole } = useUsers();
  // const mm = allUsersByRole(AuthRoles.member).map((member) => {
  // const info = memberWorktimeSummary.find(
  //     (memberInfo) => memberInfo.member.id === member.id
  //   );
  //   return {
  //     member: member,
  //     info:  ((info) => {
  //       if(info) {
  //         const { member, ...rest } = info;
  //         return rest;
  //       } else {
  //         return null
  //       }
  //         })(info)
  //   };
  // });

  // console.log(mm)

  const [toggleIndex, setToggleIndex] = useState<number>(0);


  return (
    <div className="SummaryTable">
      <Table>
        <Table.Head>
          <Row className="justify-content-between">
            <Col xs={6} className="align-self-center">
              Member
            </Col>
            <Col xs={3} className="text-center align-self-center d-none d-sm-flex">
              <span>
                <span className="d-none d-md-inline">Total </span>Day Worktime
              </span>
            </Col>
            {/* <Col xs={1} className="text-end"></Col> */}
            <Col xs={3} className="text-center align-self-center d-none d-sm-flex">
              <span>
                <span className="d-none d-md-inline">Total </span>Month Worktime
              </span>
            </Col>
            {/* <Col xs={1} className="text-center">
              
            </Col> */}
            {/* <Col xs={3} className="text-center">Action</Col> */}
            <Col xs={4}>
              <Slide value={toggleIndex} onSelect={setToggleIndex} >
                <Slide.Item>
                  <div className="text-center align-self-center d-flex flex-column">
                     <span>Day</span> <span>Worktime</span>
                  </div>
                </Slide.Item>
                <Slide.Item>
                  <div className="text-center align-self-center d-flex flex-column">
                     <span>Month</span> <span>Worktime</span>
                  </div>
                </Slide.Item>
              </Slide>
            </Col>
          </Row>
        </Table.Head>
        <Table.Body>
          {memberWorktimeSummary.map(({ member, totals }) => (
            <Row
              className={
                (totals ? "" : "disabled ") +
                "justify-content-between align-items-center"
              }
              key={member.id}
            >
              {/* Name */}
              <Col xs={6} className="">
                {member.lastName + " " + member.firstName}
              </Col>
              <Col xs={4} className="d-sm-none">
                <Slide value={toggleIndex} onSelect={setToggleIndex}>
                  <Slide.Item>
                    <div className="text-center d-flex justify-content-center align-items-center">
                      {totals && (
                        <>
                          <div
                            style={{ minWidth: "3.5rem" }}
                            className="totalTime ms-2"
                          >
                            <TimeHoursMinutes
                              totalMinutes={totals.dateTotalMinutes}
                              lightLetters={true}
                            />
                          </div>
                          <div>
                            <TooltipButton
                              tooltipContent="Details"
                              buttonVariant="light"
                              className="table-row-btn openDay"
                              onClick={() => {
                                navigateToMemberDayTimeRecords(member);
                              }}
                              // disabled={day.isDisabled}
                            >
                              <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </TooltipButton>
                          </div>
                        </>
                      )}
                      {!totals && <>not created yet</>}
                    </div>
                  </Slide.Item>
                  <Slide.Item>
                    <div className="text-center d-flex justify-content-center align-items-center">
                      {totals && (
                        <>
                          <div
                            style={{ minWidth: "3.5rem" }}
                            className="totalTime ms-2"
                          >
                            <TimeHoursMinutes
                              totalMinutes={totals.monthTotalMinutes}
                              lightLetters={true}
                            />
                          </div>
                          <div>
                            <TooltipButton
                              tooltipContent="Details"
                              buttonVariant="light"
                              className="table-row-btn openMonth"
                              onClick={() => {
                                navigateToMonthMemberTimeRecords(member);
                              }}
                              // disabled={day.isDisabled}
                            >
                              <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </TooltipButton>
                          </div>
                        </>
                      )}
                      {!totals && <>not created yet</>}
                    </div>
                  </Slide.Item>
                </Slide>
              </Col>

              {/* Day */}
              <Col
                xs={3}
                className="text-center d-none d-sm-flex justify-content-center align-items-center"
              >
                {totals && (
                  <>
                    <div
                      style={{ minWidth: "3.5rem" }}
                      className="totalTime ms-2"
                    >
                      <TimeHoursMinutes
                        totalMinutes={totals.dateTotalMinutes}
                        lightLetters={true}
                      />
                    </div>
                    <div>
                      <TooltipButton
                        tooltipContent="Details"
                        buttonVariant="light"
                        className="table-row-btn openDay"
                        onClick={() => {
                          navigateToMemberDayTimeRecords(member);
                        }}
                        // disabled={day.isDisabled}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </TooltipButton>
                    </div>
                  </>
                )}
                {!totals && <>not created yet</>}
              </Col>

              {/* Month */}
              <Col
                xs={3}
                className="text-center d-none d-sm-flex justify-content-center align-items-center"
              >
                {totals && (
                  <>
                    <div
                      style={{ minWidth: "3.5rem" }}
                      className="totalTime ms-2"
                    >
                      <TimeHoursMinutes
                        totalMinutes={totals.monthTotalMinutes}
                        lightLetters={true}
                      />
                    </div>
                    <div>
                      <TooltipButton
                        tooltipContent="Details"
                        buttonVariant="light"
                        className="table-row-btn openMonth"
                        onClick={() => {
                          navigateToMonthMemberTimeRecords(member);
                        }}
                        // disabled={day.isDisabled}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </TooltipButton>
                    </div>
                  </>
                )}
                {!totals && <>not created yet</>}
              </Col>
            </Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
