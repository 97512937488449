import { ListOfAccordions } from "../../../../../components/ListOfAccordions/ListOfAccordions";
import { useSetCollection } from "../../../../../hooks/useSetCollection";
import { useTasksList } from "../../context/TasksListContext";
import Category from "./components/Category/Category";
import CategoryTasks from "./components/CategoryTasks/CategoryTasks";

export function CategoriesList(){
    const {
        allTasks,
        openTaskCreationModal,
        openTaskEditionModal,
        openCategoryEditionModal,
      } = useTasksList();
      const activeKeysSet = useSetCollection<string>([]);
      return (
        <div className="CategoriesList">
          <ListOfAccordions
            list={allTasks.getSortedByCategory().categories}
            accordionHeader={(category, active) => (
              <Category
                category={category}
                className={`${active ? "active" : ""} ${
                  category.isPublic ? "public" : ""
                }`}
              />
            )}
            accordionBody={(category) => <CategoryTasks category={category} />}
          />
        </div>
      );
}