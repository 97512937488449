import { useNavigate } from "react-router";
import { useApp } from "../../../../contexts/AppContext";
import { Category, Task, Tasks, TasksJson } from "../../../../utils/Task";
import {
  ReactNode,
  useState,
  useEffect,
} from "react";
import { Placeholder } from "react-bootstrap";
import alertMessages from "../../../../data/alertMessages.json";
import { TaskModal, useTaskModal } from "../hooks/useTaskModal";
import { CategoryModal, useCategoryModal } from "../hooks/useCategoryModal";
// import { ModalStatus, useModal } from "../../hooks/useModal";
import { ModalStatus, useModall } from "../hooks/useModall";
import { useRoleAuth } from "../../../../contexts/RoleAuthContext/RoleAuthContext";
import { fetchers } from "../../../../serverApi/fetchers";
import { useTimerContext } from "../../../../contexts/TimerContext";
import { createContext } from "../../../../hooks/useCreateContext";

type TasksListContextVal = {
  allTasks: Tasks;
  modalStatus: ModalStatus;
  openTaskCreationModal: (category?: string | undefined) => void;
  openTaskEditionModal: (task: Task) => void;
  openCategoryEditionModal: (category: string) => void;
  closeModal: () => void;
  refreshAllTasks: () => void;
};
const [TasksListContext, useTasksList] =
  createContext<TasksListContextVal>();
export { useTasksList };

type TasksListProviderProps = {
  children: ReactNode;
};

export function TasksListProvider({ children }: TasksListProviderProps) {
  const { showAlert } = useApp();
  // const navigate = useNavigate();
  // const [modalStatus, openModalTaskCreation, openModalTaskEdition, openModalCategoryEdition, closeModal] = useModal();
  const {allTasks, timerDataRefresh} = useTimerContext();
  const [
    modalStatus,
    openTaskCreationModal,
    openTaskEditionModal,
    openCategoryEditionModal,
    closeModal,
  ] = useModall();

  // const [allTasks, setAllTasks] = useState<Tasks | undefined>(undefined);

  const refreshAllTasks = () => {
    timerDataRefresh();
    // const endpointGetTasks = serverApi.getTasks;
    // const doIfSuccess = (data: TasksJson) => {
    //   const receivedTasks = new Tasks(data.tasks.map((task) => new Task(task.id, task.name, task.category, task.isPublic, task.isDeleted)));
    //   if (receivedTasks.tasks.length < 1) {
    //     throw new Error("Something went wrong.");
    //   }
    //   setAllTasks(receivedTasks);
    // };
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // const doAlways = undefined;
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchGet<TasksJson>(
    //   endpointGetTasks,
    //   undefined,
    //   doIfSuccess,
    //   doIfFailed,
    //   doAlways,
    //   doIfError
    // );
    // fetchers.account.member.tasks.fetch({}, {
    //   success: (data, status) => {
    //     setAllTasks(new Tasks(data));
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   }
    // })
  };
  useEffect(() => {
    refreshAllTasks();
  }, []);
  return (
    <>
      {allTasks && (
        <TasksListContext.Provider
          value={{
            allTasks,
            modalStatus,
            openTaskCreationModal,
            openTaskEditionModal,
            openCategoryEditionModal,
            closeModal,
            refreshAllTasks,
          }}
        >
          {children}
        </TasksListContext.Provider>
      )}
      {!allTasks && <Placeholder />}
    </>
  );
}
