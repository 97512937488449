import { Card as CardBS, Col, Row, Button } from "react-bootstrap";
import { sumHours } from "../../../../../utils/TimeMathFormules";
import { useMonthRecordsTable } from "../../context/MonthRecordsTableContext";
import DatePicker from "../../../../../components/DatePicker";
import MonthStatusDropdown from "../../../../../components/admin/MonthStatusDropdown";
import MonthStatusUnresolvedButton from "../../../../../components/MonthStatusUnresolvedButton";
import _ from "lodash";
import "./Header.scss";
import UserSelector from "../../../../../components/admin/UserSelector";
import { useUsers } from "../../../../../contexts/UsersContext";
import { AuthRoles } from "../../../../../utils/AuthRoles";
import { User } from "../../../../../utils/Users";
import { DateTime } from "../../../../../utils";
import { Month, Year } from "../../../../../utils/TimeTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { roundUpToNearestHalf } from "../../../../../utils/TimeMathFormules";
import TooltipButton from "../../../../../components/TooltipButton";
import { MonthStatus } from "../../../../../utils/MonthStatus";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import TimeMinutesInHours from "../../../../../components/TimeMinutesInHours";
import MonthStatusUnresolvedDropdown from "../../../../../components/admin/MonthStatusUnresolvedDropdown";

export function Header() {
  const {
    member,
    month,
    setMonth,
    monthRecordedData,
    changeMonthStatus,
    availableMonths,
    redirectToAnotherMember,
    setMember,
    redirectToYear,
    downloadTimesheet,
    fileAnchorRef,
    resolveAllMonthStatusRequests
  } = useMonthRecordsTable();
  const { allUsersByRole } = useUsers();
  // var totalWorkingHours = sumHours(
  //   monthRecordedData,
  //   (day) => day.totalWorkTimeMin
  // );

  // var documentedWorkingHours = roundUpToNearestHalf(totalWorkingHours);

  var requestsUnresolved =
    monthRecordedData.monthDaysInfo.filter((day) => day.requestedStatus !== null).length > 0;

  return (
      <div className="Header">
          <div className="h-100 info">
            <div className="mb-3">
              <UserSelector
                users={allUsersByRole(AuthRoles.member).filter(
                  (member) =>
                    month.graterThanOrEqual(
                      DateTime.createLocDateTime(
                        member.created.getLocYear(),
                        member.created.getLocMonth()
                      )
                    ) && month.lessThanOrEqual(new DateTime())
                )}
                value={member}
                onSelect={(member: User) => {
                  // redirectToAnotherMember(member)
                  setMember(member);
                }}
                variant="dropdown"
              />
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <div className="d-flex border-end px-2 justify-content-center justify-content-c-sm-start align-items-center flex-wrap">
                {/* <div className="openYear">
                  <Button
                    onClick={() => {
                      redirectToYear(
                        DateTime.createLocDateTime(month.getLocYear())
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                </div> */}
                <div>
                  {/* <DatePicker selected={month.date} dateFormat="MM.yyyy" showMonthYearPicker className="mx-1" readOnly={!setMonth} onChange={(month) => month && setMonth && setMonth(new DateTime(month))} /> */}
                  <DatePicker
                    date={month}
                    setDate={setMonth}
                    format={"monthOnly"}
                    minDate={availableMonths.minMonth}
                    maxDate={availableMonths.maxMonth}
                    leftArrow={true}
                    rightArrow={true}
                    // leftArrowClassName="d-none d-sm-inline-block"
                    // rightArrowClassName="d-none d-sm-inline-block"
                  />
                </div>
                {!requestsUnresolved && (
                  <div>
                    <MonthStatusDropdown
                      variant="small"
                      status={monthRecordedData.status}
                      onChange={(status) => {
                        changeMonthStatus(status);
                      }}
                    />
                  </div>
                )}
                {requestsUnresolved && (
                  <div>
                    <MonthStatusUnresolvedDropdown variant="small"
                      onSelect={(grant) => {
                        resolveAllMonthStatusRequests(grant);
                      }}/>
                  </div>
                )}
              </div>
              

              <div className="d-flex justify-content-center justify-content-c-sm-start align-items-center time-totals">
                {/* Total Time */}
                <div className="d-flex justify-content-start align-items-center border-end px-2 total-time">
                  <div className="title mx-1">T<span className="name d-c-md-none">otal</span><span className="d-none d-c-md-inline">otal time</span>:</div>
                  <div className="hours mx-1">
                    <TimeHoursMinutes
                      totalMinutes={monthRecordedData.totalMinutes}
                      boldNumbers={true}
                      larger={true}
                    />
                  </div>
                </div>


                {/* Documented Time */}
                <div className="d-flex justify-content-start align-items-center px-2 doc-time">
                  <div className="title mx-1">D<span className="name d-c-md-none">oc.</span><span className="d-none d-c-md-inline d-c-lg-none">oc. time</span><span className="d-none d-c-lg-inline">ocumented time</span>:</div>
                  <div className="hours mx-1">
                  <TimeHoursMinutes
                    totalMinutes={monthRecordedData.documentedTotalMinutes}
                    boldNumbers={true}
                    larger={true}
                  />
                  </div>
                  <div className="hours mx-1 ms-2 d-none d-c-md-block">
                    <TimeMinutesInHours
                      totalMinutes={monthRecordedData.documentedTotalMinutes}
                      inParens={true}
                    />
                  </div>
                </div>
              </div>

              {monthRecordedData.status === MonthStatus.completed && !requestsUnresolved &&
              (<div className="d-flex flex-fill justify-content-end align-items-center  downloadButton">
                <TooltipButton
                  tooltipContent="Download Timesheet"
                  buttonVariant="light"
                  className="downloadTimesheet"
                  onClick={() => {
                    downloadTimesheet();
                  }}
                >
                <span className="d-none d-lg-inline me-1">Download</span>
                  <FontAwesomeIcon icon={faFileArrowDown} 
                  size="lg"
                  />
                </TooltipButton>
                <a href="" download="" ref={fileAnchorRef} />
              </div>)}
            </div>
          </div>
    </div>
  );
}
