import { useAuth } from "../../contexts/AuthContext";
import usePageTitle from "../../hooks/usePageTitle";
import MemberAccountSettingsWidget from "../../widgets/member/AccountSettings";

export function AccountSettings() {
  const { auth } = useAuth();
  const pageTitle = {full: "Account Settings", short: "Settings"};
  usePageTitle(pageTitle);
  return <>{auth && <MemberAccountSettingsWidget auth={auth} />}</>;
}
