import { Col, Container, Row } from "react-bootstrap";
import Head from "./components/Head";
import Body from "./components/Body";
import { TableProvider } from "./context/TableContext/TableContext";
import { ReactNode } from "react";
import "./Table.scss"

type TableProps = {
    children: ReactNode,
    className?: string,
    style?: React.CSSProperties
}

export function Table({children, className, style} : TableProps) {
  return (
    <TableProvider>
    <div className={`Table ${className ? className : ""}`} style={style}>
      {children}
    </div>
    </TableProvider>
  );
}

Table.Head = Head;
Table.Body = Body;

