import { ReactNode } from "react";
import { PendingMembersRequestsProvider } from "./context/PendingMembersRequestsProvider";
import WidgetCard from "../../../components/WidgetCard";
import Header from "./components/Header";
import RequestsList from "./components/RequestsList";

// type PendingMembersRequestsListProps = {};
  
  export function PendingMembersRequestsList() {
    const RequestsProvider = ({ children }: { children: ReactNode }) => (
      <PendingMembersRequestsProvider
        children={children}
      />
    );
    return (
      <WidgetCard className="PendingMembersRequestsList" ContextProvider={RequestsProvider}>
        {/* <WidgetCard.Header>
          
        </WidgetCard.Header> */}
        <WidgetCard.Body>
          <Header />
          <RequestsList />
        </WidgetCard.Body>
      </WidgetCard>
    );
  }
