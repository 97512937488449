import { ReactNode, useEffect, useState } from "react";
import { createContext } from "../../../../hooks/useCreateContext";
import { User } from "../../../../utils/Users";
import { DateTime } from "../../../../utils";
import { DayStatus } from "../../../../utils/DayStatus";
import Placeholder from "../../../../components/Placeholder";
import { fetchers } from "../../../../serverApi/fetchers";
import alertMessages from "../../../../data/alertMessages.json";
import { useApp } from "../../../../contexts/AppContext";
import { generatePath, To } from "react-router";
import { createSearchParams } from "react-router-dom";
import apiUrls from "../../../../data/api.json";
import { Formatter } from "../../../../utils/Formatter";

// type PendingRequestsOLD = {
//   member: User;
//   months: {
//     month: DateTime;
//     requests: {
//       id: string;
//       date: DateTime;
//       oldStatus: DayStatus;
//       newStatus: DayStatus;
//     }[];
//   }[];
// }[]
export type RequestsMonthMemberRequest = {
  id: string;
  date: DateTime;
  oldStatus: DayStatus;
  newStatus: DayStatus;
  warning: boolean;
};
export type RequestsMonthMember = {
  member: User;
  requests: RequestsMonthMemberRequest[];
  warning: boolean;
};
export type RequestsMonth = {
  month: DateTime;
  members: RequestsMonthMember[];
  warning: boolean;
};
type PendingRequests = RequestsMonth[];

type PendingMembersRequestsContextVal = {
  pendingRequests: PendingRequests;
  getMonthRecordsLink: (member: User, month: DateTime, date?: DateTime) => To
  getDayRecordsLink: (member: User, date: DateTime) => To;
  // navigateToMemberDayTimeRecords: (member: User) => void;
  // navigateToMonthMemberTimeRecords: (member: User) => void;
  clearDisabled: boolean;
  resolveRequests: (requestsIds: string[], grant: boolean, doAlways?: () => void) => void;
};
const [PendingMembersRequestsContext, usePendingMembersRequests] =
  createContext<PendingMembersRequestsContextVal>();
export { usePendingMembersRequests };

type PendingMembersRequestsProviderProps = {
  children: ReactNode;
};

export function PendingMembersRequestsProvider({
  children,
}: PendingMembersRequestsProviderProps) {
  const { showAlert } = useApp();
  // const [disabledListItems, setDisabledListItems]
  const [clearDisabled, setClearDisabled] = useState(false);
  const [pendingRequests, setPendingRequests] = useState<
    PendingRequests | undefined
  >(undefined);

  const refreshRequests = async () => {
    fetchers.account.admin.membersPendingRequests.fetch(
      {},
      {
        success: (data, status) => {
          setPendingRequests(data);
        },
        fail: () => {
          showAlert(alertMessages.somethingWentWrong);
        },
        error: () => {
          showAlert(alertMessages.somethingWentWrong);
        },
        always:() => {
          setClearDisabled(!clearDisabled);
        }
      }
    );
  };
  useEffect(() => {
    refreshRequests();
  }, []);

  // const acceptRequest = (
  //   member: User,
  //   date: DateTime,
  //   newStatus: DayStatus, doAlways: () => void = () => {}
  // ) => {
  //   if (date.graterThan(new DateTime())) {
  //     return;
  //   }
  //   const dataToSend = {
  //     userId: member.id,
  //     date: date,
  //     newStatus: newStatus,
  //   };
  //   fetchers.account.admin.changeDateStatus.fetch(dataToSend, {
  //     fail: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //     always: () => {
  //       doAlways();
  //       refreshRequests();
  //     },
  //     error: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //   });
  // };

  // const rejectRequest = (member: User, date: DateTime, doAlways: () => void = () => {}) => {
  //   if (date.graterThan(new DateTime())) {
  //     return;
  //   }
  //   const dataToSend = {
  //     userId: member.id,
  //     date: date,
  //   };
  //   fetchers.account.admin.rejectDateStatusRequest.fetch(dataToSend, {
  //     fail: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //     always: () => {
  //       doAlways();
  //       refreshRequests();
  //     },
  //     error: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //   });
  // };
  // const resolveRequest = (
  //   member: User,
  //   date: DateTime,
  //   newStatus: DayStatus,
  //   grant: boolean
  //   , doAlways: () => void = () => {}
  // ) => {
  //   if (grant) {
  //     acceptRequest(member, date, newStatus, doAlways);
  //   } else {
  //     rejectRequest(member, date, doAlways);
  //   }
  // };

  // const resolveMonth = (month: DateTime, grant: boolean, doAlways: () => void = () => {}) => {
  //   const dataToSend = {
  //     date: month,
  //     grant: grant,
  //   };
  //   fetchers.account.admin.resolveAllMonthRequests.fetch(dataToSend, {
  //     fail: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //     always: () => {
  //       doAlways();
  //       refreshRequests();
  //     },
  //     error: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //   });
  // };

  // const resolveMemberMonth = (
  //   member: User,
  //   month: DateTime,
  //   grant: boolean, doAlways: () => void = () => {}
  // ) => {
  //   const dataToSend = {
  //     userId: member.id,
  //     date: month,
  //     grant: grant,
  //   };
  //   fetchers.account.admin.resolveAllMonthDateStatusRequests.fetch(dataToSend, {
  //     fail: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //     always: () => {
  //       doAlways();
  //       refreshRequests();
  //     },
  //     error: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //   });
  // };

  // const resolveAll = (grant: boolean, doAlways: () => void = () => {}) => {
  //   const dataToSend = {
  //     grant: grant,
  //   };
  //   fetchers.account.admin.resolveAllRequests.fetch(dataToSend, {
  //     fail: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //     always: () => {
  //       doAlways()
  //       refreshRequests();
  //     },
  //     error: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //   });
  // };

  const resolveRequests = (requestsIds: string[],  grant: boolean, doAlways: () => void = () => {}) => {
    const dataToSend = {
      grant: grant,
      requests: requestsIds
    };
    fetchers.account.admin.resolveRequests.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        doAlways()
        refreshRequests();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  }

  const getMonthRecordsLink = (member: User, month: DateTime, date?: DateTime): To => {
    const idHash = date ? date.getLocDay().toString(): "";
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0");
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();
    return {
      pathname: generatePath(
        apiUrls.account.children.admin.children.memberMonth,
        {
          userId: member.id,
        }
      ),
      hash: idHash,
      search: queryParams,
    };
  };

  const getDayRecordsLink = (member: User, date: DateTime): To => {
    const dayValNum = date.getLocDay();
    const dayVal = dayValNum < 10 ? "0" + dayValNum : "" + dayValNum;
    const monthValNum = date.getLocMonth();
    const monthVal = monthValNum < 10 ? "0" + monthValNum : "" + monthValNum;
    const queryParams = createSearchParams({
      date: dayVal + "." + monthVal + "." + date.getLocYear(),
    }).toString();
    return {
      pathname: generatePath(
        apiUrls.account.children.admin.children.memberMonth,
        {
          userId: member.id,
        }
      ),
      search: queryParams,
    };
  };

  return (
    <>
      {pendingRequests !== undefined && (
        <PendingMembersRequestsContext.Provider
          value={{
            pendingRequests,
            getMonthRecordsLink,
            getDayRecordsLink,
            clearDisabled,
            resolveRequests
          }}
        >
          {children}
        </PendingMembersRequestsContext.Provider>
      )}
      {pendingRequests == undefined && <Placeholder />}
    </>
  );
}
