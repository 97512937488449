import { Col, Row } from "react-bootstrap";
import { useTable } from "../../context/TableContext/TableContext";
import { ReactNode } from "react";
import "./Body.scss"

type BodyProps = {
  children: ReactNode, 
  className? : string,
  style?: React.CSSProperties
}

export function Body({children, className, style}: BodyProps) {
  useTable();
  return (
    <div className={`Body ${className ? className : ""}`} style={style}>
      {children}
      
    </div>
  );
}
