import { ReactNode } from "react";
import { GridProvider, useGrid } from "./context/GridContext";
import "./Grid.scss";

type NodeProp = {
  children: ReactNode;
  variant?: string;
};

function Node({ children, variant = "regular" }: NodeProp) {
  useGrid();
  var className = "";
  if (variant == "main") className = "main";
  return <div className={`Node ${className}`}>{children}</div>;
}

type GridProp = {
  children: ReactNode;
};
export function Grid({ children }: GridProp) {
  return (
    <GridProvider>
      <div className="Grid">{children}</div>
    </GridProvider>
  );
}

Grid.Node = Node;
