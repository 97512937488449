import React from "react";

import {
  Nav,
  Navbar as NavbarBS,
  NavDropdown as NavDropdownBS,
} from "react-bootstrap";
import { Button } from "react-bootstrap";
import logo from "../../assets/images/logoFull.png";
import logoSmall from "../../assets/images/logoShort.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useSidebar } from "../../contexts/SidebarContext/SidebarContext";
import apiUrls from "../../data/api.json";
import "./Navbar.scss";
import Clock from "../Clock";
import alertMessages from "../../data/alertMessages.json";
import { useApp } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router";
import { useLogout } from "../../hooks/useLogout";
import { AuthRoles, getAuthRoleIndex } from "../../utils/AuthRoles";
import { useBase } from "../pageBases/Base";
import {
  detectRoleByPath,
  getIndexApiLinkForRole,
} from "../../utils/defaultPathForRoles";
import CookiesMenager from "../../utils/cookiesMenager";

export function Navbar() {
  const { auth, roleIsAuthorized } = useAuth();
  const { showAlert } = useApp();
  const { pageTitle } = useBase();
  const { isOpen, openSidebar, closeSidebar } = useSidebar();
  const navigate = useNavigate();
  const logout = useLogout();

  const settingsLink = roleIsAuthorized(AuthRoles.member)
    ? apiUrls.account.children.member.children.settings
    : roleIsAuthorized(AuthRoles.admin)
    ? apiUrls.account.children.admin.children.settings
    : undefined;
  const getRoleMainPath = () => {
    const role = detectRoleByPath(window.location.pathname);
    if (role && roleIsAuthorized(role)) {
      CookiesMenager.setCookie("lastr", getAuthRoleIndex(role).toString(), {
        ...CookiesMenager.defaultOptions,
        expires: 30,
      });
      return getIndexApiLinkForRole(role);
    } else {
      return apiUrls.index;
    }
  };
  return (
    <NavbarBS
      style={{ zIndex: 1039 }}
      className="Navbar justify-content-between align-items-center"
    >
      <Nav className="nav1">
        <Button
          variant="link"
          style={{ color: "blue" }}
          onClick={isOpen ? closeSidebar : openSidebar}
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
        <NavbarBS.Brand href={getRoleMainPath()} className="brand">
          <img src={logo} className="d-none d-sm-block"></img>
          <img src={logoSmall} className="small d-sm-none" height={40}></img>
        </NavbarBS.Brand>
        {pageTitle && (
          <>
            <div className="pageTitle d-none d-sm-flex">{pageTitle.full}</div>
            <div className="pageTitle short d-sm-none">{pageTitle.short}</div>
          </>
        )}
      </Nav>

      {/* <Nav className="m-auto"></Nav> */}
      <Nav className=" nav2 justify-content-end align-items-center">
        <Clock variant="wide" className="d-none d-md-flex" />
        <Clock variant="slim" className="d-md-none" />
        {auth && (
          <NavDropdownBS
            className="dropdown"
            title={<FontAwesomeIcon icon={faUser} />}
            id="basic-nav-dropdown"
            align="end"
          >
            <NavDropdownBS.Header>
              {auth.firstName + " " + auth.lastName}
            </NavDropdownBS.Header>
            {settingsLink && (
              <NavDropdownBS.Item
                onClick={() =>
                  navigate(
                    settingsLink
                    // { replace: true }
                  )
                }
              >
                Settings
              </NavDropdownBS.Item>
            )}
            <NavDropdownBS.Item onClick={logout}>Log out</NavDropdownBS.Item>
          </NavDropdownBS>
        )}
      </Nav>
    </NavbarBS>
  );
}
