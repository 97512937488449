import { Carousel, Col, Row } from "react-bootstrap";
import Table from "../../../../../components/Table";
import {
  SummaryMonth,
  MemberMonthlyTimesheet,
  useMonthlyTimesheetsContext,
} from "../../context/MonthlyTimesheetsContext";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import TooltipButton from "../../../../../components/TooltipButton";
import MonthStatusUnresolvedButton from "../../../../../components/MonthStatusUnresolvedButton";
import { NavLink, To } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faFileArrowDown,
  faSort,
  faCaretRight,
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./SummaryTable.scss";
import { ListOfAccordions } from "../../../../../components/ListOfAccordions/ListOfAccordions";
import { DateTime } from "../../../../../utils";
import { useEffect, useState } from "react";
import { User } from "../../../../../utils/Users";
import { MonthStatus } from "../../../../../utils/MonthStatus";
import _ from "lodash";
import { useDrag, useGesture } from "@use-gesture/react";
import MonthStatusDropdown from "../../../../../components/admin/MonthStatusDropdown";
import Slide from "../../../../../components/Slide";

// type StatusTotalsHeadToggleProps = {
//   className?: string;
//   onChange?: (index: number) => void;
// };
// function StatusTotalsHeadToggle({
//   onChange,
//   className = "",
// }: StatusTotalsHeadToggleProps) {
//   // type CarouselIndex = 0 | 1;
//   const [index, setIndex] = useState<number>(0);

//   const handleSelect = (selectedIndex: number) => {
//     // setIndex((Math.abs(selectedIndex) % 2) as CarouselIndex);
//     setIndex(selectedIndex);
//   };
//   useEffect(() => {
//     onChange && onChange(index);
//   }, [index]);
//   const itemClicked = () => {
//     onChange && onChange(index);
//   };
//   return (
//     <Carousel
//       className={"StatusTotalsToggle StatusTotalsHeadToggle " + className}
//       activeIndex={index}
//       onSelect={handleSelect}
//       controls={true}
//       interval={null}
//       touch={true}
//       wrap={false}
//       indicators={false}
//       prevIcon={<FontAwesomeIcon icon={faCaretLeft} />}
//       nextIcon={<FontAwesomeIcon icon={faCaretRight} />}
//     >
//       <Carousel.Item
//         onClick={() => {
//           itemClicked();
//         }}
//       >
//         Month Status
//       </Carousel.Item>
//       <Carousel.Item
//         onClick={() => {
//           itemClicked();
//         }}
//       >
//         Doc. Time
//       </Carousel.Item>
//     </Carousel>
//   );
// }

// type StatusTotalsToggleProps = {
//   className?: string;
//   forcedValue: { refreshToggle: boolean; index: number };
//   memberSummary: MemberMonthlyTimesheet;
//   changeMonthStatus: (
//     user: User,
//     oldStatus: MonthStatus,
//     newStatus: MonthStatus
//   ) => void;
//   getMonthRecordsLink: (member: User) => To;
// };

// function StatusTotalsToggle({
//   className = "",
//   forcedValue,
//   memberSummary,
//   changeMonthStatus,
//   getMonthRecordsLink,
// }: StatusTotalsToggleProps) {
//   const [index, setIndex] = useState<number>(0);

//   const handleSelect = (selectedIndex: number) => {
//     // setIndex((Math.abs(selectedIndex) % 2) as CarouselIndex);
//     // console.log(selectedIndex);
//     setIndex(selectedIndex);
//   };

//   useEffect(() => {
//     handleSelect(forcedValue.index);
//   }, [forcedValue]);

//   return (
//     <Carousel
//       className={"StatusTotalsToggle " + className}
//       activeIndex={index}
//       onSelect={handleSelect}
//       controls={true}
//       interval={null}
//       touch={true}
//       wrap={false}
//       indicators={false}
//       prevIcon={<FontAwesomeIcon icon={faCaretLeft} />}
//       nextIcon={<FontAwesomeIcon icon={faCaretRight} />}
//     >
//       <Carousel.Item>
//         {memberSummary.month && (
//           <>
//             {!memberSummary.month.hasRequests && (
//               <div>
//                 <span>
//                   <MonthStatusDropdown
//                     status={memberSummary.month.status}
//                     onChange={(newStatus) => {
//                       memberSummary.month &&
//                         changeMonthStatus(
//                           memberSummary.member,
//                           memberSummary.month.status,
//                           newStatus
//                         );
//                     }}
//                   />
//                 </span>
//               </div>
//             )}
//             {memberSummary.month.hasRequests && (
//               <div>
//                 <NavLink to={getMonthRecordsLink(memberSummary.member)}>
//                   <MonthStatusUnresolvedButton disabled={false} />
//                 </NavLink>
//               </div>
//             )}
//           </>
//         )}
//         {!memberSummary.month && <>not created yet</>}
//       </Carousel.Item>
//       <Carousel.Item>
//         {memberSummary.month && (
//           <div style={{ minWidth: "3.5rem" }}>
//             <TimeHoursMinutes
//               totalMinutes={memberSummary.month.documentedMonthTotalMinutes}
//               lightLetters={true}
//             />
//           </div>
//         )}
//         {!memberSummary.month && <>not created yet</>}
//       </Carousel.Item>
//     </Carousel>
//   );
// }

type OpenMonthProps = {
  memberSummary: MemberMonthlyTimesheet;
  getMonthRecordsLink: (member: User) => To;
  className?: string;
};

function OpenMonth({
  memberSummary,
  getMonthRecordsLink,
  className = "",
}: OpenMonthProps) {
  return (
    <>
      {memberSummary.month && (
        <div>
          <NavLink to={getMonthRecordsLink(memberSummary.member)}>
            <TooltipButton
              tooltipContent="Details"
              buttonVariant="light"
              className={"table-row-btn openMonth " + className}
              // disabled={day.isDisabled}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </TooltipButton>
          </NavLink>
        </div>
      )}
      {!memberSummary.month && (
        <div>
          <TooltipButton
            tooltipContent="Details"
            buttonVariant="light"
            className={"table-row-btn openMonth " + className}
            disabled={true}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </TooltipButton>
        </div>
      )}
    </>
  );
}

export default function SummaryTable() {
  const {
    monthlyTimesheetsSummary,
    changeMonthStatus,
    getMonthRecordsLink,
    isSelectedRecord,
    toggleSelectRecord,
  } = useMonthlyTimesheetsContext();

  // const [index, _setIndex] = useState<{
  //   refreshToggle: boolean;
  //   index: number;
  // }>({ refreshToggle: false, index: 0 });

  // const setIndex = (newIndex: number) => {
  //   _setIndex({
  //     refreshToggle: !index.refreshToggle,
  //     index: Math.abs(newIndex),
  //   });
  // };
  const [toggleIndex, setToggleIndex] = useState<number>(0);

  return (
    <div className="SummaryTable">
      <Table>
        <Table.Head>
          <Row className="justify-content-between">
            {/* <Col
              xs={1}
              sm={undefined}
              md={undefined}
              className="text-center align-self-center d-sm-none invisible"
            >
              O
            </Col> */}
            <Col
              xs={7}
              sm={4}
              md={4}
              className="align-self-center ps-4 ps-sm-0"
            >
              Member
            </Col>
            <Col
              xs={undefined}
              sm={2}
              md={2}
              className="text-center align-self-center d-none d-sm-flex justify-content-center"
            >
              <span className="d-none d-md-inline">Total Time</span>
              <span className="d-md-none">Total</span>
            </Col>
            {/* <Col xs={1} className="text-end"></Col> */}
            <Col
              xs={undefined}
              sm={2}
              md={2}
              className="text-center align-self-center d-none d-sm-flex justify-content-center"
            >
              <span className="d-none d-md-inline">Documented Time</span>
              <span className="d-md-none">Doc. Time</span>
            </Col>
            <Col
              xs={2}
              sm={1}
              md={1}
              className="text-center align-self-center d-none d-sm-flex invisible"
            >
              O
            </Col>
            <Col xs={5} sm={3} md={2} className="text-center align-self-center">
              {/* <StatusTotalsHeadToggle
                onChange={(newIndex: number) => {
                  setIndex(newIndex);
                }}
              /> */}
              <span className="d-none d-sm-inline">Month Status</span>
              <Slide className="d-sm-none" value={toggleIndex} onSelect={setToggleIndex}>
                <Slide.Item>
                  <div className="d-flex flex-column">
                    <span>Month</span> <span>Status</span>
                  </div>
                </Slide.Item>
                <Slide.Item>
                  <div className="d-flex flex-column">
                    <span>Doc.</span> <span>Time</span>
                  </div>
                </Slide.Item>
              </Slide>
            </Col>
            <Col
              xs={undefined}
              sm={undefined}
              md={1}
              className="text-center align-self-center d-none d-md-flex invisible"
            >
              D
            </Col>
          </Row>
        </Table.Head>

        <Table.Body>
          {monthlyTimesheetsSummary.map((memberSummary) => (
            <Row
              className={
                (memberSummary.month ? "" : "disabled ") +
                "justify-content-between align-items-center " +
                (isSelectedRecord(memberSummary) ? "active" : "")
              }
              key={memberSummary.member.id}
              onClick={() => {
                toggleSelectRecord(memberSummary);
              }}
            >
              {/* Open Month Mobile */}
              {/* <Col xs={1} sm={1} md={1} className="text-center d-sm-none px-0">
                {"x"}
                <OpenMonth
                  memberSummary={memberSummary}
                  getMonthRecordsLink={getMonthRecordsLink}
                  className="p-0"
                />
              </Col> */}

              {/* Name */}
              <Col
                xs={7}
                sm={4}
                md={4}
                className="d-flex"
                style={{ wordWrap: "break-word" }}
              >
                <div className="text-center d-sm-none">
                  <OpenMonth
                    memberSummary={memberSummary}
                    getMonthRecordsLink={getMonthRecordsLink}
                    className="ps-0"
                  />
                </div>
                <div className="ps-1 pe-2 flex-fill d-flex align-items-center">
                  {memberSummary.member.lastName +
                    " " +
                    memberSummary.member.firstName}
                </div>
              </Col>

              {/* Totals */}
              <Col
                xs={undefined}
                sm={2}
                md={2}
                className="text-center justify-content-center align-items-center d-none d-sm-flex"
              >
                {memberSummary.month && (
                  <div style={{ minWidth: "3.5rem" }}>
                    <TimeHoursMinutes
                      totalMinutes={memberSummary.month.monthTotalMinutes}
                      lightLetters={true}
                    />
                  </div>
                )}
                {!memberSummary.month && <>not created yet</>}
              </Col>
              <Col
                xs={undefined}
                sm={2}
                md={2}
                className="text-center justify-content-center align-items-center d-none d-sm-flex"
              >
                {memberSummary.month && (
                  <div style={{ minWidth: "3.5rem" }}>
                    <TimeHoursMinutes
                      totalMinutes={
                        memberSummary.month.documentedMonthTotalMinutes
                      }
                      lightLetters={true}
                    />
                  </div>
                )}
                {!memberSummary.month && <>not created yet</>}
              </Col>

              {/* Open Month */}
              <Col
                xs={1}
                sm={1}
                md={1}
                className="text-center d-none d-sm-flex openMonthCol"
              >
                <OpenMonth
                  memberSummary={memberSummary}
                  getMonthRecordsLink={getMonthRecordsLink}
                />
                {/* {memberSummary.month && (
                  <div>
                    <NavLink to={getMonthRecordsLink(memberSummary.member)}>
                      <TooltipButton
                        tooltipContent="Details"
                        buttonVariant="light"
                        className="openMonth"
                        // disabled={day.isDisabled}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </TooltipButton>
                    </NavLink>
                  </div>
                )}
                {!memberSummary.month && (
                  <div>
                    <TooltipButton
                      tooltipContent="Details"
                      buttonVariant="light"
                      className="openMonth"
                      disabled={true}
                    >
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </TooltipButton>
                  </div>
                )} */}
              </Col>
              {/* Status */}
              <Col xs={5} sm={3} md={2} className="text-center status">
                {/* <StatusTotalsToggle
                  className="d-sm-none"
                  forcedValue={index}
                  memberSummary={memberSummary}
                  changeMonthStatus={changeMonthStatus}
                  getMonthRecordsLink={getMonthRecordsLink}
                /> */}
                <Slide value={toggleIndex} onSelect={setToggleIndex} className="d-sm-none">
                  <Slide.Item>
                    <div>
                      {memberSummary.month && (
                        <>
                          {!memberSummary.month.hasRequests && (
                            <div>
                              <span>
                                <MonthStatusDropdown
                                  status={memberSummary.month.status}
                                  onChange={(newStatus) => {
                                    memberSummary.month &&
                                      changeMonthStatus(
                                        memberSummary.member,
                                        memberSummary.month.status,
                                        newStatus
                                      );
                                  }}
                                />
                              </span>
                            </div>
                          )}
                          {memberSummary.month.hasRequests && (
                            <div>
                              <NavLink
                                to={getMonthRecordsLink(memberSummary.member)}
                              >
                                <MonthStatusUnresolvedButton disabled={false} />
                              </NavLink>
                            </div>
                          )}
                        </>
                      )}
                      {!memberSummary.month && <>not created yet</>}
                    </div>
                  </Slide.Item>
                  <Slide.Item>
                    {memberSummary.month && (
                      <div style={{ minWidth: "3.5rem" }}>
                        <TimeHoursMinutes
                          totalMinutes={
                            memberSummary.month.documentedMonthTotalMinutes
                          }
                          lightLetters={true}
                        />
                      </div>
                    )}
                    {!memberSummary.month && <>not created yet</>}
                  </Slide.Item>
                </Slide>
                <div className="d-none d-sm-block">
                  {memberSummary.month && (
                    <>
                      {!memberSummary.month.hasRequests && (
                        // <Tooltip tooltipContent="Change status" className="openDay"></Tooltip>
                        <div>
                          <span>
                            <MonthStatusDropdown
                              status={memberSummary.month.status}
                              onChange={(newStatus) => {
                                memberSummary.month &&
                                  changeMonthStatus(
                                    memberSummary.member,
                                    memberSummary.month.status,
                                    newStatus
                                  );
                              }}
                            />
                          </span>
                        </div>
                      )}
                      {memberSummary.month.hasRequests && (
                        <div>
                          <NavLink
                            to={getMonthRecordsLink(memberSummary.member)}
                          >
                            <MonthStatusUnresolvedButton disabled={false} />
                          </NavLink>
                        </div>
                      )}
                    </>
                  )}
                  {!memberSummary.month && <>not created yet</>}
                </div>
              </Col>

              {/* Download */}
              <Col
                xs={undefined}
                sm={undefined}
                md={1}
                className="text-center align-self-center d-none d-md-flex downloadTimesheet download"
              >
                <div>
                  <TooltipButton
                    tooltipContent="Download"
                    buttonVariant="light"
                    className={"table-row-btn download"}
                    disabled={
                      !memberSummary.month ||
                      memberSummary.month.status !== MonthStatus.completed ||
                      memberSummary.month.hasRequests
                    }
                  >
                    <FontAwesomeIcon icon={faFileArrowDown} />
                  </TooltipButton>
                </div>
              </Col>
            </Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
