import { DateTime } from "./DateTime";
import { Task } from "./Task";
export enum TaskRecordGroups {
  billableTimerecords = "billableTimerecords",
  unverifiedTimerecords = "unverifiedTimerecords",
}
export type TaskRecord = {
  id: string;
  task: Task;
  period: {
    startedAt: DateTime;
    endedAt: DateTime | null;
  };
  durationMin: number;
  isManual: boolean;
  group: TaskRecordGroups;
}
// export class TaskRecord {
//   id: string;
//   task: Task;
//   period: {
//     startedAt: DateTime;
//     endedAt: DateTime | null;
//   };
//   durationMin: number;
//   isManual: boolean;
//   group: TaskRecordGroups;

//   constructor(
//     id: string,
//     task: Task,
//     period: {
//       startedAt: DateTime;
//       endedAt: DateTime | null;
//     },
//     durationMin: number,
//     isManual: boolean,
//     group: TaskRecordGroups
//   ) {
//     this.id = id;
//     this.task = task;
//     this.period = period;
//     this.durationMin = durationMin;
//     this.isManual = isManual;
//     this.group = group;
//   }
// }

export type TimeRecord = {
  id: string | null;
  task: Task;
  period: {
    startedAt: DateTime;
    endedAt: DateTime | null;
  };
  durationMin: number;
  isManual: boolean;
  group: TaskRecordGroups;
}

export type ManualTimeRecord = {
  id: string | null;
  task: Task;
  period: {
    startedAt: DateTime;
    endedAt: null;
  };
  durationMin: number;
  isManual: true;
  group: TaskRecordGroups;
};
export type ClockedTimeRecord = {
  id: string;
  task: Task;
  period: {
    startedAt: DateTime;
    endedAt: DateTime;
  };
  durationMin: number;
  isManual: false;
  group: TaskRecordGroups;
};

function isManualTimeRecord(timeRecord: ManualTimeRecord | TimeRecord): timeRecord is ManualTimeRecord {
  return timeRecord.isManual === true && timeRecord.period.endedAt === null;
}

function isClockedTimeRecord(timeRecord: ClockedTimeRecord | TimeRecord): timeRecord is ClockedTimeRecord {
  return timeRecord.isManual === false && timeRecord.period.endedAt !== null;
}

