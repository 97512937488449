import { useEffect, useReducer, useRef } from "react";
import { useNavigate } from "react-router";
import { JSONObject, JSONValue } from "./JsonType";
import { objectMap } from "./objectMap";

const dev = process.env.NODE_ENV === "development";
// const server = (dev ? "http://127.0.0.1:5000" : "") + "/api";
const server = "/api";
export enum RequestMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  HEAD = "HEAD",
  PATCH = "PATCH",
  DELETE = "DELETE",
}
// interface State<T> {
//   data?: T;
//   error?: Error;
// }
// type  State<T> = [
//   data?: T,
//   error?: Error
// ]

// type ReceivedData<T> = T;

export type ErrorResponce = { error: string };

type FetchOptions = {
  method?: string;
  mode?: "cors";
  headers?: HeadersInit;
  body?: BodyInit | null;
  credentials?: "include" | "same-origin";
};

async function fetchFromEndpoint<T = unknown>(
  endpoint: string,
  options?: FetchOptions,
  doIfSuccess?: (data: T, status: number) => void,
  doIfFailed?: (data: ErrorResponce, status: number) => void,
  doAlways?: () => void,
  doIfError?: (error: any) => void
): Promise<T> {
  const url = server + endpoint;
  return fetch(url, options)
    .then((resp) =>
      Promise.all([
        resp.json(),
        Promise.resolve(resp.status),
        Promise.resolve(resp.ok),
      ])
    )
    .then((dataAndStatusAndOk) => {
      const [data, status, isOk] = dataAndStatusAndOk;
      if (isOk) {
        doIfSuccess && doIfSuccess(data, status);
      } else {
        doIfFailed && doIfFailed(data, status);
      }
      doAlways && doAlways();
      return data;
    })
    .catch((error) => {
      if (process.env.NODE_ENV == "development") {
      console.log("Request error: ",error);
      }
      doIfError && doIfError(error);
      doAlways && doAlways();
    });
  // .finally(() => {
  //   doAlways && doAlways();
  // });
}

async function fetchFileFromEndpoint(
  endpoint: string,
  options?: FetchOptions,
  doIfSuccess?: (data: {filename: string | null, file: Blob}, status: number) => void,
  doIfFailed?: (data: ErrorResponce, status: number) => void,
  doAlways?: () => void,
  doIfError?: (error: any) => void
): Promise<{filename: string | null, file: Blob}> {
  const url = server + endpoint;
  return fetch(url, options)
    .then((resp) =>
      Promise.all([
        resp,
        Promise.resolve(resp.status),
        Promise.resolve(resp.ok),
      ])
    )
    .then((respAndStatusAndOk) => {
      const [resp, status, isOk] = respAndStatusAndOk;
      if (isOk) {
        return resp
          .blob()
          .then((blob) => {
            const extractFilename = (contentDisposition: string | null): string | null => {
              if (!contentDisposition) {
                return null;
              }
              // const matches = contentDisposition.match(/filename\*=UTF-8''(.+)/);
              const matches = contentDisposition.match(/filename=([^;]+)/);
              if (matches && matches.length > 1) {
                return decodeURIComponent(matches[1]);
              }
              return null;
            }
            const contentDisposition = resp.headers.get('Content-Disposition');
            const filename = extractFilename(contentDisposition);
            const data = {
              filename: filename,
              file: blob
            }
            doIfSuccess && doIfSuccess(data, status);
            doAlways && doAlways();
            return data;
          })
          .catch((error) => {
            doIfError && doIfError(error);
            doAlways && doAlways();
          });
      }
      return resp
        .json()
        .then((json) => {
          doIfFailed && doIfFailed(json, status);
          doAlways && doAlways();
          return json;
        })
        .catch((error) => {
          doIfError && doIfError(error);
          doAlways && doAlways();
        });
    })
    .catch((error) => {
      doIfError && doIfError(error);
      doAlways && doAlways();
    });
}

async function _fetchGet<T = unknown>(
  endpoint: string,
  queryParams?: { [k: string]: string },
  doIfSuccess?: (data: T, status: number) => void,
  doIfFailed?: (data: ErrorResponce, status: number) => void,
  doAlways?: () => void,
  doIfError?: () => void
): Promise<T> {
  const _doIfError = (error: any) => {
    console.log(
      "%c Sorry! Something went wrong. Please, try again later.",
      "color: #B81104;"
    );
    if (process.env.NODE_ENV == "development") {
      console.log(`%c Request error: ${error.message}`, "color: #B81104;");
    }
    doIfError && doIfError();
  };
  const _doIfFailed = (data: ErrorResponce, status: number) => {
    if (status === 401) {
      // window.location.replace("/");
    }
    doIfFailed && doIfFailed(data, status);
  };

  let queryString = "";
  if (queryParams) {
    queryString = "?" + new URLSearchParams(queryParams).toString();
  }

  // const url = server + endpoint;

  return fetchFromEndpoint<T>(
    endpoint + queryString,
    {
      method: "GET",
      credentials: "include",
    },
    doIfSuccess,
    _doIfFailed,
    doAlways,
    _doIfError
  );
}

function getCookie(name: string): string {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const value = parts.pop()?.split(";").shift();
    return value ? value : "";
  }
  return "";
}

async function _fetchPost<T = unknown>(
  endpoint: string,
  dataToSend: object,
  doIfSuccess?: (data: T, status: number) => void,
  doIfFailed?: (data: ErrorResponce, status: number) => void,
  doAlways?: () => void,
  doIfError?: () => void
): Promise<T> {
  const _doIfError = (error: any) => {
    console.log(
      "%c Sorry! Something went wrong. Please, try again later.",
      "color: #B81104;"
    );
    if (process.env.NODE_ENV == "development") {
      console.log("%c Request error: ", "color: #B81104;", error);
    }
    doIfError && doIfError();
  };
  const _doIfFailed = (data: ErrorResponce, status: number) => {
    if (status === 401) {
      // window.location.replace("/");
    }
    doIfFailed && doIfFailed(data, status);
  };
  // const url = server + endpoint;
  return fetchFromEndpoint<T>(
    endpoint,
    {
      method: "POST",
      // mode: 'cors',
      headers: {
        "Content-Type": "aplication/json",
        Accept: "application/json",
        "X-CSRF-TOKEN": getCookie("csrf_access_token"),
        // "Access-Control-Allow-Credentials": "true"
      },
      body: JSON.stringify(dataToSend),
      credentials: "include",
    },
    doIfSuccess,
    _doIfFailed,
    doAlways,
    _doIfError
  );
}

type FetchGetOptions<T> = {
  query?: { [k: string]: string };
  success?: (data: T, status: number) => void;
  fail?: (data: ErrorResponce, status: number) => void;
  always?: () => void;
  error?: () => void;
};
export async function fetchGet<T = unknown>(
  endpoint: string,
  options?: FetchGetOptions<T>
): Promise<T> {
  return _fetchGet<T>(
    endpoint,
    options?.query,
    options?.success,
    options?.fail,
    options?.always,
    options?.error
  );
}

type FetchPostOptions<T> = {
  success?: (data: T, status: number) => void;
  fail?: (data: ErrorResponce, status: number) => void;
  always?: () => void;
  error?: () => void;
};
export async function fetchPost<T = unknown>(
  endpoint: string,
  data: JSONObject,
  options?: FetchPostOptions<T>
): Promise<T> {
  return _fetchPost<T>(
    endpoint,
    data,
    options?.success,
    options?.fail,
    options?.always,
    options?.error
  );
}

export type FetchServerOptions<T> = {
  success?: (data: T, status: number) => void;
  fail?: (data: ErrorResponce, status: number) => void;
  always?: () => void;
  error?: (error?: any) => void;
};

export async function fetchServer<T = unknown>(
  method: RequestMethods,
  endpoint: string,
  data: JSONObject,
  options?: FetchServerOptions<T>
) {
  const bodyless =
    method === RequestMethods.GET ||
    method === RequestMethods.HEAD ||
    method === RequestMethods.DELETE;
  let queryString = "";
  if (bodyless && data) {
    let queryData = objectMap(
      data,
      (value: JSONValue, key: string, index: number) => String(value)
    );
    queryString = "?" + new URLSearchParams(queryData).toString();
  }
  return fetchFromEndpoint<T>(
    endpoint + queryString,
    {
      method: method,
      // mode: 'cors',
      headers: {
        "Content-Type": "aplication/json",
        Accept: "application/json",
        "X-CSRF-TOKEN": getCookie("csrf_access_token"),
        // "Access-Control-Allow-Credentials": "true"
      },
      body: !bodyless ? JSON.stringify(data) : undefined,
      credentials: "include",
    },
    options?.success,
    options?.fail,
    options?.always,
    options?.error
  );
}

export type FetchFileServerOptions = {
  success?: (data: {filename: string | null, file: Blob}, status: number) => void;
  fail?: (data: ErrorResponce, status: number) => void;
  always?: () => void;
  error?: (error?: any) => void;
};

export async function fetchFileServer(
  method: RequestMethods,
  endpoint: string,
  data: JSONObject,
  options?: FetchFileServerOptions
) {
  const bodyless =
    method === RequestMethods.GET ||
    method === RequestMethods.HEAD ||
    method === RequestMethods.DELETE;
  let queryString = "";
  if (bodyless && data) {
    let queryData = objectMap(
      data,
      (value: JSONValue, key: string, index: number) => String(value)
    );
    queryString = "?" + new URLSearchParams(queryData).toString();
  }
  return fetchFileFromEndpoint(
    endpoint + queryString,
    {
      method: method,
      // mode: 'cors',
      headers: {
        "Content-Type": "aplication/json",
        Accept: "application/json",
        "X-CSRF-TOKEN": getCookie("csrf_access_token"),
        // "Access-Control-Allow-Credentials": "true"
      },
      body: !bodyless ? JSON.stringify(data) : undefined,
      credentials: "include",
    },
    options?.success,
    options?.fail,
    options?.always,
    options?.error
  );
}

export async function fetchAll<T extends readonly unknown[] | []>(
  fetches: T,
  options?: {
    success?: (data: {
      -readonly [P in keyof T]: Exclude<Awaited<T[P]>, undefined>;
    }) => void;
    fail?: () => void;
    always?: () => void;
    error?: () => void;
  }
) {
  const len = fetches.length;
  Promise.all(fetches)
    .then((results) => {
      let hasUndefined = false;
      for (let i = 0; i < len; i++) {
        if (results[i] === undefined) {
          hasUndefined = true;
          break;
        }
      }
      if (hasUndefined) {
        options?.fail && options.fail();
        options?.always && options.always();
        return;
      }
      const nonUndefinedResults = results as {
        [P in keyof T]: Exclude<Awaited<T[P]>, undefined>;
      };
      options?.success && options.success(nonUndefinedResults);
      options?.always && options.always();
    })
    .catch((error) => {
      options?.error && options.error();
      options?.always && options.always();
    });
}
