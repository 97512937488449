import { useEffect, useRef } from "react";

type Comparator<T> = (v1: T, v2: T) => boolean;
type Dependency<T> = [T, Comparator<T>];

function useDeepCompareMemorize<T>(dependency: Dependency<T>) {
const [value, deepCompareEquals] = dependency;
  const ref = useRef<T | undefined>(undefined);
  if (ref.current === undefined || !deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current as T;
}

export function useDeepCompareEffect(
  callback: () => void,
  dependencies: Dependency<any>[]
): void {
  useEffect(
    callback,
    dependencies.map(useDeepCompareMemorize)
  );
}