import { Outlet } from "react-router";
import AccountBase from "../../components/pageBases/AccountBase";

export function AccountLayout() {
  return (
    <AccountBase>
      <Outlet />
    </AccountBase>
  );
}
