import { User } from "../../../../utils/Users";
import { ReactNode, useEffect, useState } from "react";
import { Placeholder } from "react-bootstrap";
import { useApp } from "../../../../contexts/AppContext";
import { useUsers } from "../../../../contexts/UsersContext";
import { AuthRoles } from "../../../../utils/AuthRoles";
import { OpenUserModalStatus, useUsersModal } from "../hooks/useUserModal";
import { ModalStatus } from "../../../../hooks/useModal";
import alertMessages from "../../../../data/alertMessages.json";
import { generatePath, useNavigate } from "react-router";
import { DateTime, fetchPost } from "../../../../utils";
import { createSearchParams } from "react-router-dom";
import apiUrls from "../../../../data/api.json";
import { createContext } from "../../../../hooks/useCreateContext";
import { usePrevious } from "../../../../hooks/usePreviousValue";
import { fetchers } from "../../../../serverApi/fetchers";


type UsersListContextVal = {
  allUsers: User[];
  deletedUsers: User[];
  allUsersByRole: (role: AuthRoles) => User[];
  modalStatus: ModalStatus<OpenUserModalStatus>;
  openUserCreationModal: (role?: AuthRoles | undefined) => void;
  openUserEditionModal: (user: User) => void;
  openDeletedUsersModal: () => void;
  closeModal: () => void;
  usersRefresh: (doAlways?: ()=>void) => void;
  openMemberMonthTimeRecords: (member: User) => void;
  openMemberYearTimeRecords: (member: User) => void;
  restoreUserFromDeleted: (user: User) => void;
  // disabledUsers: boolean;
  // setDisabledUsers: (newVal: boolean) => void; 
  disabledUsers: User[];
  lastOpenedTab: AuthRoles;
  setLastOpenedTab: (lastOpenedTab: AuthRoles) => void;
};
const [UsersListContext, useUsersList] =
  createContext<UsersListContextVal>();
export { useUsersList };

type UsersListProviderProps = {
  children: ReactNode;
};

export function UsersListProvider({ children }: UsersListProviderProps) {
  const { showAlert } = useApp();
  const navigate = useNavigate();
  const { allUsers,deletedUsers, allUsersByRole, usersRefresh } = useUsers();
  const [disabledUsers, setDisablesUsers] = useState<User[]>([]);
  const [lastOpenedTab, setLastOpenedTab] = useState<AuthRoles>(Object.values(AuthRoles)[0]); 
  const [
    modalStatus,
    openUserCreationModal,
    openUserEditionModal,
    openDeletedUsersModal,
    closeModal,
   ] = useUsersModal();
  // const [disabledUsers,  setDisabledUsers] = useState<boolean>(false);
  const openMemberMonthTimeRecords = (member: User) => {
    if(!member.roles.includes(AuthRoles.member)){
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const queryParams = createSearchParams({year: (new DateTime()).getLocYear()}).toString();
    navigate(
      {
        pathname: generatePath(apiUrls.account.children.admin.children.memberMonth, {
          userId: member.id,
        }),
        search: queryParams,
      },
      // { replace: true }
    );
  }
  const openMemberYearTimeRecords = (member: User) => {
    if(!member.roles.includes(AuthRoles.member)){
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const queryParams = createSearchParams({year: (new DateTime()).getLocYear()}).toString();
    navigate(
      {
        pathname: generatePath(apiUrls.account.children.admin.children.memberYear, {
          userId: member.id,
        }),
        search: queryParams,
      },
      // { replace: true }
    );
  }

  const restoreUserFromDeleted = (user: User) => {
    const dataToSend = {
      id: user.id,
    };
    fetchers.account.admin.restoreUser.fetch(dataToSend, {
      success: () => {
        usersRefresh();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // fetchPost("/admin/users/restore-user", dataToSend, {
    //   success: () => {
    //     usersRefresh();
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  }

  return (
    <UsersListContext.Provider
      value={{
        allUsers,
        deletedUsers,
        allUsersByRole,
        modalStatus,
        openUserCreationModal: (role?: AuthRoles | undefined) => {setDisablesUsers(role !== undefined ? allUsersByRole(role) : allUsers); openUserCreationModal(role);},
        openUserEditionModal: (user: User) => {setDisablesUsers([...disabledUsers, user]); openUserEditionModal(user);},
        openDeletedUsersModal: () => {setDisablesUsers(allUsers); openDeletedUsersModal();},
        closeModal: () => {usersRefresh(()=>{setDisablesUsers([]);}); closeModal()},
        usersRefresh: () => {usersRefresh(()=>{setDisablesUsers([]);})},
        openMemberMonthTimeRecords,
        openMemberYearTimeRecords,
        restoreUserFromDeleted: (user: User)=> {setDisablesUsers([...disabledUsers, user]); restoreUserFromDeleted(user);},
        disabledUsers,
        lastOpenedTab,
        setLastOpenedTab
      }}
    >
      {children}
    </UsersListContext.Provider>
  );
}
