import TooltipButton from "../../../../../components/TooltipButton";
import { useTasksList } from "../../context/TasksListContext";
import "./Header.scss";

export function Header() {
  const { allTasks, openTaskCreationModal } = useTasksList();
  return (
    <div className="Header d-flex align-self-center">
      <div>Task Categories</div>
      <div style={{ marginRight: "0px" }}>
        <TooltipButton
          tooltipContent="Create new record"
          buttonVariant="outline-primary"
          size="sm"
          className="mx-1 px-1"
          style={{ fontWeight: "bold", marginRight: "-8px" }}
          onClick={() => openTaskCreationModal()}
        >
          New task
        </TooltipButton>
      </div>
    </div>
  );
}
