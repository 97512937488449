import { ReactNode, useState } from "react"
import { createContext } from "../../hooks/useCreateContext";

type ThemeContextVal = {
    theme: string
    allThemes: string[]
    changeTheme: (theme: string) => void
};
const [ThemeContext, useTheme] =
createContext<ThemeContextVal>();
export { useTheme };

type ThemeProviderProps = {
    children: ReactNode
}
export function ThemeProvider({ children }: ThemeProviderProps) {
    const allThemes = ['light', 'dark']
    const [theme, setTheme] = useState(allThemes[0])
    const changeTheme = (theme: string) => setTheme(theme)

    return(
        <ThemeContext.Provider value={{theme, allThemes, changeTheme}}>
            <div className={`${theme}-theme h-100`}>
                {children}
            </div>
        </ThemeContext.Provider>
    )
}
