import { Outlet } from "react-router";
import MemberPanelBase from "../../components/pageBases/MemberPanelBase";
import { useApp } from "../../contexts/AppContext";
import { AuthRoles, getAuthRoleIndex } from "../../utils/AuthRoles";
import CookiesMenager from "../../utils/cookiesMenager";

export function MemberLayout() {
  return (
    <MemberPanelBase>
      <Outlet />
    </MemberPanelBase>
  );
}
