import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CategorySelector from "../../../../../../components/CategorySelector";
import { useTasksList } from "../../../context/TasksListContext";
import { useEffect, useRef, useState } from "react";
import { Category, Task, TasksByCategory } from "../../../../../../utils/Task";
import ValidationFeedback from "../../../../../../components/ValidationFeedback";
import { ErrorLog, useFormErrors } from "../../../../../../hooks/useFormErrors";
import TooltipButton from "../../../../../../components/TooltipButton";
import { logValidationError } from "../../../../../../utils/logError";
import InputText from "../../../../../../components/InputText";
import restrictions from "../../../../../../data/restrictions.json";
import { useApp } from "../../../../../../contexts/AppContext";
import alertMessages from "../../../../../../data/alertMessages.json";
import {
  ValidationResult,
  useFormSubmit,
} from "../../../../../../hooks/useFormSubmit";
import {
  sameTaskAlreadyExist,
  validateCategoryName,
  validateTaskName,
} from "../utils/validators";
import { fetchers } from "../../../../../../serverApi/fetchers";
import { fetchPost } from "../../../../../../utils";

type FormErrors = {
  categoryNameError: ErrorLog;
  taskNameError: ErrorLog;
  generalError: ErrorLog;
};

type TaskInfo = {
  categoryName: string;
  taskName: string;
};
type ModalTaskCreationProps = {
  defaultTask: Task;
};
export function ModalTaskCreation({ defaultTask }: ModalTaskCreationProps) {
  const { showAlert } = useApp();
  const { allTasks, closeModal, refreshAllTasks } = useTasksList();

  const [taskInfo, setTaskInfo] = useState<TaskInfo>({
    categoryName: defaultTask.category,
    taskName: defaultTask.name,
  });

  const validateSubmitCategoryName = (
    categoryName: string
  ): ValidationResult => {
    return validateCategoryName(categoryName, allTasks);
  };
  const validateSubmitTaskName = (taskName: string): ValidationResult => {
    return validateTaskName(taskName);
  };
  const validateSubmitGeneral = (
    category: string,
    taskName: string
  ): ValidationResult => {
    if (sameTaskAlreadyExist(category, taskName, allTasks)) {
      const message = "Task already exists.";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };

  const validateSubmitData = (): boolean => {
    return (
      validateSubmitCategoryName(taskInfo.categoryName.trim()).isValid &&
      validateSubmitTaskName(taskInfo.taskName.trim()).isValid &&
      validateSubmitGeneral(taskInfo.categoryName.trim(), taskInfo.taskName.trim()).isValid
    );
  };

  const sendSubmitData = (_doIfSuccess: () => void, _doAlways: () => void) => {
    const dataToSend = {
      taskName: taskInfo.taskName.trim(),
      categoryName: taskInfo.categoryName.trim(),
    };
    fetchers.account.admin.createPublicTask.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        refreshAllTasks();
      },
      always: () => {
        _doAlways();
      },
      fail: (data, status) => {
        if (status === 449) {
          showAlert((alertMessages.maxTasksNumberIsReached).replace("{{maxTasksNumber}}", restrictions.maxTasksNumber.toString()));
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/tasks/create_task";
    // const dataToSend = {
    //   taskName: taskInfo.taskName.trim(),
    //   categoryName: taskInfo.categoryName.trim(),
    // };
    // fetchPost(endpoint, dataToSend, {
    //   success: () => {
    //     _doIfSuccess();
    //     closeModal();
    //     refreshAllTasks();
    //   },
    //   always: () => {
    //     _doAlways();
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };

  const defaultFormErrorsValues = {
    categoryNameError: null,
    taskNameError: null,
    generalError: null,
  };

  const getUpdatedFormErrors = (
    _taskInfo: TaskInfo | undefined = taskInfo
  ): FormErrors => {
    const vCategoryRes = validateSubmitCategoryName(_taskInfo.categoryName.trim());
    const vTaskNameRes = validateSubmitTaskName(_taskInfo.taskName.trim());
    const vGeneralRes = validateSubmitGeneral(
      _taskInfo.categoryName.trim(),
      _taskInfo.taskName.trim()
    );
    return {
      ...formErrors,
      categoryNameError: vCategoryRes.message,
      taskNameError: vTaskNameRes.message,
      generalError: vGeneralRes.message,
    };
  };

  const [
    formStatus,
    formErrors,
    handleSubmit,
    submitIsPossible,
    updateFormErrors,
  ] = useFormSubmit<FormErrors, TaskInfo>(
    defaultFormErrorsValues,
    validateSubmitData,
    sendSubmitData,
    getUpdatedFormErrors
  );

  const handleCategoryNameChange = (newCategoryName: string) => {
    const newCategoryNameTrimmed = newCategoryName; //.trim();
    const newTaskInfo = {
      ...taskInfo,
      categoryName: newCategoryNameTrimmed,
    };
    updateFormErrors(newTaskInfo);
    setTaskInfo(newTaskInfo);
  };

  const handleTaskNameChange = (newTaskName: string) => {
    const newTaskNameTrimmed = newTaskName; //.trim();
    const newTaskInfo = {
      ...taskInfo,
      taskName: newTaskNameTrimmed,
    };
    updateFormErrors(newTaskInfo);
    setTaskInfo(newTaskInfo);
  };

  const submitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && submitIsPossible) {
      handleSubmit();
    }
  }
  return (
    <Container className="w-auto mx-md-3 ModalTaskCreation" onKeyDown={submitOnEnter}>
      <Form.Group className="mb-3">
        <Form.Label>Category:</Form.Label>
        <CategorySelector
          variant="combobox"
          categories={allTasks.tasksByCategory.categories
            .filter((category) => !!category.isPublic)
            .map((category) => category.name)}
          defaultValue={taskInfo.categoryName}
          onSelect={handleCategoryNameChange}
          inputMaxChars={restrictions.maxTaskCategoryNameLength}
        />
        <ValidationFeedback message={formErrors.categoryNameError} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Task:</Form.Label>
        <InputText
          value={taskInfo.taskName}
          maxLength={restrictions.maxTaskCategoryNameLength}
          onChange={handleTaskNameChange}
        />
        <ValidationFeedback message={formErrors.taskNameError} />
      </Form.Group>
      <Form.Group as={Row} className="mt-5">
        <Col className="d-flex justify-content-end">
          <ValidationFeedback
            message={formErrors.generalError}
            className="text-end me-3"
          />
          <TooltipButton
            tooltipContent={`Save changes`}
            className="px-4 submit"
            onClick={handleSubmit}
            disabled={!submitIsPossible}
          >
            {formStatus.isSubmitting ? "Saving" : "Save"}
          </TooltipButton>
        </Col>
      </Form.Group>
    </Container>
  );
}
