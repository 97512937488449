import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import React, { ReactNode, useEffect, useState } from "react";
import  "./Slide.scss";
function SlideItem({ children }: { children: ReactNode }) {
  return (<div className="SlideItem">{children}</div>);
}

// type SlideHeadProps = {
//   className?: string;
// };
// export function SlideHead() {}

type SlideControlledProps = {
  children: ReactNode;
  className?: string;
  value: number;
  onSelect?: (value: number) => void;
  hideArrows?: boolean;
};
function SlideControlled({
  children,
  value,
  onSelect,
  className = "",
  hideArrows = false
}: SlideControlledProps) {
  let itemChildren: ReactNode[] = [];
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type.toString() === SlideItem.toString()) {
        itemChildren.push(child);
      }
    }
  });

  return (
    <Carousel
      className={"Slide " + (hideArrows ? "hideArrows " : "") + className}
      activeIndex={value}
      onSelect={onSelect}
      controls={true}
      interval={null}
      touch={false}
      wrap={false}
      indicators={false}
      prevIcon={<FontAwesomeIcon icon={faCaretLeft} />}
      nextIcon={<FontAwesomeIcon icon={faCaretRight} />}
    >
      {itemChildren.map((itemChild, index) => (
        <Carousel.Item key={index}>{itemChild}</Carousel.Item>
      ))}
    </Carousel>
  );
}

type SlideUncontrolledProps = {
  children: ReactNode;
  className?: string;
  defaultValue?: number;
  forcedValue?: number;
  onSelect?: (value: number) => void;
  hideArrows?: boolean;
};

function SlideUncontrolled({
  children,
  className,
  defaultValue,
  forcedValue,
  onSelect,
  hideArrows
}: SlideUncontrolledProps) {
  const [value, setValue] = useState<number>(
    defaultValue !== undefined ? defaultValue : 0
  );

  useEffect(() => {
    if (forcedValue != undefined) {
        setValue(forcedValue);
    }
  }, [forcedValue]);  

  const handleSelect = (value: number) => {
    setValue(value);
    onSelect && onSelect(value);
  }

  return (
    <SlideControlled
    value={value}
    onSelect={handleSelect}
    className={className}
    hideArrows={hideArrows}
    >
    {children}
    </SlideControlled>
  )

}

type SlideProps = {
  children: ReactNode;
  className?: string;
  forcedValue?: number;
  value?: number;
  defaultValue?: number;
  onSelect?: (value: number) => void;
  hideArrows?: boolean;
};
export function Slide({
  children,
  className,
  forcedValue,
  defaultValue,
  value,
  onSelect,
  hideArrows
}: SlideProps) {
 

  return (
    <>
    {value !== undefined && (
        <SlideControlled
        value={value}
        onSelect={onSelect}
        className={className}
        hideArrows={hideArrows}
        >
        {children}
        </SlideControlled>
    )}
   {value === undefined && (
        <SlideUncontrolled
        defaultValue={defaultValue}
        forcedValue={forcedValue}
        onSelect={onSelect}
        className={className}
        hideArrows={hideArrows}
        >
        {children}
        </SlideUncontrolled>
   )}
   </>
  );
}
Slide.Item = SlideItem;
