import { useEffect, useRef, useState } from "react";
import {
  ErrorLog,
  FormErrors,
  FormErrors as FormErrorsGeneralType,
  useFormErrors,
} from "./useFormErrors";
import { usePrevious } from "./usePreviousValue";

type FormStatus = {
  isSubmitting: boolean;
};
function useFormStatus() {
  const [formStatus, _setStatusForm] = useState<FormStatus>({
    isSubmitting: false,
  });
  const startSubmitting = () => {
    _setStatusForm({ ...formStatus, isSubmitting: true });
  };
  const stopSubmitting = () => {
    _setStatusForm({ ...formStatus, isSubmitting: false });
  };
  return [formStatus, startSubmitting, stopSubmitting] as const;
}

function useFormSubmitSimple(
  validateData: () => boolean,
  sendSubmitData: (doAlways: () => void) => void
) {
  const [formStatus, startSubmitting, stopSubmitting] = useFormStatus();
  const submitForm = () => {
    if (!validateData()) {
      stopSubmitting();
      return;
    }
    const doAlways = stopSubmitting;
    sendSubmitData(doAlways);
  };

  useEffect(() => {
    if (formStatus.isSubmitting) {
      submitForm();
    }
  }, [formStatus]);

  const handleSubmit = () => {
    if (formStatus.isSubmitting) {
      return;
    }
    startSubmitting();
  };

  return [formStatus, handleSubmit] as const;
}

export type ValidationResult = {
  isValid: boolean;
  message: ErrorLog;
};

type ValidationStatus<FormErrorsType> = {
  submit: { isValid: boolean; formErrors: FormErrorsType };
};

export function useFormSubmit<
  FormErrorsType extends FormErrorsGeneralType,
  DataType
>(
  defaultFormErrorsValues: FormErrorsType,
  data: DataType,
  dataComparator: (data1: DataType, data2: DataType) => boolean,
  validateSubmitData: (data: DataType) => {
    isValid: boolean;
    formErrors: FormErrorsType;
  },
  sendSubmitData: (
    data: DataType,
    doIfSuccess: () => void,
    doAlways: () => void
  ) => void
) {
  const [
    formErrors,
    setFormErrors,
    resetFormErrorsToNull,
    formErrorsHasNoError,
  ] = useFormErrors<FormErrorsType>(defaultFormErrorsValues);
  const prevData = usePrevious(data);
  const _sendSubmitData = (doAlways: () => void) => {
    sendSubmitData(
      data,
      () => {
        resetFormErrorsToNull();
      },
      doAlways
    );
  };
  const validationStatus = useRef<ValidationStatus<FormErrorsType>>({
    submit: validateSubmitData(data),
  });

  const updateFormErrors = () => {
    let newFormErrors = { ...defaultFormErrorsValues };
    if (!validationStatus.current.submit.isValid) {
      newFormErrors = validationStatus.current.submit.formErrors;
    }
    setFormErrors(newFormErrors);
  };

  useEffect(() => {
    if (prevData !== undefined && !dataComparator(prevData, data)) {
      const submitValidationRes = validateSubmitData(data);
      validationStatus.current = {
        submit: submitValidationRes,
      };
      updateFormErrors();
    }
  }, [data]);

  const [formStatus, _handleSubmit] = useFormSubmitSimple(
    () => validationStatus.current.submit.isValid,
    _sendSubmitData
  );
  const submitIsPossible =
    !formStatus.isSubmitting &&
    formErrorsHasNoError() &&
    validationStatus.current.submit.isValid;

  const handleSubmit = () => {
    if (!submitIsPossible) {
      return;
    }
    _handleSubmit();
  };

  return [formStatus, formErrors, handleSubmit, submitIsPossible] as const;
}
