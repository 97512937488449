import { Card as CardBS, Col, Row } from "react-bootstrap";
import DatePicker from "../../../../../components/DatePicker";
import { useYearRecordsTable } from "../../context/YearRecordsTableContext";
import UserSelector from "../../../../../components/admin/UserSelector";
import { useUsers } from "../../../../../contexts/UsersContext";
import { AuthRoles } from "../../../../../utils/AuthRoles";
import { User } from "../../../../../utils/Users";
import { DateTime } from "../../../../../utils";
import "./Header.scss";

export function Header() {
  const { year, setYear, member, setMember, availableYears } =
    useYearRecordsTable();
  const { allUsersByRole } = useUsers();
  const allmembers = allUsersByRole(AuthRoles.member)
  // console.log(allmembers)//.map(mem => mem.name + " " + mem.created + ","))
  return (
    <div className="Header">
      <Row>
        <Col>
          <div className="h-100 info">
            <div>
              <UserSelector
                users={allUsersByRole(AuthRoles.member).filter(
                  (member) =>
                    year.graterThanOrEqual(DateTime.createLocDateTime(member.created.getLocYear())) &&
                    year.lessThanOrEqual(new DateTime())
                )}
                value={member}
                onSelect={(member: User) => {
                  // redirectToAnotherMember(member)
                  setMember(member);
                }}
                variant="dropdown"
              />
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <div className="d-flex px-2 me-1 justify-content-center justify-content-c-sm-start align-items-center">
                <div>
                  {/* <DatePicker selected={month.date} dateFormat="MM.yyyy" showMonthYearPicker className="mx-1" readOnly={!setMonth} onChange={(month) => month && setMonth && setMonth(new DateTime(month))} /> */}
                  <DatePicker
                    date={year}
                    setDate={(date) => setYear(date)}
                    format={"yearOnly"}
                    minDate={availableYears.minYear}
                    maxDate={availableYears.maxYear}
                    leftArrow={true}
                    rightArrow={true}
                    // leftArrowClassName="d-none d-sm-inline-block"
                    // rightArrowClassName="d-none d-sm-inline-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
