import "./UsersList.scss";
import WidgetCard from "../../../components/WidgetCard";
import RoleTabs from "./components/RoleTabs";
import { UsersListProvider, useUsersList } from "./context/UsersListContext";
import Modal from "./components/Modal";
import CategorySelector from "../../../components/CategorySelector";
import AuthRolesSelect from "../../../components/AuthRolesSelect";
import { Footer } from "./components/Footer/Footer";
import { Content } from "./components/Content/Content";

export function UsersList() {
  return (
    <WidgetCard className="UsersList" ContextProvider={UsersListProvider} useContext={useUsersList}>
      {/* <WidgetCard.Header>
      </WidgetCard.Header> */}
      <WidgetCard.Body>
        <Content/>
      </WidgetCard.Body>
      <WidgetCard.Modal>
        <Modal />
      </WidgetCard.Modal>
      <WidgetCard.Footer>
        <Footer/>
      </WidgetCard.Footer>
    </WidgetCard>
  );
}
