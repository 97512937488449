import { generatePath, useNavigate, useParams } from "react-router";
import { useRoleAuth } from "../../../contexts/RoleAuthContext/RoleAuthContext";
import { useQueryParams } from "../../../hooks/useQuery";
import { useEffect, useRef, useState } from "react";
import { DateTime } from "../../../utils";
import { Year, isYear } from "../../../utils/TimeTypes";
import MemberYearTimeRecordTable from "../../../widgets/admin/MemberYearTimeRecordTable";
import { User } from "../../../utils/Users";
import { useUsers } from "../../../contexts/UsersContext";
import { AuthRoles } from "../../../utils/AuthRoles";
import apiUrls from "../../../data/api.json";
import { useUrlParamMember } from "./hooks/useUrlParamMember";
import { useQueryYear } from "./hooks/useQueryYear";
import usePageTitle from "../../../hooks/usePageTitle";

type MemberYearRecordsProps = {
  member: User;
  setMember: (newMember: User) => void;
};

export function MemberYear() {
  const pageTitle = {full: "Member's Yearly Records", short: "Year"};
  usePageTitle(pageTitle);
  const { member, setMember } = useUrlParamMember(
    apiUrls.account.children.admin.children.memberYear
  );
 
 
  const { year, setYear } = useQueryYear({
    minYear: member.created.getStartOfYear(),
    maxYear: (new DateTime()).getEndOfYear(),
  });

  return (
    <MemberYearTimeRecordTable
    member={member}
    setMember={setMember}
    year={year}
    setYear={setYear}
  />
  );
}