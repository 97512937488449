import "./AccountSettings.scss";
import { AccountSettingsContent } from "./components/AccountSettingsContent/AccountSettingsContent";
import { AccountSettingsHeader } from "./components/AccountSettings/AccountSettingsHeader";
import WidgetCard from "../../../components/WidgetCard";
import { Auth } from "../../../contexts/AuthContext/AuthContext";

type AccountSettingsProps = {
  auth: Auth
}
export function AccountSettings({auth}: AccountSettingsProps) {
  return (
    <WidgetCard className="AccountSettings">
      {/* <WidgetCard.Header>
        <AccountSettingsHeader />
      </WidgetCard.Header> */}
      <WidgetCard.Body>
        <AccountSettingsContent auth={auth} />
      </WidgetCard.Body>
    </WidgetCard>
  );
}
