import { error } from "console";
import { useEffect, useState } from "react";
import { ErrorResponce, fetchAll, fetchGet, fetchPost } from "../utils/fetchFromEndpoint";
import { DateTime } from "../utils";
// import { useFetch } from "../hooks/useFetch"
import { utcToZonedTime, format } from "date-fns-tz";
import { useApp } from "../contexts/AppContext";
import { useErrorBoundary } from "react-error-boundary";
import Ajv, { Schema } from "ajv";
import sch from "./sch.json";
import { TasksJson } from "../utils/Task";
import { fetchers } from "../serverApi/fetchers";
import AuthRolesSelect from "../components/AuthRolesSelect";

export default function Test() {
  const { showBoundary } = useErrorBoundary();
  const [alert, setAlert] = useState<boolean>(true);
  const { showAlert, hideAlert } = useApp();


  // const doRequest = (
  //   endpoint: string,
  //   dataToSend: any,
  //   post: boolean | undefined = true
  // ) => {
  //   // console.log("params: ", JSON.stringify(dataToSend));
  //   post &&
  //     fetchPost(endpoint, dataToSend, {
  //       success: (resp) => {
  //         setdata(JSON.stringify(resp));
  //         // console.log(resp);
  //       },
  //       fail: (resp) => {
  //         setdata(JSON.stringify(resp));
  //       },
  //     });

  //   !post &&
  //     fetchGet(endpoint, {
  //       success: (resp) => {
  //         setdata(JSON.stringify(resp));
  //         // console.log(resp);
  //       },
  //       fail: (resp) => {
  //         setdata(JSON.stringify(resp));
  //       },
  //     });
  // };

  // const error = ()  => {
  //   const err =  new Error();
  //   showBoundary(err);
  // }
  // all<T extends readonly unknown[] | []>(values: T): Promise<{ -readonly [P in keyof T]: Awaited<T[P]>; }>;



  // return (
  //   <div>
  //     <div className="d-flex my-3 justify-content-between flex-column">
  //       {/* <button onClick={() => setAlert(!alert)}>Alert</button> */}
  //       {/* <button onClick={doRequest}>Refresh</button> */}
  //       <button onClick={doLogin}>Login</button>
  //       <button onClick={doALogin}>ALogin</button>
  //       <button onClick={doLogout}>Logout</button>
  //       <button onClick={doTLog}>TestLog</button>
  //       <button onClick={doTesting}>Testing</button>
  //       <button onClick={doAuthInfo}>AuthInfo</button>
  //       <button onClick={DoSmth}>Do smth</button>
  //     </div>
  //     <div className="border-top d-flex my-3 px-2 justify-content-between flex-column">
  //       <button onClick={doTasks}>Tasks</button>
  //     </div>
  //     <div
  //       className="p-2"
  //       style={{
  //         border: "1px solid gray",
  //         minHeight: "400px",
  //         overflowWrap: "break-word",
  //       }}
  //     >
  //       {data}
  //     </div>
  //   </div>
  // );

  return (
    <>
    <AuthRolesSelect/>
    </>
  )
}
