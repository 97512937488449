import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
import "./ExpandButton.scss";

import {
  faExpand,
  faMagnifyingGlass,
  faUpRightAndDownLeftFromCenter,
  faAngleRight,
  faAnglesRight,
  faCaretDown,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, To } from "react-router-dom";

export function ExpandButton({ expandLink }: { expandLink: To }) {
  return (
    <NavLink
      to={expandLink}
      className={({ isActive, isPending }) => {
        return "ExpandButton d-inline-block w-100 no-st-lk" + (isActive ? " active" : "");
      }}
    >
      <Button variant="outline-primary">
        {/* <FontAwesomeIcon icon={faEye} size="sm" /> */}
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          size="sm"
          // flip="horizontal"
        />
      </Button>
    </NavLink>
  );
}
