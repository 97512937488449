export enum AuthRoles {
  member = "member",
  admin = "admin",
}

export function isAuthRole(role: AuthRoles | string): role is AuthRoles {
  return Object.values(AuthRoles).includes(role as AuthRoles);
}

export function areAuthRoles(roles: AuthRoles[] | string[]): roles is AuthRoles[] {
    return roles.every(role => isAuthRole(role));
}

export function getAuthRoleIndex(role: AuthRoles): number {
  const enumkeys = (Object.keys(AuthRoles) as Array<keyof typeof AuthRoles>);
  return enumkeys.indexOf(role);
}

export function getAuthRoleByIndex(index: number): AuthRoles | null {
  if (index < 0) {
    return null;
  }
  const enumkeys = (Object.keys(AuthRoles) as Array<keyof typeof AuthRoles>);
  if (enumkeys.length <= index) {
    return null;
  }
  const role = enumkeys[index];
  if (!isAuthRole(role)) {
    return null;
  }
  return role; 
}