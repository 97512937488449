import { Card } from "react-bootstrap";
import "./Modal.scss";
import { useApp } from "../../../../../contexts/AppContext";
import { useTasksList } from "../../context/TasksListContext";
import { ModalModes } from "../../hooks/useModall";
import { TaskModalModes } from "../../hooks/useTaskModal";
import { CategoryModalModes } from "../../hooks/useCategoryModal";
import { ModalTaskCreation } from "./components/ModalTaskCreation";
import { ModalTaskEdition } from "./components/ModalTaskEdition";
// import { ModalCategoryCreation } from "./components/ModalCategoryCreation";
import { ModalCategoryEdition } from "./components/ModalCategoryEdition";

export function Modal() {
    const {showAlert} = useApp();
    const { modalStatus } = useTasksList();
    return (
        <Card.Body>
            {modalStatus.mode === ModalModes.TaskCreationModal && (
                    <ModalTaskCreation defaultTask={modalStatus.status.task}/>
            )}
            {modalStatus.mode === ModalModes.TaskEditionModal && (
                    <ModalTaskEdition defaultTask={modalStatus.status.task}/>
            )}
            {modalStatus.mode === ModalModes.CategoryEditionModal && (
                    <ModalCategoryEdition defaultCategory={modalStatus.status.category}/>
            )}
        </Card.Body>
    )
}