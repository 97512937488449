import { useQueryToken } from "./hooks/useQueryToken";
import ResetPasswordForm from "../../widgets/general/ResetPasswordForm";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import apiUrls from "../../data/api.json";
import Base from "../../components/pageBases/Base";
import "./ResetPassword.scss";
import { Alert, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import logo from "../../assets/images/logoFull.png";
import { useApp } from "../../contexts/AppContext";
import { useEffect, useState } from "react";
import { fetchers } from "../../serverApi/fetchers";
import alertMessages from "../../data/alertMessages.json";
import ForgotPassword from "../ForgotPassword";
import { useAuth } from "../../contexts/AuthContext";
import './ResetPassword.scss';

type ResetPasswordProps = {
  reset_to_set?: boolean;
};
export function ResetPassword({ reset_to_set = false }: ResetPasswordProps) {
  const { auth } = useAuth();
  const { token } = useQueryToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useApp();
  const [confirmationStatus, setConfirmationStatus] = useState<{
    tokenIsValid: boolean;
    tried: boolean;
  }>({ tokenIsValid: false, tried: false });

  const sendEmailConfirmation = () => {
    if (!token) {
      return;
    }
    const dataToSend = {
      token: token,
    };
    fetchers.account.general.validateResetPasswordToken.fetch(dataToSend, {
      success: (data, status) => {
        // showAlert(alertMessages.emailHaveBeenConfirmed, true);
        setConfirmationStatus({ tokenIsValid: true, tried: true });
      },
      fail: (data, status) => {
        if (status === 441) {
          showAlert(alertMessages.invalidOrExpiredPwdResetLink);
          setConfirmationStatus({
            ...confirmationStatus,
            tokenIsValid: false,
            tried: true,
          });
          navigate(apiUrls.forgotPassword);
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
        setConfirmationStatus({ ...confirmationStatus, tried: true });
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
        setConfirmationStatus({ ...confirmationStatus, tried: true });
      },
    });
  };
  useEffect(() => {
    if (
      !auth &&
      !!token &&
      !confirmationStatus.tokenIsValid &&
      !confirmationStatus.tried
    ) {
      sendEmailConfirmation();
    }
  }, [token, confirmationStatus]);

  return (
    <>
      {!auth &&
        token &&
        confirmationStatus.tokenIsValid &&
        confirmationStatus.tried && (
          <Base>
            <div className="ResetPassword">
              <Container fluid="sm" className="Container">
                <Row className="h-100 d-flex justify-content-center align-items-start form-container">
                  <Col>
                    {/* <Row className="d-flex justify-content-center align-items-end logo-continer">
                    <Col>
                      <Image src={logo} className="logo w-100" />
                    </Col>
                  </Row> */}
                    <ResetPasswordForm
                      token={token}
                      reset_to_set={reset_to_set}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Base>
        )}
      {!auth &&
        token &&
        !confirmationStatus.tokenIsValid &&
        confirmationStatus.tried && <ForgotPassword />}
      {!auth && token && !confirmationStatus.tried && (
        <Base className="text-center ResetPassword">
          <div>
          <div className="py-5">
            <Spinner
              animation="grow"
              variant="primary"
              size="sm"
              className="me-1"
            />{" "}
            Please wait.
          </div>
          <div className="text-center mt-3 mb-1 small link_login">
            <Link to={apiUrls.login}>Log in</Link>
          </div>
          </div>
        </Base>
      )}
      {!auth && !token && (
        <Navigate
          to={apiUrls.unauthorized}
          state={{ from: location }}
          replace
        />
      )}
      {!!auth && (
        <Navigate to={apiUrls.login} state={{ from: location }} replace />
      )}
    </>
  );
}
