import { TaskRecord, TaskRecordGroups } from "../../../../utils/TaskRecord";
import { DateTime, fetchPost } from "../../../../utils";
import alertMessages from "../../../../data/alertMessages.json";
import { fetchers } from "../../../../serverApi/fetchers";
import restrictions from "../../../../data/restrictions.json";

export function moveSelectedTaskToBillableTimerecords(
  date: DateTime,
  selectedRecord: TaskRecord | null,
  refreshDay: () => void,
  showAlert: (message: string) => void
) {
  if (
    !selectedRecord ||
    selectedRecord.group !== TaskRecordGroups.unverifiedTimerecords
  ) {
    return;
  }
  // Move
  // const endpointMoveToBillableTimerecords = serverApi.moveTaskRecordToBillableTimerecords;
  // const dataToSend = {
  //   date: date.toJSON(),
  //   recordId: selectedRecord.id,
  // };
  // const doIfSuccess = undefined;
  // const doIfFailed = () => {
  //   showAlert(alertMessages.somethingWentWrong);
  // };
  // const doAlways = () => {
  //   refreshDay();
  // };
  // const doIfError = () => {
  //   showAlert(alertMessages.somethingWentWrong);
  // };
  // fetchPost<{}>(endpointMoveToBillableTimerecords, dataToSend, {
  //   success: doIfSuccess,
  //   fail: doIfFailed,
  //   always: doAlways,
  //   error: doIfError,
  // });
  const dataToSend = {
    date: date,
    recordId: selectedRecord.id,
  };
  fetchers.account.member.moveTimerecordToBillable.fetch(dataToSend, {
    fail: (data, status) => {
      if(status === 443){
        showAlert((alertMessages.changesNotSaved, (alertMessages.timeRecordDurationNoLongerThan.replace("{{maxTimerecordDuration}}", restrictions.maxPossibleWorkingDurationPerDayHours + " hours"))));
        return;
      }
      if (status === 446) {
        showAlert(
          (alertMessages.changesNotSaved, alertMessages.timeRecordOverlaps)
        );
        return;
      }
      if(status === 447){
        showAlert((alertMessages.changesNotSaved, (alertMessages.totalWorkingDurationIsLongerThan.replace("{{maxTotalWorkingDuration}}", restrictions.maxPossibleWorkingDurationPerDayHours + " hours"))));
        return;
      }
      showAlert(alertMessages.somethingWentWrong);
    },
    always: () => {
      refreshDay();
    },
    error: () => {
      showAlert(alertMessages.somethingWentWrong);
    }
  })
}
