import { useNavigate } from "react-router";
import { DateTime } from "../../../../utils";
import { Month, Year, isMonth, isYear } from "../../../../utils/TimeTypes";
import { useQueryParams } from "../../../../hooks/useQuery";
import { useEffect, useRef } from "react";
import { usePrevious } from "../../../../hooks/usePreviousValue";
import { useQuerySearchParam } from "../../../../hooks/useQuerySearchParam";

function parseMonth(monthStr: string): DateTime {
  const monthPattern = /^(0[1-9]|1[0,1,2])\.(\d{4})$/;
  if (monthStr.length < 1 || !monthPattern.test(monthStr)) {
    throw new Error();
  }
  const matches = monthStr.match(monthPattern);
  if (!matches) {
    throw new Error();
  }
  const monthVal = parseInt(matches[1]);
  if (isNaN(monthVal) || !isMonth(monthVal)) {
    throw new Error();
  }
  const yearVal = matches[2];
  if (!yearVal || !isYear(yearVal)) {
    throw new Error();
  }
  return DateTime.createLocDateTime(yearVal, monthVal);
}

// function monthInRange(
//   month: DateTime,
//   startMonth: DateTime,
//   endMonth: DateTime
// ): boolean {
//   return month.graterThanOrEqual(startMonth) && month.lessThanOrEqual(endMonth);
// }

type UseQueryMonthReturnVal = {
  month: DateTime;
  setMonth: (newMonth: DateTime) => void;
};

export function useQueryMonth(range: {
  minMonth: DateTime | null;
  maxMonth: DateTime;
}): UseQueryMonthReturnVal {
  const queryMonthName = "month";
  const parseQueryMonthVal = (param: string): DateTime => {
    return parseMonth(param);
  };
  const createQueryMonthStr = (dateTime: DateTime): string => {
    return (
      dateTime.getLocMonth().toString().padStart(2, "0") +
      "." +
      dateTime.getLocYear()
    );
  };

  const validateMonth = (month: DateTime): boolean => {
    return (
      ((range.minMonth && month.graterThanOrEqual(range.minMonth)) || !range.minMonth) &&
      month.lessThanOrEqual(range.maxMonth)
    );
  };

  const areEqual = (month1: DateTime, month2: DateTime) => {
    return (
      month1.getLocYear() === month2.getLocYear() &&
      month1.getLocMonth() === month2.getLocMonth()
    );
  };
  const [month, setMonth] = useQuerySearchParam<DateTime>(
    queryMonthName,
    new DateTime(),
    parseQueryMonthVal,
    createQueryMonthStr,
    validateMonth,
    areEqual
  );

  return { month, setMonth } as const;
}
