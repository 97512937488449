import { useEffect, useState } from "react";
import YearRecordsTable from "../../../widgets/member/YearRecordsTable";
import { DateTime } from "../../../utils";
import { useQueryParams } from "../../../hooks/useQuery";
import { Year, isYear } from "../../../utils/TimeTypes";
import { useNavigate } from "react-router-dom";
import { useRoleAuth } from "../../../contexts/RoleAuthContext/RoleAuthContext";
import usePageTitle from "../../../hooks/usePageTitle";
import { useQueryYear } from "./hooks/useQueryYear";

export function YearRecords() {
  const pageTitle = {full: "Yearly Summary", short: "Year"};
  usePageTitle(pageTitle);
  const { auth } = useRoleAuth();
  const { year, setYear } = useQueryYear({
    minYear: auth.created.getStartOfYear(),
    maxYear: (new DateTime()).getEndOfYear(),
  });

  return <>{year && <YearRecordsTable year={year} setYear={setYear} />}</>;
}
