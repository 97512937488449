import { Button } from "react-bootstrap";
import { User as UserType } from "../../../../../utils/Users";
import "./User.scss";
import TooltipButton from "../../../../../components/TooltipButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faPencil,
  faPlus,
  faMagnifyingGlass,
  faTrashArrowUp
} from "@fortawesome/free-solid-svg-icons";
import { ListRowStyled } from "../../../../../components/List/List";
import { useUsersList } from "../../context/UsersListContext";
import { AuthRoles } from "../../../../../utils/AuthRoles";

type UserProps = {
  user: UserType;
  active: boolean;
  available: boolean;
  className?: string;
};
export function User({ user, active, available, className = "" }: UserProps) {
  const { openUserEditionModal, openMemberMonthTimeRecords, openMemberYearTimeRecords, restoreUserFromDeleted } = useUsersList();
  return (
    <ListRowStyled
      active={active}
      showArrow={true}
      className={`User d-flex align-items-center w-100 h-100 list-row-styled justify-content-between ${className}`}
    >
      {/* Name */}
      <div
        className="w-100 d-flex name px-1 flex-fill"
      >
        <div>{user.lastName + ' ' + user.firstName}</div>
        <div className="list-label-opened ms-1">| info</div>
      </div>

      <div
        className="d-inline-flex align-items-center justify-content-start w-100"
        style={{
          maxWidth: "20%",
          minWidth: "fit-content",
          flexDirection: "row-reverse",
        }}
      >
        {!user.deleted && (
          <>
          {/* Edit */}
          <div>
            <TooltipButton
              tooltipContent="Edit User"
              buttonVariant="light"
              className="edit list-row-btn"
              onClick={(e) => {
                e.stopPropagation();
                openUserEditionModal(user);
              }}
            >
              <FontAwesomeIcon icon={faPencil} size="sm" className="mx-2" />
            </TooltipButton>
          </div>
          {/* Month */}
          {user.roles.includes(AuthRoles.member) && (
            <div>
              <TooltipButton
                tooltipContent="Show Time Records"
                buttonVariant="light"
                className="showTimeRecords list-row-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  openMemberYearTimeRecords(user);
                }}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  size="sm"
                  className="mx-2"
                />
              </TooltipButton>
            </div>
          )}
          </>
        )}

        {/* Restore */}
        {user.deleted && (
          <div>
          <TooltipButton
            tooltipContent="Restore User"
            buttonVariant="light"
            className="edit list-row-btn"
            onClick={(e) => {
              e.stopPropagation();
              restoreUserFromDeleted(user);
            }}
          >
            <FontAwesomeIcon icon={faTrashArrowUp} size="sm" className="mx-2" />
          </TooltipButton>
        </div>
        )}
      </div>
    </ListRowStyled>
  );
}
