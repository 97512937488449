import "./Content.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faPlay,
  faStop,
  faCalendar,
  faEllipsisVertical,
  faEllipsis,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { Image as ImageBS, Container } from "react-bootstrap";
import startBtn from "../../../../../assets/images/help/start.png";
import stopBtn from "../../../../../assets/images/help/stop.png";
import addBtn from "../../../../../assets/images/help/add.png";
import addSimpleBtn from "../../../../../assets/images/help/addSimple.png";
import editBtn from "../../../../../assets/images/help/edit.png";
import editSimpleBtn from "../../../../../assets/images/help/editSimple.png";
import calendarBtn from "../../../../../assets/images/help/calendar.png";
import arrowsBtn from "../../../../../assets/images/help/arrows.png";
import navToSummaryBtn from "../../../../../assets/images/help/navToSummary.png";
import moveRecordDownBtn from "../../../../../assets/images/help/moveRecordDown.png";
import moveRecordUpBtn from "../../../../../assets/images/help/moveRecordUp.png";
import openBtn from "../../../../../assets/images/help/open.png";
import safariMenuBtn from "../../../../../assets/images/help/safariMenu2.png";
import TooltipButton from "../../../../../components/TooltipButton";
import { useApp } from "../../../../../contexts/AppContext";
import { default as Options } from "../../../../../components/TextOptions";
import { default as Image } from "../../../../../components/TextImage";

type ContentProps = {};
export function Content({}: ContentProps) {
  const { supportsPWA, triggerPWAInstall } = useApp();
  return (
    <div className="HelpContent">
      <Container fluid={true}>
        <div className="section">
          <div className="text">
            Welcome to Flytime! This guide will walk you through the steps to
            effectively use our time-tracking application to record and manage
            your working hours.
          </div>
        </div>
        <Options className="pb-1">
          <Options.Option header="1. Signing In" eventKey="1">
            <div className="text">
              Open Flytime on your device. Enter your username and password to
              log in to your account. If forgot your password, click{" "}
              <span className="accent">Forgot&nbsp;password?</span> on the login
              page. Enter your email to find your account, and then check your
              inbox for reset link.
              <p className="mt-2">
                After loggining in, you can always change your password by going
                to the <span className="accent">Settings</span>.
              </p>
            </div>
          </Options.Option>
          <Options.Option header="2. Dashboard" eventKey="2">
            <div className="text">
              Upon logging in, you'll land on the Dashboard. Here, you'll find
              the following sections:
              <ul>
                <li>
                  <span className="accent">Timer:</span> Allows you to start and
                  stop timer to track your working time for tasks throughout the
                  day.
                </li>
                <li>
                  <span className="accent">Daily Summary:</span> Provides an
                  overview of your recorded time for the current day, including
                  time records and total time worked.
                </li>
              </ul>
              Use the <span className="accent">Daily Summary</span> to quickly
              review your time records for the current day, and utilize the{" "}
              <span className="accent">Timer</span> to track time for new tasks
              as needed.
              <p className="mt-2">
                <span className="accent">Quick access</span>: Clicking on the
                Flytime logo automaticly navigates to the Dashboard page,
                offering a convenient alternative to accessing the Dashboard
                through the menu.
              </p>
            </div>
          </Options.Option>
          <Options.Option header="3. Recording Time" eventKey="3">
            {" "}
            <div className="text">
              <p>
                To record your working time, utilize the{" "}
                <span className="accent">Timer</span> feature located on the{" "}
                <span className="accent">Dashboard</span>. Click on the{" "}
                <span className="accent">
                  <Image src={startBtn} />
                </span>{" "}
                button on the <span className="accent">Timer</span> to begin
                tracking time for the selected task. Once you've completed the
                task or need to pause tracking, click on the{" "}
                <span className="accent">
                  <Image src={stopBtn} />
                </span>{" "}
                button. The elapsed time will be recorded automatically.
              </p>
              Alternatively, if you prefer to enter time manually:
              <ul>
                <li>
                  Navigate to the <span className="accent">Daily Summary</span>{" "}
                  section.
                </li>
                <li>
                  Click on the{" "}
                  <span className="accent">
                    <Image src={addBtn} />
                  </span>{" "}
                  button.
                </li>
                <li>
                  Select the task you worked on and enter the duration manually
                  in hours and minutes.
                </li>
              </ul>
              <p>
                Remember, you can also edit or delete time records as needed.
                Simply select a time record and click on the{" "}
                <span className="accent">
                  <Image src={editBtn} />
                </span>{" "}
                button in the <span className="accent">Daily Summary</span>{" "}
                section to make changes.
              </p>
              <p>
                Ensure to accurately record your working time for each task to
                maintain accurate records.
              </p>
              <div>
                <span className="accent">Rounding:</span>
                <ul>
                  <li>
                    Please note that the timer records time in seconds, and
                    seconds will be{" "}
                    <span className="accent">
                      rounded to the nearest minute
                    </span>{" "}
                    using standard rounding methods. Specifically, 0 to 29
                    seconds will be rounded down to 0 minutes, 30-59 seconds
                    will be rounded up to 1 minute.
                  </li>
                </ul>
              </div>
              <p>
                <span className="accent">Important Note</span>: Supervisors have
                the ability to stop timers that have exceeded 16 hours.
              </p>
            </div>
          </Options.Option>
          <Options.Option header="4. Managing Tasks" eventKey="4">
            <div className="text">
              In the <span className="accent">Tasks</span> section, you can
              create and manage tasks that you work on. <br />
              <p className="mt-2">
                There two types of tasks available in the section:{" "}
                <span className="accent">public</span> tasks and{" "}
                <span className="accent">private</span> tasks. Public tasks are
                visible to a group of users and are maintained by a supervisor
                or manager only. Private tasks, on the other hand, are visible
                only to the user who created them and can be fully modified by
                that user.
              </p>
              <div className="mt-2">
                To create a new task:
                <ul>
                  <li>
                    Navigate to the <span className="accent">Tasks</span>{" "}
                    section.
                  </li>
                  <li>
                    Click on the{" "}
                    <span className="accent">
                      <Image src={addSimpleBtn} width={18} />
                    </span>{" "}
                    button.
                  </li>
                  <li>
                    Enter the name and enter or select a category for the task.
                  </li>
                  <li>
                    Click <span className="accent">Save</span> to create the
                    task.
                  </li>
                </ul>
              </div>
              <p className="mb-2">
                Tasks can be categorized to help you organize your work
                effectively.
                <br />
              </p>
              <div>
                To edit or delete tasks:
                <ul>
                  <li>
                    Select the task you want to modify from the task list.
                  </li>
                  <li>
                    Click on the{" "}
                    <span className="accent">
                      <Image src={editSimpleBtn} />
                    </span>{" "}
                    button to update the task details, click on the{" "}
                    <span className="accent">Delete</span> button to remove the
                    task.
                  </li>
                </ul>
              </div>
              To create a new category:
              <ul>
                <li>Simply create a task under the desired category.</li>
              </ul>
              To edit or delete a category:
              <ul>
                <li>
                  Select the category you want to modify from the category list.
                </li>
                <li>
                  Click on the{" "}
                  <span className="accent">
                    <Image src={editSimpleBtn} />
                  </span>{" "}
                  button to update the category details, click on the{" "}
                  <span className="accent">Delete</span> button to remove the
                  task.
                </li>
              </ul>
              <span className="accent">Remember</span>: Deleting the category
              would result in deleting all tasks under that category.
              <p className="mt-2">
                Ensure to keep your task list updated with the tasks you work on
                regularly for accurate time tracking.
              </p>
            </div>
          </Options.Option>
          <Options.Option header="5. Viewing Summaries" eventKey="5">
            <div className="text">
              You can view summaries of your recorded work time:{" "}
              <span className="accent">Daily Summary</span>,{" "}
              <span className="accent">Monthly Summary</span> and{" "}
              <span className="accent">Yearly Summary</span>.
              <div className="mt-2">
                <Options className="inner-options">
                  <Options.Option header="5.1 Daily Summary" eventKey="51">
                    <p>
                      <span className="accent">Daily Summary</span> view allows
                      you to efficiently manage and track your time records for
                      each day. You can also view your total working time and
                      update the status for any selected day.
                    </p>
                    <div>
                      To create a manual time record, follow this steps:
                      <ul>
                        <li>
                          Click the{" "}
                          <span className="accent">
                            <Image src={addBtn} />
                          </span>{" "}
                          button.
                        </li>
                        <li>
                          Select the task you worked on from the dropdown menu.
                        </li>
                        <li>
                          Input the duration you worked in hours and minutes.
                        </li>
                        <li>Click Save to apply the changes.</li>
                      </ul>
                      To edit a time record:
                      <ul>
                        <li>
                          Select the time record you want to edit from the list.
                        </li>
                        <li>
                          Click the{" "}
                          <span className="accent">
                            <Image src={editBtn} />
                          </span>{" "}
                          button.
                        </li>
                        <li>
                          Adjust the task and/or duration details as needed.
                        </li>
                        <li>Click Save to update the record.</li>
                      </ul>
                      To delete a time record:
                      <ul>
                        <li>Select the time record from the list.</li>
                        <li>
                          Click the{" "}
                          <span className="accent">
                            <Image src={editBtn} />
                          </span>{" "}
                          button.
                        </li>
                        <li>
                          Click Delete without making any changes to the
                          details.
                        </li>
                        <li>Confirm the action.</li>
                      </ul>
                    </div>
                    <p>
                      In this section, you can also handle time records that
                      need verification. To move a selected time record between
                      the <span className="accent">Verification needed</span>{" "}
                      section and the main section, simply use the{" "}
                      <span className="accent">
                        <Image src={moveRecordUpBtn} />
                      </span>{" "}
                      and{" "}
                      <span className="accent">
                        <Image src={moveRecordDownBtn} />
                      </span>{" "}
                      buttons.
                    </p>
                    <div>
                      Understanding and changing day's status:
                      <ul>
                        <li>
                          <span className="accent">Draft</span>: When your day
                          status is Draft, you can freely modify your list of
                          time records — adding, editing, or deleting entries as
                          needed. When you're ready to submit your time records
                          to your supervisor, change the status to Submitted by
                          clicking on the current status and selecting Submit
                          from the dropdown menu.
                        </li>
                        <li>
                          <span className="accent">Submitted</span>: Once the
                          day is Submitted, you can no longer modify your time
                          records. If you need to make changes, click on the day
                          status and select Request Edit. Wait for your
                          supervisor to resolve your request. If approved, the
                          status will revert to Draft, allowing you to make
                          changes. If denied, no changes can be made. You can
                          cancel the edit request by clicking on the day status
                          and selecting Cancel Request before the supervisor
                          makes a decision.
                        </li>
                        <li>
                          <span className="accent">Approved</span>: After your
                          supervisor has reviewed and accepted your time
                          records, the day status becomes Approved. Even though
                          the day is approved, you can still request changes if
                          needed, similar to the process for a submitted day.
                          Click on the status and select Request Edit to ask for
                          modifications.
                        </li>
                      </ul>
                    </div>
                    <p>
                      To view the summary for a specific date, click on the{" "}
                      <span className="accent">
                        <Image src={calendarBtn} />
                      </span>{" "}
                      button. You can navigate to the previous or next date
                      using the{" "}
                      <span className="accent">
                        <Image src={arrowsBtn} />
                      </span>{" "}
                      buttons. For a quick overview of your monthly progress,
                      click the{" "}
                      <span className="accent">
                        <Image src={navToSummaryBtn} />
                      </span>{" "}
                      button to jump directly to the Monthly Summary for the
                      selected date.
                    </p>
                  </Options.Option>
                  <Options.Option header="5.2 Monthly Summary" eventKey="52">
                    <p>
                      <span className="accent">Monthly Summary</span> provides a
                      comprehensive overview of your work for the selected
                      month. It displays each day's working time and status, the
                      total working time for the whole month, as well as
                      documented working time, and the month's status.
                    </p>
                    <p>
                      In the Monthly Summary, you can easily access detailed
                      information for each day by clicking the{" "}
                      <span className="accent ms-1">
                        <Image src={openBtn} />
                      </span>{" "}
                      button on the respective day's row. This action opens up
                      the Daily Summary for that specific day, allowing you to
                      review time records.
                    </p>
                    <p>
                      Additionally, managing day statuses is simplified within
                      the Monthly Summary. By clicking on the day status
                      directly in the day's row, you can quickly view and update
                      statuses without navigating to the Daily Summary. This
                      streamlined approach enables efficient status management
                      across all days of the month, ensuring you can easily
                      monitor and adjust progress without extra navigation
                      steps.
                    </p>
                    <div>
                      Understanding and changing month's status:
                      <ul>
                        <li>
                          The month's status is determined by the statuses of
                          its individual days.
                        </li>
                        <li>
                          If any day within the month is marked as Draft, then
                          the entire month will be in{" "}
                          <span className="accent">Draft</span> status.
                        </li>
                        <li>
                          You can change the month status to{" "}
                          <span className="accent">Submitted</span> by clicking
                          on it and selecting submit. This action automatically
                          submits all days of the month that are not yet
                          submitted or approved.{" "}
                        </li>
                        <li>
                          Once a supervisor reviews and approves all days within
                          the month, the month status will automatically change
                          to <span className="accent">Approved</span>. This
                          indicates that all tasks and activities for the month
                          have been reviewed and accepted.
                        </li>

                        <li>
                          If there are unresolved issues, such as pending status
                          change requests that have not yet been resolved by a
                          supervisor, the month status will be marked as{" "}
                          <span className="accent">Unresolved</span>.
                        </li>
                      </ul>
                    </div>
                    <p>
                      To access details for a particular month, click on the{" "}
                      <span className="accent">
                        <Image src={calendarBtn} />
                      </span>{" "}
                      button. Navigate between previous and next months using
                      the{" "}
                      <span className="accent">
                        <Image src={arrowsBtn} />
                      </span>{" "}
                      buttons. For a comprehensive yearly overview, click the{" "}
                      <span className="accent">
                        <Image src={navToSummaryBtn} />
                      </span>{" "}
                      button to jump directly to the Yearly Summary for the
                      selected month.
                    </p>
                  </Options.Option>
                  <Options.Option header="5.3 Yearly Summary" eventKey="53">
                    <p>
                      In the <span className="accent">Yearly Summary</span>{" "}
                      view, you can review all months for the selected year,
                      including their total working hours, documented work time,
                      and current statuses. Provides an overview of your working
                      time for the entire year.
                    </p>
                    <p>
                      You can navigate to the Monthly Summary for any month by
                      clicking the{" "}
                      <span className="accent">
                        <Image src={openBtn} />
                      </span>{" "}
                      button in its row.
                    </p>
                    <p>
                      You also have the ability to change the status of any
                      month directly within the Yearly Summary. Just click on
                      the month's status to update it.
                    </p>
                    <p>
                      To access the summary for a specific year, click the{" "}
                      <span className="accent">
                        <Image src={calendarBtn} />
                      </span>{" "}
                      button. Use the{" "}
                      <span className="accent">
                        <Image src={arrowsBtn} />
                      </span>{" "}
                      buttons to navigate to the previous or next year.
                    </p>
                  </Options.Option>
                </Options>
              </div>
            </div>
          </Options.Option>
          <Options.Option
            header="6. Differentiating Worktime from Documented Worktime"
            eventKey="6"
          >
            <div className="text">
              <ul>
                <li>
                  <span className="accent">Total Working Time</span>: This
                  refers to the total time you have worked and recorded within
                  the application, including all tracked time and manually
                  entered time for tasks.
                </li>
                <li>
                  <span className="accent">Total Documented Time</span>: This
                  represents the time that would be included on the final
                  timesheet. It reflects total working time where minutes have
                  been{" "}
                  <span className="accent">
                    rounded up to the nearest half-hour increment
                  </span>
                  . Specifically, durations ranging from 1 to 29 minutes are
                  rounded up to the nearest half-hour, while durations from
                  31-59 minutes are rounded up to the nearest hour. For example,
                  if the working time is 160 hours and 14 minutes, it will be
                  rounded to 160 hours and 30 minutes, while 160 hours and 37
                  minutes will be rounded to 161 hours and 0 minutes.
                </li>
              </ul>
              The total documented time may differ from the total worktime hours
              due to the rounding applied during the calculation process. It's
              important to review both the total worktime time and total
              documented time hours to ensure accuracy in reporting your time
              report.
            </div>
          </Options.Option>
          <Options.Option header="7. Understanding Statuses" eventKey="7">
            <div className="text">
              <span className="accent">Day Statuses</span>:
              <ul>
                <li>
                  <span className="accent">Draft</span>: This status indicates
                  that the time records for a specific day are still editable
                  and have not been submitted for review.
                </li>
                <li>
                  <span className="accent">Submitted</span>: The day is marked
                  as submitted once it has been sent for review. Submitted time
                  records cannot be edited and are awaiting approval.
                </li>
                <li>
                  <span className="accent">Approved</span>: When all time
                  records for a day are reviewed and accepted, the day is marked
                  as approved. Approved days are considered finalized and are
                  added to the final timesheet for payroll processing.
                </li>
              </ul>
              <span className="accent">Month Statuses</span>:
              <ul>
                <li>
                  <span className="accent">Draft</span>: If any day within a
                  month is in draft status, the entire month remains in draft
                  status, indicating pending time records.
                </li>
                <li>
                  <span className="accent">Submitted</span>: Once all time
                  records within a month have been submitted for review, the
                  month is marked as submitted. This indicates that all days are
                  ready for review and approval.
                </li>
                <li>
                  <span className="accent">Approved</span>: When all time
                  records within a month are reviewed and accepted, the month is
                  marked as approved. At this point, the month is considered
                  closed, and the final timesheet is created.
                </li>
                <li>
                  <span className="accent">Unresolved</span>: An unresolved
                  month indicates that there are still pending actions or
                  requests that need to be addressed before the month can be
                  considered finalized.
                </li>
              </ul>
              <span className="accent">Changing Status</span>: In certain cases,
              you may need to change the status of a day or month. Here's how to
              do it:
              <ul>
                <li>
                  If the day or month is in draft status, you can change it to
                  submitted on your own.
                </li>
                <li>
                  However, if the day or month is already submitted or approved,
                  and you need to make edits, you'll need to place a{" "}
                  <span className="accent">request</span> to change the status
                  back to draft.
                </li>
                <li>
                  The reviewer will review your request and, if approved, will
                  update the status accordingly.
                </li>
              </ul>
              Monitoring the status of days and the overall status of the month
              is important to ensure timely submission and approval. If needed,
              appropriate action can be taken, such as submitting days or months
              for review or requesting changes to specific days. Effective
              communication between employees and employers is key to resolving
              any discrepancies or issues and ensuring accurate reporting of
              working hours.
              <p className="mt-2">
                <span className="accent">Important Note</span>: Please be aware
                that days are{" "}
                <span className="accent">automatically submitted</span> up to
                three days ago. For instance, if today's date is 05.07.2024, all
                days up to and including 02.07.2024 will be automatically
                submitted. Auto-submission process occurs daily around midnight.
              </p>
            </div>
          </Options.Option>
          <Options.Option
            header="8. Understanding the Verification Needed Section"
            eventKey="8"
          >
            <div className="text">
              <p>
                The <span className="accent">Verification Needed</span> section
                flags time records that need extra attention. Users must review
                and confirm the accuracy of these records before including them
                in the final timesheet. This section helps prevent errors and
                promotes accountability in time tracking. To address records in
                this section, users should check for accuracy, make any
                necessary adjustments, and move verified records to the main
                time records. Careful review and correction of these records
                ensure accuracy before submission.
              </p>
              <p>
                Tou can manage the Verification Needed section in the Daily
                Summary.
              </p>
            </div>
          </Options.Option>
          <Options.Option
            header="9. Handling Timers That Exceed 24 Hours"
            eventKey="9"
          >
            <div className="text">
              In rare instances when the timer is forgotten and runs for an
              extended period exceeding 24 hours, extra steps are taken to help
              the user record their work time accurately:
              <ul>
                <li className="pb-2">
                  <div className="accent">Email Notification</div>
                  An automatic email notification is sent, detailing the
                  oversight and providing information about the forgotten timer.
                  The email includes essential details such as the start time
                  and end time of the forgotten timer.
                </li>
                <li>
                  <div className="accent">Creation of 1-Minute Time Record</div>
                  To facilitate the process of adding the actual work time, a
                  1-minute time record is automatically created in the
                  application on the day when the forgotten timer was started.
                  This time record starts at the time the forgotten timer was
                  started and ends 1 minute afterward. The purpose of this
                  record is to serve as a reference point for the user to
                  accurately adjust their time records and eliminates the need
                  to rely solely on the email notification to determine the
                  start time of the work period.
                  <div className="pt-2">
                    <span className="accent">To add the actual work time</span>:
                    <ul>
                      <li>
                        Edit the existing time record's end time to reflect the
                        actual end time of the work period.
                      </li>
                      <li>
                        Move the edited time record from the{" "}
                        <span className="accent">Verification Needed</span>{" "}
                        section to the regular time records section.
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              By promptly addressing the forgotten timer, users ensure accuracy
              in reporting their work hours, thereby facilitating payroll
              processing and compliance with company policies.
            </div>
          </Options.Option>
          <Options.Option
            header="10. Automatic Splitting Time Records That Exceede 8 Hours"
            eventKey="10"
          >
            <div className="text">
              <div>
                Time records created with the timer that exceed 8 hours (but do
                not surpass 16 hours) are divided into{" "}
                <span className="accent">
                  smaller segments of 8 hours or less
                </span>
                . For example, a 12-hour time record created with the timer
                would be split into two segments: one for 8 hours and another
                for the remaining 4 hours. The purpose of splitting time records
                is to ensure accurate tracking of working hours.{" "}
              </div>
              <div className="pt-2">
                Split segments of time records exceeding 8 hours are placed in
                the <span className="accent">Verification Needed</span> group
                for review. This allows users to verify the accuracy of each
                segment and make adjustments as necessary. Verified segments can
                then be moved from the{" "}
                <span className="accent">Verification Needed</span> group to the
                regular time records section for inclusion in the final
                timesheet.
              </div>
            </div>
          </Options.Option>
          <Options.Option
            header="11. Our Mobile and Desktop Applications"
            eventKey="11"
          >
            <div className="text">
              <div>
                <p>
                  For added convinience, we also offer you a Progressive Web
                  Application (PWA) that can be installed on your smartphone or
                  computer, providing seamless experience across devices. You
                  can find instructions on how to install our Flytime app below.
                </p>
                <p>
                  <span className="accent">Important</span>: Choosing which
                  browser to use for installing a Progressive Web Application
                  can notably influence the overall user experience, since
                  different browsers support varying levels of PWA features.
                </p>
                <p>
                  Using the{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Chrome browser
                  </span>{" "}
                  for installing our mobile/desktop application is optional, but{" "}
                  <span style={{ textDecoration: "underline" }}>
                    highly recommended
                  </span>{" "}
                  duo to its comprehensive feature support.
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <TooltipButton
                  tooltipContent="Download Timesheet"
                  buttonVariant="primary"
                  className={"installPWA mb-3" + (supportsPWA ? "" : " d-none")}
                  disabled={!supportsPWA}
                  onClick={triggerPWAInstall}
                >
                  {supportsPWA && <>{"Click to Install Flytime App"}</>}
                  {!supportsPWA && (
                    <>
                      <span>Use instructions to install our app manually.</span>
                    </>
                  )}
                </TooltipButton>
              </div>

              <div>
                <Options className="pb-2 inner-options">
                  <Options.Option
                    header="Mobile application for Android and iOS devices"
                    eventKey="111"
                  >
                    Follow the steps to install our mobile application from your
                    browser:
                    <ol>
                      <li>
                        Open the web browser of your choice on your smartphone
                        or tablet.
                      </li>
                      <li>
                        Go to the Flytime website at{" "}
                        <a className="link" href="https://time.whitefly.pl/">https://time.whitefly.pl/</a>.
                      </li>
                      <li>
                        Tap the <span className="accent">Menu</span> button
                        (Share button on Safari).
                        <Options className="hint">
                          <Options.Option header="Show hint" headerActive="Hide hint" eventKey="1111">
                          <ul>
                          <li>
                            <span className="accent-light">Chrome</span>:{" "}
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              className="mx-2"
                            />{" "}
                            button in the top-right corner on Android or{" "}
                            <FontAwesomeIcon
                              icon={faEllipsis}
                              className="mx-2"
                            />{" "}
                            button in the bottom-right corner on iOS.
                          </li>
                          <li>
                            <span className="accent-light">Safari</span>:{" "}
                            <span className="accent">
                              <Image src={safariMenuBtn} />
                            </span>{" "}
                            button in the bottom of the screen in the middle of
                            the toolbar.
                          </li>
                          <li>
                            <span className="accent-light">Firefox</span>:{" "}
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              className="mx-2"
                            />{" "}
                            button in the top-right corner on Android or{" "}
                            <FontAwesomeIcon icon={faBars} className="mx-2" />{" "}
                            button in the bottom-right corner.
                          </li>
                          <li>
                            <span className="accent-light">Opera</span>:{" "}
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              className="mx-2"
                            />{" "}
                            button in the top-right corner on Android or{" "}
                            <FontAwesomeIcon icon={faBars} className="mx-2" />{" "}
                            button in the bottom-right corner.
                          </li>
                          <li>
                            <span className="accent-light">Edge</span>:{" "}
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              className="mx-2"
                            />{" "}
                            button in the top-right corner on Android or{" "}
                            <FontAwesomeIcon
                              icon={faEllipsis}
                              className="mx-2"
                            />{" "}
                            button in the bottom-right corner on iOS.
                          </li>
                          <li>
                            <span className="accent-light">
                              Samsung Internet
                            </span>
                            : <FontAwesomeIcon icon={faBars} className="mx-2" />{" "}
                            button in the top-right corner
                          </li>
                          <li>
                            <span className="accent-light">Brave</span>:{" "}
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              className="mx-2"
                            />{" "}
                            button in the top-right corner
                          </li>
                        </ul>
                          </Options.Option>
                        </Options>
                        
                      </li>

                      <li>
                        Find an install option like "
                        <span className="accent">Add to Home Screen</span>
                        ", "<span className="accent">Add to phone</span>", "
                        <span className="accent">Install app</span>" or "
                        <span className="accent">Install Flytime</span>" and
                        select it.
                        <Options className="hint">
                          <Options.Option header="Show hint"  headerActive="Hide hint" eventKey="1112">
                        <ul>
                          <li>
                            <span className="accent-light">Chrome</span>: Select
                            "Add to Home screen" option.
                          </li>
                          <li>
                            <span className="accent-light">Safari</span>: Select
                            "Add to Home Screen" option.
                          </li>
                          <li>
                            <span className="accent-light">Firefox</span>:
                            Select "Add to Home screen" option.
                          </li>
                          <li>
                            <span className="accent-light">Opera</span>: Select
                            "Add to" option, and then "Home screen".
                          </li>
                          <li>
                            <span className="accent-light">Edge</span>: Swipe
                            menu options left to see more options, then select
                            "Add to phone" option.
                          </li>
                          <li>
                            <span className="accent-light">
                              Samsung Internet
                            </span>
                            : Select "Add page to" option, and then "Home
                            screen".
                          </li>
                          <li>
                            <span className="accent-light">Brave</span>: Select
                            "Install app" option.
                          </li>
                        </ul>
                        </Options.Option>
                        </Options>
                      </li>
                      <li>
                        <span className="accent">Confirm</span> the
                        installation.
                      </li>
                    </ol>
                    <div>
                      If you need to uninstall the application, the process
                      would be similar to uninstalling any other mobile app:
                      long-press on an icon of the Flytime app and select
                      "Uninstall" option.
                    </div>
                  </Options.Option>
                  <Options.Option
                    header="Desktop application for Windows, Mac and Linux devices"
                    eventKey="112"
                  >
                    Here are the steps to install our desktop application from
                    your browser:
                    <ol>
                      <li>
                        Open the web browser of your choice on your computer.
                      </li>
                      <li>
                        Go to the Flytime website at{" "}
                        <a className="link" href="https://time.whitefly.pl/">https://time.whitefly.pl/</a>.
                      </li>
                      <li>
                        Look for an{" "}
                        <span className="accent">install button or option</span>{" "}
                        in the address bar or under the three-dot menu{" "}
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          className="mx-2"
                        />{" "}
                        .
                      </li>
                      <li>
                        <span className="accent">Confirm</span> the
                        installation.
                      </li>
                    </ol>
                    <div>
                      To uninstall the application, open your installed Flytime
                      app, click on the menu icon in the top-right corner of the
                      application, and then select "Uninstall" to remove it from
                      your device.
                    </div>
                  </Options.Option>
                </Options>

                <div>
                  <span className="accent">Please note</span> that on very rare
                  occasions you may be asked to{" "}
                  <span className="accent">reinstall</span> the application to
                  get the newest version.
                </div>
                <div className="mt-2">
                  If you encounter any issues while installing or uninstalling
                  our Flytime application, don't worry, we've got your back! Our
                  team is ready to assist you with any problems you may face.
                  Check out the{" "}
                  <span className="accent">Contacting Support</span> section to
                  see how to get in touch with us.
                </div>
              </div>
            </div>
          </Options.Option>
          <Options.Option header="12. Contacting Support" eventKey="12">
            <div className="text">
              If you have any questions, encounter issues, or need assistance
              while using our application, we're here to help! Here are a few
              options to get support:
              <ul>
                <li>
                  <div className="accent">Contact Your Supervisor:</div>
                  If you have questions or need assistance related to your work
                  tasks or time tracking activities, we encourage you to reach
                  out to your supervisor or manager. They can provide guidance
                  and support tailored to your specific role and
                  responsibilities within the organization.
                </li>
                <li>
                  <div className="accent">Contact Us Directly:</div>
                  If you have technical issues, encounter bugs, or need
                  assistance that your supervisor cannot resolve, you can
                  contact our tech team directly (
                  <a className="link" href="mailto:time@whitefly.pl">
                    time@whitefly.pl
                  </a>
                  ). Our dedicated tech team is available to assist you and
                  ensure that your experience with our application is smooth and
                  hassle-free.{" "}
                </li>
              </ul>
              Remember, we're here to support you every step of the way. Don't
              hesitate to reach out if you need assistance or have any
              questions. Your feedback is valuable to us, and we're committed to
              providing you with the best possible support experience.
            </div>
          </Options.Option>
        </Options>

        <div className="section">
          <div className="text">
            That's it! You're now ready to efficiently track your working hours
            using Flytime. If you have any further questions or need assistance,
            don't hesitate to reach out to us.
            <div className="mt-3">Thank you for using Flytime!</div>
          </div>
        </div>
      </Container>
    </div>
  );
}
