import { Formatter } from "../../utils/Formatter";
import "./TimeHoursMinutes.scss";
type TimeHoursMinutesProps = {
  totalMinutes: number;
  boldNumbers?: boolean;
  lightLetters?: boolean;
  larger?:boolean;
  className?: string;
};
export function TimeHoursMinutes({ totalMinutes, boldNumbers=false, lightLetters=false, larger=false, className="" }: TimeHoursMinutesProps) {
  const [hours, minutes] = Formatter.formatDurationRaw(totalMinutes);
  return (
    <div className={"TimeHoursMinutes d-inline-flex" + (boldNumbers ? " numbers-bold": "") + (lightLetters ? " letters-light": "") + (larger ? " larger": "") + " " +  className}>
      <div  className="me-1">
      <span
        className={"number" + (boldNumbers ? " fw-bold": "")}
      >
        {hours}
      </span>
      <span className="tit">
        h
      </span>

      </div>
      <div>
      <span
        className={"number" + (boldNumbers ? " fw-bold": "")}
      >
        {minutes}
      </span>
      <span className="tit" >
        m
      </span>
      </div>
    </div>
  );
  // return (
  //   <>
  //     <span
  //       className={"number" + (boldNumbers ? " fw-bold": "")}
  //       style={{ fontSize: "1.05rem" }}
  //     >
  //       {hours}
  //     </span>
  //     <span className="tit" style={{ fontSize: "0.85rem" }}>
  //       h
  //     </span>
  //     <span
  //       className={"number ms-1" + (boldNumbers ? " fw-bold": "")}
  //       style={{ fontSize: "1.05rem" }}
  //     >
  //       {minutes}
  //     </span>
  //     <span className="tit" style={{ fontSize: "0.85rem" }}>
  //       m
  //     </span>
  //   </>
  // );
}
