import TooltipButton from "../../../../../../../components/TooltipButton";
import { Formatter } from "../../../../../../../utils/Formatter";
import {
  RequestsMonthMemberRequest,
  usePendingMembersRequests,
} from "../../../../context/PendingMembersRequestsProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCheck,
  faXmark,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import "./Request.scss";
import { renderDayStatus } from "../../../../../../../utils/DayStatus";
import { User } from "../../../../../../../utils/Users";
import { useMonthRequestsItem } from "../../RequestsList";
import { NavLink } from "react-router-dom";

export function Request({
  member,
  request,
}: {
  member: User;
  request: RequestsMonthMemberRequest;
}) {
  const { resolveRequests, getMonthRecordsLink } = usePendingMembersRequests();
  const { setIsMonthRequestsItemDisabled } = useMonthRequestsItem();
  return (
    <div
      className={
        "Request d-flex list-row-styled justify-content-between align-items-center " +
        (request.warning ? "warning" : "")
      }
    >
      <div className="d-flex flex-column">
        <div className="">
          <div className="label date-label d-inline me-1">Date:</div>
          <NavLink className="month-link" to={getMonthRecordsLink(member, request.date.getStartOfMonth(), request.date)}>
          <div className="d-inline-flex date-info">
            <div className="date">{Formatter.formatDate(request.date)}</div>
            <div className="date-open">
              <TooltipButton
                tooltipContent="Open Month"
                buttonVariant="light"
                className="openMonth list-row-btn p-0"
                
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  size="sm"
                  className="mx-2"
                />
              </TooltipButton>
            </div>
          </div>
          </NavLink>
        </div>
        <div className="">
          <div className="label d-inline me-1">
            <span className="d-sm-none">Curr.:</span>
            <span className="d-none d-sm-inline">Current Status:</span>
          </div>{" "}
          {renderDayStatus(request.oldStatus)}
        </div>
        <div className="">
          <div className="label d-inline me-1">
            <span className="d-sm-none">New:</span>
            <span className="d-none d-sm-inline">Requested Status:</span>
          </div>
          <span className="accent">{renderDayStatus(request.newStatus)}</span>
        </div>
      </div>
      <div className="d-flex ms-1 actions">
        <div>
          <TooltipButton
            tooltipContent="Reject All"
            buttonVariant="light"
            className="rejectAll list-row-btn"
            onClick={(e) => {
              e.stopPropagation();
              setIsMonthRequestsItemDisabled(true);
              resolveRequests([request.id], false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} size="sm" className="mx-2" />
          </TooltipButton>
        </div>
        <div>
          <TooltipButton
            tooltipContent="Grant All"
            buttonVariant="light"
            className="grantAll list-row-btn"
            onClick={(e) => {
              e.stopPropagation();
              setIsMonthRequestsItemDisabled(true);
              resolveRequests([request.id], true);
            }}
          >
            <FontAwesomeIcon icon={faCheck} size="sm" className="mx-2" />
          </TooltipButton>
        </div>
      </div>
    </div>
  );
}
