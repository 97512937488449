import { useEffect, useState } from "react";
import { Alert as AlertBS, Offcanvas } from "react-bootstrap";
import "./Alert.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

type AlertProps = {
  message?: string;
  variant: "danger"| "success"
  onClose: () => void;
};
export function Alert({ message, onClose, variant  }: AlertProps) {
  return (
    <>
        <Offcanvas show={!!message} onHide={() => {onClose();}} placement="top" style={{backgroundColor: "transparent", zIndex: "1047", height: "60px"}} className="AlertOffcanvas" >
          <Offcanvas.Body>
          <AlertBS variant={variant} onClose={onClose} dismissible className="Alert">
            <FontAwesomeIcon icon={faExclamationCircle} className="mx-2"/>
              {message}
          </AlertBS>
          </Offcanvas.Body>
        </Offcanvas>
    </>
  );
}
