import { Card, Col, Row } from "react-bootstrap";
import { useDayRecords } from "../../context/DayRecordsContext";
import { TaskRecordGroups } from "../../../../../utils/TaskRecord";
import "./CardFooter.scss";

export function CardFooter(){
    const { dayTimeRecordsInfo } = useDayRecords();
    var billableTimerecords = dayTimeRecordsInfo.timeRecords.groupToArray(TaskRecordGroups.billableTimerecords);
    var unverifiedTimerecords = dayTimeRecordsInfo.timeRecords.groupToArray(TaskRecordGroups.unverifiedTimerecords);
    var billableTimerecordsCount = billableTimerecords ? billableTimerecords.length : 0;
    var unverifiedTimerecordsCount = unverifiedTimerecords ? unverifiedTimerecords.length : 0;
    return (
        <Card.Footer className="CardFooter">
            <Row>
                <Col>
                <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex justify-content-end align-items-center small me-2 count">
                        <div className="mx-1">Count:</div>
                        <div className="ms-1">{billableTimerecordsCount}</div>
                        {unverifiedTimerecordsCount > 0 && (
                            <>
                                <div className="divider">/</div>
                                <div className={`me-1 invalid ${!dayTimeRecordsInfo.editMode ? "disabled": ""}`}>{unverifiedTimerecordsCount}</div>
                            </>
                        )}
                    </div>
                </div>
                </Col>

            </Row>
        </Card.Footer>
    )
}