import { useEffect, useState } from "react";

export function usePWAInstall() {
  const [deferredPrompt, setDefferedPrompt] = useState<Event | null>(null);
  const [supportsPWA, setSupportsPWA] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      setDefferedPrompt(event);
      setSupportsPWA(true);
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const disableInAppInstallPrompt = () => {
    setDefferedPrompt(null);
    setSupportsPWA(false);
  }

  const triggerPWAInstall = async () => {
    if (!deferredPrompt) {
      return;
    }
    const result = await (deferredPrompt as any).prompt();
    // console.log(`Install prompt was: ${result.outcome}`)
    // (deferredPrompt as any).userChoice.then((choiceResult: any) => {
    //   console.log(choiceResult);
    // });
    disableInAppInstallPrompt();
  };

  return {supportsPWA, triggerPWAInstall} as const;
}
