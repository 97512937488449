import { ReactNode } from "react";
import { default as AccordionBS } from "react-bootstrap/Accordion";
import { AccordionProvider } from "./context/AccordionContext";
import AccordionItem from "./components/AccordionItem";
import "./Accordion.scss";

type AccordionProps = {
  children: ReactNode;
  defaultActiveKey?: string;
  activeKey?: string;
  onSelect?: (eventKey: string) => void;
  alwaysOpen?: boolean;
  className?: string;
};
export function Accordion({ children, defaultActiveKey, activeKey, onSelect, alwaysOpen, className }: AccordionProps) {
    const accordionOnSelect = onSelect 
    ? (eventKey: any) => {
        if(eventKey && typeof(eventKey) === "string") {
            return onSelect(eventKey);
        }
    }
    : undefined;
    
  return (
    <AccordionProvider>
      <AccordionBS className={`Accordion ${className}`} defaultActiveKey={defaultActiveKey} activeKey={activeKey} onSelect={accordionOnSelect} alwaysOpen={alwaysOpen} flush={true}>{children}</AccordionBS>
    </AccordionProvider>
  );
}

Accordion.Item = AccordionItem;
