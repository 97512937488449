import { ReactNode, useState } from "react";
import { createContext } from "../../../hooks/useCreateContext";

type AccordionContextVal = {};

const [AccordionContext, useAccordion] =
createContext<AccordionContextVal>();
export { useAccordion };

type AccordionProviderProps = {
  children: ReactNode;
};

export function AccordionProvider({ children }: AccordionProviderProps) {
  return (
    <AccordionContext.Provider value={{}}>{children}</AccordionContext.Provider>
  );
}
