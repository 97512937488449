import {
  ReactNode,
  useEffect,
  useState,
} from "react";
import { User } from "../../utils/Users";
import { DateTime, fetchGet } from "../../utils";
import alertMessages from "../../data/alertMessages.json";
import { AuthRoles } from "../../utils/AuthRoles";
import { useApp } from "../AppContext";
import Placeholder from "../../components/Placeholder";
import { createContext } from "../../hooks/useCreateContext";
import { fetchers } from "../../serverApi/fetchers";

type UsersContextVal = {
  allUsers: User[];
  deletedUsers: User[];
  allUsersByRole: (role: AuthRoles) => User[];
  usersRefresh: (doAlways?: ()=>void) => void;
  activeMembers: User[];
  activeMembersRefresh: () => void;
};
const [UsersContext, useUsers] =
  createContext<UsersContextVal>();
export { useUsers };

type UsersProviderProps = {
  children: ReactNode;
};
export function UsersProvider({ children }: UsersProviderProps) {
  const { showAlert } = useApp();
  const [allUsers, setAllUsers] = useState<User[] | undefined>(undefined);
  const [deletedUsers, setDeletedUsers] = useState<User[] | undefined>(undefined);
  const [activeMembers, setActiveMembers] = useState<User[] | undefined>(undefined);
  const activeMembersRefresh = () => {
    fetchers.account.admin.activeMembers.fetch({}, {
      success: (data, status) => {
        setActiveMembers(data);
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // type RespType = {
    //     activeMembers: {
    //     id: string;
    //     name: string;
    //     role: string;
    //     email: string;
    //     created: string;
    //     updated: string;
    //     deleted: string | null;
    //   }[]
    // };
    // fetchGet<RespType>("/admin/users/active_members", {
    //   success: (data, status) => {
    //     const members: User[] = data.activeMembers.map((user) => {
    //       return {
    //         id: user.id,
    //         name: user.name,
    //         roles: [user.role as AuthRoles],
    //         email: user.email,
    //         created: new DateTime(user.created),
    //         updated: new DateTime(user.updated),
    //         deleted: user.deleted ? new DateTime(user.deleted): null
    //       };
    //     });
    //     setActiveMembers(members);
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // }); 
  }
  const usersRefresh = (doAlways?: ()=>void) => {
    fetchers.account.admin.users.fetch({}, {
      success: (data, status) => {
        setAllUsers(data.filter(user => !user.deleted));
        setDeletedUsers(data.filter(user => user.deleted));
        activeMembersRefresh();
        // doIfSuccess && doIfSuccess()
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        doAlways && doAlways();
      }
    });
    // type RespType = {
    //   users: {
    //     id: string;
    //     name: string;
    //     role: string;
    //     email: string;
    //     created: string;
    //     updated: string;
    //     deleted: string | null;
    //   }[]
    // };
    // fetchGet<RespType>("/admin/users", {
    //   success: (data, status) => {
    //     const users: User[] = data.users.map((user) => {
    //       return {
    //         id: user.id,
    //         name: user.name,
    //         roles: [user.role as AuthRoles],
    //         email: user.email,
    //         created: new DateTime(user.created),
    //         updated: new DateTime(user.updated),
    //         deleted: user.deleted ? new DateTime(user.deleted) : null,
    //       };
    //     });
    //     setAllUsers(users.filter(user => !user.deleted));
    //     setDeletedUsers(users.filter(user => user.deleted));
    //     activeMembersRefresh();
    //     // doIfSuccess && doIfSuccess()
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     doAlways && doAlways();
    //   }
    // });
  };
  useEffect(() => usersRefresh(), []);

  const allUsersByRole = (role: AuthRoles): User[] => {
    if (allUsers === undefined) {
      throw new Error();
    }
    return allUsers.filter(user => user.roles.find(userRole => userRole === role))
  }
  
  return (
    <>
      {allUsers !== undefined && deletedUsers !== undefined && activeMembers !== undefined && (
        <UsersContext.Provider
          value={{
            allUsers,
            deletedUsers,
            allUsersByRole,
            usersRefresh,
            activeMembers,
            activeMembersRefresh
          }}
        >
          {children}
        </UsersContext.Provider>
      )}
      {(allUsers === undefined || deletedUsers === undefined || activeMembers === undefined) && (
        <Placeholder/>
      )}
    </>
  );
}
