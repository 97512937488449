// export type Category = string;
export class Task {
    id: string;
    name: string;
    category: string;
    isPublic: boolean;
    isDeleted: boolean;
 
    constructor(id: string, name: string, category: string, isPublic: boolean| undefined = false, isDeleted: boolean | undefined = false) {
      this.id = id;
      this.name = name;
      this.category = category;
      this.isPublic = isPublic;
      this.isDeleted = isDeleted;
    }
}

export type TasksJson = {
      tasks: {
        id: string;
        name: string;
        category: string;
        isPublic: boolean;
        isDeleted: boolean;
      }[];
  };
 export type Category = {
    name: string;
    isPublic: boolean;
    tasks: Task[];
  }
 export type TasksByCategory = {
    categories: Category[];
  }

export class Tasks {
    allTasksWithDeleted: Task[];
    tasks: Task[];
    tasksByCategory: TasksByCategory;
    constructor(tasks: Task[]) {
        // this.allTasksWithDeleted = tasksJson.tasks.map((task) => new Task(task.id, task.name, task.category, task.isPublic, task.isDeleted));
        this.allTasksWithDeleted = tasks;
        this.tasks = this.allTasksWithDeleted.filter((task) => !task.isDeleted)
        this.tasksByCategory = this.getSortedByCategory();
    }
    _getSortedByCategory() : {[key: string]: Task[]} {
        return [...this.tasks].reduce((tasksByCategories: {[key: string]: Task[]}, task: Task) => {
            (tasksByCategories[task.category] = tasksByCategories[task.category] || []).push(task);
            return tasksByCategories;
            }, {});
    }
    getSortedByCategory(): TasksByCategory {
        var sorted = this._getSortedByCategory();
        Object.keys(sorted).forEach((category) => sorted[category].sort((task1, task2) => task1.name.localeCompare(task2.name)));

        var allCategories: Category[] = [];
        Object.keys(sorted).forEach((key) => {
          const publicTasks = sorted[key].filter((task => task.isPublic));
          const privateTasks = sorted[key].filter((task => !task.isPublic));
          if (publicTasks.length) {
            allCategories.push({
              name: key,
              isPublic: true,
              tasks: publicTasks
            })
          }
          if (privateTasks.length) {
            allCategories.push({
              name: key,
              isPublic: false,
              tasks: privateTasks
            })
          }
          });

        var publicCategories = allCategories.filter((category) => category.isPublic);
        publicCategories.sort((category1, category2) => category1.name.localeCompare(category2.name));
        var privateCategories = allCategories.filter((category) => !category.isPublic);
        privateCategories.sort((category1, category2) => category1.name.localeCompare(category2.name));

        allCategories = [...publicCategories, ...privateCategories];
        // allCategories.categories.sort((category1, category2) => category1.name.localeCompare(category2.name));
        return {categories: allCategories};

    }

}