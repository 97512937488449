import { useState } from "react";
import { DateTime } from "../../../../utils";
import { DayRecordedData } from "../context/MonthRecordsContext";

export type Modal = {
  show: boolean;
  date: DateTime | null;
};
export function useModal(month:DateTime, selectedDay: DayRecordedData | null, refreshMonth: () => void) {
  const [modalStatus, setModalStatus] = useState<Modal>({
    show: false,
    date: null,
  });

  const openModal = (day: DayRecordedData | undefined = undefined) => {
    var dayToOpen: DayRecordedData | undefined = undefined;
    if (day) 
    dayToOpen = day; 
    else if (selectedDay) 
    dayToOpen = selectedDay;
    
    if (!dayToOpen) return;
    if (!dayToOpen.date.getStartOfMonth().equalTo(month.getStartOfMonth())) return;
    // tmp
    // console.log(dayToOpen.date);
    setModalStatus({
      show: true,
      date: dayToOpen.date,
    });
  };
  const closeModal = () => {
    const date = modalStatus.date;
    setModalStatus({
      show: false,
      date: null,
    });
    const doAlways = date ? () => {
      const day = document.getElementById(date.getLocDay().toString());
      // console.log(day)
      if (day) {
        day.scrollIntoView({behavior: "smooth", block: "center"})
      }
    }: undefined;
    refreshMonth();
    doAlways && doAlways();
  };

  const changeModalDate = (date: DateTime) => {
    if (date.getStartOfMonth().equalTo(month.getStartOfMonth())) {
      if (modalStatus.show) {
        setModalStatus({
          show: true,
          date: date,
        });
      }
    }
  }

  return [modalStatus, openModal, closeModal, changeModalDate] as const;
}
