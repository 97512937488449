import { useLocation, useNavigate } from "react-router";
import { useQueryParams } from "./useQuery";
import { useEffect, useRef, useState } from "react";

// function getQueryParam<T>(queryParamName: string, parseQueryParamString: (param: string)=> T): T|null {
//     const param = queryParams.get(queryParamName);
//     if (!param) {
//       return null;
//     }
//     try {
//       return parseQueryParamString(param);
//     } catch (error) {
//       return null;
//     }
// }

type UseQuerySearchParamReturn<T> = [
  value: T,
  //   getQueryParamValue: () => T | null,
  setQueryParam: (value: T) => void
];
export function useQuerySearchParam<T>(
  queryParamName: string,
  defaultValue: T,
  parseQueryParam: (queryParam: string) => T,
  formatQueryParam: (value: T) => string,
  validateValue: (value: T) => boolean,
  areEqual: (value1: T, value2: T) => boolean
): UseQuerySearchParamReturn<T> {
  // const navigate = useNavigate();
  const hash = (() => {
    const match = window.location.href.match(/#([^?]+)/);
    if (match) {
      return match[1];
    } else {
      return "";
    }
  })();
  const queryParams = useQueryParams();
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParamValue = (): T | null => {
    const queryParam = queryParams.get(queryParamName);
    if (!queryParam) {
      return null;
    }
    try {
      return parseQueryParam(queryParam);
    } catch (error) {
      return null;
    }
  };

  const loadVal = () => {
    const loadedVal = getQueryParamValue();
    return loadedVal && validateValue(loadedVal) ? loadedVal : defaultValue;
  };

  const [value, setValue] = useState<T>(loadVal());

  const setQueryParam = (newValue: T) => {
    if ((!value || !areEqual(value, newValue)) && validateValue(newValue)) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (value) {
      queryParams.delete(queryParamName);
      queryParams.set(queryParamName, formatQueryParam(value));
      navigate(
        {
          pathname: location.pathname,
          hash: hash,
          search: queryParams.toString(),
        },
        { replace: true }
      );
    }
  }, [value]);

  return [
    value,
    setQueryParam,
  ];
}

// No Update
type useQuerySearchParamNoUpdate<T> = [
  value: T,
];
export function useQuerySearchParamNoUpdate<T>(
  queryParamName: string,
  defaultValue: T,
  parseQueryParam: (queryParam: string) => T,
): useQuerySearchParamNoUpdate<T> {
  // const navigate = useNavigate();
  const queryParams = useQueryParams();

  const getQueryParamValue = (): T | null => {
    const queryParam = queryParams.get(queryParamName);
    if (!queryParam) {
      return null;
    }
    try {
      return parseQueryParam(queryParam);
    } catch (error) {
      return null;
    }
  };

  const loadVal = () => {
    const loadedVal = getQueryParamValue();
    return loadedVal ? loadedVal : defaultValue;
  };

  const [value, setValue] = useState<T>(loadVal());

  return [value];
}
