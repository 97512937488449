import React, { ReactNode, useContext, useEffect } from "react";
import { default as AccordionBS } from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordion } from "../../context/AccordionContext";
import CardHeaderToggle from "../CardHeaderToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import "./AccordionItem.scss";

type AccordionItemHeaderProps = {
  children: ReactNode;
};
function AccordionItemHeader({ children }: AccordionItemHeaderProps) {
  const accordion = useAccordion();
  return <>{children}</>;
}

type AccordionItemHeaderActiveProps = {
  children: ReactNode;
};
function AccordionItemHeaderActive({ children }: AccordionItemHeaderActiveProps) {
  const accordion = useAccordion();
  return <>{children}</>;
}

type AccordionItemBodyProps = {
  children: ReactNode;
};
function AccordionItemBody({ children }: AccordionItemBodyProps) {
  const accordion = useAccordion();
  return <>{children}</>;
}

type AccordionItemProps = {
  children: React.JSX.Element[];
  eventKey: string;
  onActive?: (active: boolean) => void
  className?: string;
};

export function AccordionItem({ children, eventKey, onActive, className="" }: AccordionItemProps) {
  const accordion = useAccordion();
  let headerChildren: ReactNode[] = [];
  let headerActiveChildren: ReactNode[] = [];
  let bodyChildren: ReactNode[] = [];
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (typeof child.type === "string") {
        throw Error(
          "Type '" +
            child.type.toString() +
            "' does not exist on type 'AccordionItemHeader| AccordionItemHeaderActive | AccordionItemBody'"
        );
      }
      if (child.type.toString() === AccordionItemHeader.toString()) {
        headerChildren.push(child); 
      } else if (child.type.toString() === AccordionItemHeaderActive.toString()) {
        headerActiveChildren.push(child); 
      } else if (child.type.toString() === AccordionItemBody.toString()) {
        bodyChildren.push(child);
      } else {
        console.log(child.type)
        throw Error(
          "Type '" +
            child.type.name.toString() +
            "' does not exist on type 'AccordionItemHeader | AccordionItemHeaderActive | AccordionItemBody'"
        );
      }
    }
  });
  const { activeEventKey } = useContext(AccordionContext);
  useEffect(()=>{
    onActive && onActive(activeEventKey === eventKey);
  }, [activeEventKey])
  return (
    <Card className={`AccordionItem ${activeEventKey === eventKey ? "active" : ""} ${className}`}>
      <CardHeaderToggle eventKey={eventKey} className="AccordionItemHeader h-100 w-100" children={(isActive) => isActive && headerActiveChildren.length ? (<>{headerActiveChildren}</>) : (<>{headerChildren}</>)}/>
      <AccordionBS.Collapse eventKey={eventKey}>
        <Card.Body className="AccordionItemBody h-100 w-100">{bodyChildren}</Card.Body>
      </AccordionBS.Collapse>
    </Card>
  );
}
AccordionItem.Header = AccordionItemHeader;
AccordionItem.HeaderActive = AccordionItemHeaderActive;
AccordionItem.Body = AccordionItemBody;
