import { ReactNode, useEffect, useState } from "react";
import { createContext } from "../../../../hooks/useCreateContext";
import { Task, Tasks } from "../../../../utils/Task";
import { ModalStatus, useModall } from "../hooks/useModall";
import Placeholder from "../../../../components/Placeholder";
import { fetchGet, fetchPost } from "../../../../utils";
import { useApp } from "../../../../contexts/AppContext";
import alertMessages from "../../../../data/alertMessages.json";
import { fetchers } from "../../../../serverApi/fetchers";

type TasksListContextType = {
  allTasks: Tasks;
  modalStatus: ModalStatus;
  openTaskCreationModal: (category?: string | undefined) => void;
  openTaskEditionModal: (task: Task) => void;
  openCategoryEditionModal: (category: string) => void;
  closeModal: () => void;
  refreshAllTasks: () => void;
};
const [TasksListContext, useTasksList] = createContext<TasksListContextType>();
export { useTasksList };

type TasksListProviderProps = {
  children: ReactNode;
};

export function TasksListProvider({ children }: TasksListProviderProps) {
  const { showAlert } = useApp();
  const [allTasks, setAllTasks] = useState<Tasks | undefined>(undefined);
  const [
    modalStatus,
    openTaskCreationModal,
    openTaskEditionModal,
    openCategoryEditionModal,
    closeModal,
  ] = useModall();

  const refreshAllTasks = () => {
    fetchers.account.admin.publicTasks.fetch({}, {
      success: (data, status) => {
        setAllTasks(new Tasks(data));
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    })
    // type RespData = {
    //   tasks: {
    //     id: string;
    //     name: string;
    //     category: string;
    //     isPublic: boolean;
    //     isDeleted: boolean;
    //   }[];
    // }
    // const endpoint = "/admin/tasks";
    // fetchGet<RespData>(endpoint, {
    //   success: (data, status) => {
    //     let tasks = data.tasks.map((task) => 
    //     new Task(
    //       task.id,
    //       task.name,
    //       task.category,
    //       task.isPublic,
    //       task.isDeleted
    //     ));
    //     setAllTasks(new Tasks(tasks));
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };
  useEffect(() => {
    refreshAllTasks();
  }, []);
  return (
    <>
      {allTasks && (
        <TasksListContext.Provider
          value={{
            allTasks,
            modalStatus,
            openTaskCreationModal,
            openTaskEditionModal,
            openCategoryEditionModal,
            closeModal,
            refreshAllTasks,
          }}
        >
          {children}
        </TasksListContext.Provider>
      )}
      {!allTasks && <Placeholder />}
    </>
  );
}
