import { Card } from "react-bootstrap";
import DatePicker from "../../../../../components/DatePicker";
import { DateTime } from "../../../../../utils";
import { useMemberWorktimeSummary } from "../../context/SummaryContext";

export function Header() {
  const { date, setDate } = useMemberWorktimeSummary();
  return (
    <Card.Header className="Header d-flex justify-content-c-sm-start">
      <div className="d-flex align-items-center">
      <DatePicker
        date={date}
        setDate={setDate}
        format={"dayMonthYear"}
        // minDate={availableDays.minDay}
        maxDate={new DateTime()}
        leftArrow={true}
        rightArrow={true}
        // leftArrowClassName="d-none d-sm-inline-block"
        // rightArrowClassName="d-none d-sm-inline-block"
      />
      </div>
    </Card.Header>
  );
}
