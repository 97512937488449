import { ReactNode } from "react";
import { useCard } from "../../context/CardContext";
import "./TopExtras.scss";

type TopExtrasProps = {
    children: ReactNode;
    style?: React.CSSProperties;
    className?: string;
}
export function TopExtras({ children, className, style}: TopExtrasProps){
  useCard();
    return (
        <div className={"TopExtras " + className} style={style}>
            {children}
        </div>
    )
}