import React, { useEffect, useState } from "react";
import { Task, Tasks } from "../../utils/Task";
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  Placeholder,
} from "react-bootstrap";
import "./TaskSelector2.scss";
import { useApp } from "../../contexts/AppContext";
import alertMessages from "../../data/alertMessages.json";
import FullTaskName from "../FullTaskName";
import FullTaskName2 from "../FullTaskName2";

type TasksSelectProps = {
  tasks: Tasks;
  value: Task;
  onSelect?: (selectedTask: Task) => void;
  variant?: "select" | "dropdown";
  toggleMaxWidth?: string;
  disabled?: boolean;
  readonly?: boolean;
};
export function TaskSelector({
  tasks,
  value,
  onSelect,
  variant = "select",
  toggleMaxWidth = "unset",
  disabled = false,
  readonly = false,
}: TasksSelectProps) {
  const { showAlert } = useApp();
  var selectedTask: Task = value;
  const setSelectedTask = (newSelectedTask: Task) => {
    selectedTask = newSelectedTask;
  };

  const handleSelect = (taskId: string | null) => {
    if (!taskId) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const findTaskById = (taskId: string): Task | null | undefined => {
      if (!tasks) {
        showAlert(alertMessages.somethingWentWrong);
        return undefined;
      }
      const task = tasks.tasks.filter((task) => task.id == taskId);
      if (!task || task.length < 1) {
        showAlert(alertMessages.somethingWentWrong);
        return null;
      }
      return task[0];
    };

    var task = findTaskById(taskId);
    if (!task) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    setSelectedTask(task);
    onSelect && onSelect(selectedTask);
  };
  return (
    <div className="d-flex"> 
    <div
      className={`TaskSelector2 ${"TaskSelector2-" + variant}`}
      // style={{ height: "35%" }}
    >
      {/* <button style={{backgroundColor: "red", maxHeight: "30px", border: "2px solid red", display: "flex", whiteSpace:"unset"}} ><div style={{backgroundColor: "yellow",  maxHeight: "100%", overflow: "auto"}}>some text some textsome textsome textsome textsome textsome textsome textsome textsome text ...</div>
        </button> */}

      <Dropdown
        drop={variant == "select" ? "down" : "down-centered"}
        className={`h-100`}
        onSelect={handleSelect}
      >
        
        <Dropdown.Toggle
          id={`dropdown-task-selector`}
          variant="light"
          style={{ maxWidth: toggleMaxWidth, whiteSpace: "unset" }}
          disabled={disabled || readonly}
        >
  
          {selectedTask && (
            <>
            {/* <FullTaskName categoryName={selectedTask.category} taskName={selectedTask.name} /> */}
            <FullTaskName2 categoryName={selectedTask.category} taskName={selectedTask.name} />
            
            </>
          )}
   
        </Dropdown.Toggle>

        <Dropdown.Menu className={variant == "select" ? "w-100" : ""}>
          {tasks.tasksByCategory.categories.map((category) => (
            <React.Fragment key={category.name + category.isPublic}>
              <Dropdown.Header key={category.name + category.isPublic}>
                <span>
                  {category.isPublic && (
                    <span className="me-1 small public">pub.</span>
                  )}
                  {category.name}
                </span>
              </Dropdown.Header>
              {category.tasks.map((task) => (
                <Dropdown.Item eventKey={task.id} key={task.id}>
                  <div className="ps-2">{task.name}</div>
                </Dropdown.Item>
              ))}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
    </div>
  );
}
