import { useEffect, useState } from "react";
import { useDeepCompareEffect } from "./useDeepCompareEffect";
import _, { isEqual } from "lodash";

export function useSelectedRecords<T>(
  allRecords: T[],
  areEqual: (v1: T, v2: T) => boolean,
  defaultRecord?: T
) {
  const [selectedRecords, setSelectedRecords] = useState<T[]>(
    defaultRecord ? [defaultRecord] : []
  );

  const existsInAllRecords = (record: T) => {
    return allRecords.some((existingRecord) =>
      areEqual(existingRecord, record)
    );
  };

  const selectRecordsExcl = (records: T[]) => {
    const existingRecords = records.filter((record) =>
      existsInAllRecords(record)
    );
    setSelectedRecords(existingRecords);
  };

  const reviewSelected = () => {
    selectRecordsExcl(selectedRecords);
  };

  const compareSelectedRecords = (v1: T[], v2:T[]) => {
   return _.isEqualWith(v1, v2, isEqual);
  }
  useDeepCompareEffect(() => {
    reviewSelected();
  }, [[selectedRecords, compareSelectedRecords]]);

  const isSelectedRecord = (record: T) => {
    return selectedRecords.some((selectRecord) =>
      areEqual(selectRecord, record)
    );
  };
  const selectRecord = (record: T) => {
    if (existsInAllRecords(record) && !isSelectedRecord(record)) {
      setSelectedRecords([...selectedRecords, record]);
    }
  };
  const unselectRecord = (record: T) => {
    selectedRecords.filter(
      (selectedRecord) => !areEqual(selectedRecord, record)
    );
  };

  const toggleSelectRecord = (record: T) => {
    if (!isSelectedRecord(record)) {
      setSelectedRecords([...selectedRecords, record]);
      return;
    }
    setSelectedRecords(
      selectedRecords.filter(
        (selectedRecord) => !areEqual(selectedRecord, record)
      )
    );
  };

  const selectAll = () => {
    setSelectedRecords(allRecords);
  };
  const unselectAll = () => {
    setSelectedRecords([]);
  };

  const allAlreadySelected = () => {
    return allRecords.every((item) => isSelectedRecord(item));
  };

  const selectRecords = (records: T[]) => {
    const existingRecords = records.filter((record) =>
      existsInAllRecords(record)
    );
    const notSelectedRecords = existingRecords.filter(
      (record) => !isSelectedRecord(record)
    );
    setSelectedRecords([...selectedRecords, ...notSelectedRecords]);
  };
  return {
    selectedRecords,
    isSelectedRecord,
    selectRecord,
    unselectRecord,
    toggleSelectRecord,
    selectAll,
    unselectAll,
    allAlreadySelected,
    selectRecords,
    selectRecordsExcl,
  } as const;
}
