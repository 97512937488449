import { ReactNode, useEffect, useState, } from "react"
import { createContext } from "../../hooks/useCreateContext";
import { useDrag } from '@use-gesture/react'
import { isStandalone } from "../../utils/StandAlone";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import TabBar from "../../components/TabBar";

type link = {
    id: string,
    name: string;
    url: string;
}


type SidebarContextVal = {
    isOpen: boolean
    openSidebar: () => void
    closeSidebar: () => void
    sidebarLinks: SidebarLinksType;
};
const [SidebarContext, useSidebar] =
createContext<SidebarContextVal>();
export { useSidebar };

export type SidebarLinksType = {
  categories: {
      id: string;
      name: string;
      links: ({
          id: string;
          name: string;
          shortName?: string;
          url: string;
      } | {
        id: string;
        name: string;
        shortName?: string;
        url: string;
        tab: {
          id: string;
          name?: string;
          main?: boolean;
          icon?: IconDefinition
        }
    })[];
  }[];
}

// {
//     categories: {
//         id: string;
//         name: string;
//         links: link[];
//     }[];
// }

type SidebarProviderProps = {
    children: ReactNode;
    sidebarLinks: SidebarLinksType
}

export function SidebarProvider({ children, sidebarLinks }: SidebarProviderProps) {
    const [isOpen, setIsOpen] = useState(false)
    const openSidebar = () => setIsOpen(true)
    const closeSidebar = () => setIsOpen(false)

    const bind = useDrag((state) => {
        // console.log(state.movement)
        // const [dx, dy] = state.distance;
        // console.log(dx > dy)
        if (isStandalone() && state.distance[0] > state.distance[1] && state.distance[0] > 10){
            if (state.direction[0] > 0){
                !isOpen && openSidebar()
            } else if (state.direction[0] < 0) {
                isOpen && closeSidebar()
            }
        }
    })
    return(
        <SidebarContext.Provider value={{isOpen, openSidebar, closeSidebar, sidebarLinks}}>
            <div {...bind()} className="SidebarProvider h-100" style={{position: "relative"}}>
            {children}
            {isStandalone() && (
                <TabBar className="d-md-none"/> 
            )}
            </div>
        </SidebarContext.Provider>
    )
}
