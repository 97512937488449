import { ReactNode } from "react";
import Base from "../Base";
import sidebarLinks from "./sidebarLinks.json";
import PanelBase from "../PanelBase";
import { useApp } from "../../../contexts/AppContext";
import apiUrls from "../../../data/api.json";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "../../ErrorBoundaryFallback";
import RoleAuthProvider from "../../../contexts/RoleAuthContext";
import { AuthRoles } from "../../../utils/AuthRoles";
import { UsersProvider, useUsers } from "../../../contexts/UsersContext";
import AccountBase from "../AccountBase";
import { faUserClock, faClipboardList, faHandHoldingHand} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../contexts/AuthContext";
import { generatePath } from "react-router";

type AdminPanelBaseInnerProps = {
  children: ReactNode;
};
function AdminPanelBaseInner({ children }: AdminPanelBaseInnerProps){
  const {allUsers} = useUsers();
  const firstUser = allUsers.find( user => user.roles.find(userRole => userRole === AuthRoles.member));
  const sidebarLinks = {
    categories: [
      {
        id: "1",
        name: "Members Stats",
        links: [
          // {
          //   id: "1",
          //   name: "Dashboard",
          //   url: apiUrls.account.children.admin.children.dashboard,
          // },
          {
            id: "5",
            name: "Active Members",
            url: apiUrls.account.children.admin.children.activeMembers,
            tab: {
              id: 2,
              main: true,
              icon: faUserClock
            }
          },
          {
            id: "7",
            name: "Pending Requests",
            url: apiUrls.account.children.admin.children.pendingRequests,
            tab: {
              id: 1,
              icon: faHandHoldingHand
            }
          },
          {
            id: "2",
            name: "Worktime Summary",
            url: apiUrls.account.children.admin.children.membersWorkingtimeSummary,
            tab: {
              id: 3,
              icon: faClipboardList
            }
          },
          {
            id: "6",
            name: "Monthly Timesheets",
            url: apiUrls.account.children.admin.children.membersMonthlyTimesheets,
          },

          

          // {
          //   id: "3",
          //   name: "Month Records",
          //   url: apiUrls.account.children.admin.children.monthRecords,
          // },
          // {
          //   id: "4",
          //   name: "Year Records",
          //   url: apiUrls.account.children.admin.children.yearRecords,
          // },
        ],
      }
    ]
  };

  if (firstUser != undefined) {
    sidebarLinks.categories.push(
      {
        id: "2",
        name: "Members Records",
        links: [
          {
            id: "1",
            name: "Daily",
            url: generatePath(apiUrls.account.children.admin.children.memberDay, {
              userId: firstUser.id
            }),
            
          },
          {
            id: "2",
            name: "Monthly",
            url: generatePath(apiUrls.account.children.admin.children.memberMonth, {
              userId: firstUser.id
            }),
            
          },
          {
            id: "3",
            name: "Yearly",
            url: generatePath(apiUrls.account.children.admin.children.memberYear, {
              userId: firstUser.id
            }),
            
          },
        ]
      }
    )
  }
  
  sidebarLinks.categories.push(
      {
        id: "3",
        name: "General",
        links: [
          {
            id: "1",
            name: "Users",
            url: apiUrls.account.children.admin.children.users,
            
          },
          {
            id: "2",
            name: "Tasks",
            url: apiUrls.account.children.admin.children.tasks,
          },
        ]
      }
  );

  return (
    <PanelBase sidebarLinks={sidebarLinks}>{children}</PanelBase>
  );
}
type AdminPanelBaseProps = {
  children: ReactNode;
};
export function AdminPanelBase({ children }: AdminPanelBaseProps) {
  const { showAlert } = useApp();

  return (
      <RoleAuthProvider role={AuthRoles.admin}>
        <UsersProvider>
          <AdminPanelBaseInner>
            {children}
          </AdminPanelBaseInner>
        </UsersProvider>
      </RoleAuthProvider>
  );
}
