import { useModal } from "../../../../hooks/useModal";

export enum ModalModes {
  Readonly = "readonly",
}

export type OpenExportModalStatus = {
  mode: ModalModes.Readonly;
  status: { text: string };
};

export function useExportModal(refreshData: () => void) {
  const { modalStatus, openModal, closeModal } =
    useModal<OpenExportModalStatus>();

  const openExportModal = (text: string) => {
    openModal({
      mode: ModalModes.Readonly,
      status: {
        text: text,
      },
    });
  };
  const closeExportModal = () => {
    closeModal();
    refreshData();
  };
  return { modalStatus, openExportModal, closeExportModal } as const;
}
