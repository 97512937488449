import { useState } from "react";
import { TaskRecord } from "../../../../utils/TaskRecord";

export enum ModalModes {
  RecordEdition = "edition",
  RecordCreation = "creation",
}
export type Modal = {
  show: boolean;
  mode: ModalModes | null;
  taskRecord: TaskRecord | null;
};
export type OpenModalProps = {
//   isEditionMode: boolean;
  isBillableTimerecord: boolean;
  isManual: boolean;
};


export function useModal(selectedRecord: TaskRecord | null) {
  const [modalStatus, setModalStatus] = useState<Modal>({
    show: false,
    mode: null,
    taskRecord: null,
  });

  const openModal = (isEdition: boolean) => {
    if (!isEdition) {
        // Open empty
        let newModalStatus = {
            show: true,
            mode: ModalModes.RecordCreation,
            taskRecord: null
        }
        setModalStatus(newModalStatus);
        return;
    }
    // Open filled
    if (!selectedRecord){
        return
    }
    let newModalStatus = {
      show: true,
      mode: ModalModes.RecordEdition,
      taskRecord: selectedRecord,
    };
    setModalStatus(newModalStatus);
  };

  const closeModal = () => {
    setModalStatus({
      show: false,
      mode: null,
      taskRecord: null,
    });
  };
  
  return [modalStatus, openModal, closeModal] as const;
}
