import { Button, Col, Row } from "react-bootstrap";
import InputEmail from "../../../../../components/InputEmail";
import InputPassword from "../../../../../components/InputPassword";
import { useState } from "react";
import alertMessages from "../../../../../data/alertMessages.json";
import restrictions from "../../../../../data/restrictions.json";
import { useApp } from "../../../../../contexts/AppContext";
import { ErrorLog } from "../../../../../hooks/useFormErrors";
import ValidationFeedback from "../../../../../components/ValidationFeedback";
import {
  useFormSubmit,
  ValidationResult,
} from "../../../../../hooks/useFormSubmit";
import { fetchers } from "../../../../../serverApi/fetchers";

type FormErrors = {
  generalError: ErrorLog;
  oldPasswordError: ErrorLog;
  newPasswordError: ErrorLog;
  newRepeatedPasswordError: ErrorLog;
};
type PasswordInfo = {
  oldPassword: string;
  newPassword: string;
  newRepeatedPassword: string;
};

export function ChangePasswordForm() {
  const { showAlert } = useApp();
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
    oldPassword: "",
    newPassword: "",
    newRepeatedPassword: "",
  });
  const resetForm = () => {
    setPasswordInfo({
      oldPassword: "",
      newPassword: "",
      newRepeatedPassword: "",
    });
  };
  const validateOldPassword = (password: string): ValidationResult => {
    if (!password) {
      const message = "Password cannot be empty.";
      return { isValid: false, message: message };
    }

    return { isValid: true, message: null };
  };
  const validateNewPassword = (password: string): ValidationResult => {
    if (!password) {
      const message = "Password cannot be empty.";
      return { isValid: false, message: message };
    }
    if (password.length < restrictions.minPasswordLength) {
      const message =
        "Password should have at least " +
        restrictions.minPasswordLength +
        " characters.";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };
  const validateNewRepeatedPassword = (
    password: string,
    repeatedPassword: string
  ): ValidationResult => {
    if (!repeatedPassword) {
      const message = "Password cannot be empty.";
      return { isValid: false, message: message };
    }
    if (repeatedPassword !== password) {
      const message = "Passwords do not match";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };

  const validatePasswordInfo = () => {
    return (
      validateOldPassword(passwordInfo.oldPassword).isValid &&
      validateNewPassword(passwordInfo.newPassword).isValid &&
      validateNewRepeatedPassword(
        passwordInfo.newPassword,
        passwordInfo.newRepeatedPassword
      ).isValid
    );
  };

  const sendPasswordInfo = (
    _doIfSuccess: () => void,
    _doAlways: () => void
  ): void => {
    const dataToSend = {
      password: passwordInfo.oldPassword,
      newPassword: passwordInfo.newPassword,
    };
    fetchers.account.general.changePassword.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        showAlert(alertMessages.passwordSuccessfullyChanged, true);
        resetForm();
      },
      fail: (data, status) => {
        if(status === 401) {
          showAlert(alertMessages.invalidOldPassword);
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      }
    });
  };
  const getUpdatedFormErrors = (_passwordInfo: PasswordInfo| undefined = passwordInfo): FormErrors => {
    const vOldPasswordRes = validateOldPassword(_passwordInfo.oldPassword);
    const vNewPassword = validateNewPassword(_passwordInfo.newPassword);
    const vNewRepeatedPassword = validateNewRepeatedPassword(
      _passwordInfo.newPassword,
      _passwordInfo.newRepeatedPassword
    );
    return {
      ...formErrors,
      oldPasswordError: vOldPasswordRes.message,
      newPasswordError: vNewPassword.message,
      newRepeatedPasswordError: vNewRepeatedPassword.message,
    };
  };

  const [
    formStatus,
    formErrors,
    handleSubmit,
    submitIsPossible,
    updateFormErrors,
  ] = useFormSubmit<FormErrors, PasswordInfo>(
    {
      generalError: null,
      oldPasswordError: null,
      newPasswordError: null,
      newRepeatedPasswordError: null,
    },
    validatePasswordInfo,
    sendPasswordInfo,
    getUpdatedFormErrors
  );


  const handleOldPasswordChange = (oldPassword: string) => {
    const newpasswordInfo = { ...passwordInfo, oldPassword: oldPassword };
    updateFormErrors(newpasswordInfo)
    setPasswordInfo(newpasswordInfo);

  };

  const handleNewPasswordChange = (newPassword: string) => {
    const newpasswordInfo = { ...passwordInfo, newPassword: newPassword };
    updateFormErrors(newpasswordInfo)
    setPasswordInfo(newpasswordInfo);
  };

  const handleNewRepeatedPasswordChange = (newRepeatedPassword: string) => {
    const newpasswordInfo = {
      ...passwordInfo,
      newRepeatedPassword: newRepeatedPassword,
    };
    updateFormErrors(newpasswordInfo)
    setPasswordInfo(newpasswordInfo);
  };

  return (
    <Row className="flex-column">
      <Col className="mb-2">
        <InputPassword
          placeholder="Enter old password"
          value={passwordInfo.oldPassword}
          onChange={handleOldPasswordChange}
        />
        <ValidationFeedback message={formErrors.oldPasswordError} />
      </Col>
      <Col className="mb-2">
        <InputPassword
          placeholder="Enter new password"
          value={passwordInfo.newPassword}
          onChange={handleNewPasswordChange}
        />
        <ValidationFeedback message={formErrors.newPasswordError} />
      </Col>
      <Col className="mb-2">
        <InputPassword
          placeholder="Repeat new password"
          value={passwordInfo.newRepeatedPassword}
          onChange={handleNewRepeatedPasswordChange}
        />
        <ValidationFeedback message={formErrors.newRepeatedPasswordError} />
      </Col>
      <Col>
        <Button
          variant="outline-primary"
          className="w-100"
          disabled={!submitIsPossible}
          onClick={handleSubmit}
        >
          {formStatus.isSubmitting ? "Submitting" : "Change password"}
        </Button>
      </Col>
    </Row>
  );
}
