import { ReactNode, useEffect, useState } from "react";
import { useCard } from "../../context/CardContext";
import Grid from "../../../Grid";
import SimpleCard from "../../../SimpleCard";
import "./Modal.scss";
import CrossBtn from "../../../CrossBtn";

type ModalProps = {
  children: ReactNode;
  show: boolean;
  onClose: () => void;
};

export function Modal({ children, show, onClose }: ModalProps) {
  useCard();
  return (
    <>
      {show && (
        <Grid.Node variant="main">
          <div className="Modal" onClick={onClose}>
            <SimpleCard className="border" onClick={(e)=>{e.stopPropagation();}}>
              <div className="close">
                {/* <div onClick={onClose}></div> */}
                <CrossBtn onClick={onClose} width="1em"/>
              </div>
              {children}
            </SimpleCard>
          </div>
        </Grid.Node>
      )}
    </>
  );
}
