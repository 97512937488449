import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import "./MonthStatusUnresolvedDropdown.scss";
import { MonthStatus, renderMonthStatus } from "../../../utils/MonthStatus";
import MonthStatusUnresolvedButton from "../../MonthStatusUnresolvedButton";

type MonthStatusUnresolvedDropdown = {
  onSelect: (status: boolean) => void;
  variant?: "small" | "normal";
  className?: string;
};
export function MonthStatusUnresolvedDropdown({
  onSelect,
  variant = "normal",
  className = "",
}: MonthStatusUnresolvedDropdown) {
  const handleChange = (status: boolean) => {

    onSelect(status);
  };
  return (
    <DropdownButton
      title={"unresolved"}
      as={ButtonGroup}
      size="sm"
      variant="outline-secondary"
      className={`MonthStatusUnresolvedDropdown ${variant} ${className}`}
      drop="down-centered"
      onSelect={(evk: string | null) => evk && handleChange(evk === "grant")}
    >
      <Dropdown.Item eventKey={"grant"}>
      grant all
      </Dropdown.Item>
      <Dropdown.Item eventKey={"reject"}>
      reject all
      </Dropdown.Item>
        
    </DropdownButton>
  );
}
