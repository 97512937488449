import { useEffect, useRef } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import "./ButtonScale.scss";
type ButtonScale = ButtonProps & {
    parentClassName?: string;
}
export function ButtonScale({parentClassName, className, ...props}: ButtonScale){
    const childRef = useRef<HTMLButtonElement | null>(null)
    useEffect(()=>{
        const childElement = childRef.current;
        if(childElement && parentClassName) {
            const parentElement = childElement.closest(`.${parentClassName}`);
            if (parentElement) {
                parentElement.addEventListener("mouseenter", function(){
                    childElement.classList.add("parent-hovered");
                });
                parentElement.addEventListener("mouseleave", function(){
                    childElement.classList.remove("parent-hovered");
                });
            }
        }
    }, [parentClassName])
    return (
        <Button ref={childRef} className={"ButtonScale " + (className ? className : "")} {...props}/>
    );
}