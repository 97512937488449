import { ReactNode } from "react";
import List from "../List";
import Accordion from "../Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import "./TextOptions.scss";

type OptionProps = {
  eventKey: string;
  header: string;
  children: ReactNode;
  headerActive?: string;
  className?: string;
};
function Option({ eventKey, header, children, className = "", headerActive=header }: OptionProps) {
  return (
    <List.Item className={"TextOption " + className}>
      <Accordion>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Item.Header>
            <div className="list-row-styled title d-flex">
              <div className="arrow">
                <FontAwesomeIcon
                  icon={faCaretLeft}
                  className="mx-2"
                  rotation={90}
                />
              </div>
              {header}
            </div>
          </Accordion.Item.Header>
          <Accordion.Item.HeaderActive>
            <div className="list-row-styled title d-flex">
              <div className="arrow">
                <FontAwesomeIcon
                  icon={faCaretLeft}
                  className="mx-2"
                  rotation={90}
                />
              </div>
              {headerActive}
            </div>
          </Accordion.Item.HeaderActive>
          <Accordion.Item.Body>
            <div className="content">{children}</div>
          </Accordion.Item.Body>
        </Accordion.Item>
      </Accordion>
    </List.Item>
  );
}
type OptionsProps = {
  children: ReactNode;
  className?: string;
};
export function TextOptions({ children, className }: OptionsProps) {
  return <List className={"TextOptions " + className}>{children}</List>;
}
TextOptions.Option = Option;
