import { ReactNode, useState } from "react";
import { createContext } from "../../../../hooks/useCreateContext";

export type PageTitle = {
    full: string;
    short: string;
}
type BaseContextVal = {
//   message: string | undefined;
//   showAlert: (message: string) => void;
//   hideAlert: () => void;
  pageTitle: PageTitle | undefined;
  setPageTitle: (pageTitle: PageTitle | undefined) => void;

};
const [BaseContext, useBase] =
createContext<BaseContextVal>();
export { useBase };

type BaseProviderProps = {
    children: ReactNode;
}
export function BaseProvider({ children }: BaseProviderProps){
    const [pageTitle, setPageTitle] = useState<PageTitle | undefined>(undefined);
    // const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
    // const showAlert = (message: string) => {
    //     setAlertMessage(message);
    // }
    // const hideAlert = () => {
    //     setAlertMessage(undefined)
    // }
    // var message = alertMessage;
    return (
        <BaseContext.Provider value={{
            // message, showAlert, hideAlert, 
            pageTitle, setPageTitle}}>
            {children}
        </BaseContext.Provider>
    )
    
}
