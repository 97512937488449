import { useState } from "react";
import { TaskRecord, TaskRecordGroups } from "../../../../utils/TaskRecord";
import { TaskRecords } from "../../../../utils/TaskRecords";

export function useSelectedRecord(taskRecords: TaskRecords | undefined) {
  const [selectedRecord, setSelectedRecord] = useState<TaskRecord | null>(null);
 
  const selectRecord = (group: TaskRecordGroups, recordId: string) => {
    if (!taskRecords) return;
    var tasksForGroup = taskRecords.findGroup(group);
    if (!tasksForGroup) return;
    // Select
    if (selectedRecord == null) {
      setSelectedRecord(tasksForGroup[recordId]);
      return;
    }
    var isSameGrSelected = selectedRecord.group.valueOf() === group;
    var isSameIdSelected = selectedRecord.id === recordId;
    if (
      (isSameGrSelected && !isSameIdSelected) ||
      (!isSameGrSelected && isSameIdSelected) ||
      (!isSameGrSelected && !isSameIdSelected)
    ) {
      setSelectedRecord(tasksForGroup[recordId]);
      return;
    }
    // Unselect
    setSelectedRecord(null);
  };

  const resetSelecedRecord = () => {
    setSelectedRecord(null);
  }
  return [selectedRecord, selectRecord, resetSelecedRecord] as const;
}
