import WidgetCard from "../../../components/WidgetCard";
import "./TasksList.scss";
import CategoriesList from "./components/CategoriesList";
import Header from "./components/Header";
import Modal from "./components/Modal";
import { TasksListProvider, useTasksList } from "./context/TasksListContext";

export function TasksList() {
    return (<WidgetCard
        className="AdminTasksList"
        ContextProvider={TasksListProvider}
        useContext={useTasksList}
      >
        {/* <WidgetCard.Header>
          <Header/>
        </WidgetCard.Header> */}
        <WidgetCard.Body>
          <Header />
          <CategoriesList /> 
        </WidgetCard.Body>
        <WidgetCard.Modal>
          <Modal />
        </WidgetCard.Modal>
      </WidgetCard>
    );
}