import { Container, Form } from "react-bootstrap";
import { Task, Tasks } from "../../../../../../../utils/Task";
import {
  TaskRecord,
  TaskRecordGroups,
} from "../../../../../../../utils/TaskRecord";
import { useDayRecords } from "../../../../context/DayRecordsContext";
import { DateTime } from "../../../../../../../utils";
import ModalEditManualTimeRecord from "./components/ModalEditManualTimeRecord";
import ModalEditClockedTimeRecord from "./components/ModalEditClockedTimeRecord";
import alertMessages from "../../../../../../../data/alertMessages.json";
import { useApp } from "../../../../../../../contexts/AppContext";
import { fetchers } from "../../../../../../../serverApi/fetchers";

type ModalEditTimeRecordProps = {
  taskRecord: TaskRecord;
  tasks: Tasks;
};
export function ModalEditTimeRecord({
  taskRecord,
  tasks,
}: ModalEditTimeRecordProps) {
  const { showAlert } = useApp();
  const { date, closeModal, refreshDay } = useDayRecords();

  const deleteRecord = (doIfSuccess: () => void, doAlways: () => void) => {
    if (
      window.confirm("Are you sure you want to delete this record?") !== true
    ) {
      doAlways();
      return;
    }
    // const endpointDeleteRecord = serverApi.deleteTaskRecord;
    // const dataToSend = {
    //     date: date.toJSON(),
    //     recordId: taskRecord.id,
    //     group: taskRecord.group
    // }
    // const doIfSuccess = () => {
    //   closeModal();
    //   refreshDay();
    // }
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // }
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // }
    // fetchPost<{}>(endpointDeleteRecord, dataToSend, doIfSuccess, doIfFailed, doAlways, doIfError)
    const dataToSend = {
      date: date,
      recordId: taskRecord.id,
      group: taskRecord.group,
    };
    fetchers.account.member.deleteTimerecord.fetch(dataToSend, {
      success: () => {
        doIfSuccess();
        closeModal();
        refreshDay();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: doAlways,
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  return (
    <>
      {taskRecord.isManual && (
        <ModalEditManualTimeRecord
          timeRecord={taskRecord}
          deleteRecord={deleteRecord}
          tasks={tasks}
        />
      )}
      {!taskRecord.isManual && taskRecord.period.endedAt !== null && (
        <ModalEditClockedTimeRecord
          timeRecord={{
            ...taskRecord,
            period: {
              ...taskRecord.period,
              endedAt: taskRecord.period.endedAt,
            },
          }}
          deleteRecord={deleteRecord}
          tasks={tasks}
        />
      )}
    </>
  );
}
