import React, { ReactNode, cloneElement, useState } from "react";
import Table from "../Table";
import { Col, Row } from "react-bootstrap";
import "./List.scss";
import Accordion from "../Accordion";
import { AccordionItemProps } from "react-bootstrap/esm/AccordionItem";
import { useSetCollection } from "../../hooks/useSetCollection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faPencil,
  faPlus,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

type ListItemProps = {
  // key: React.Key;
  children: ReactNode;
  className?: string;
};

function ListItem({ children, className }: ListItemProps) {
  return (
    <Row className={`ListItem ${className ? className : ""}`}>
      <Col>{children}</Col>
    </Row>
  );
}

type ListProps = {
  children: ReactNode;
  className?: string;
};
export function List({ children, className }: ListProps) {
  return (
    <div className={`List ${className}`}>
      <Table>
        <Table.Body>{children}</Table.Body>
      </Table>
    </div>
  );
}
List.Item = ListItem;

type ListRowStyledProps = {
  children: ReactNode;
  className?: string;
  active: boolean;
  showArrow?: boolean;
};
export function ListRowStyled({
  children,
  className = "",
  active,
  showArrow = false,
}: ListRowStyledProps) {
  return (
    <div className={`list-row-styled ${active ? "active" : ""} ${className}`}>
      {showArrow && (
        <div className="list-active-arrow">
          <FontAwesomeIcon icon={faCaretLeft} className="mx-2" rotation={90} />
        </div>
      )}
      {children}
    </div>
  );
}
