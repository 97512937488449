import { Link } from "react-router-dom";
import WidgetCard from "../../../components/WidgetCard";
import { EmailForm } from "./components/EmailForm/EmailForm";
import { Header } from "./components/Header/Header";
import apiUrls from "../../../data/api.json";
import "./ForgotPasswordForm.scss";
export function ForgotPasswordForm() {
  return (
    <WidgetCard className="ForgotPasswordForm">
      <WidgetCard.Header>
        <Header />
      </WidgetCard.Header>
      <WidgetCard.Body>
        <EmailForm />
        <div className="text-center mt-3 mb-1 small link_login">
          <Link to={apiUrls.login}>Log in</Link>
        </div>
      </WidgetCard.Body>
    </WidgetCard>
  );
}
