import { useNavigate } from "react-router";
import { DateTime } from "../../../../utils";
import {
  Month,
  Year,
  isDay,
  isMonth,
  isYear,
} from "../../../../utils/TimeTypes";
import { useQueryParams } from "../../../../hooks/useQuery";
import { useEffect, useRef } from "react";
import { usePrevious } from "../../../../hooks/usePreviousValue";
import { useQuerySearchParam } from "../../../../hooks/useQuerySearchParam";

function parseDate(dataStr: string): DateTime {
  const dataPattern = /^([0-2][1-9]|3[0,1]|[1-2]0)\.(0[1-9]|1[0,1,2])\.(\d{4})$/;
  if (dataStr.length < 1 || !dataPattern.test(dataStr)) {
    throw new Error();
  }
  const matches = dataStr.match(dataPattern);
  if (!matches) {
    throw new Error();
  }
  const dayVal = parseInt(matches[1]);
  if (isNaN(dayVal) || !isDay(dayVal)) {
    throw new Error();
  }
  const monthVal = parseInt(matches[2]);
  if (!monthVal || !isMonth(monthVal)) {
    throw new Error();
  }
  const yearVal = matches[3];
  if (!yearVal || !isYear(yearVal)) {
    throw new Error();
  }
  return DateTime.createLocDateTime(yearVal, monthVal, dayVal);
}

// function dateInRange(
//   date: DateTime,
//   startDate: DateTime,
//   endDate: DateTime
// ): boolean {
//   return date.graterThanOrEqual(startDate) && date.lessThanOrEqual(endDate);
// }

type UseQueryDateReturnVal = {
  date: DateTime;
  setDate: (newDate: DateTime) => void;
};

export function useQueryDate(range: {
  minDate: DateTime;
  maxDate: DateTime;
}): UseQueryDateReturnVal {
  const queryDateName = "date";

  const parseQueryDateVal = (param: string): DateTime => {
    return parseDate(param);
  };
  const createQueryDateStr = (dateTime: DateTime): string => {
    return (
      dateTime.getLocDay().toString().padStart(2, "0") +
      "." +
      dateTime.getLocMonth().toString().padStart(2, "0") +
      "." +
      dateTime.getLocYear()
    );
  };

  const validateDate = (date: DateTime): boolean => {
    return (
      date.graterThanOrEqual(range.minDate) &&
      date.lessThanOrEqual(range.maxDate)
    );
  };

  const areEqual = (date1: DateTime, date2: DateTime) => {
    return (
      date1.getLocYear() === date2.getLocYear() &&
      date1.getLocMonth() === date2.getLocMonth() &&
      date1.getLocDay() === date2.getLocDay()
    );
  };

  const [date, setDate] = useQuerySearchParam<DateTime>(
    queryDateName,
    DateTime.createLocDateTime(
      new DateTime().getLocYear(),
      new DateTime().getLocMonth(),
      new DateTime().getLocDay()
    ),
    parseQueryDateVal,
    createQueryDateStr,
    validateDate,
    areEqual
  );

  return { date, setDate } as const;
}
