import { ReactNode } from "react";
import Base, { useBase } from "../Base";
import { TimerProvider } from "../../../contexts/TimerContext";
import { useApp } from "../../../contexts/AppContext/AppContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "../../ErrorBoundaryFallback";
import PanelBase from "../PanelBase";
import apiUrls from "../../../data/api.json";
import RoleAuthProvider from "../../../contexts/RoleAuthContext";
import { AuthRoles } from "../../../utils/AuthRoles";
import AccountBase from "../AccountBase";
import { faClock, faCalendarWeek, faCalendarDay, faCalendarDays, faCalendar} from "@fortawesome/free-solid-svg-icons";

type MemberPanelBaseProps = {
  children: ReactNode;
};

export function MemberPanelBase({ children }: MemberPanelBaseProps) {
  const { showAlert } = useApp();
  const sidebarLinks = {
    categories: [
      {
        id: "1",
        name: "All",
        links: [
          {
            id: "1",
            name: "Dashboard",
            url: apiUrls.account.children.member.children.dashboard,
            tab: {
              id: 2,
              name: "Timer",
              main: true,
              icon: faClock
            }
          },
          {
            id: "5",
            name: "Tasks",
            url: apiUrls.account.children.member.children.tasks,
          },
          {
            id: "2",
            name: "Daily Summary",
            shortName: "Day",
            url: apiUrls.account.children.member.children.dayRecords,
            tab: {
              id: 1,
              icon: faCalendarDay
            }
          },
          {
            id: "3",
            name: "Monthly Summary",
            shortName: "Month",
            url: apiUrls.account.children.member.children.monthRecords,
            tab: {
              id: 3,
              icon: faCalendarDays

            }
          },
          {
            id: "4",
            name: "Yearly Summary",
            url: apiUrls.account.children.member.children.yearRecords,
          },
          // {
          //   id: "6",
          //   name: "Settings",
          //   url: apiUrls.account.children.member.children.settings,
          // },
        ],
      },
    ],
  };
  return (
      <RoleAuthProvider role={AuthRoles.member}>
        <TimerProvider>
          <PanelBase sidebarLinks={sidebarLinks}>{children}</PanelBase>
        </TimerProvider>
      </RoleAuthProvider>
  );
}
