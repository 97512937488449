import { useEffect } from "react";
import { DateTime } from "../../../utils";
import { Day, Month, Year } from "../../../utils/TimeTypes";
import MembersWorktimeSummaryTable from "../../../widgets/admin/MembersWorktimeSummaryTable";
import { useQueryDay } from "./hooks/useQueryDay";
import { useQueryDate } from "./hooks/useQueryDate";
import usePageTitle from "../../../hooks/usePageTitle";

export function MembersWorktimeSummary() {
  const pageTitle = {full: "Members Worktime Summary", short: "Overview"};
  usePageTitle(pageTitle);
    const { date, setDate } = useQueryDate({
      minDate: DateTime.createLocDateTime("2024", 3, 1),
      maxDate: DateTime.createLocDateTime(
        new DateTime().getLocYear(),
        new DateTime().getLocMonth(),
          new DateTime().getLocDay(),
      ),
    });

    return (<MembersWorktimeSummaryTable date={date} setDate={setDate}/>);
}