import { Badge } from "react-bootstrap";
import DatePicker from "../../../../../../../components/DatePicker";
import { RequestsMonth, usePendingMembersRequests } from "../../../../context/PendingMembersRequestsProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  faXmarkCircle,
  faCheckCircle
} from "@fortawesome/free-regular-svg-icons";
import "./Month.scss";
import TooltipButton from "../../../../../../../components/TooltipButton";
import { useState } from "react";
import { useMonthRequestsItem } from "../../RequestsList";

export function Month({
  monthInfo,
  active,
}: {
  monthInfo: RequestsMonth;
  active: boolean;
}) {
  const {resolveRequests} = usePendingMembersRequests();
  const {setIsMonthRequestsItemDisabled} = useMonthRequestsItem();
  
  return (
    <div
      className={`Month list-row-styled d-flex align-items-center justify-content-between ${
        active ? "active" : ""
      } ${monthInfo.warning ? "warning" : ""}
      `}
    >
      <div className="d-flex align-items-center">
        <div>
          <FontAwesomeIcon icon={faCaretLeft} className="mx-2" rotation={90} />
        </div>
        <div className="date">
          <DatePicker
            date={monthInfo.month}
            setDate={undefined}
            format={"monthOnly"}
            // minDate={availableMonths.minMonth}
            // maxDate={availableMonths.maxMonth}
            // leftArrow={true}
            // rightArrow={true}
            // leftArrowClassName="d-none d-sm-inline-block"
            // rightArrowClassName="d-none d-sm-inline-block"
          />
        </div>
        <div className="mx-2 count">
          <Badge
            bg="secondary"
            pill
            className="list-row-badge d-flex justify-content-center align-items-center"
          >
            {monthInfo.members.length}
          </Badge>
        </div>
        <div className="ms-1 open">| tasks</div>
      </div>
      <div className="d-flex ms-1 actions">
      
          <div>
            <TooltipButton
              tooltipContent="Reject All Month"
              buttonVariant="light"
              className="rejectAll list-row-btn"
              onClick={(e) => {
                e.stopPropagation();
                setIsMonthRequestsItemDisabled(true);
                const requests = monthInfo.members.flatMap(member => member.requests).map(request => request.id);
                resolveRequests(requests, false)
              }}
            >
              <FontAwesomeIcon icon={faXmark} size="sm" className="mx-2" />
            </TooltipButton>
          </div>
          <div>
            <TooltipButton
              tooltipContent="Grant All Month"
              buttonVariant="light"
              className="grantAll list-row-btn"
              onClick={(e) => {
                e.stopPropagation();
                setIsMonthRequestsItemDisabled(true);
                const requests = monthInfo.members.flatMap(member => member.requests).map(request => request.id);
                resolveRequests(requests, true)
              }}
            >
              <FontAwesomeIcon icon={faCheck} size="sm" className="mx-2" />
            </TooltipButton>
          </div>
      </div>
    </div>
  );
}
