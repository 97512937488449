import Base from "../../components/pageBases/Base";
import "./Lost.scss";
import apiUrls from "../../data/api.json";

export function Lost() {
  return (
    <Base>
      <div className="Lost">
        <div className="wrapper">
          <h1>Hmm.</h1>
          <p>
            It seems that you're lost
            <span className="extra"> in a perpetual black hole</span>. Let us
            help guide you out and get you back home.
          </p>
          <div className="buttons">
            <a href={apiUrls.index}>Home</a>
            <br />
          </div>
        </div>
        <div className="side">
            <div className="space">
            <div className="blackhole"></div>
            <div className="ship"></div>
            </div>
        </div>
      </div>
    </Base>
  );
}
