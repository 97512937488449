import React, { useEffect, useState } from "react";
import { useDayRecords } from "../../context/DayRecordsContext";
import { Card } from "react-bootstrap";
import { ModalModes } from "../../hooks/useModal";
import "./Modal.scss";
import { Task, Tasks, TasksJson } from "../../../../../utils/Task";
import ModalCreateTimeRecord from "./components/ModalCreateTimeRecord";
import ModalEditTimeRecord from "./components/ModalEditTimeRecord";
import alertMessages from "../../../../../data/alertMessages.json";
import { useApp } from "../../../../../contexts/AppContext";
import { fetchers } from "../../../../../serverApi/fetchers";
import Placeholder from "../../../../../components/Placeholder";


export function Modal() {
  const {showAlert} = useApp();
  const { modalStatus } = useDayRecords();
  const [tasks, setTasks] = useState<Tasks | undefined>(undefined);
  const [selectedTask, setSelectedTask] = useState<Task | undefined>(
    modalStatus.taskRecord?.task ? modalStatus.taskRecord.task : undefined
  );
  const loadTasks = () => {
    // const endpointGetTasks = serverApi.getTasks;
    // const doIfSuccess = (data: TasksJson) => {
    //   const receivedTasks = new Tasks(data);
    //   if (receivedTasks.tasks.length < 1) {
    //     throw new Error("Something went wrong.");
    //   }
    //   setTasks(receivedTasks);
    // };
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // }
    // const doAlways = undefined;
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // }
    // fetchGet<TasksJson>(endpointGetTasks, undefined, doIfSuccess, doIfFailed, doAlways, doIfError);
    fetchers.account.member.tasks.fetch({}, {
      success: (data, status) => {
        setTasks(new Tasks(data));
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      }
    })
  }
  
  useEffect(() => {
    loadTasks();
  }, []);

  return (
    <Card.Body>
      {tasks && tasks.getSortedByCategory()?.categories[0]?.tasks[0] && (
        <>
          {modalStatus.mode === ModalModes.RecordCreation && (
            <ModalCreateTimeRecord
              tasks={tasks}
            />
          )}
          {modalStatus.taskRecord &&
            modalStatus.mode === ModalModes.RecordEdition && (
              <ModalEditTimeRecord
                taskRecord={modalStatus.taskRecord}
                tasks={tasks}
              />
            )}
        </>
      )}
      {tasks !== undefined && !(tasks && tasks.getSortedByCategory()?.categories[0]?.tasks[0]) && (
        <>No Tasks Available</>
      )}
      {/* {tasks === undefined &&  (
        <Placeholder />
      )} */}
    </Card.Body>
  );
}
