import { Col, Row } from "react-bootstrap";
import InputText from "../../../../../components/InputText";
import InputEmail from "../../../../../components/InputEmail";
import ChangePasswordForm from "../ChangePasswordForm";
import { Formatter } from "../../../../../utils/Formatter";
import "./AccountSettingsContent.scss";
import { useRoleAuth } from "../../../../../contexts/RoleAuthContext/RoleAuthContext";
import { useAuth } from "../../../../../contexts/AuthContext";
import { AuthRoles } from "../../../../../utils/AuthRoles";
import { Auth } from "../../../../../contexts/AuthContext/AuthContext";

type AccountSettingsContentProps = {
  auth: Auth
}
export function AccountSettingsContent({auth}: AccountSettingsContentProps) {
  const lablelColWidthSmall = 12;
  const lablelColWidth = 3;
  const lablelColWidthLarge = 3;
  const controlColumnWidthSmall = 12;
  const controlColumnWidth = 9;
  const controlColumnWidthLarge = 7;
  const tmp = "mx-sm-5";
  return (
    <>
        <div className="AccountSettingsContent px-sm-5">
          <div className="info px-2 w-100">
            <Row className="name">
              <Col
                xs={lablelColWidthSmall}
                sm={lablelColWidth}
                md={lablelColWidthLarge}
                className="label text-capitalize"
              >
                User:
              </Col>
              <Col
                xs={controlColumnWidthSmall}
                sm={controlColumnWidth}
                md={controlColumnWidthLarge}
              >
                <InputText defaultValue={auth.firstName + ' ' + auth.lastName} readOnly={true} />
              </Col>
            </Row>
            <Row className="whenCreated">
              <Col
                xs={lablelColWidthSmall}
                sm={lablelColWidth}
                md={lablelColWidthLarge}
                className="label text-capitalize"
              >
                Created:
              </Col>
              <Col
                xs={controlColumnWidthSmall}
                sm={controlColumnWidth}
                md={controlColumnWidthLarge}
              >
                <InputText
                  defaultValue={Formatter.formatDate(auth.created)}
                  readOnly={true}
                />
              </Col>
            </Row>
            <Row className="email">
              <Col
                xs={lablelColWidthSmall}
                sm={lablelColWidth}
                md={lablelColWidthLarge}
                className="label text-capitalize"
              >
                Email:
              </Col>
              <Col
                xs={controlColumnWidthSmall}
                sm={controlColumnWidth}
                md={controlColumnWidthLarge}
              >
                <InputEmail defaultValue={auth.email} readOnly={true} />
              </Col>
            </Row>
            <Row className="changePassword">
              <Col
                xs={lablelColWidthSmall}
                sm={lablelColWidth}
                md={lablelColWidthLarge}
                className="label text-capitalize"
              >
                Change Password:
              </Col>
              <Col
                xs={controlColumnWidthSmall}
                sm={controlColumnWidth}
                md={controlColumnWidthLarge}
              >
                <ChangePasswordForm />
              </Col>
            </Row>
          </div>
        </div>
    </>
  );
}
