import { useUsersList } from "../../context/UsersListContext";
import RoleTabs from "../RoleTabs";
  
export function Content() {
  const { allUsersByRole, openUserCreationModal } = useUsersList();

    return (
        <div>
            <RoleTabs createUser={openUserCreationModal} allUsersByRole={allUsersByRole}/>
        </div>
    );
}