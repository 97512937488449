import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useApp } from "../../../contexts/AppContext";
import { DayStatus, renderDayStatus } from "../../../utils/DayStatus";
import React, { ReactNode } from "react";
import "./DayRequestedStatusDropdown.scss";

type SelectedAction = {
  newStatus: DayStatus,
  approve: boolean
}

type DayRequestedStatusDropdown = {
  currentStatus: DayStatus;
  requestedStatus: DayStatus;
  onReject: (status: DayStatus) => void;
  onApprove: (status: DayStatus) => void;
  variant?: "small" | "normal";
  className?: string;
  disabled?: boolean;
};

export function DayRequestedStatusDropdown({
  currentStatus,
  requestedStatus,
  onReject,
  onApprove,
  variant = "normal",
  className,
  disabled = false,
}: DayRequestedStatusDropdown) {
  const handleChange = (strStatus: string) => {
    if (!Object.values(DayStatus).includes(strStatus as DayStatus)) {
      throw new Error();
    }
    const newStatus = strStatus as DayStatus;
    if (newStatus === requestedStatus){
      onApprove(newStatus)
      return;
    }
    if (newStatus === currentStatus) {
      onReject(strStatus as DayStatus);
      return;
    }
    throw new Error();
  };


  const title = () => {
    return (
      <div>
        {renderDayStatus(currentStatus)} <br/>
      {" (requested " + (requestedStatus == DayStatus.submitted ? "submission" : "edition")+")"}
      </div>
    );
  }
  return (
    <DropdownButton
      title={title()}
      as={ButtonGroup}
      size="sm"
      variant="outline-secondary"
      className={`DayRequestedStatusDropdown ${variant} ${className} ${requestedStatus}`}
      drop="down-centered"
      onSelect={(evk: string | null) => evk && handleChange(evk)}
      disabled={disabled}
    >
      {/* {status == DayStatus.editable && (
        <Dropdown.Item eventKey={DayStatus.submitted}>submit</Dropdown.Item>
      )} */}
      {(requestedStatus === DayStatus.submitted || requestedStatus === DayStatus.editable) && (
        <>
          <Dropdown.Item eventKey={requestedStatus}>
            grant {requestedStatus == DayStatus.submitted ? "submission" : "edition"}
          </Dropdown.Item>
          <Dropdown.Item eventKey={currentStatus}>
            reject request
            {/* {requestedStatus == DayStatus.submitted ? "submission" : "edition"} */}
          </Dropdown.Item>
        </>
      )}
     
    </DropdownButton>
  );
}
