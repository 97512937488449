import { Col, Container, Form, Row } from "react-bootstrap";
import { Task, fetchPost } from "../../../../../../utils";
import { useTasksList } from "../../../context/TasksListContext";
import { ErrorLog } from "../../../../../../hooks/useFormErrors";
import { useState } from "react";
import CategorySelector from "../../../../../../components/CategorySelector";
import restrictions from "../../../../../../data/restrictions.json";
import ValidationFeedback from "../../../../../../components/ValidationFeedback";
import InputText from "../../../../../../components/InputText";
import TooltipButton from "../../../../../../components/TooltipButton";
import { useApp } from "../../../../../../contexts/AppContext";
import alertMessages from "../../../../../../data/alertMessages.json";
import {
  ValidationResult,
  useFormSubmitDelete,
} from "../../../../../../hooks/useFormSubmitDelete";
import {
  sameTaskAlreadyExist,
  validateCategoryName,
  validateTaskName,
} from "../utils/validators";
import { fetchers } from "../../../../../../serverApi/fetchers";

type FormErrors = {
  categoryNameError: ErrorLog;
  taskNameError: ErrorLog;
  generalError: ErrorLog;
};

type TaskInfo = {
  taskId: string;
  categoryName: string;
  taskName: string;
};

type ModalTaskEditionProps = {
  defaultTask: Task;
};
export function ModalTaskEdition({ defaultTask }: ModalTaskEditionProps) {
  const { showAlert } = useApp();
  const { allTasks, closeModal, refreshAllTasks } = useTasksList();

  const [taskInfo, setTaskInfo] = useState<TaskInfo>({
    taskId: defaultTask.id,
    categoryName: defaultTask.category,
    taskName: defaultTask.name,
  });

  const validateSubmitCategoryName = (
    categoryName: string
  ): ValidationResult => {
    return validateCategoryName(categoryName, allTasks);
  };
  const validateSubmitTaskName = (taskName: string): ValidationResult => {
    return validateTaskName(taskName);
  };
  const validateSubmitGeneral = (taskInfo: TaskInfo): ValidationResult => {
    if (!taskInfo.taskId.length) {
      showAlert(alertMessages.somethingWentWrong);
      return { isValid: false, message: "" };
    }
    if (
      (taskInfo.categoryName === defaultTask.category &&
        taskInfo.taskName === defaultTask.name) ||
      sameTaskAlreadyExist(taskInfo.categoryName, taskInfo.taskName, allTasks)
    ) {
      const message = "Task already exists.";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };
  const validateDeleteGeneral = (taskInfo: TaskInfo): ValidationResult => {
    if (!taskInfo.taskId.length) {
      showAlert(alertMessages.somethingWentWrong);
      return { isValid: false, message: "" };
    }
    if (
      taskInfo.categoryName !== defaultTask.category ||
      taskInfo.taskName !== defaultTask.name
    ) {
      const message = "Task info was changed.";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };

  const validateSubmitData = (): boolean => {
    return (
      validateSubmitCategoryName(taskInfo.categoryName.trim()).isValid &&
      validateSubmitTaskName(taskInfo.taskName.trim()).isValid &&
      validateSubmitGeneral({...taskInfo, categoryName: taskInfo.categoryName.trim(), taskName: taskInfo.taskName.trim()}).isValid
    );
  };
  const validateDeleteData = (): boolean => {
    return validateDeleteGeneral(taskInfo).isValid;
  };

  const sendSubmitData = (_doIfSuccess: () => void, _doAlways: () => void) => {
    const dataToSend = {
      taskId: taskInfo.taskId,
      taskName: taskInfo.taskName.trim(),
      categoryName: taskInfo.categoryName.trim(),
    };
    fetchers.account.admin.editPublicTask.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        refreshAllTasks();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/tasks/edit_task";
    // const dataToSend = {
    //   taskId: taskInfo.taskId,
    //   taskName: taskInfo.taskName.trim(),
    //   categoryName: taskInfo.categoryName.trim(),
    // };
    // fetchPost(endpoint, dataToSend, {
    //   success: () => {
    //     _doIfSuccess();
    //     closeModal();
    //     refreshAllTasks();
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     _doAlways();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };
  const sendDeleteData = (_doIfSuccess: () => void, _doAlways: () => void) => {
    if (window.confirm("Are you sure you want to delete this task?") !== true) {
      _doAlways();
      return;
    }
    const dataToSend = {
      taskId: taskInfo.taskId,
    };
    fetchers.account.admin.deletePublicTask.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        refreshAllTasks();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/tasks/delete_task";
    // const dataToSend = {
    //   taskId: taskInfo.taskId,
    // };
    // fetchPost(endpoint, dataToSend, {
    //   success: () => {
    //     _doIfSuccess();
    //     closeModal();
    //     refreshAllTasks();
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     _doAlways();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };

  const defaultFormErrors = {
    categoryNameError: null,
    taskNameError: null,
    generalError: null,
  };
  const getUpdatedFormErrors = (
    _taskInfo: TaskInfo | undefined = taskInfo
  ): FormErrors => {
    const vDeleteRes = validateDeleteGeneral(_taskInfo);
    if (vDeleteRes.isValid) {
      return {
        ...formErrors,
        categoryNameError: null,
        taskNameError: null,
        generalError: vDeleteRes.message,
      };
    }
    const vCategoryNameRes = validateSubmitCategoryName(_taskInfo.categoryName);
    const vTaskName = validateSubmitTaskName(_taskInfo.taskName);
    const vGeneralRes = validateSubmitGeneral(_taskInfo);
    return {
      ...formErrors,
      categoryNameError: vCategoryNameRes.message,
      taskNameError: vTaskName.message,
      generalError: vGeneralRes.message,
    };
  };

  const [
    formStatus,
    formErrors,
    handleSubmit,
    handleDelete,
    submitIsPossible,
    deleteIsPossible,
    updateFormErrors,
  ] = useFormSubmitDelete<FormErrors, TaskInfo>(
    defaultFormErrors,
    validateSubmitData,
    validateDeleteData,
    sendSubmitData,
    sendDeleteData,
    getUpdatedFormErrors
  );

  const handleCategoryNameChange = (newCategoryName: string) => {
    const newCategoryNameTrimmed = newCategoryName; //.trim();
    const newTaskInfo = { ...taskInfo, categoryName: newCategoryNameTrimmed };
    updateFormErrors(newTaskInfo);
    setTaskInfo(newTaskInfo);
  };
  const handleTaskNameChange = (newTaskName: string) => {
    const newTaskNameTrimmed = newTaskName; //.trim();
    const newTaskInfo = { ...taskInfo, taskName: newTaskNameTrimmed };
    updateFormErrors(newTaskInfo);
    setTaskInfo(newTaskInfo);
  };

  const submitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && submitIsPossible) {
      handleSubmit();
    }
  }
  return (
    <Container className="w-auto mx-md-3 ModalTaskEdition" onKeyDown={submitOnEnter}>
      <Form.Group className="mb-3">
        <Form.Label>Category:</Form.Label>
        <CategorySelector
          variant="combobox"
          categories={allTasks.tasksByCategory.categories
            .filter((category) => !!category.isPublic)
            .map((category) => category.name)}
          defaultValue={taskInfo.categoryName}
          onSelect={handleCategoryNameChange}
          inputMaxChars={restrictions.maxTaskCategoryNameLength}
        />
        <ValidationFeedback message={formErrors.categoryNameError} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Task:</Form.Label>
        <InputText
          value={taskInfo.taskName}
          maxLength={restrictions.maxTaskCategoryNameLength}
          onChange={handleTaskNameChange}
        />
        <ValidationFeedback message={formErrors.taskNameError} />
      </Form.Group>
      <Form.Group as={Row} className="mt-5">
        <Col className="d-flex justify-content-start">
          <TooltipButton
            tooltipContent={`Delete this task`}
            buttonVariant="outline-primary"
            className="px-4 delete"
            onClick={handleDelete}
            disabled={!deleteIsPossible}
          >
            {formStatus.isDeleting ? "Deleting" : "Delete"}
          </TooltipButton>
        </Col>
        <Col className="d-flex justify-content-end">
          <ValidationFeedback
            message={formErrors.generalError}
            className="text-end me-3"
          />
          <TooltipButton
            tooltipContent={`Save changes`}
            className="px-4 submit"
            onClick={handleSubmit}
            disabled={!submitIsPossible}
          >
            {formStatus.isSubmitting ? "Saving" : "Save"}
          </TooltipButton>
        </Col>
      </Form.Group>
    </Container>
  );
}
