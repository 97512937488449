import List from "../../../../../../../components/List";
import { User } from "../../../../../../../utils/Users";
import { RequestsMonthMemberRequest } from "../../../../context/PendingMembersRequestsProvider";
import { Request } from "../Request/Request";
import "./Requests.scss";

export function Requests({member, requests}: {member: User; requests: RequestsMonthMemberRequest[]}){
    return (
        <div className="Requests">
        <List>
        {requests.map((request) => (
          <List.Item key={request.id}>
            <Request member={member} request={request} />
          </List.Item>
        ))}
      </List>
        </div>
    );
}