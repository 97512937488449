import { Dropdown } from "react-bootstrap";
import { AuthRoles } from "../../utils/AuthRoles";
import { useApp } from "../../contexts/AppContext";
import alertMessages from "../../data/alertMessages.json";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import "./AuthRoleSelect.scss";
import { usePrevious } from "../../hooks/usePreviousValue";
type AuthRoleSelectControlledProps = {
  roles: AuthRoles[];
  selectedRole: AuthRoles;
  onSelect?: (selectedRole: AuthRoles) => void;
  variant: "select" | "dropdown";
  toggleMaxWidth: string;
  disabled: boolean;
};
function AuthRoleSelectControlled({
  roles,
  selectedRole,
  onSelect,
  variant,
  toggleMaxWidth,
  disabled,
}: AuthRoleSelectControlledProps) {
  const { showAlert } = useApp();
  const handleSelect = (role: string | null) => {
    if (!role || !Object.values(AuthRoles).includes(role as AuthRoles)) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    // setSelectedRole(role as AuthRoles);
    onSelect && onSelect(role as AuthRoles);
  };
  return (
    <div
      className={`AuthRoleSelect ${"AuthRoleSelect-" + variant}`}
      style={{ height: "35%" }}
    >
      <Dropdown
        drop={variant == "select" ? "down" : "down-centered"}
        className={`h-100`}
        onSelect={handleSelect}
      >
        <Dropdown.Toggle
          id={`dropdown-role-selector`}
          variant="light"
          style={{ maxWidth: toggleMaxWidth }}
          disabled={disabled}
        >
          {_.capitalize(selectedRole)}
        </Dropdown.Toggle>
        <Dropdown.Menu className={variant == "select" ? "w-100" : ""}>
          {roles
            .filter((role) => role !== selectedRole)
            .map((role) => (
              <Dropdown.Item eventKey={role} key={role}>
                <div className="ps-2">{_.capitalize(role)}</div>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
type AuthRoleSelectUnControlledProps = {
  roles: AuthRoles[];
  defaultRole?: AuthRoles;
  onSelect?: (selectedRole: AuthRoles) => void;
  variant: "select" | "dropdown";
  toggleMaxWidth: string;
  disabled: boolean;
};
function AuthRoleSelectUncontrolled({
  roles,
  defaultRole,
  onSelect,
  variant,
  toggleMaxWidth,
  disabled,
}: AuthRoleSelectUnControlledProps) {
  const [selectedRole, setSelectedRole] = useState<AuthRoles>(
    defaultRole !== undefined ? defaultRole : Object.values(AuthRoles)[0]
  );
  const previousSelectedRole = usePrevious<AuthRoles>(selectedRole
    // defaultRole !== undefined ? defaultRole : Object.values(AuthRoles)[0]
  );
  useEffect(() => {
    if (
      onSelect &&
      previousSelectedRole !== undefined &&
      !_.isEqual(selectedRole, previousSelectedRole)
    ) {
      onSelect(selectedRole);
    }
  }, [selectedRole]);
  return (
    <AuthRoleSelectControlled
      roles={roles}
      selectedRole={selectedRole}
      onSelect={(selectedRole) => setSelectedRole(selectedRole)}
      variant={variant}
      toggleMaxWidth={toggleMaxWidth}
      disabled={disabled}
    />
  );
}

type AuthRoleSelectProps = {
  value?: AuthRoles;
  defaultValue?: AuthRoles;
  onSelect?: (selectedRole: AuthRoles) => void;
  variant?: "select" | "dropdown";
  toggleMaxWidth?: string;
  disabled?: boolean;
  roles?: AuthRoles[];
};
export function AuthRoleSelect({
  value,
  defaultValue,
  onSelect,
  variant = "select",
  toggleMaxWidth = "unset",
  disabled = false,
  roles = Object.values(AuthRoles)
}: AuthRoleSelectProps) {
  return (
    <>
      {value !== undefined && (
        <AuthRoleSelectControlled
          roles={roles}
          selectedRole={value}
          onSelect={onSelect}
          variant={variant}
          toggleMaxWidth={toggleMaxWidth}
          disabled={disabled}
        />
      )}
      {value === undefined && (
        <AuthRoleSelectUncontrolled
          roles={Object.values(AuthRoles)}
          defaultRole={defaultValue}
          onSelect={onSelect}
          variant={variant}
          toggleMaxWidth={toggleMaxWidth}
          disabled={disabled}
        />
      )}
    </>
  );
}
