import { DateTime } from "../../../../utils";
import { isYear } from "../../../../utils/TimeTypes";
import { useQuerySearchParam } from "../../../../hooks/useQuerySearchParam";

function parseYear(yearStr: string): DateTime {
  const yearPattern = /^(\d{4})$/;
  if (yearStr.length < 1 || !yearPattern.test(yearStr)) {
    throw new Error();
  }
  const matches = yearStr.match(yearPattern);
  if (!matches) {
    throw new Error();
  }
  const yearVal = matches[1];
  if (!yearVal || !isYear(yearVal)) {
    throw new Error();
  }
  return DateTime.createLocDateTime(yearVal);
}

type UseQueryYearReturnVal = {
  year: DateTime;
  setYear: (newYear: DateTime) => void;
};

export function useQueryYear(range: {
  minYear: DateTime;
  maxYear: DateTime;
}): UseQueryYearReturnVal {
  const queryYearName = "year";
  const parseQueryYearVal = (param: string): DateTime => {
    return parseYear(param);
  };
  const createQueryYearStr = (dateTime: DateTime): string => {
    return dateTime.getLocYear();
  };

  const validateYear = (year: DateTime): boolean => {
    return (
      year.graterThanOrEqual(range.minYear) &&
      year.lessThanOrEqual(range.maxYear)
    );
  };

  const areEqual = (year1: DateTime, year2: DateTime) => {
    return (
      year1.getLocYear() === year2.getLocYear() &&
      year1.getLocMonth() === year2.getLocMonth()
    );
  };
  const [year, setYear] = useQuerySearchParam<DateTime>(
    queryYearName,
    new DateTime(),
    parseQueryYearVal,
    createQueryYearStr,
    validateYear,
    areEqual
  );

  return { year, setYear } as const;
}
