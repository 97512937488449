import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import "./MonthStatusDropdown.scss";
import { MonthStatus, renderMonthStatus } from "../../utils/MonthStatus";

type MonthStatusDropdown = {
  status: MonthStatus;
  onChange: (status: MonthStatus) => void;
  variant?: 'small'|'normal';
  className?: string;
};
export function MonthStatusDropdown({ status, onChange, variant="normal", className = "" }: MonthStatusDropdown) {
  const handleChange = (strStatus: string) => {
    if (!Object.values(MonthStatus).includes(strStatus as MonthStatus)) {
      throw new Error();
    }
    onChange(strStatus as MonthStatus);
  };
  return (
    <DropdownButton
      title={renderMonthStatus(status)}
      as={ButtonGroup}
      size="sm"
      variant="outline-secondary"
      className={`MonthStatusDropdown ${variant} ${className} ${status}`}
      drop="down-centered"
      onSelect={(evk: string | null) => evk && handleChange(evk)}
      disabled={(status != MonthStatus.editable)}
    >
      {status == MonthStatus.editable && (
        <Dropdown.Item eventKey={MonthStatus.submitted}>submit</Dropdown.Item>
      )}
    </DropdownButton>
      // {/* TO DO FUTURE IMPROVEMENTS */}
      // {status == MonthStatus.submitted && (
      //   <>
      //     {/* <Dropdown.Item eventKey={MonthStatus.completed}>approved</Dropdown.Item> */}
      //     <Dropdown.Item eventKey={MonthStatus.editable}>request edition</Dropdown.Item>
      //   </>
      // )}
      // {status == MonthStatus.completed && (
      //   <Dropdown.Item eventKey={MonthStatus.submitted}>request submission</Dropdown.Item>
      // )}
  );
}
