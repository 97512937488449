import "./CrossBtn.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

type CrossBtnProps = {
  onClick: () => void;
  width?: string;
};
export function CrossBtn({ onClick, width="1em" }: CrossBtnProps) {
  return (
    <div className="CrossBtn" onClick={onClick} style={{width: width, height: width}}>
        <FontAwesomeIcon icon={faXmark} size="sm"  />
    </div>
  );
}
