import { ListOfAccordions } from "../../../../../../../components/ListOfAccordions/ListOfAccordions";
import { DateTime } from "../../../../../../../utils";
import { RequestsMonthMember } from "../../../../context/PendingMembersRequestsProvider";
import { Member } from "../Member/Member";
import { Requests } from "../Requests/Requests";
import "./Members.scss";

export function Members({month, members} :{month: DateTime; members: RequestsMonthMember[],}){
    return (<div className="Members">
    <ListOfAccordions
      list={members}
      accordionHeader={(memberInfo, active) => (
        <Member memberInfo={memberInfo} month={month} active={active}/>
      )}
      accordionBody={(memberInfo) => <Requests member={memberInfo.member} requests={memberInfo.requests}/>
    }
    />
    </div>
    );
}