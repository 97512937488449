import { AuthRoles } from "../../../../../../../utils/AuthRoles";
import { useUsersList } from "../../../../context/UsersListContext";
import RoleTabs from "../../../RoleTabs";
import "./ModalDeletedUsers.scss";

export function ModalDeletedUsers() {
  const { deletedUsers } = useUsersList();
  const allDeletedUsersByRole = (role: AuthRoles) => {
    return deletedUsers.filter((user) =>
      user.roles.find((userRole) => userRole === role)
    );
  };
  return (
    <div className="ModalDeletedUsers">
      <div className="w-100 d-flex justify-content-center align-items-center mb-1" style={{marginTop: "-40px"}}>
        <div className="title">Deleted Users</div>
      </div>
      <RoleTabs allUsersByRole={allDeletedUsersByRole} />
    </div>
  );
}
