import { useEffect, useState } from "react";
import {
  ErrorLog,
  FormErrors,
  FormErrors as FormErrorsGeneralType,
  useFormErrors,
} from "./useFormErrors";

type FormStatus = {
  isSubmitting: boolean;
};
function useFormStatus() {
  const [formStatus, _setStatusForm] = useState<FormStatus>({
    isSubmitting: false,
  });
  const startSubmitting = () => {
    _setStatusForm({ ...formStatus, isSubmitting: true });
  };
  const stopSubmitting = () => {
    _setStatusForm({ ...formStatus, isSubmitting: false });
  };
  return [formStatus, startSubmitting, stopSubmitting] as const;
}

function useFormSubmitSimple(
  validateData: () => boolean,
  sendSubmitData: (doAlways: () => void) => void
) {
  const [formStatus, startSubmitting, stopSubmitting] = useFormStatus();
  const submitForm = () => {
    if (!validateData()) {
      stopSubmitting();
      return;
    }
    const doAlways = stopSubmitting;
    sendSubmitData(doAlways);
  };

  useEffect(() => {
    if (formStatus.isSubmitting) {
      submitForm();
    }
  }, [formStatus]);

  const handleSubmit = () => {
    if (formStatus.isSubmitting) {
      return;
    }
    startSubmitting();
  };

  return [formStatus, handleSubmit] as const;
}


export type ValidationResult = {
  isValid: boolean;
  message: ErrorLog;
};

export function useFormSubmit<FormErrorsType extends FormErrorsGeneralType, DataType>(
  defaultFormErrorsValues: FormErrorsType,
  validateSubmitData: () => boolean,
  sendSubmitData: (doIfSuccess: () => void, doAlways: () => void) => void,
  getUpdatedFormErrors: (data: DataType | undefined) => FormErrorsType
) {
  const [
    formErrors,
    setFormErrors,
    resetFormErrorsToNull,
    formErrorsHasNoError,
  ] = useFormErrors<FormErrorsType>(defaultFormErrorsValues);
  const _sendSubmitData = (doAlways: () => void) => {
    sendSubmitData(() => {
      resetFormErrorsToNull();
    }, doAlways);
  };
  const [formStatus, _handleSubmit] = useFormSubmitSimple(validateSubmitData, _sendSubmitData);
  const submitIsPossible =
    !formStatus.isSubmitting && formErrorsHasNoError() && validateSubmitData();

  const updateFormErrors = (data: DataType | undefined = undefined) => {
    setFormErrors(getUpdatedFormErrors(data));
  }
  const handleSubmit = () => {
    updateFormErrors();
    _handleSubmit();
  }
  return [
    formStatus,
    formErrors,
    handleSubmit,
    submitIsPossible,
    updateFormErrors,
  ] as const;
}
