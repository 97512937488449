import Cookies, { CookieAttributes } from "js-cookie";
import api from "../data/api.json";

namespace CookiesMenager {
  export const defaultOptions: CookieAttributes = {
    path: api.index,
    secure: true,
    sameSite: "strict"
  };
  export function setCookie(name: string, value: string, options?: CookieAttributes) {
    Cookies.set(name, value, options ? options : defaultOptions);
  }
  export function getCookie(name: string) {
    return Cookies.get(name);
  }
  export function removeCookie(name: string, options?: CookieAttributes) {
    Cookies.remove(name, options ? options : defaultOptions);
  }

  export function clearAllSiteCookies() {
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    })
  }
}



export default CookiesMenager;
