import { DateTime } from "../../../utils";
import DayRecordsTable from "../../../widgets/member/DayRecordsTable";
import { useQueryParams } from "../../../hooks/useQuery";
import { useNavigate } from "react-router";
import { useRoleAuth } from "../../../contexts/RoleAuthContext/RoleAuthContext";
import usePageTitle from "../../../hooks/usePageTitle";
import { useQueryDate } from "./hooks/useQueryDate";
import { Month } from "../../../utils/TimeTypes";

export function DayRecords() {
  const pageTitle = {full: "Daily Summary", short: "Day"};
  usePageTitle(pageTitle);
  const { auth } = useRoleAuth();
  const { date, setDate } = useQueryDate({
    minDate: auth.created.getStartOfMonth(),
    maxDate: new DateTime().getEndOfMonth(),
  });

  return (
    <>
      {date && (
        <DayRecordsTable date={date} setDate={setDate}></DayRecordsTable>
      )}
    </>
  );
}
