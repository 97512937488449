import WidgetCard from "../../../components/WidgetCard";
import { Content } from "./components/Content/Content";
import { Header } from "./components/Header/Header";

export function HelpPageContent() {
return (
    <WidgetCard
      className="HelpPageContent"
    >
      <WidgetCard.Header>
          <Header/>
      </WidgetCard.Header>
      <WidgetCard.Body>
        <Content />
      </WidgetCard.Body>
    </WidgetCard>
  );
}