import { useState } from "react";
import ValidationFeedback from "../../../../../components/ValidationFeedback";
import { useApp } from "../../../../../contexts/AppContext";
import { ErrorLog } from "../../../../../hooks/useFormErrors";
import {
  useFormSubmit,
  ValidationResult,
} from "../../../../../hooks/useFormSubmit";
import { fetchers } from "../../../../../serverApi/fetchers";
import restrictions from "../../../../../data/restrictions.json";
import alertMessages from "../../../../../data/alertMessages.json";
import { Button, Col, Form, Row } from "react-bootstrap";
import InputPassword from "../../../../../components/InputPassword";
import { useNavigate } from "react-router";
import apiUrls from "../../../../../data/api.json";

type FormErrors = {
  generalError: ErrorLog;
  newPasswordError: ErrorLog;
  newRepeatedPasswordError: ErrorLog;
};

type PasswordInfo = {
  newPassword: string;
  newRepeatedPassword: string;
};

type ResetPasswordFormProps = {
  token: string;
};
export function ResetForm({ token }: ResetPasswordFormProps) {
  const { showAlert } = useApp();
  const navigate = useNavigate();
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
    newPassword: "",
    newRepeatedPassword: "",
  });
  const resetForm = () => {
    setPasswordInfo({
      newPassword: "",
      newRepeatedPassword: "",
    });
  };
  const validateNewPassword = (password: string): ValidationResult => {
    if (!password) {
      const message = "Password cannot be empty.";
      return { isValid: false, message: message };
    }
    if (password.length < restrictions.minPasswordLength) {
      const message =
        "Password should have at least " +
        restrictions.minPasswordLength +
        " characters.";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };
  const validateNewRepeatedPassword = (
    password: string,
    repeatedPassword: string
  ): ValidationResult => {
    if (!repeatedPassword) {
      const message = "Password cannot be empty.";
      return { isValid: false, message: message };
    }
    if (repeatedPassword !== password) {
      const message = "Passwords do not match";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };

  const validatePasswordInfo = () => {
    return (
      validateNewPassword(passwordInfo.newPassword).isValid &&
      validateNewRepeatedPassword(
        passwordInfo.newPassword,
        passwordInfo.newRepeatedPassword
      ).isValid
    );
  };

  const sendPasswordInfo = (
    _doIfSuccess: () => void,
    _doAlways: () => void
  ): void => {
    const dataToSend = {
      token: token,
      newPassword: passwordInfo.newPassword,
    };
    fetchers.account.general.resetPassword.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        showAlert(alertMessages.passwordSuccessfullyChanged, true);
        resetForm();
        navigate(apiUrls.login)
      },
      fail: (data, status) => {
        if (status === 441) {
          showAlert(alertMessages.invalidOrExpiredPwdResetLink);
          navigate(apiUrls.forgotPassword)
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  const getUpdatedFormErrors = (
    _passwordInfo: PasswordInfo | undefined = passwordInfo
  ): FormErrors => {
    const vNewPassword = validateNewPassword(_passwordInfo.newPassword);
    const vNewRepeatedPassword = validateNewRepeatedPassword(
      _passwordInfo.newPassword,
      _passwordInfo.newRepeatedPassword
    );
    return {
      ...formErrors,
      newPasswordError: vNewPassword.message,
      newRepeatedPasswordError: vNewRepeatedPassword.message,
    };
  };
  const [
    formStatus,
    formErrors,
    handleSubmit,
    submitIsPossible,
    updateFormErrors,
  ] = useFormSubmit<FormErrors, PasswordInfo>(
    {
      generalError: null,
      newPasswordError: null,
      newRepeatedPasswordError: null,
    },
    validatePasswordInfo,
    sendPasswordInfo,
    getUpdatedFormErrors
  );
  const handleNewPasswordChange = (newPassword: string) => {
    const newpasswordInfo = { ...passwordInfo, newPassword: newPassword };
    updateFormErrors(newpasswordInfo);
    setPasswordInfo(newpasswordInfo);
  };

  const handleNewRepeatedPasswordChange = (newRepeatedPassword: string) => {
    const newpasswordInfo = {
      ...passwordInfo,
      newRepeatedPassword: newRepeatedPassword,
    };
    updateFormErrors(newpasswordInfo);
    setPasswordInfo(newpasswordInfo);
  };
  return (
    <Row className="flex-column">
      <Col className="my-2">
        <Form.Label htmlFor="newPassword" className="small">
          New Password
        </Form.Label>
        <InputPassword
            id="newPassword"
          placeholder="Enter new password"
          value={passwordInfo.newPassword}
          onChange={handleNewPasswordChange}
        />
        <ValidationFeedback message={formErrors.newPasswordError} />
      </Col>
      <Col className="my-3">
        <Form.Label htmlFor="repeat" className="small">
          Repeat New Password
        </Form.Label>
        <InputPassword
          id="repeat"
          placeholder="Repeat new password"
          value={passwordInfo.newRepeatedPassword}
          onChange={handleNewRepeatedPasswordChange}
        />
        <ValidationFeedback message={formErrors.newRepeatedPasswordError} />
      </Col>
      <Col className="d-flex align-items-center justify-content-center mt-4">
        <Button
          variant="primary"
          className="py-2 px-5"
          disabled={!submitIsPossible}
          onClick={handleSubmit}
        >
          {formStatus.isSubmitting ? "Submitting" : "Reset password"}
        </Button>
      </Col>
    </Row>
  );
}
