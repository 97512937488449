import {
  ReactNode,
  // createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Placeholder from "../../../../components/Placeholder";
import { DateTime, fetchPost } from "../../../../utils";
import { Day, Month, Year } from "../../../../utils/TimeTypes";
import { useSelectedValue } from "../hooks/useSelectedValue";
import alertMessages from "../../../../data/alertMessages.json";
import { useApp } from "../../../../contexts/AppContext";
import { User } from "../../../../utils/Users";
import { AuthRoles } from "../../../../utils/AuthRoles";
import { generatePath, useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import apiUrls from "../../../../data/api.json";
import { useUsers } from "../../../../contexts/UsersContext";
import { createContext } from "../../../../hooks/useCreateContext";
import { fetchers } from "../../../../serverApi/fetchers";

type MemberWorktimeSummary = {
  member: User;
  totals: {
    monthTotalMinutes: number;
    dateTotalMinutes: number;
  } | null
}[];

type MemberWorktimeSummaryContextVal = {
  date: DateTime;
  setDate: (date: DateTime) => void;
  memberWorktimeSummary: MemberWorktimeSummary;
  navigateToMemberDayTimeRecords: (member: User) => void;
  navigateToMonthMemberTimeRecords: (member: User) => void;
};
const [SummaryContext, useMemberWorktimeSummary] =
  createContext<MemberWorktimeSummaryContextVal>();
export { useMemberWorktimeSummary };

type SummaryProviderProps = {
  children: ReactNode;
  date: DateTime;
  setDate: (day: DateTime) => void;
};

export function SummaryProvider({
  children,
  date,
  setDate,
}: SummaryProviderProps) {
  const { showAlert } = useApp();
  const navigate = useNavigate();
  const { allUsersByRole } = useUsers();
  const [memberWorktimeSummary, setMemberWorktimeSummary] = useState<
    MemberWorktimeSummary | undefined
  >(undefined);
  // const [selectedRecord, selectRecord, resetSelecedRecord] = useSelectedValue<{}>([]);

  const refreshSummary = () => {
    const dataToSend = {
      date: date,
    };
    fetchers.account.admin.membersWorktimeSummary.fetch(dataToSend, {
      success: (data, status) => {
        const summary = allUsersByRole(AuthRoles.member).map((member) => {
          const memberSummary = data.membersSummary.find(
            (memberInfo) => memberInfo.member.id === member.id
          );
          return {
            member: member,
            totals:  ((memberSummary) => {
              if(memberSummary) {
                const { member, totals } = memberSummary;
                return totals;
              } else {
                return null
              }
            })(memberSummary)
          };
        });
        setMemberWorktimeSummary(summary);
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // type RespData = {
    //   membersSummary: {
    //     member: {
    //       id: string;
    //       name: string;
    //       role: string;
    //       email: string;
    //       created: string;
    //       updated: string;
    //       deleted: string | null;
    //     },
    //     totals: {
    //       monthTotalHours: number;
    //       dateTotalHours: number;
    //     }
    //   }[]
    // };
    // const endpoint = "/admin/members-summary/users_date_total";

    // fetchPost<RespData>(endpoint, dataToSend, {
    //   success: (dataRaw: RespData, status) => {
    //     const existingMembersSummary = dataRaw.membersSummary.map((memberInfo) => {
    //       return {
    //         member: {
    //           id: memberInfo.member.id,
    //           name: memberInfo.member.name,
    //           roles: [memberInfo.member.role as AuthRoles],
    //           email: memberInfo.member.email,
    //           created: new DateTime(memberInfo.member.created),
    //           updated: new DateTime(memberInfo.member.updated),
    //         },
    //         totals: memberInfo.totals
    //       };
    //     });
    //     const summary = allUsersByRole(AuthRoles.member).map((member) => {
    //       const info = existingMembersSummary.find(
    //           (memberInfo) => memberInfo.member.id === member.id
    //         );
    //         return {
    //           member: member,
    //           info:  ((info) => {
    //             if(info) {
    //               const { member, totals } = info;
    //               return totals;
    //             } else {
    //               return null
    //             }
    //               })(info)
    //         };
    //       });
    //     setMemberWorktimeSummary(summary);
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };
  useEffect(() => {
    refreshSummary();
  }, [date]);


  const navigateToMonthMemberTimeRecords = (member: User) => {
    const month = date;
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0"); //< 10 ? "0" + monthValNum : "" + monthValNum;
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();
    // console.log(queryParams)

    navigate(
      {
        pathname: generatePath(apiUrls.account.children.admin.children.memberMonth, {
          userId: member.id,
        }),
        search: queryParams,
      },
      // { replace: true }
    );
  };

  const navigateToMemberDayTimeRecords = (member: User) => {
    const dayValNum = date.getLocDay();
    const dayVal = dayValNum.toString().padStart(2, "0"); // < 10 ? "0" + dayValNum : "" + dayValNum;
    const monthValNum = date.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0"); // < 10 ? "0" + monthValNum : "" + monthValNum;
    const queryParams = createSearchParams({
      date: dayVal + "." + monthVal + "." + date.getLocYear()
    }).toString()
    // console.log(queryParams)
    navigate({
      pathname: generatePath(apiUrls.account.children.admin.children.memberDay, {
        userId: member.id,
      }),
      search: queryParams
    });
  }
  return (
    <>
      {memberWorktimeSummary && (
        <SummaryContext.Provider
          value={{
            date,
            setDate,
            memberWorktimeSummary,
            navigateToMemberDayTimeRecords,
            navigateToMonthMemberTimeRecords
          }}
        >
          {children}
        </SummaryContext.Provider>
      )}
      {!memberWorktimeSummary && <Placeholder />}
    </>
  );
}
