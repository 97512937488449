import { ReactNode } from "react";
import { useSetCollection } from "../../hooks/useSetCollection";
import Accordion from "../Accordion";
import { List } from "../List/List";
import "./ListOfAccordions.scss";

type ListOfAccordions<Item> = {
  list: Array<Item>;
  accordionHeader: (item: Item, active: boolean) => JSX.Element;
  accordionBody: (item: Item) => JSX.Element;
  firstDefaultOpen?: boolean;
  listitemClassName?: string;
  ListItemContentWrapper?: React.ComponentType<{ children: React.ReactNode }>;
};
export function ListOfAccordions<ListItemType>({
  list,
  accordionHeader,
  accordionBody,
  firstDefaultOpen = false,
  listitemClassName = "",
  ListItemContentWrapper,
}: ListOfAccordions<ListItemType>) {
  const activeKeysSet = useSetCollection<string>([]);

  return (
    <List className={`ListOfAccordions`}>
      {list.map((item, index) => (
        <List.Item key={index} className={listitemClassName}>
          {ListItemContentWrapper ? (
            <ListItemContentWrapper>
              <Accordion
                className="h-100 w-100"
                defaultActiveKey={firstDefaultOpen ? "0" : undefined}
              >
                <Accordion.Item
                  eventKey={index.toString()}
                  onActive={(active) =>
                    active
                      ? activeKeysSet.add(index.toString())
                      : activeKeysSet.remove(index.toString())
                  }
                >
                  <Accordion.Item.Header>
                    {accordionHeader(
                      item,
                      activeKeysSet.includes(index.toString())
                    )}
                  </Accordion.Item.Header>
                  <Accordion.Item.Body>
                    {accordionBody(item)}
                  </Accordion.Item.Body>
                </Accordion.Item>
              </Accordion>
            </ListItemContentWrapper>
          ) : (
            <Accordion
              className="h-100 w-100"
              defaultActiveKey={firstDefaultOpen ? "0" : undefined}
            >
              <Accordion.Item
                eventKey={index.toString()}
                onActive={(active) =>
                  active
                    ? activeKeysSet.add(index.toString())
                    : activeKeysSet.remove(index.toString())
                }
              >
                <Accordion.Item.Header>
                  {accordionHeader(
                    item,
                    activeKeysSet.includes(index.toString())
                  )}
                </Accordion.Item.Header>
                <Accordion.Item.Body>{accordionBody(item)}</Accordion.Item.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </List.Item>
      ))}
    </List>
  );
}
