import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import "./MonthStatusDropdown.scss";
import { MonthStatus, renderMonthStatus } from "../../../utils/MonthStatus";
import { useEffect, useRef, useState } from "react";
import { useBootstrapBreakpoints } from "react-bootstrap/esm/ThemeProvider";

type MonthStatusDropdown = {
  status: MonthStatus;
  onChange: (status: MonthStatus) => void;
  variant?: "small" | "normal";
  className?: string;
};
export function MonthStatusDropdown({
  status,
  onChange,
  variant = "normal",
  className = "",
}: MonthStatusDropdown) {
  const [show, setShow] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const handleChange = (strStatus: string) => {
    if (!Object.values(MonthStatus).includes(strStatus as MonthStatus)) {
      throw new Error();
    }
    onChange(strStatus as MonthStatus);
  };

  // const handleCloseOnSwipe = (event: TouchEvent) => {
  //   if( dropdownRef.current && !dropdownRef.current.contains(event.target as Element)) {

  //     setShow(false);
      
  //   }
  // }
  // useEffect(() => {
  //   document.addEventListener('touchstart', handleCloseOnSwipe);
  //   return () => {
  //     document.removeEventListener('touchstart', handleCloseOnSwipe);
  //   }

  // }, []);
  return (
    <Dropdown
      className={`MonthStatusDropdown ${variant} ${className} ${status}`}
      onSelect={(evk: string | null) => evk && handleChange(evk)}
      drop="down-centered"
      autoClose={true}
      ref={dropdownRef}
      show={show}
      onToggle={() => {setShow(!show)}}
      // onTouchMove={() => { if (window.innerWidth < 576) {setShow(!show)}}}
    >
      <Dropdown.Toggle
        variant="outline-secondary"
        size="sm"
        disabled={status == MonthStatus.editable}
      >
        {renderMonthStatus(status)}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {status == MonthStatus.submitted && (
          <>
            <Dropdown.Item eventKey={MonthStatus.completed}>
              approve
            </Dropdown.Item>
            <Dropdown.Item eventKey={MonthStatus.editable}>
              return edition
            </Dropdown.Item>
            <Dropdown.Item eventKey={MonthStatus.editable}>
              return submission
            </Dropdown.Item>
          </>
        )}
        {status == MonthStatus.completed && (
          <Dropdown.Item eventKey={MonthStatus.submitted}>
            return submission
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );

  // return (
  //   <DropdownButton
  //     title={renderMonthStatus(status)}
  //     as={ButtonGroup}
  //     size="sm"
  //     variant="outline-secondary"
  //     className={`MonthStatusDropdown ${variant} ${className} ${status}`}
  //     drop="down-centered"
  //     onSelect={(evk: string | null) => evk && handleChange(evk)}
  //     disabled={status == MonthStatus.editable}
  //   >
  //     {status == MonthStatus.submitted && (
  //       <>
  //         <Dropdown.Item eventKey={MonthStatus.completed}>
  //           approve
  //         </Dropdown.Item>
  //         <Dropdown.Item eventKey={MonthStatus.editable}>
  //           return edition
  //         </Dropdown.Item>
  //       </>
  //     )}
  //     {status == MonthStatus.completed && (
  //       <Dropdown.Item eventKey={MonthStatus.submitted}>
  //         return submission
  //       </Dropdown.Item>
  //     )}
  //   </DropdownButton>
  // );
}
