import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { Tooltip } from "../Tooltip/Tooltip";
import React, { ReactNode } from "react";
import "./TooltipDropdownButton.scss";

function TooltipDropdownHead({ children }: { children: ReactNode }) {
  return <>{children}</>;
}
function TooltipDropdownItems({ children }: { children: ReactNode }) {
  return <>{children}</>;
}
type TooltipDropdownItemProps = {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  eventKey?: string;
};
function TooltipDropdownItem({
  children,
  onClick,
  className,
  eventKey
}: TooltipDropdownItemProps) {
  return (
    <Dropdown.Item
      onClick={onClick}
      className={"TooltipDropdownItem " + className}
      eventKey={eventKey}
    >
      {children}
    </Dropdown.Item>
  );
}

TooltipDropdownItems.Item = TooltipDropdownItem;

type TooltipDropdownButtonProps = {
  children: ReactNode;
  className?: string;
  tooltipContent: ReactNode;
  delay?: {
    show: number;
    hide: number;
  };
  buttonVariant?: string;
  onClick?: (event: any) => void;
  type?: "button" | "reset" | "submit";
  size?: "sm" | "lg";
  style?: React.CSSProperties;
  disabled?: boolean;
  hideArrow?: boolean;
  dropUp?: boolean;
  onAnimationEnd?: (e: React.AnimationEvent) => void;
  onSelect?: (eventkey: string | null) => void;
};
export function TooltipDropdownButton({
  children,
  tooltipContent,
  delay,
  className,
  buttonVariant,
  onClick,
  type,
  size,
  style,
  disabled,
  onAnimationEnd,
  onSelect,
  hideArrow = false,
  dropUp = false,
}: TooltipDropdownButtonProps) {
  let HeadChild: ReactNode = undefined;
  let ItemsChild: ReactNode = undefined;

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type.toString() === TooltipDropdownHead.toString()) {
        HeadChild = child;
      }
      if (child.type.toString() === TooltipDropdownItems.toString()) {
        ItemsChild = child;
      }
    }
  });
  return (
    // <Tooltip tooltipContent={tooltipContent} delay={delay}>
    <>
      {HeadChild && ItemsChild && (
        <DropdownButton
          as={ButtonGroup}
          title={HeadChild}
          variant={buttonVariant}
          className={
            "TooltipDropdownButton " +
            size +
            " " +
            (hideArrow ? "no-arrow " : "") +
            (className ? className : "")
          }
          size={size}
          disabled={disabled}
          style={style}
          drop={dropUp ? "up-centered" : "down-centered"}
          onAnimationEnd={onAnimationEnd}
          onSelect={onSelect}
        >
          {ItemsChild}
        </DropdownButton>
      )}
    </>
    // </Tooltip>
  );
}

TooltipDropdownButton.Head = TooltipDropdownHead;
TooltipDropdownButton.Items = TooltipDropdownItems;
