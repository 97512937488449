import TooltipButton from "../../../../../components/TooltipButton";
import { useUsersList } from "../../context/UsersListContext";
import "./Footer.scss";

export function Footer(){
    const {openDeletedUsersModal} = useUsersList();
    return (
        <div className="Footer">
            <TooltipButton tooltipContent="Deleted Users" className="delete_users" buttonVariant="link" onClick={openDeletedUsersModal}>
                Archive
            </TooltipButton>  
        </div>

    );
}