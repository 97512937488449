import React, { useEffect, useState } from "react";
import "./TimeCount.scss";
import { useTimerContext } from "../../../../../contexts/TimerContext";
import { useApp } from "../../../../../contexts/AppContext";
import { Formatter2 } from "../../../DayRecordsTable/utils/Formatter2";
// import { ElapsedTimeType } from "../../../../hooks/useTimer";

type TimeCountProps = {
  style?: React.CSSProperties;
};
export function TimeCount({ style }: TimeCountProps) {
  const {
    timerStatus,
    // startTimer,
    // stopTimer,
    // allTasks,
    // findTaskById,
    // selectedTask,
    // setSelectedTask,
  } = useTimerContext();

  const defaultElapsedTime = {
    days: "0",
    hours: "00",
    minutes: "00",
    seconds: "00",
  };
  const [elapsedTime, setElapsedTime] = useState(
    timerStatus ? timerStatus.getElapsedTime() : defaultElapsedTime
  );

  const [refreshInterval, setRefreshInterval] = useState<
    NodeJS.Timer | undefined
  >(undefined);
  const [autoStart, setAutoStart] = useState<boolean>(false);

  const updateCounter = (tmp: number) => {
    // console.log("updateCounter ",tmp, ":", "\ntimerStatus=", timerStatus?.getElapsedTime())
    if (timerStatus) {
      setElapsedTime(timerStatus.getElapsedTime());
    }
  };

  useEffect(() => {
    // Anything in here is fired on component mount.
    // if(timerStatus) {
    // var tmp = Math.random();
    // console.log("Mount, startCounting",tmp )
    // var interval = setInterval(() => updateCounter(tmp), 1000)
    // }
    return () => {
      // Anything in here is fired on component unmount.
      // console.log("Mount, stopCounting",tmp )
      clearInterval(refreshInterval);
    };
  }, []);
  // useEffect(()=>{
  //   console.log("timer_counter_refresh:","\nelapsedTime=", elapsedTime, "\ntimerStatus=", timerStatus?.getElapsedTime())
  // })

  const removeInterval = (ifAutoStart: boolean) => {
    // console.log("ii ", refreshInterval)
    if (refreshInterval) {
      // console.log("iii ", refreshInterval)
      clearInterval(refreshInterval);
      setRefreshInterval(undefined);
    }
    setAutoStart(ifAutoStart);
    // console.log("set", refreshInterval, ifAutoStart)
  };
  // const newInterval = (callback: () => void, ms?: number | undefined) => {
  //   console.log("created_new_interval", ms, callback)
  //   removeInterval();
  //   var interval = setInterval(callback, ms);
  //   setRefreshInterval(interval);
  //   console.log("end_creating_intervall",interval, refreshInterval)
  // };

  // const stopCounting = () => {
  //   removeInterval();
  //   setElapsedTime(defaultElapsedTime);
  // };
  // const startCounting = (tmp: number) => {
  //   console.log("startCounting",tmp )
  //   newInterval(() => updateCounter(tmp)
  //   , 1000);
  // };

  useEffect(() => {
    // console.log('>> timerStatus:',timerStatus ? true: false,  timerStatus?.getElapsedTime())

    if (timerStatus) {
      // console.log('>> start')
      removeInterval(true);
    } else {
      // console.log('>> stop')
      // Stop counter
      removeInterval(false);
      setElapsedTime(defaultElapsedTime);
    }
  }, [timerStatus]);

  useEffect(() => {
    // console.log(">> autoStart:", autoStart, "&&", !refreshInterval)
    if (autoStart && !refreshInterval) {
      let tmp = Math.random();
      var interval = setInterval(() => updateCounter(tmp), 1000);
      setRefreshInterval(interval);
    }
  }, [autoStart, refreshInterval]);

  return (
    <div className="TimeCount" style={{ height: "65%" }}>
      {elapsedTime.days != "0" && (
        <span className="days">
          +{elapsedTime.days}
          <span className="day-sign">d</span>
        </span>
      )}
      <span className="count">
        {elapsedTime.hours}:{elapsedTime.minutes}:{elapsedTime.seconds}
      </span>
    </div>
  );
}
