import { Card } from "react-bootstrap";
import DatePicker from "../../../../../components/DatePicker";
import { useMonthlyTimesheetsContext } from "../../context/MonthlyTimesheetsContext";
import { DateTime } from "../../../../../utils";
import TooltipButton from "../../../../../components/TooltipButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faFileArrowDown,
  faSort,
  faCaretRight,
  faCaretLeft,
  faClipboard,
  faSquareCheck,
  faSquareMinus,
  faCopy,
  faFileExport,
  faPersonCircleCheck,
  faCheckToSlot,
  faCircleCheck,
  faClipboardCheck,
  faThumbsUp
  // faFilePdf,
  // faFileZipper
} from "@fortawesome/free-solid-svg-icons";
import { faFilePdf, faFileZipper } from "@fortawesome/free-regular-svg-icons";
import TooltipDropdownButton from "../../../../../components/TooltipDropdownButton";
import "./Header.scss";
import { MonthStatus } from "../../../../../utils/MonthStatus";

export default function Header() {
  const {
    month,
    setMonth,
    allAlreadySelected,
    selectedRecords,
    selectAll,
    unselectAll,
    approvedTimesheets,
    selectRecordsExcl,
    openExportSelectedModal,
    fileAnchorRef,
    downloadMergedTimesheetsForSelected
  } = useMonthlyTimesheetsContext();

  return (
    <Card.Header className="Header d-flex justify-content-between justify-content-c-sm-between">
      <div className="d-flex align-items-center">
        <DatePicker
          date={month}
          setDate={setMonth}
          format={"monthOnly"}
          minDate={DateTime.createLocDateTime("2024", 3, 1)}
          maxDate={new DateTime().getStartOfMonth()}
          leftArrow={true}
          rightArrow={true}
          // leftArrowClassName="d-none d-sm-inline-block"
          // rightArrowClassName="d-none d-sm-inline-block"
        />
      </div>
      <div className="h-100 options ms-1">
        <div className="d-flex justify-content-end align-items-center">
        <div>
            <TooltipButton
              tooltipContent="Select Approved"
              buttonVariant="light"
              className={"select"}
              size="sm"
              disabled={approvedTimesheets.length < 1 || selectedRecords.filter(record => !record.month || record.month.status !== MonthStatus.completed).length > 0 || selectedRecords.length === approvedTimesheets.length}
              onClick={() => {selectRecordsExcl(approvedTimesheets);}}
            >
              <span className="d-none d-lg-inline">Select All Approved</span>
              <FontAwesomeIcon icon={faThumbsUp} className="ms-1" />
            </TooltipButton>
          </div>
        <div>
            <TooltipButton
              tooltipContent="Select All"
              buttonVariant="light"
              className={"select"}
              size="sm"
              disabled={allAlreadySelected()}
              onClick={selectAll}
            >
              <span className="d-none d-lg-inline">Select All</span>
              <FontAwesomeIcon icon={faSquareCheck} className="ms-1" />
            </TooltipButton>
          </div>
          <div>
            <TooltipButton
              tooltipContent="Unselect All"
              buttonVariant="light"
              className={"unselect"}
              // disabled={!memberSummary.month || memberSummary.month.status !== MonthStatus.completed || memberSummary.month.hasRequests}
              size="sm"
              disabled={selectedRecords.length < 1}
              onClick={unselectAll}
            >
              <span className="d-none d-lg-inline">Unselect All</span>
              <FontAwesomeIcon icon={faSquareMinus} className="ms-1" />
            </TooltipButton>
          </div>
          {/* <div>
            <TooltipButton
              tooltipContent="Select All"
              buttonVariant="light"
              className={"download"}
              size="sm"
              disabled={allAlreadySelected()}
              onClick={selectAll}
            >
              Select All
              <FontAwesomeIcon icon={faSquareCheck} className="ms-1" />
            </TooltipButton>
          </div> */}

          {/* <div>
          <TooltipButton
                    tooltipContent="Download"
                    buttonVariant="light"
                    className={"download"}
                    // disabled={!memberSummary.month || memberSummary.month.status !== MonthStatus.completed || memberSummary.month.hasRequests}
                    
                  >Download All 
                    <FontAwesomeIcon icon={faFileArrowDown} />
                  </TooltipButton>
          </div> */}
          <div>
            <TooltipButton
              tooltipContent="Copy To Clipboard"
              buttonVariant="light"
              className={"download"}
              // disabled={!memberSummary.month || memberSummary.month.status !== MonthStatus.completed || memberSummary.month.hasRequests}
              size="sm"
              disabled={selectedRecords.length < 1}
              onClick={openExportSelectedModal}
            >
              <span className="d-none d-lg-inline">Export Selected</span>
              <FontAwesomeIcon icon={faFileExport} className="ms-1" />
              {/* <FontAwesomeIcon icon={faCopy} className="ms-1" /> */}
            </TooltipButton>
          </div>

          <div>
            <TooltipDropdownButton
              tooltipContent="Download"
              buttonVariant="light"
              className={"download"}
              size="sm"
              hideArrow={true}
              disabled={!(approvedTimesheets.length > 0 && selectedRecords.length > 0 && selectedRecords.every(record => record.month && !record.month.hasRequests && record.month.status === MonthStatus.completed))}
              onSelect={(eventKey) => {eventKey && downloadMergedTimesheetsForSelected(eventKey === "PDF" ? "PDF" : "ZIP")}}
            >
              <TooltipDropdownButton.Head>
                <span className="d-none d-lg-inline">Download Selected</span>
                <FontAwesomeIcon icon={faFileArrowDown} className="ms-1" />
              </TooltipDropdownButton.Head>
              <TooltipDropdownButton.Items>
                <TooltipDropdownButton.Items.Item eventKey="PDF">
                  PDF
                  {/* <FontAwesomeIcon icon={faFilePdf} className="ms-1" /> */}
                </TooltipDropdownButton.Items.Item>
                <TooltipDropdownButton.Items.Item eventKey="ZIP">
                  ZIP
                  {/* <FontAwesomeIcon icon={faFileZipper} className="ms-1" /> */}
                </TooltipDropdownButton.Items.Item>
              </TooltipDropdownButton.Items>
            </TooltipDropdownButton>
            <a href="" download="" ref={fileAnchorRef} />
          </div>
        </div>
      </div>
    </Card.Header>
  );
}
