import { useEffect, useRef, useState } from "react";
import {
  ErrorLog,
  FormErrors as FormErrorsGeneralType,
  useFormErrors,
} from "./useFormErrors";
import _ from "lodash";

type FormStatus = {
  isSubmitting: boolean;
  isDeleting: boolean;
};
function useFormStatus() {
  const [formStatus, _setStatusForm] = useState<FormStatus>({
    isSubmitting: false,
    isDeleting: false,
  });
  const startSubmitting = () => {
    _setStatusForm({ ...formStatus, isSubmitting: true });
  };
  const stopSubmitting = () => {
    _setStatusForm({ ...formStatus, isSubmitting: false });
  };
  const startDeleting = () => {
    _setStatusForm({ ...formStatus, isDeleting: true });
  };
  const stopDeleting = () => {
    _setStatusForm({ ...formStatus, isDeleting: false });
  };
  return [
    formStatus,
    startSubmitting,
    stopSubmitting,
    startDeleting,
    stopDeleting,
  ] as const;
}

function useFormSubmitDeleteSimple(
  validateSubmitData: () => boolean,
  validateDeleteData: () => boolean,
  sendSubmitData: (doAlways: () => void) => void,
  sendDeleteData: (doAlways: () => void) => void
) {
  const [
    formStatus,
    startSubmitting,
    stopSubmitting,
    startDeleting,
    stopDeleting,
  ] = useFormStatus();
  const submitForm = () => {
    if (!validateSubmitData()) {
      stopSubmitting();
      return;
    }
    const doAlways = stopSubmitting;
    sendSubmitData(doAlways);
  };
  const deleteRecord = () => {
    if (!validateDeleteData()) {
      stopDeleting();
      return;
    }
    const doAlways = stopDeleting;
    sendDeleteData(doAlways);
  };

  useEffect(() => {
    if (formStatus.isSubmitting && !formStatus.isDeleting) {
      submitForm();
    }
    if (!formStatus.isSubmitting && formStatus.isDeleting) {
      deleteRecord();
    }
  }, [formStatus]);

  const handleSubmit = () => {
    if (formStatus.isSubmitting || formStatus.isDeleting) {
      return;
    }
    startSubmitting();
  };
  const handleDelete = () => {
    if (formStatus.isSubmitting || formStatus.isDeleting) {
      return;
    }
    startDeleting();
  };

  return [formStatus, handleSubmit, handleDelete] as const;
}

export type ValidationResult = {
  isValid: boolean;
  message: ErrorLog;
};

type ValidationStatus<FormErrorsType> = {
  submit: {isValid: boolean, formErrors: FormErrorsType};
  delete: {isValid: boolean, formErrors: FormErrorsType};
}

export function useFormSubmitDelete<FormErrorsType extends FormErrorsGeneralType, DataType>(
  defaultFormErrorsValues: FormErrorsType,
  data: DataType,
  validateSubmitData: (data: DataType) => {isValid: boolean, formErrors: FormErrorsType},
  validateDeleteData: (data: DataType) => {isValid: boolean, formErrors: FormErrorsType},
  sendSubmitData: (data: DataType, doIfSuccess: () => void, doAlways: () => void) => void,
  sendDeleteData: (data: DataType, doIfSuccess: () => void, doAlways: () => void) => void,
) {
  const [
    formErrors,
    setFormErrors,
    resetFormErrorsToNull,
    formErrorsHasNoError,
  ] = useFormErrors<FormErrorsType>(defaultFormErrorsValues);

  const _sendSubmitData = (doAlways: () => void) => {
    sendSubmitData(data, () => {
      resetFormErrorsToNull();
    }, doAlways);
  };
  const _sendDeleteData = (doAlways: () => void) => {
    sendDeleteData(data, () => {
      resetFormErrorsToNull();
    }, doAlways);
  };
  const validationStatus = useRef<ValidationStatus<FormErrorsType>> ({
    submit: validateSubmitData(data),
    delete: validateDeleteData(data)
  })

  const updateFormErrors = () => {
    let newFormErrors = {...defaultFormErrorsValues};
    if (!validationStatus.current.delete.isValid && !validationStatus.current.submit.isValid) {
      newFormErrors = validationStatus.current.submit.formErrors;
    }
    setFormErrors(newFormErrors)
  }
  useEffect(()=>{
    const submitValidationRes = validateSubmitData(data);
    const deleteValidationRes = validateDeleteData(data);
    validationStatus.current = {
      submit: submitValidationRes,
      delete: deleteValidationRes
    }
    updateFormErrors();

  }, [data]);

  const [formStatus, _handleSubmit, _handleDelete] = useFormSubmitDeleteSimple(()=>validationStatus.current.submit.isValid, ()=>validationStatus.current.delete.isValid, _sendSubmitData, _sendDeleteData);

  const submitIsPossible =
    !formStatus.isSubmitting && !formStatus.isDeleting && formErrorsHasNoError() && validationStatus.current.submit.isValid;
  
  const deleteIsPossible =
    !formStatus.isSubmitting && !formStatus.isDeleting && formErrorsHasNoError() && validationStatus.current.delete.isValid;


  const handleSubmit = () => {
    if (!submitIsPossible){
      return;
    }
    _handleSubmit();
  }
  const handleDelete = () => {
    if (!deleteIsPossible){
      return;
    }
    _handleDelete();
  }
  return [
    formStatus,
    formErrors,
    handleSubmit,
    handleDelete,
    submitIsPossible,
    deleteIsPossible,
    // updateFormErrors
  ] as const;
}