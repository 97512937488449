import "./FullTaskName2.scss";

type FullTaskName2Props = {
  categoryName: string;
  taskName: string;
};
export function FullTaskName2({ categoryName, taskName }: FullTaskName2Props) {
  

  return (
    // Tip: make parent a flex container
    <div className="FullTaskName2 d-inline-flex flex-wrap">
      <div className="p1">
      <span className="fn-categoryName">{categoryName}</span>
      <span className="fn-separator">:</span>
      </div>
      <div className="p2">
      <span className="fn-taskName">{taskName}</span>
      </div>
    </div>
  );
  // return (
  //   // Tip: make parent a flex container
  //   <div className="FullTaskName2">
  //     <span className="fn-categoryName">{categoryName}</span>
  //     <span className="fn-separator">:</span>
  //     <span className="fn-taskName">{taskName}</span>
  //   </div>
  // );
}
