import { RequestsMonthMember, usePendingMembersRequests } from "../../../../context/PendingMembersRequestsProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCheck,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import {
  faXmarkCircle,
  faCheckCircle,
  faRectangleXmark, faCheckSquare
} from "@fortawesome/free-regular-svg-icons";
import "./Member.scss";
import { Badge } from "react-bootstrap";
import TooltipButton from "../../../../../../../components/TooltipButton";
import { DateTime } from "../../../../../../../utils";
import { useMonthRequestsItem } from "../../RequestsList";

export function Member({
  month,
  memberInfo,
  active,
}: {
  month: DateTime;
  memberInfo: RequestsMonthMember;
  active: boolean;
}) {

  const {resolveRequests} = usePendingMembersRequests();
  const {setIsMonthRequestsItemDisabled} = useMonthRequestsItem();

  return (
    <div
      className={`Member list-row-styled d-flex align-items-center justify-content-between ${
        active ? "active" : ""
      } ${memberInfo.warning ? "warning": ""}`}
    >
      <div className="d-flex align-items-center">
        <div>
          <FontAwesomeIcon icon={faCaretLeft} className="mx-2" rotation={90} />
        </div>
        <div className="name">{memberInfo.member.lastName + ' ' + memberInfo.member.firstName}</div>
        <div className="mx-2 count">
          <Badge
            bg="secondary"
            pill
            className="list-row-badge d-flex justify-content-center align-items-center"
          >
            {memberInfo.requests.length}
          </Badge>
        </div>
        <div className="ms-1 open">| requests</div>
      </div>
      <div className="d-flex ms-1 actions">

        <div>
          <TooltipButton
            tooltipContent="Reject All"
            buttonVariant="light"
            className="rejectAll list-row-btn"
            onClick={(e) => {
              e.stopPropagation();
              setIsMonthRequestsItemDisabled(true);
              resolveRequests(memberInfo.requests.map(request => request.id), false);
            }}
          >
            <FontAwesomeIcon icon={faXmarkCircle} className="mx-1 fa-extra-padding" />
          </TooltipButton>
        </div>
        <div>
          <TooltipButton
            tooltipContent="Grant All"
            buttonVariant="light"
            className="grantAll list-row-btn"
            onClick={(e) => {
              e.stopPropagation();
              setIsMonthRequestsItemDisabled(true);
              resolveRequests(memberInfo.requests.map(request => request.id), true);

            }}
          >
            <FontAwesomeIcon icon={faCheckCircle} className="mx-1 fa-extra-padding" />
          </TooltipButton>
        </div>
      </div>
    </div>
  );
}
