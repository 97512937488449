import "./TasksList.scss";
import { TasksListProvider, useTasksList } from "./context/TasksListContext";
import Card from "../../../components/Card";
import { Button, Card as CardBS, Col, Container, Row } from "react-bootstrap";
import CategoriesList from "./components/CategoriesList";
import Modal from "./components/Modal";
import WidgetCard from "../../../components/WidgetCard";
import Header from "./components/Header";

export function TasksList() {
  return (
    <WidgetCard
      className="TasksList"
      ContextProvider={TasksListProvider}
      useContext={useTasksList}
    >
      {/* <WidgetCard.Header>
        <Header/>
      </WidgetCard.Header> */}
      <WidgetCard.Body>
        <Header />
        <CategoriesList />
      </WidgetCard.Body>
      <WidgetCard.Modal>
        <Modal />
      </WidgetCard.Modal>
    </WidgetCard>
  );
}
