import { DateTime } from "../../utils";
import "./MonthStatusUnresolvedButton.scss";
import { Button } from "react-bootstrap";

type MonthStatusUnresolvedButtonProps = {
  variant?: 'small'|'normal';
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}
export function MonthStatusUnresolvedButton({variant="normal", className="", disabled=false, onClick}: MonthStatusUnresolvedButtonProps) {
  return (
    <>
      <Button
      className={`MonthStatusUnresolvedButton ${variant} ${className}`}
        variant="outline-primary"
        disabled={disabled}
        onClick={onClick}
      >
        {" "}
        unresolved
      </Button>
    </>
  );
}
