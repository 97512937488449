import {
    ErrorBoundary,
    FallbackProps,
    useErrorBoundary,
  } from "react-error-boundary";
  import "./SnapError.scss";
  import pageDeadImg from "../../assets/images/pageDead.png";
  import { Image } from "react-bootstrap";
import Base from "../pageBases/Base";
//   import lockImage from "../../assets/images/lock2.png";
import apiUrls from "../../data/api.json";

export function SnapError({reloadPage}: {reloadPage: () => void}){
    return (
        <Base>
        <div className="SnapError">
          <div className="wrapper">
              <div></div>
            <div className="locked">
              <Image src={pageDeadImg} />
            </div>
            <div>
            <h1>Aw, Snap!</h1>
            <p className="mb-4">
            Something went wrong <br/>with displaying this page.
              {/* <span className="extra"></span>. <br /> Let us help guide you out
              and get you back home. */}
            </p>
            </div>
            <div className="buttons">
              <button onClick={reloadPage}>Reload page</button>
              <br />
            </div>
          </div>
          {/* <div className="side">
            <div className="lock">
              <Image src={lockImage}/>
            </div>
          </div> */}
        </div>
        {/* <FontAwesomeIcon icon={faLock} size="lg" /> */}
      </Base>
    );
}