import { ReactNode, useEffect, useRef, useState } from "react";
import { User } from "../../../../utils/Users";
import { DateTime, fetchPost } from "../../../../utils";
import { Month, Year } from "../../../../utils/TimeTypes";
import { useApp } from "../../../../contexts/AppContext";
import { generatePath, useNavigate } from "react-router";
import Placeholder from "../../../../components/Placeholder";
import { DayStatus } from "../../../../utils/DayStatus";
import { MonthStatus } from "../../../../utils/MonthStatus";
import alertMessages from "../../../../data/alertMessages.json";
import { ModalStatus } from "../../../../hooks/useModal";
import {
  OpenDayTimeRecordsModalStatus,
  useDayTimeRecordsModal,
} from "../hooks/useModal";
import apiUrls from "../../../../data/api.json";
import { createSearchParams } from "react-router-dom";
import { createContext } from "../../../../hooks/useCreateContext";
import { fetchers } from "../../../../serverApi/fetchers";
import { Formatter } from "../../../../utils/Formatter";
import { usePrevious } from "../../../../hooks/usePreviousValue";

export type DayRecordedData = {
  date: DateTime;
  totalMinutes: number;
  status: DayStatus;
  isDisabled: boolean;
  requestedStatus: DayStatus | null;
};
type MonthRecordedData = {
  status: MonthStatus;
  totalMinutes: number;
  documentedTotalMinutes: number;
  monthDaysInfo: DayRecordedData[];
};

type AvailableMonths = {
  minMonth: DateTime;
  maxMonth: DateTime;
};

type MonthRecordsTableContextVal = {
  member: User;
  setMember: (newMember: User) => void;
  month: DateTime;
  setMonth: (month: DateTime) => void;
  availableMonths: AvailableMonths;
  monthRecordedData: MonthRecordedData;
  selectedDay: DayRecordedData | null;
  setSelectedDay: (selectedDay: DayRecordedData | null) => void;
  changeMonthStatus: (newStatus: MonthStatus) => void;
  changeDayStatus: (
    date: DateTime,
    oldStatus: DayStatus,
    newStatus: DayStatus
  ) => void;
  rejectChangeStatusRequest: (date: DateTime) => void;
  refreshMonthData: () => void;
  modalStatus: ModalStatus<OpenDayTimeRecordsModalStatus>;
  openDayTimeRecordsModal: (date: DateTime) => void;
  closeModal: () => void;
  changeModalDate: (date: DateTime) => void
  redirectToAnotherMember: (member: User) => void;
  redirectToYear: (year: DateTime) => void;
  downloadTimesheet: () => void;
  fileAnchorRef: React.MutableRefObject<HTMLAnchorElement | null>;
  resolveAllMonthStatusRequests: (grant: boolean) => void;
};
const [MonthRecordsContext, useMonthRecordsTable] =
  createContext<MonthRecordsTableContextVal>();
export { useMonthRecordsTable };

type MonthRecordsProviderProps = {
  children: ReactNode;
  member: User;
  setMember: (newMember: User) => void;
  month: DateTime;
  setMonth: (month: DateTime) => void;
};
export function MonthRecordsProvider({
  children,
  member,
  setMember,
  month,
  setMonth,
}: MonthRecordsProviderProps) {
  const fileAnchorRef = useRef<HTMLAnchorElement | null>(null);
  const { showAlert } = useApp();
  const navigate = useNavigate();
  const [monthRecordedData, setMonthRecordedData] = useState<
    MonthRecordedData | undefined
  >(undefined);
  // const [monthStatus, setMonthStatus] = useState<MonthStatus | undefined>(
  //   undefined
  // );
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [selectedDay, setSelectedDay] = useState<DayRecordedData | null>(null);


  const availableMonths: AvailableMonths = {
    minMonth: member.created.getStartOfMonth(),
    maxMonth: new DateTime().getEndOfMonth(),
  };

  const monthRecordedDataRefresh = (doAlways?: () => void,  allow_recursion: boolean = true) => {
    contentRef.current?.classList.add("loading");
    const dataToSend = {
      userId: member.id,
      date: month,
    };
    fetchers.account.admin.monthInfo.fetch(dataToSend, {
      success: (data, status) => {
        const newMonthRecordedData = data.monthDays.map((month_day) => {
          return {
            ...month_day,
            isDisabled: false, //!checkDateIsAvailable(month_day.date, month_day.status),
          };
        });
        setMonthRecordedData({
          status: data.status,
          totalMinutes: data.totalMinutes,
          documentedTotalMinutes: data.documentedTotalMinutes,
          monthDaysInfo: newMonthRecordedData,
        });
      },
      fail: (data, status) => {
        if (status === 445 && allow_recursion) {
          setTimeout(() => monthRecordedDataRefresh(undefined, false), 1000);
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        contentRef.current?.classList.remove("loading");
        doAlways && doAlways();
      }
    });
  };

  const refreshMonthData = (doAlways: (() => void) | undefined = undefined) => {
    // setMonthRecordedData(undefined);
    // setMonthStatus(undefined);
    monthRecordedDataRefresh(doAlways);
  };
  const prevMonthRecordedData = usePrevious(monthRecordedData);
  useEffect(() => {
    if (!prevMonthRecordedData && monthRecordedData) {
      const autoscrollToId = (() => {
        const match = window.location.href.match(/#([^?]+)/);
        if (match) {
          return match[1];
        } else {
          return "";
        }
      })();
      if (autoscrollToId && autoscrollToId) {
        const day = document.getElementById(autoscrollToId);
        if (day) {
            day.scrollIntoView({behavior: "smooth", block: "center"})
            // const record = monthRecordedData?.monthDaysInfo.find(dayInfo => dayInfo.date.getLocDay().toString() === autoscrollToId);
            // if (record)
            // setSelectedDay(record);
          }
        window.history.replaceState({}, '', window.location.href.replace("#" + autoscrollToId, ""))
      }
    }

  }, [monthRecordedData]);
 
  useEffect(() => {
    refreshMonthData(undefined);
  }, [month]);

  const { modalStatus, openDayTimeRecordsModal, closeDayTimeRecordsModal, changeModalDate } =
  useDayTimeRecordsModal(month, refreshMonthData);


  // const _setMonth = (month: DateTime) => {
  //   setMonth(month.getLocMonth());
  // }

  const changeMonthStatus = (newStatus: MonthStatus) => {
    if (!monthRecordedData) {
      return;
    }
    if (monthRecordedData.status === newStatus) {
      return;
    }
    var valid =
      (monthRecordedData.status === MonthStatus.submitted &&
        newStatus === MonthStatus.editable) ||
      (monthRecordedData.status === MonthStatus.submitted &&
        newStatus === MonthStatus.completed) ||
      (monthRecordedData.status === MonthStatus.completed &&
        newStatus === MonthStatus.submitted);

    if (!valid) {
      showAlert(alertMessages.invalidData);
      return;
    }
    if (
      (monthRecordedData.status === MonthStatus.submitted ||
        monthRecordedData.status === MonthStatus.completed) &&
      newStatus === MonthStatus.editable
    ) {
      if (
        window.confirm(
          'Are you sure you want to grant "draft" status for this month? This action will allow the member to make changes to this month\'s time records.'
        ) !== true
      ) {
        return;
      }
    }
    // setMonthRecordedData(undefined);
    contentRef.current?.classList.add("loading");
    const dataToSend = {
      userId: member.id,
      date: month,
      newStatus: newStatus,
    };
    fetchers.account.admin.changeMonthStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        refreshMonthData();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/member-records/change_month_status";
    // const dataToSend = {
    //   userId: member.id,
    //   date: month.toJSON(),
    //   newStatus: newStatus,
    // };
    // fetchPost<{}>(endpoint, dataToSend, {
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     refreshMonthData();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };

  const changeDayStatus = (
    date: DateTime,
    oldStatus: DayStatus,
    newStatus: DayStatus
  ) => {
    // if (!checkDateIsAvailable(date, oldStatus)) {
    //   return;
    // }
    if (newStatus == oldStatus) {
      return;
    }
    var valid =
      (oldStatus == DayStatus.completed && newStatus == DayStatus.submitted) ||
      (oldStatus == DayStatus.submitted && newStatus == DayStatus.completed) ||
      newStatus == DayStatus.editable;

    // || (oldStatus == DayStatus.editable && newStatus == DayStatus.submitted);
    if (!valid) {
      showAlert(alertMessages.invalidData);
      return;
    }
    // setMonthRecordedData(undefined);
    contentRef.current?.classList.add("loading");
    const dataToSend = {
      userId: member.id,
      date: date,
      newStatus: newStatus,
    };
    fetchers.account.admin.changeDateStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        refreshMonthData();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/member-records/change_date_status";
    // const dataToSend = {
    //   userId: member.id,
    //   date: date.toJSON(),
    //   newStatus: newStatus,
    // };

    // fetchPost<{}>(endpoint, dataToSend, {
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     refreshMonthData();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };

  const rejectChangeStatusRequest = (date: DateTime) => {
    if (date.graterThan(new DateTime())) {
      return;
    }
    if (
      !monthRecordedData?.monthDaysInfo?.find((day) => day.date.equalTo(date))
    ) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    // setMonthRecordedData(undefined);
    contentRef.current?.classList.add("loading");
    const dataToSend = {
      userId: member.id,
      date: date,
    };
    fetchers.account.admin.rejectDateStatusRequest.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        refreshMonthData();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/member-records/reject_change_date_status_request";
    // const dataToSend = {
    //   userId: member.id,
    //   date: date.toJSON(),
    // };

    // fetchPost<{}>(endpoint, dataToSend, {
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     refreshMonthData();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };

  const redirectToAnotherMember = (member: User) => {
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0");
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();

    navigate(
      {
        pathname: generatePath(
          apiUrls.account.children.admin.children.memberMonth,
          {
            userId: member.id,
          }
        ),
        search: queryParams,
      }
      // { replace: true }
    );
  };

  const redirectToYear = (year: DateTime) => {
    const queryParams = createSearchParams({
      year: year.getLocYear(),
    }).toString();
    navigate({
      pathname: generatePath(
        apiUrls.account.children.admin.children.memberYear,
        {
          userId: member.id,
        }
      ),
      search: queryParams,
    });
  };

  const downloadTimesheet = () => {
    const dataToSend = {
      userId: member.id,
      date: month,
    };
    fetchers.account.admin.generateMemberMonthTimesheetFile.fetch(dataToSend, {
      success: (data, status) => {
        let url = URL.createObjectURL(data.file);
        const defaultFilename =
          "whitefly_ewidencja_" +
          member.firstName.replace(/\s/g, "-") +
          "-" +
          member.lastName.replace(/\s/g, "-") +
          "_" +
          month.getLocDay().toString().padStart(2, "0") +
          "-" +
          month.getLocYear().toString() +
          "-" +
          new DateTime().toLocIsoShortString() +
          ".pdf";
        const filename = data.filename ? data.filename : defaultFilename;
        // console.log(data.filename);
        if (fileAnchorRef.current) {
          fileAnchorRef.current.href = url;
          fileAnchorRef.current.download = filename;
          fileAnchorRef.current.click();
        }
        URL.revokeObjectURL(url);
        // alert(alertMessages.downloaded + "\n" + "File name: " + filename + "");
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  const resolveAllMonthStatusRequests = (grant: boolean) => {
    contentRef.current?.classList.add("loading");
    const dataToSend = {
      userId: member.id,
      date: month,
      grant: grant
    };
    fetchers.account.admin.resolveAllMonthDateStatusRequests.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        refreshMonthData();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    })
  }
  const closeModal = closeDayTimeRecordsModal;
  return (
    <>
      {monthRecordedData && (
        <MonthRecordsContext.Provider
          value={{
            member,
            setMember,
            month,
            setMonth,
            availableMonths,
            monthRecordedData,
            selectedDay,
            setSelectedDay,
            changeMonthStatus,
            changeDayStatus,
            rejectChangeStatusRequest,
            refreshMonthData,
            modalStatus,
            openDayTimeRecordsModal,
            closeModal,
            changeModalDate,
            redirectToAnotherMember,
            redirectToYear,
            downloadTimesheet,
            fileAnchorRef,
            resolveAllMonthStatusRequests,
          }}
        >
          <div ref={contentRef} className="MemberMonthTimeRecordTableProvider">
          {children}
          </div>
        </MonthRecordsContext.Provider>
      )}
      {!monthRecordedData && <Placeholder />}
    </>
  );
}
