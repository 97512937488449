import { useModal } from "../../../../hooks/useModal";
import { AuthRoles } from "../../../../utils/AuthRoles";
import { User } from "../../../../utils/Users";
export enum UserModalModes {
  UserCreation = "userCreation",
  UserEdition = "userEdition",
  DeletedUsers = "DeletedUsers",
}

export type OpenUserModalStatus =
  | {
      mode: UserModalModes.UserCreation;
      status: { firstName: string; lastName: string; email: string; roles: AuthRoles[] };
    }
  | {
      mode: UserModalModes.UserEdition;
      status: { user: User };
    }
  | {
      mode: UserModalModes.DeletedUsers;
      status: null;
    };

export function useUsersModal() {
  const { modalStatus, openModal, closeModal } =
    useModal<OpenUserModalStatus>();

  const openUserCreationModal = (role?: AuthRoles) => {
    const defaultStatus = {
      firstName: "",
      lastName: "",
      email: "",
      roles: role !== undefined ? [role] : [AuthRoles.member],
    };
    openModal({
      mode: UserModalModes.UserCreation,
      status: defaultStatus,
    });
  };

  const openUserEditionModal = (user: User) => {
    openModal({
      mode: UserModalModes.UserEdition,
      status: {
        user: user,
      },
    });
  };

  const openDeletedUsersModal = () => {
    openModal({
      mode: UserModalModes.DeletedUsers,
      status: null,
    });
  };
  return [
    modalStatus,
    openUserCreationModal,
    openUserEditionModal,
    openDeletedUsersModal,
    closeModal,
  ] as const;
}
