import TooltipButton from "../../../../../../../components/TooltipButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./Task.scss";
import { Task as TaskType } from "../../../../../../../utils";
import { useTasksList } from "../../../../context/TasksListContext";

type TaskProps = {
  task: TaskType;
  className?: string;
};
export default function Task({ task, className="" }: TaskProps) {
  const {
    allTasks,
    openTaskCreationModal,
    openTaskEditionModal,
    openCategoryEditionModal,
  } = useTasksList();
  return (
    <div className={`Task d-flex list-row-styled justify-content-between align-items-center ${className}`}>
      <div className="taskName" style={{ maxWidth: "calc(100% - 55px)" }}>
        {task.name}
      </div>
      {!task.isPublic && (
        <div>
          <TooltipButton
            tooltipContent="Edit Task"
            buttonVariant="light"
            className="editTask"
            onClick={() => openTaskEditionModal(task)}
          >
            <FontAwesomeIcon icon={faPencil} size="sm" className="mx-2" />
          </TooltipButton>
        </div>
      )}
    </div>
  );
}
