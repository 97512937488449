import { Button, Card } from "react-bootstrap";
import DatePicker from "../../../../../components/DatePicker";
import { DateTime } from "../../../../../utils";
import { useActiveMembers } from "../../context/ActiveMembersContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import "./Header.scss";
import ButtonScale from "../../../../../components/ButtonScale";

export function Header() {
  const { date, refreshActiveMembers } = useActiveMembers();
  return (
    <>
    {/* <div className="AccountSettingsHeader w-100 d-flex justify-content-center align-items-center border-bottom mb-1">
      <div className="title">Active Members</div>
    </div> */}
      {/* <div className="title">Active Members</div> */}
    <Card.Header className="Header d-flex justify-content-c-sm-start">
      <div className="w-100 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <DatePicker
            date={date}
            setDate={undefined}
            format={"dayMonthYear"}
            maxDate={new DateTime()}
          />
        </div>
        {/* <div className="refresh">
          <Button onClick={refreshActiveMembers}>
            <FontAwesomeIcon icon={faRotateRight} />
          </Button>
        </div> */}
        <div>
          <ButtonScale onClick={refreshActiveMembers} className="py-0">
            <FontAwesomeIcon icon={faRotateRight} />
          </ButtonScale>
        </div>
      </div>
    </Card.Header>
    </>
  );
}
