import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
// import Content from "../../components/Content";
import Base from "../../components/pageBases/Base";
import "./Login.scss";
import SimpleCard from "../../components/SimpleCard";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
} from "react-bootstrap";

import logo from "../../assets/images/logoFull.png";
import { useEffect, useState } from "react";
import { useApp } from "../../contexts/AppContext";
import alertMessages from "../../data/alertMessages.json";
import { DateTime, fetchPost } from "../../utils";
import { ErrorResponce } from "../../utils/fetchFromEndpoint";
import {
  logError,
  logResponseError,
  logValidationError,
} from "../../utils/logError";
import { AuthRoles } from "../../utils/AuthRoles";
import apiUrls from "../../data/api.json";
import { fetchers } from "../../serverApi/fetchers";
import { Link } from "react-router-dom";

type LogInfo = {
  email: string;
  pwd: string;
  isSubmitting: boolean;
};

export function Login() {
  const { showAlert } = useApp();
  const [logInfo, setLogInfo] = useState<LogInfo>({
    email: "",
    pwd: "",
    isSubmitting: false,
  });
  const { auth, setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const defaultLocation = apiUrls.index;
  const from = location.state?.from?.pathname || defaultLocation;

  useEffect(() => {
    if (logInfo.isSubmitting) {
      submitForm();
    }
  }, [logInfo]);

  const autoLogOut = () => {
    // const endpointLogout = serverApi.logout;
    // const dataToSend = {};
    // const doIfSuccess = undefined;
    // const doIfFailed = () => {
    //   navigate(defaultLocation, { replace: true });
    // };
    // const doAlways = undefined;
    // const doIfError = () => {
    //   // navigate(defaultLocation, { replace: true });
    // };
    // fetchPost<any>(endpointLogout, dataToSend, {
    //   success: doIfSuccess,
    //   fail: doIfFailed,
    //   always: doAlways,
    //   error: doIfError,
    // });
    fetchers.general.logout.fetch({}, {
      fail: () => {
        navigate(defaultLocation, { replace: true });
      },
      // error: () => {
      //   // navigate(defaultLocation, { replace: true });
      // }
    })
  };
  useEffect(() => {
    // autoLogOut();
  }, []);

  useEffect(() => {
    if (!!auth) {
      navigate(defaultLocation, { replace: true });
    }
  });

  const submitForm = () => {
    // validate
    const emailPattern =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

    if (!logInfo.email || !logInfo.pwd || !emailPattern.test(logInfo.email)) {
      logValidationError("Invalid credentials.");
      showAlert(alertMessages.invalidCredentials);
      setLogInfo({ ...logInfo, isSubmitting: false });
      return;
    }
    const email = logInfo.email.trim();
    if (!email.length) {
      logValidationError("Invalid credentials.");
      showAlert(alertMessages.invalidCredentials);
      setLogInfo({ ...logInfo, isSubmitting: false });
      return;
    }
    // submit
    const dataToSend = {
      email: email,
      password: logInfo.pwd,
    }
    fetchers.general.authorize.fetch(dataToSend, {
      success: (data, status) => {
          if (!data){
            return;
          }
          const newAuth = {
            id: data.authInfo.id,
            firstName: data.authInfo.firstName,
            lastName: data.authInfo.lastName,
            roles: data.authInfo.roles,
            email: data.authInfo.email,
            created: data.authInfo.created,
            updated: data.authInfo.updated,
            deleted: data.authInfo.deleted
          };
          setAuth(newAuth);
          // navigate(from, { replace: true });
          navigate(defaultLocation, { replace: true });
      },
      fail: (data, status) => {
        if (status == 401) {
          showAlert(alertMessages.invalidCredentials);
          logResponseError(data.error);
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
        logResponseError(data.error);
      },
      always: () => {
        setLogInfo({ ...logInfo, isSubmitting: false });
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      }
    })
    // type ResponceData = {
    //   msg: string;
    //   name: string;
    //   role: string;
    //   created: string;
    //   email: string;
    // };

    // const endpointAuthorize = serverApi.authorize;
    // const dataToSend = {
    //   email: logInfo.email,
    //   password: logInfo.pwd,
    // };
    // // console.log(dataToSend);
    // const doIfSuccess = (data: ResponceData) => {
    //   if (!Object.values(AuthRoles).includes(data.role as AuthRoles)) {
    //     throw new Error();
    //   }
    //   const role: AuthRoles = data.role as AuthRoles;
    //   console.log(role);
    //   const newAuth = {
    //     username: data.name,
    //     roles: [data.role as AuthRoles],
    //     created: new DateTime(data.created),
    //     email: data.email,
    //   };
    //   setAuth(newAuth);
    //   navigate(from, { replace: true });
    // };
    // const doIfFailed = (data: ErrorResponce, status: number) => {
    //   console.log("status:", status, status == 401);
    //   if (status == 401) {
    //     showAlert(alertMessages.invalidCredentials);
    //     logResponseError(data.error);
    //     return;
    //   }
    //   showAlert(alertMessages.somethingWentWrong);
    //   logResponseError(data.error);
    // };
    // const doAlways = () => {
    //   setLogInfo({ ...logInfo, isSubmitting: false });
    // };
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };

    // fetchPost<ResponceData>(endpointAuthorize, dataToSend, {
    //   success: doIfSuccess,
    //   fail: doIfFailed,
    //   always: doAlways,
    //   error: doIfError,
    // });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (logInfo.isSubmitting) {
      return;
    }
    setLogInfo({ ...logInfo, isSubmitting: true });
  };

  return (
    <Base>
      <div className="Login">
        <Container fluid="sm" className="Container">
          <Row className="h-100">
            <Col>
              <Row className="d-flex justify-content-center align-items-end logo-continer">
                <Col className="text-center mb-2">
                  <Image src={logo} className="logo w-100" />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-start form-container">
                <Col>
                  <SimpleCard className="shadow-lg border-0 rounded-lg">
                    <Card.Header>
                      <div className="text-center fs-2 my-3">Log in</div>
                    </Card.Header>
                    <Card.Body>
                      <form className="form" onSubmit={handleSubmit}>
                        <Form.Group className="my-2">
                          <Form.Label htmlFor="email" className="small">
                            Email
                          </Form.Label>
                          <Form.Control
                            id="email"
                            placeholder="Email"
                            // type="email"
                            type="text"
                            value={logInfo.email}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setLogInfo({ ...logInfo, email: e.target.value })
                            }
                            required
                          />
                        </Form.Group>
                        <Form.Group className="my-3">
                          <Form.Label htmlFor="password" className="small">
                            Password
                          </Form.Label>
                          <Form.Control
                            id="password"
                            placeholder="Password"
                            type="password"
                            value={logInfo.pwd}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setLogInfo({ ...logInfo, pwd: e.target.value })
                            }
                            required
                          />
                        </Form.Group>
                        {/* <Form.Group className="my-3">
                        <Form.Check
                          type="checkbox"
                          id="rememberme"
                          label="Remember me"
                        ></Form.Check>
                      </Form.Group> */}
                        <Form.Group className="d-flex align-items-center justify-content-center mt-4">
                          <Button
                            type="submit"
                            variant="primary"
                            className="py-2 px-5"
                            disabled={
                              logInfo.isSubmitting ||
                              !logInfo.email ||
                              !logInfo.pwd
                            }
                          >
                            {logInfo.isSubmitting ? "Wait…" : "Log in"}
                          </Button>
                        </Form.Group>
                      </form>
                      <div className="text-center mt-3 mb-1 small forgot-password">
                      <Link to={apiUrls.forgotPassword}>Forgot password?</Link>
                      </div>
                    </Card.Body>
                  </SimpleCard>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Base>
  );
}
