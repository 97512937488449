import { NavLink } from "react-router-dom";
import { useCard } from "../../context/CardContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  faExpand,
  faMagnifyingGlass,
  faUpRightAndDownLeftFromCenter,
  faAngleRight,
  faAnglesRight,
  faCaretDown,
  faArrowUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
// import { faEye } from "@fortawesome/free-svg-icons";
import "./Expand.scss";
import { Button } from "react-bootstrap";

type ExpandProps = {
  onClick: () => void;
};
export function Expand({ onClick }: ExpandProps) {
  useCard();

  return (
    <div className="Expand">
      <Button variant="outline-primary" onClick={onClick}>
        {/* <FontAwesomeIcon icon={faEye} size="sm" /> */}
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" 
        // flip="horizontal"
        />
      </Button>
    </div>
  );
}
