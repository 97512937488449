import React, { ReactNode } from "react";
import { Container as ContainerBS, Card as CardBS } from "react-bootstrap";
import "./SimpleCard.scss";

type SimpleCardProps = {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export function SimpleCard({ children, className, style, onClick }: SimpleCardProps) {

  return <CardBS className={`SimpleCard ${className ? className : ""}`} style={style} onClick={onClick}>{children}</CardBS>;
}
