import { DateTime } from "../../../../utils";
import _ from "lodash";
import { Formatter } from "../../../../utils/Formatter";

export class Formatter2 extends Formatter {

  static formatPeriodOrManual(period: {
    startedAt: DateTime;
    endedAt: DateTime | null;
  }) {
    if (!period.endedAt) {
      return "manual";
    }
    return Formatter2.formatPeriod(period.startedAt, period.endedAt);
  }

}
