import { useEffect, useState } from "react";
import { Task, Tasks } from "../../../../../../../utils/Task";
import { useDayRecords } from "../../../../context/DayRecordsContext";
import { DateTime } from "../../../../../../../utils";
import { Container, Form } from "react-bootstrap";
import {
  TimeInputHM,
  InputTimeHM,
} from "../../../../../../../components/TimeInput";
import TooltipButton from "../../../../../../../components/TooltipButton";
import { Row, Col } from "react-bootstrap";
import { TaskRecordGroups } from "../../../../../../../utils/TaskRecord";
import { Hours, Time, TimeHM } from "../../../../../../../utils/TimeTypes";
import _ from "lodash";
import alertMessages from "../../../../../../../data/alertMessages.json";
import { useApp } from "../../../../../../../contexts/AppContext";
import TaskSelector from "../../../../../../../components/TaskSelector";
import { logValidationError } from "../../../../../../../utils/logError";
// import { useFormErrors } from "../../hooks/useFormErrors";
import ValidationFeedback from "../../../../../../../components/ValidationFeedback";
import { useFormErrors } from "../../../../../../../hooks/useFormErrors";
import { fetchers } from "../../../../../../../serverApi/fetchers";
import restrictions from "../../../../../../../data/restrictions.json";
import {
  ValidationResult,
  useFormSubmit,
} from "../../../../../../../hooks/useFormSubmit";
// import ValidationFeedback from "../ValidationFeedback";
import CookiesMenager from "../../../../../../../utils/cookiesMenager";
import { Exception } from "sass";


function getLastTask(tasks: Tasks): Task {
  const existingTasks = tasks.tasks.filter(task => !task.isDeleted);
  const lastTimerTaskId = CookiesMenager.getCookie("lastCreatedTask");
  const lastTimerTask = lastTimerTaskId
    ? existingTasks.find((task) => task.id === lastTimerTaskId)
    : undefined;
  if (lastTimerTask) {
    return lastTimerTask;
  }
  const firstUserTask = existingTasks.find((task) => !task.isPublic);
  const firstPublicTask = existingTasks.find((task) => !!task.isPublic);

  const firstTask = firstUserTask
  ? firstUserTask
  : firstPublicTask
  ? firstPublicTask
  : undefined;  //tasks.getSortedByCategory().categories[0].tasks[0];
  if (firstTask === undefined) {
    throw new Error();
  }
  CookiesMenager.setCookie("lastCreatedTask", firstTask.id);
  return firstTask;
}
type TimeRecordInfo = {
  task: Task;
  duration: TimeHM | undefined;
};

type FormErrors = {
  generalError: string | null;
  taskError: string | null;
  durationError: string | null;
};

type ModalCreateTimeRecordProps = {
  tasks: Tasks;
};
export function ModalCreateTimeRecord({ tasks }: ModalCreateTimeRecordProps) {
  const { showAlert } = useApp();
  const { date, closeModal, refreshDay, dayTimeRecordsInfo } = useDayRecords();
  const [timeRecordInfo, setTimeRecordInfo] = useState<TimeRecordInfo>({
    task: getLastTask(tasks),
    duration: undefined,
  });

  const validateTask = (task: Task): ValidationResult => {
    return { isValid: true, message: null };
  };
  const validateDuration = (duration: {
    hours: number;
    minutes: number;
  } | undefined): ValidationResult => {
    // console.log(duration)
    if (
      !duration ||
      duration.hours < 0 ||
      duration.hours > 23 ||
      duration.minutes < 0 ||
      duration.minutes > 59
    ) {
      const message = "Wrong duration format.";
      return { isValid: false, message: message };
    }
    var durationMin = duration.hours * 60 + duration.minutes;
    if (durationMin < 1) {
      const message = "Duration is too short. Minimum 1 min required.";
      return { isValid: false, message: message };
    }
    if (durationMin > restrictions.maxPossibleWorkingDurationPerDayHours * 60) {
      const message = "Duration is too long. Maximum " + restrictions.maxPossibleWorkingDurationPerDayHours + " hours allowed.";
      return { isValid: false, message: message };
    }
    // if (!dayTimeRecordsInfo.timeRecords) {
      //   const message = "Something wrong.";
      //   return { isValid: false, message: message };
      // }
      // var totalBillableWorkingTimeForDay = dayTimeRecordsInfo.totalHours;
      if (
      // dayTimeRecordsInfo.totalHours &&
      dayTimeRecordsInfo.totalMinutes + durationMin > restrictions.maxPossibleWorkingDurationPerDayHours * 60
    ) {
      const message = "Total working time for this day exceeds " + restrictions.maxPossibleWorkingDurationPerDayHours + " hours.";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };
  const validateTimeRecordInfo = () => {
    return (
      validateTask(timeRecordInfo.task).isValid &&
      validateDuration(timeRecordInfo.duration).isValid
    );
  };
  const sendTimeRecordInfo = (
    _doIfSuccess: () => void,
    _doAlways: () => void
  ): void => {
    if (!timeRecordInfo.duration) {
      return;
    }
    const dataToSend = {
      date: date,
      taskId: timeRecordInfo.task.id,
      durationMin:
        timeRecordInfo.duration.hours * 60 + timeRecordInfo.duration.minutes,
    };
    fetchers.account.member.createManualTimerecord.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        refreshDay();
        CookiesMenager.setCookie("lastCreatedTask", dataToSend.taskId);
      },
      fail: (data, status) => {
        if(status === 443){
          showAlert((alertMessages.changesNotSaved, alertMessages.timeRecordDurationIsTooLong));
          return;
        }
        if(status === 447){
          showAlert((alertMessages.changesNotSaved, (alertMessages.totalWorkingDurationIsLongerThan.replace("{{maxTotalWorkingDuration}}", restrictions.maxPossibleWorkingDurationPerDayHours + " hours"))));
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };
  const getUpdatedFormErrors = (
    _timeRecordInfo: TimeRecordInfo | undefined = timeRecordInfo
  ): FormErrors => {
    const vTaskRes = validateTask(_timeRecordInfo.task);
    const vDurationRes = validateDuration(_timeRecordInfo.duration);
    return {
      ...formErrors,
      taskError: vTaskRes.message,
      durationError: vDurationRes.message,
    };
  };
  const [
    formStatus,
    formErrors,
    handleSubmit,
    submitIsPossible,
    updateFormErrors,
  ] = useFormSubmit<FormErrors, TimeRecordInfo>(
    {
      generalError: null,
      durationError: null,
      taskError: null,
    },
    validateTimeRecordInfo,
    sendTimeRecordInfo,
    getUpdatedFormErrors
  );

  const handleDurationChange = (newDuration: TimeHM | undefined) => {
    const newDurationVal =
      // newDuration === undefined
      //   ? { hours: 0 as Hours, minutes: 0 as Minutes }
      //   : 
        newDuration;
    const newTimeRecordInfo: TimeRecordInfo = {
      ...timeRecordInfo,
      duration: newDurationVal,
    };
    updateFormErrors(newTimeRecordInfo);
    setTimeRecordInfo(newTimeRecordInfo);
  };
  const handleTaskChange = (newTask: Task) => {
    const newTimeRecordInfo = { ...timeRecordInfo, task: newTask };
    updateFormErrors(newTimeRecordInfo);
    setTimeRecordInfo(newTimeRecordInfo);
  };
  const submitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && submitIsPossible) {
      handleSubmit();
    }
  }
  return (
    <Container className="w-auto mx-md-3 ModalCreateTimeRecord"
    onKeyDown={submitOnEnter}>
      <Form.Group className="mb-3">
        <Form.Label>Task:</Form.Label>
        <TaskSelector
          variant="select"
          tasks={tasks}
          value={timeRecordInfo.task}
          onSelect={handleTaskChange}
        ></TaskSelector>
        <ValidationFeedback message={formErrors.taskError} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Duration:</Form.Label>
        <InputTimeHM
          // value={timeRecordInfo.duration}
          onChange={handleDurationChange}
          isInvalid={!!formErrors.durationError}
        ></InputTimeHM>
        <ValidationFeedback message={formErrors.durationError} />
      </Form.Group>
      <Form.Group as={Row} className="mt-5">
        <Col className="d-flex justify-content-start"></Col>
        <Col className="d-flex justify-content-end">
          <ValidationFeedback
            message={formErrors.generalError}
            className="text-end me-3"
          />
          <TooltipButton
            tooltipContent={`Create record`}
            className="px-4"
            onClick={handleSubmit}
            disabled={!submitIsPossible}
          >
            {formStatus.isSubmitting ? "Saving" : "Save"}
          </TooltipButton>
        </Col>
      </Form.Group>
    </Container>
  );
}
