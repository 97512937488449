import WidgetCard from "../../../components/WidgetCard";
import { Header } from "./components/Header/Header";
import { ResetForm } from "./components/ResetForm/ResetForm";
import apiUrls from "../../../data/api.json";
import "./ResetPasswordForm.scss";
import { Link } from "react-router-dom";

type ResetPasswordProps = {
  token: string;
  reset_to_set?: boolean;
};
export function ResetPasswordForm({ token, reset_to_set = false }: ResetPasswordProps) {
  return (
    <WidgetCard className="ResetPasswordForm">
      <WidgetCard.Header>
        <Header reset_to_set={reset_to_set} />
      </WidgetCard.Header>
      <WidgetCard.Body>
        <ResetForm token={token} />
        <div className="text-center mt-3 mb-1 small link_login">
          <Link to={apiUrls.login}>Log in</Link>
        </div>
      </WidgetCard.Body>
    </WidgetCard>
  );
}
