import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

type ValidationFeedbackProps = {
    message: string | null;
    className?: string;
}
export function ValidationFeedback({message, className}: ValidationFeedbackProps){
    return (
        <Form.Control.Feedback type="invalid" className={`${className} ${!!message ? "d-block" : "d-none"}`}>
            <div className="d-flex">
            <div><FontAwesomeIcon icon={faExclamationCircle} className="mx-2"/></div>
            <div>{message}</div>
            </div>
        </Form.Control.Feedback>
    )
}