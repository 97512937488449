import { ValidationResult } from "../../../../../../hooks/useFormSubmitDelete";
import { Tasks } from "../../../../../../utils/Task";
import restrictions from "../../../../../../data/restrictions.json";

const categoryIsPublic = (categoryName: string, allTasks: Tasks) => {
  return (
    allTasks.tasksByCategory.categories.filter(
      (category) => category.isPublic && category.name === categoryName
    ).length > 0
  );
};
export function sameTaskAlreadyExist(
  category: string,
  taskName: string,
  allTasks: Tasks
): boolean {
  let existingCategories = allTasks.tasksByCategory.categories.filter(
    (eCategory) => !eCategory.isPublic && eCategory.name === category
  );
  let existingSameTasks = existingCategories.filter(
    (category) =>
      category.tasks.filter((cTask) => cTask.name === taskName).length > 0
  );
  return existingSameTasks.length > 0;
}
export function validateCategoryName(
  categoryName: string,
  allTasks: Tasks
): ValidationResult {
  if (!categoryName || !categoryName.trim()) {
    const message = "Category name cannot be empty.";
    return { isValid: false, message: message };
  }
  // if (categoryIsPublic(categoryName.trim(), allTasks)) {
  //   const message = "You cannot access a public category.";
  //   return { isValid: false, message: message };
  // }
  if (categoryName.length > restrictions.maxTaskCategoryNameLength) {
    const message =
      "Category name is too long. Max " +
      restrictions.maxTaskCategoryNameLength +
      " characters allowed.";
    return { isValid: false, message: message };
  }
  return { isValid: true, message: null };
}

export function validateTaskName(taskName: string): ValidationResult {
  if (!taskName || !taskName.trim()) {
    const message = "Task name cannot be empty.";
    return { isValid: false, message: message };
  }
  if (taskName.length > restrictions.maxTaskNameLength) {
    const message =
      "Task name is too long. Max " +
      restrictions.maxTaskNameLength +
      " characters allowed.";
    return { isValid: false, message: message };
  }
  return { isValid: true, message: null };
}
