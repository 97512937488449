import { useState } from "react";

export type ErrorLog = string | null;
export type FormErrors = {
    [key: string] : ErrorLog;
} 

export function useFormErrors<E extends FormErrors>(defaultValue: E) {
    const [formErrors, setFormErrors] = useState<E>(defaultValue);
    const resetFormErrorsToNull = () => {
        const newFormErrors: E = {...formErrors};
        for (const key in newFormErrors){
            newFormErrors[key] = null as E[Extract<keyof E, string>];
        }
        setFormErrors(newFormErrors);
    }
    const formErrorsHasNoError = () => {
        return Object.values(formErrors).every((error) => error === null);
    }


    return [formErrors, setFormErrors, resetFormErrorsToNull, formErrorsHasNoError] as const;

}