import { ReactNode } from "react";
import WidgetCard from "../../../components/WidgetCard";
// import Header from "./components/Header";
// import SummaryTable from "./components/SummaryTable";
import { DateTime } from "../../../utils";
import { Day, Month, Year } from "../../../utils/TimeTypes";
import { MonthlyTimesheetsProvider, useMonthlyTimesheetsContext } from "./context/MonthlyTimesheetsContext";
import Header from "./components/Header/Header";
import SummaryTable from "./components/SummaryTable/SummaryTable";
import { ExportModal } from "./components/ExportModal/ExportModal";
import "./MembersMonthlyTimesheetsTable.scss";

type MembersWorktimeSummaryTableProps = {
  month: DateTime;
  setMonth: (month: DateTime) => void;
};

export function MembersMonthlyTimesheetsTable({month, setMonth}: MembersWorktimeSummaryTableProps) {
  const MWSummaryProvider = ({ children }: { children: ReactNode }) => (
    <MonthlyTimesheetsProvider
      month={month}
      setMonth={setMonth}
      children={children}
    />
  );
  return (
    <WidgetCard className="MembersMonthlyTimesheetsTable" ContextProvider={MWSummaryProvider} useContext={useMonthlyTimesheetsContext}>
      <WidgetCard.Header>
        <Header />
      </WidgetCard.Header>
      <WidgetCard.Body>
        <SummaryTable />
      </WidgetCard.Body>
      <WidgetCard.Modal>
        <ExportModal/>
      </WidgetCard.Modal>
    </WidgetCard>
  );
}
