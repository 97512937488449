import { useState } from "react";

type Status = {
  [prop: string]: any;
} | null;

type OpenedModalStatus = {
  mode: string;
  status: Status;
};
export type ModalStatus<T extends OpenedModalStatus> =
  | (T & { show: true })
  | {
      show: false;
      mode: null;
      status: null;
    };
export function useModal<T extends OpenedModalStatus>() {
  const [modalStatus, setModalStatus] = useState<ModalStatus<T>>({
    show: false,
    mode: null,
    status: null,
  });
  const closeModal = () => {
    setModalStatus({ show: false, mode: null, status: null });
  };
  const openModal = (status: T) => {
    setModalStatus({ ...status, show: true });
  };

  return {modalStatus, openModal, closeModal} as const;
}
