import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "./Combobox.scss";
import {
  ClearButton,
  Menu,
  MenuItem,
  RenderMenuProps,
  Typeahead,
  withItem,
} from "react-bootstrap-typeahead";
import CrossBtn from "../CrossBtn";
import { CloseButton, Form } from "react-bootstrap";
import React, {
  InputHTMLAttributes,
  Ref,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Option,
  TypeaheadInputProps,
  TypeaheadManagerChildProps,
  TypeaheadProps,
} from "react-bootstrap-typeahead/types/types";
import { default as TypeaheadType } from "react-bootstrap-typeahead/types/core/Typeahead";
import _ from "lodash";

type ComboboxInputProps = {
  inputRef: React.RefCallback<HTMLElement>;
  referenceElementRef: React.RefCallback<HTMLElement>;
  value: string | number | readonly string[] | undefined;
  restInputProps: InputHTMLAttributes<HTMLInputElement>;
  // inputProps: TypeaheadInputProps,
  props: TypeaheadManagerChildProps;
  inputMaxChars?: number
};
function ComboboxInput({
  inputRef,
  referenceElementRef,
  value,
  restInputProps,
  props,
  inputMaxChars
}: ComboboxInputProps) {
  // const { inputRef, referenceElementRef, value, ...restInputProps } = inputProps;
  // var inputValue: string | number | string[] | undefined  = value?.toString();
  if (
    typeof value !== "string" &&
    typeof value !== "number" &&
    typeof value !== "undefined"
  ) {
    const inputValue: string[] = value.map((val) => val);
  } else {
    var inputValue: string | number | string[] | undefined = value;
  }
  var { size, ...restInputProps2 } = restInputProps;
  return (
    <Form.Control
      type="text"
      {...restInputProps2}
      value={inputValue}
      ref={(input: HTMLInputElement) => {
        inputRef(input);
        referenceElementRef(input);
      }}
      maxLength={inputMaxChars}
    />
  );
}

type ComboboxMenuProps = {
  results: Option[];
  menuProps: RenderMenuProps;
  inputValue: string;
};
function ComboboxMenu({ results, menuProps, inputValue }: ComboboxMenuProps) {
  const resultsStr: string[] = results
    .map((result) => result.toString())
    .filter((result) => result !== inputValue);
  const {
    newSelectionPrefix,
    paginationText,
    renderMenuItemChildren,
    ...rest
  } = menuProps;
  return (
    <>
      {!!resultsStr.length && (
        <Menu {...rest}>
          {resultsStr.map((result, index) => (
            <MenuItem option={result} position={index} key={index}>
              {result}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}

type ComboboxProps = {
  options: string[];
  onChange?: (value: string) => void;
  disabled?: boolean;
  defaultValue?: string;
  inputMaxChars?: number;
  isInvaid?: boolean;
};
export function Combobox({
  options,
  onChange,
  defaultValue,
  disabled,
  inputMaxChars,
  isInvaid
}: ComboboxProps) {
  // const ref = useRef<TypeaheadType>(null)
  // let input = ref.current?.getInput();
  const id = useRef<string>(_.uniqueId('Combobox-'));
  const [selected, _setSelected] = useState<string>(
    defaultValue ? defaultValue : ""
  );
  const setSelected = (newSelected: string): void => {
    _setSelected(newSelected);
    // console.log(">newSel:", newSelected);
    onChange && onChange(newSelected);
  }
  // const [inputValue, setInputValue] = useState<string>('');
  const inputValueRaw = useRef<string>(defaultValue ? defaultValue : "");
  const inputValue = inputValueRaw.current;
  const setInputValue = (newInputValue: string) =>
    (inputValueRaw.current = newInputValue);
  const newOptions = useRef<string[]>([]);
  // const invalid = useRef<boolean>(false);

  // useEffect(() => {
  //   console.log(">newSel:", selected);
  //   onChange && onChange(selected);
  // }, [selected]);

  const updateSel = (nsel: string) => {
    // invalid.current = false;
    if (nsel === selected) {
      return;
    }
    if (options.filter((option) => option === nsel).length > 0) {
      setSelected(nsel);
      setInputValue(nsel);
      return;
    }
    // new
    if (!nsel.replace(/\s/g, "").length) {
      // invalid.current = true;
      newOptions.current = [];
      setSelected("");
      setInputValue("");
      return;
    }
    newOptions.current = [nsel];
    setSelected(nsel);
  };

  var thOptions = [...options, ...newOptions.current];

  return (
    <Typeahead
    // multiple={true}
      id={id.current}
      className="Combobox"
      options={thOptions}
      // placeholder="Enter category name"
      //   allowNew={true}
      selected={!!selected ? [selected] : []}
      onChange={(newSelected: any[]) => {
        !!newSelected.length && updateSel(newSelected[0]);
      }}
      onInputChange={(text) => {
        setInputValue(text);
        updateSel(text);
      }}
      open={isInvaid ? false : undefined}
      isInvalid={isInvaid}
      renderMenu={(results, menuProps) => (
        <ComboboxMenu
          results={results}
          menuProps={menuProps}
          inputValue={inputValue}
        />
      )}
      renderInput={(
        { inputRef, referenceElementRef, value, ...restInputProps },
        props
      ) => (
        <ComboboxInput
          inputRef={inputRef}
          referenceElementRef={referenceElementRef}
          value={inputValue}
          props={props}
          restInputProps={restInputProps}
          inputMaxChars={inputMaxChars}
        />
      )}
      // ref={ref}
      disabled={disabled}
    >
      {/* {({ onClear, selected }) => (
        <>
          <div
            className="rbt-aux"
            style={{ pointerEvents: "auto", width: "2.5rem" }}
          >
            {!!selected.length && <CrossBtn onClick={onClear} />}
          </div>
        </>
      )} */}
    </Typeahead>
  );
}
