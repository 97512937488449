import { ReactNode, useEffect, useState } from "react";
import { useApp } from "../../../../contexts/AppContext";
import { DateTime, fetchPost } from "../../../../utils";
import { DayStatus } from "../../../../utils/DayStatus";
import { TaskRecords } from "../../../../utils/TaskRecords";
import { TaskRecord, TaskRecordGroups } from "../../../../utils/TaskRecord";
import { useRoleAuth } from "../../../../contexts/RoleAuthContext/RoleAuthContext";
import { useTimerContext } from "../../../../contexts/TimerContext";
import Placeholder from "../../../../components/Placeholder";
import { useSelectedRecord } from "../hooks/useSelectedRecord";
import { Day, Month, Year } from "../../../../utils/TimeTypes";
import { User } from "../../../../utils/Users";
import alertMessages from "../../../../data/alertMessages.json";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import apiUrls from "../../../../data/api.json";
import { createContext } from "../../../../hooks/useCreateContext";
import { fetchers } from "../../../../serverApi/fetchers";

type Status = {
  status: DayStatus;
  requestedStatus: DayStatus | null;
};

type AvailableDays = {
  minDay: DateTime;
  maxDay: DateTime;
  excludedDays: DateTime[];
};
type DayTimeRecordsInfo = {
  status: DayStatus;
  requestedStatus: DayStatus | null;
  totalMinutes: number;
  timeRecords: TaskRecords;
};

type DayRecordsContextVal = {
  member: User;
  setMember: (newMember: User) => void;
  date: DateTime;
  setDate?: (date: DateTime) => void;
  availableDays: AvailableDays;
  dayTimeRecordsInfo: DayTimeRecordsInfo;
  refreshDay: () => void;
  changeDayStatus: (oldStatus: DayStatus, newStatus: DayStatus) => void;
  rejectChangeStatusRequest: () => void;
  selectedRecord: TaskRecord | null;
  selectRecord: (group: TaskRecordGroups, recordId: string) => void;
  redirectToMonth: (month: DateTime) => void;
  embeded: boolean;
};
const [DayRecordsContext, useDayRecords] =
  createContext<DayRecordsContextVal>();
export { useDayRecords };

type DayRecordsProviderProps = {
  children: ReactNode;
  member: User;
  setMember: (newMember: User) => void;
  date: DateTime;
  setDate?: (day: DateTime) => void;
  onlyMonth?: DateTime;
  embeded: boolean;
};

export function DayRecordsProvider({
  children,
  member,
  setMember,
  date,
  setDate,
  onlyMonth,
  embeded
}: DayRecordsProviderProps) {
  const { showAlert } = useApp();
  const navigate = useNavigate();
  // const { childrenRefresh } = useTimerContext();
  const [dayTimeRecordsInfo, setDayTimeRecordsInfo] = useState<
    DayTimeRecordsInfo | undefined
  >();
  // const [dayStatus, setDayStatus] = useState<
  //   | {
  //       status: DayStatus;
  //       requestedStatus: DayStatus | null;
  //     }
  //   | undefined
  // >();
  // const [taskTrackingData, setTaskTrackingData] = useState<
  //   TaskRecords | undefined
  // >();
  const [selectedRecord, selectRecord, resetSelecedRecord] = useSelectedRecord(
    dayTimeRecordsInfo?.timeRecords
  );

  const [availableDays, setAvailableDays] = useState<
    AvailableDays
  >(onlyMonth ? {
    minDay: onlyMonth.getStartOfMonth(),
    maxDay: onlyMonth.getEndOfMonth(),
    excludedDays:[]
  } : {
    minDay: member.created.getStartOfMonth(),
    maxDay: new DateTime().getEndOfMonth(), 
    excludedDays: []
  });

  const taskTrackingDataRefresh = () => {
    const dataToSend = {
      userId: member.id,
      date: date,
    };
    fetchers.account.admin.dayInfo.fetch(dataToSend, {
      success: (data, status) => {
        setDayTimeRecordsInfo({
          status: data.status,
          requestedStatus: data.requestedStatus,
          totalMinutes: data.totalMinutes,
          timeRecords: data.taskRecords,
        });
        // setDayStatus({
        //   status: data.status,
        //   requestedStatus: data.requestedStatus,
        // });
        // setTaskTrackingData(data.taskRecords);
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };
  // const availableDaysRefresh = () => {
  //   const dataToSend = {
  //     userId: member.id,
  //     date: date,
  //   };
  //   fetchers.account.admin.monthStatusesInfo.fetch(dataToSend, {
  //     success: (data, status) => {
  //       const now = new DateTime();
  //       const excludedDays = data.monthDays.filter(
  //         (day) =>
  //           day.date.graterThan(now) &&
  //           day.status !== DayStatus.submitted &&
  //           day.status !== DayStatus.completed
  //       );
  //       setAvailableDays({availa
  //         excludedDays: excludedDays ? excludedDays.map((day) => day.date) : [],
  //       });
  //     },
  //     fail: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //     error: () => {
  //       showAlert(alertMessages.somethingWentWrong);
  //     },
  //   });
  // };

  const refreshDay = () => {
    taskTrackingDataRefresh();
  };

  useEffect(() => {
    refreshDay();
  }, [date]);

  const checkDateIsAvailable = (date: DateTime) => {
    const lastMonthDay = (new DateTime()).getEndOfMonth();
    const startOfLastMonthWeek = lastMonthDay.addDays(-14);
    if (new DateTime().graterThan(startOfLastMonthWeek)) {
      return true;
    }
    const afterToday = date.graterThan(new DateTime());
    return !afterToday;
  };
  const changeDayStatus = (oldStatus: DayStatus, newStatus: DayStatus) => {
    if (!dayTimeRecordsInfo?.status || newStatus == oldStatus) {
      return;
    }

    var valid =
      (oldStatus == DayStatus.completed && newStatus == DayStatus.submitted) ||
      (oldStatus == DayStatus.submitted && newStatus == DayStatus.completed) ||
      newStatus == DayStatus.editable;

    // || (oldStatus == DayStatus.editable && newStatus == DayStatus.submitted);
    if (!valid) {
      showAlert(alertMessages.invalidData);
      return;
    }
    setDayTimeRecordsInfo(undefined);
    const dataToSend = {
      userId: member.id,
      date: date,
      newStatus: newStatus,
    };
    fetchers.account.admin.changeDateStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        refreshDay();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };
  const rejectChangeStatusRequest = () => {
    if (!checkDateIsAvailable(date)) {
      return;
    }
    setDayTimeRecordsInfo(undefined);
    const dataToSend = {
      userId: member.id,
      date: date,
    };
    fetchers.account.admin.rejectDateStatusRequest.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        refreshDay();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/member-records/reject_change_date_status_request";
    // const dataToSend = {
    //   userId: member.id,
    //   date: date.toJSON(),
    // };

    // fetchPost<{}>(endpoint, dataToSend, {
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     refreshMonthData();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };
  const redirectToMonth = (month: DateTime) => {
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0");
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();
    navigate(
      {
        pathname: generatePath(
          apiUrls.account.children.admin.children.memberMonth,
          {
            userId: member.id,
          }
        ),
        search: queryParams,
      }
      // { replace: true }
    );
  };

  return (
    <>
      {dayTimeRecordsInfo && availableDays && (
        <DayRecordsContext.Provider
          value={{
            member,
            setMember,
            date,
            setDate,
            availableDays,
            dayTimeRecordsInfo,
            refreshDay,
            changeDayStatus,
            rejectChangeStatusRequest,
            selectedRecord,
            selectRecord,
            redirectToMonth,
            // modalStatus,
            // openModal,
            // closeModal,
            // navigateToDayRecords
            embeded
          }}
        >
          {children}
        </DayRecordsContext.Provider>
      )}
      {(!dayTimeRecordsInfo || !availableDays) && <Placeholder />}
    </>
  );
}
