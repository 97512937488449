import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
// import "../Combobox/Combobox.scss";
import "./MultiSelect.scss";
import {
  ClearButton,
  Menu,
  MenuItem,
  RenderMenuProps,
  Typeahead,
  withItem,
} from "react-bootstrap-typeahead";
import CrossBtn from "../CrossBtn";

import { CloseButton, Form } from "react-bootstrap";
import React, {
  InputHTMLAttributes,
  Ref,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Option,
  TypeaheadInputProps,
  TypeaheadManagerChildProps,
  TypeaheadProps,
} from "react-bootstrap-typeahead/types/types";
import { default as TypeaheadType } from "react-bootstrap-typeahead/types/core/Typeahead";
import _ from "lodash";
import { usePrevious } from "../../hooks/usePreviousValue";

export type MultiSelectOption = {
  key: string;
  label: string;
};
function isMultiSelectOption(
  option: any | MultiSelectOption
): option is MultiSelectOption {
  return (
    typeof option === "object" &&
    option !== null &&
    !Array.isArray(option) &&
    Object.keys(option).length === 2 &&
    option.hasOwnProperty("key") &&
    option.hasOwnProperty("label") &&
    typeof option.key === "string" &&
    typeof option.label === "string"
  );
}

type MultiSelectControlledProps = {
  selectedOptions: MultiSelectOption[];
  options: MultiSelectOption[];
  onChange: ((value: MultiSelectOption[]) => void) | undefined;
  disabled: boolean | undefined;
  isInvaid: boolean | undefined;
  maxSelectedOptions: number | undefined;
};
function MultiSelectControlled({
  selectedOptions,
  options,
  onChange,
  disabled,
  isInvaid,
  maxSelectedOptions,
}: MultiSelectControlledProps) {
  const id = useRef<string>(_.uniqueId("MultiSelect-"));
  const notSelectedOptions = () => {
    return options.filter(
      (option) =>
        !selectedOptions.some((selOption) => _.isEqual(option, selOption))
    );
  };
  return (
    <Typeahead
      multiple={true}
      id={id.current}
      className={
        "MultiSelect" +
        (maxSelectedOptions && selectedOptions.length >= maxSelectedOptions
          ? " no-input"
          : "")
      }
      selected={_.sortBy(selectedOptions, (option) => option.label)}
      options={options}
      onChange={(newSelectedOptions) => {
        if (
          onChange &&
          newSelectedOptions.every((option) => isMultiSelectOption(option))
        ) {
          // console.log(
          //   ">>",
          //   options.filter(
          //     (option) =>
          //       !newSelectedOptions.some((selOption) =>
          //         _.isEqual(option, selOption)
          //       )
          //   ).length
          // );
          if (maxSelectedOptions !== undefined) {
            newSelectedOptions.length <= maxSelectedOptions &&
              onChange(newSelectedOptions as MultiSelectOption[]);
          } else {
            onChange(newSelectedOptions as MultiSelectOption[]);
          }
        }
      }}
      disabled={disabled}
      isInvalid={isInvaid}
      open={isInvaid || notSelectedOptions().length <= 0 ? false : undefined}
      onKeyDown={(e) => {
        if (
          maxSelectedOptions &&
          selectedOptions.length >= maxSelectedOptions
        ) {
          e.preventDefault();
        }
      }}
    />
  );
}

type MultiSelectUncontrolledProps = {
  options: MultiSelectOption[];
  defaultValue: MultiSelectOption[] | undefined;
  onChange: ((value: MultiSelectOption[]) => void) | undefined;
  disabled: boolean | undefined;
  isInvaid: boolean | undefined;
  maxSelectedOptions: number | undefined;
};

function MultiSelectUncontrolled({
  defaultValue = [],
  options,
  onChange,
  disabled,
  isInvaid,
  maxSelectedOptions,
}: MultiSelectUncontrolledProps) {
  const [selectedOptions, setSelectedOptions] =
    useState<MultiSelectOption[]>(defaultValue);
  const previousSelectedOptions =
    usePrevious<MultiSelectOption[]>(selectedOptions);
  useEffect(() => {
    if (
      onChange &&
      previousSelectedOptions !== undefined &&
      !_.isEqual(selectedOptions, previousSelectedOptions)
    ) {
      onChange(selectedOptions);
    }
  }, [selectedOptions]);
  return (
    <MultiSelectControlled
      options={options}
      selectedOptions={selectedOptions}
      onChange={(newSelectedOption) => setSelectedOptions(newSelectedOption)}
      disabled={disabled}
      isInvaid={isInvaid}
      maxSelectedOptions={maxSelectedOptions}
    />
  );
}

type MultiSelectProps = {
  options: MultiSelectOption[];
  value?: MultiSelectOption[];
  defaultValue?: MultiSelectOption[];
  onChange?: (value: MultiSelectOption[]) => void;
  disabled?: boolean;
  isInvaid?: boolean;
  maxSelected?: number;
};

export function MultiSelect({
  options,
  value,
  defaultValue,
  onChange,
  disabled,
  isInvaid,
  maxSelected,
}: MultiSelectProps) {
  if (value !== undefined) {
    return (
      <MultiSelectControlled
        options={options}
        selectedOptions={value}
        onChange={onChange}
        disabled={disabled}
        isInvaid={isInvaid}
        maxSelectedOptions={maxSelected}
      />
    );
  }
  return (
    <MultiSelectUncontrolled
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      isInvaid={isInvaid}
      maxSelectedOptions={maxSelected}
    />
  );
}
