import { ReactNode } from "react";
import { SidebarProvider } from "../../../contexts/SidebarContext";
import { SidebarLinksType } from "../../../contexts/SidebarContext/SidebarContext";
import Content from "../../Content";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";
import Base from "../Base";

type PanelBaseProps = {
  children: ReactNode;
  sidebarLinks: SidebarLinksType;
};
export function PanelBase({ children, sidebarLinks }: PanelBaseProps) {
  return (
    <Base>
      <SidebarProvider sidebarLinks={sidebarLinks}>
        <Navbar />
        <Sidebar />
        <Content>{children}</Content>
      </SidebarProvider>
    </Base>
  );
}
