import { Dropdown } from "react-bootstrap";
import { Category, Task, Tasks, TasksByCategory } from "../../utils/Task";
import React, { useEffect, useState } from "react";
import { useApp } from "../../contexts/AppContext";
import alertMessages from "../../data/alertMessages.json";
import "./CategorySelector.scss";
// import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import { keyBy } from "lodash";
import { Option } from "react-bootstrap-typeahead/types/types";
import { Combobox } from "../Combobox/Combobox";

type CategorySelectorComboboxProps = {
  categories: string[];
  onChange?: (selectedCategory: string) => void;
  defaultCategory?: string;
  disabled?: boolean;
  inputMaxChars?: number;
};
function CategorySelectorCombobox({
  categories,
  onChange,
  defaultCategory,
  disabled,
  inputMaxChars
}: CategorySelectorComboboxProps) {
  return <Combobox options={categories} onChange={onChange} defaultValue={defaultCategory} disabled={disabled} inputMaxChars={inputMaxChars}/>;
}

type CategorySelectorSelectDropdownProps = {
  categories: string[];
  variant: "select" | "dropdown";
  disabled?: boolean;
  onChange?: (selectedCategory: string) => void;
  toggleMaxWidth?: string;
  defaultCategory?: string;
}
function CategorySelectorSelectDropdown({categories, variant, disabled, onChange, toggleMaxWidth, defaultCategory}: CategorySelectorSelectDropdownProps) {
  const { showAlert } = useApp();
  const [selectedCategory, setSelectedCategory] = useState<string>(defaultCategory? defaultCategory : "");
  useEffect(() => {
    onChange && onChange(selectedCategory);
  }, [selectedCategory])
  
  return (
    <Dropdown
      drop={variant == "select" ? "down" : "down-centered"}
      className={`h-100`}
      onSelect={(category) => setSelectedCategory(category ? category : "")}
    >
      <Dropdown.Toggle
        id={`dropdown-task-selector`}
        variant="light"
        style={{ maxWidth: toggleMaxWidth }}
        disabled={disabled}
      >
        {selectedCategory ? selectedCategory : ""}
      </Dropdown.Toggle>

      <Dropdown.Menu className={variant == "select" ? "w-100" : ""}>
        {categories.map((category) => (
          <React.Fragment key={category}>
            <Dropdown.Item eventKey={category} key={category}>
              <div className="ps-2">{category}</div>
            </Dropdown.Item>
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

type CategorySelectorProps = {
  categories: string[];
  defaultValue: string;
  onSelect?: (selectedTask: string) => void;
  variant?: "select" | "dropdown" | "combobox";
  toggleMaxWidth?: string;
  disabled?: boolean;
  inputMaxChars?: number
};
export function CategorySelector({
  categories,
  defaultValue,
  onSelect,
  variant = "select",
  toggleMaxWidth = "unset",
  disabled = false,
  inputMaxChars
}: CategorySelectorProps) {

  return(
    <div className={`CategorySelector ${"CategorySelector-" + variant}`}>
    {variant === "combobox" && (
      <CategorySelectorCombobox
          categories={categories} defaultCategory={defaultValue} disabled={disabled} onChange={onSelect}inputMaxChars={inputMaxChars}
        />
    )}
    {(variant === "select" || variant === "dropdown") && (
      <CategorySelectorSelectDropdown variant={variant} categories={categories} defaultCategory={defaultValue} disabled={disabled} toggleMaxWidth={toggleMaxWidth} onChange={onSelect}/>
    ) }
    </div>
  )
}

// type CategorySelectorProps2 = {
//   categories: TasksByCategory;
//   value: string;
//   onSelect?: (selectedTask: string) => void;
//   variant?: "select" | "dropdown" | "combobox";
//   toggleMaxWidth?: string;
//   disabled?: boolean;
// };
// export function CategorySelector2({
//   categories,
//   value,
//   onSelect,
//   variant = "select",
//   toggleMaxWidth = "unset",
//   disabled = false,
// }: CategorySelectorProps2) {
//   const { showAlert } = useApp();
//   var selectedCategory: string | null = value;
//   const setSelectedCategory = (newSelectedCategory: string) => {
//     selectedCategory = newSelectedCategory;
//   };
//   useEffect(() => {
//     console.log(selectedCategory);
//   }, [selectedCategory]);

//   const handleSelect = (
//     categoryName: string | null | undefined,
//     allowCustomValues: boolean | undefined = false
//   ) => {
//     if (allowCustomValues) {
//       if (!categoryName) {
//         setSelectedCategory("");
//         onSelect && onSelect("");
//         return;
//       }
//       setSelectedCategory(categoryName);
//       onSelect && onSelect(categoryName);
//       return;
//     }
//     if (!categoryName) {
//       showAlert(alertMessages.somethingWentWrong);
//       return;
//     }
//     const findCategoryByName = (
//       categoryName: string
//     ): string | null | undefined => {
//       if (!categoryName) {
//         showAlert(alertMessages.somethingWentWrong);
//         return undefined;
//       }
//       const category = categories.categories.filter(
//         (category) => category.name === categoryName
//       );
//       if (!category || category.length < 1) {
//         showAlert(alertMessages.somethingWentWrong);
//         return null;
//       }
//       return category[0].name;
//     };

//     var category = findCategoryByName(categoryName);
//     if (!category) {
//       showAlert(alertMessages.somethingWentWrong);
//       return;
//     }
//     setSelectedCategory(category);
//     onSelect && onSelect(category);
//   };

//   if (variant === "combobox") {
//     return (
//       <div className={`CategorySelector ${"CategorySelector-" + variant}`}>
//         <CategorySelectorCombobox
//           categories={categories.categories.map((category) => category.name)}
//         />
//       </div>
//     );
//   }
//   return (
//     <div
//       className={`CategorySelector ${"CategorySelector-" + variant}`}
//       // style={{ height: "35%" }}
//     >
//       <Dropdown
//         drop={variant == "select" ? "down" : "down-centered"}
//         className={`h-100`}
//         onSelect={(categoryName) => handleSelect(categoryName)}
//       >
//         <Dropdown.Toggle
//           id={`dropdown-task-selector`}
//           variant="light"
//           style={{ maxWidth: toggleMaxWidth }}
//           disabled={disabled}
//           placeholder="LL"
//         >
//           {selectedCategory ? selectedCategory : ""}
//           {/* {selectedTask && (
//                   <div className="d-flex"><span className="me-2 d-flex category"><div className="categoryName">{selectedTask.category}</div><div>:</div></span><span className="taskName">{selectedTask.name}</span></div>
//               )} */}
//           {/* {selectedCategory && (
//             <FullTaskName
//               categoryName={selectedTask.category}
//               taskName={selectedTask.name}
//             />
//           )} */}
//           {/* {selectedTask && (
//                   <>{selectedTask.name}</>
//               )}
//               {!selectedTask && (
//                 ""
//               )} */}
//         </Dropdown.Toggle>

//         <Dropdown.Menu className={variant == "select" ? "w-100" : ""}>
//           {categories.categories.map((category) => (
//             <React.Fragment key={category.name}>
//               <Dropdown.Item eventKey={category.name} key={category.name}>
//                 <div className="ps-2">{category.name}</div>
//               </Dropdown.Item>
//             </React.Fragment>
//           ))}
//         </Dropdown.Menu>
//       </Dropdown>
//     </div>
//   );
// }
