import {
  Navigate,
  Route,
  RouterProvider,
  createRoutesFromElements,
} from "react-router";
import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import Lost from "../pages/Lost";
// Account
import { default as AccountSettings } from "../pages/AccountSettings";

// Admin
import { default as AdminDashboard } from "../pages/admin/Dashboard";
import { default as AdminMemberDay } from "../pages/admin/MemberDayRecords";
import { default as AdminActiveMembers } from "../pages/admin/ActiveMembers";
// import { default as AdminMemberYearRecords } from "../pages/admin/MemberYearRecords";
import { default as AdminUsers } from "../pages/admin/Users";
import { default as AdminMemberYear } from "../pages/admin/MemberYearRecords";
import { default as AdminMemberMonth } from "../pages/admin/MemberMonthRecords";
import { default as AdminSettings } from "../pages/admin/Settings";
import { default as AdminTasks } from "../pages/admin/Tasks";
// User
import { default as Dashboard } from "../pages/member/Dashboard";
import { default as DayRecords } from "../pages/member/DayRecords";
import { default as MonthRecords } from "../pages/member/MonthRecords";
import { default as YearRecords } from "../pages/member/YearRecords";
// import { default as Settings } from "../pages/member/Settings";
import { default as Tasks } from "../pages/member/Tasks";

import AdminLayout from "../layouts/AdminLayout";
import MemberLayout from "../layouts/MemberLayout";
import Test from "../pages/Test";
import { AuthRoles } from "../utils/AuthRoles";
import RequireAuth from "../layouts/RequireAuth";
import Unauthorized from "../pages/Unauthorized";
import Home from "../pages/Home";
import Layout from "./Layout";
import IndexRedirect from "./IndexRedirect";
import apiUrls from "../data/api.json";
import MembersWorktimeSummary from "../pages/admin/MembersWorktimeSummary";
import { AccountLayout } from "../layouts/AccountLayout/AccountLayout";
import ErrorBoundaryFallback from "../components/ErrorBoundaryFallback";
import { SnapError } from "../components/ErrorBoundaryFallback/SnapError";
import ResetPassword from "../pages/ResetPassword";
import ConfirmEmail from "../pages/ConfirmEmail";
import ForgotPassword from "../pages/ForgotPassword";
import { default as MemberHelpPage } from "../pages/member/HelpPage";
import { default as AdminHelpPage } from "../pages/admin/HelpPage";
import MembersMonthlyTimesheets from "../pages/admin/MembersMonthlyTimesheets";
import PendingMembersRequests from "../pages/admin/PendingMembersRequests";

export const router = createBrowserRouter(
  [
    {
      path: apiUrls.index,
      element: <Layout />,
      children: [
        {
          index: true,
          element: <IndexRedirect />,
        },
        {
          path: apiUrls.login,
          element: <Login />,
        },
        {
          path: apiUrls.unauthorized,
          element: <Unauthorized />,
        },
        {
          path: apiUrls.forgotPassword,
          element: <ForgotPassword />,
        },
        {
          path: apiUrls.resetPassword,
          element: <ResetPassword />,
        },
        {
          path: apiUrls.setPassword,
          element: <ResetPassword reset_to_set={true} />,
        },
        // {
        //   path: apiUrls.confirmEmail,
        //   element: <ConfirmEmail />,
        // },
        // Account
        {
          element: <RequireAuth allowedRoles={Object.values(AuthRoles)} />,
          children: [
            {
              path: apiUrls.account.route,
              element: <AccountLayout />,
              children: [
                {
                  index: true,
                  element: <IndexRedirect />,
                },
                // {
                //   path: apiUrls.account.children.settings,
                //   element: <AccountSettings/>,
                // },
                // User
                {
                  element: <RequireAuth allowedRoles={[AuthRoles.member]} />,
                  children: [
                    {
                      path: apiUrls.account.children.member.route,
                      element: <MemberLayout />,
                      children: [
                        {
                          index: true,
                          element: <Dashboard />,
                        },
                        {
                          path: apiUrls.account.children.member.children
                            .dayRecords,
                          element: <DayRecords />,
                        },
                        {
                          path: apiUrls.account.children.member.children
                            .monthRecords,
                          element: <MonthRecords />,
                        },
                        {
                          path: apiUrls.account.children.member.children
                            .yearRecords,
                          element: <YearRecords />,
                        },
                        {
                          path: apiUrls.account.children.member.children.tasks,
                          element: <Tasks />,
                        },
                        {
                          path: apiUrls.account.children.member.children
                            .settings,
                          element: <AccountSettings />,
                        },
                        {
                          path: apiUrls.account.children.member.children.help,
                          element: <MemberHelpPage />,
                        },
                      ],
                    },
                  ],
                },

                // Admin
                {
                  element: <RequireAuth allowedRoles={[AuthRoles.admin]} />,
                  children: [
                    {
                      path: apiUrls.account.children.admin.route,
                      element: <AdminLayout />,
                      children: [
                        {
                          index: true,
                          // element: <AdminDashboard />,
                          element: (
                            <Navigate
                              to={
                                apiUrls.account.children.admin.children
                                  .activeMembers
                              }
                              replace
                            />
                          ),
                        },
                        {
                          path: apiUrls.account.children.admin.children
                            .activeMembers,
                          element: <AdminActiveMembers />,
                        },
                        {
                          path: apiUrls.account.children.admin.children
                            .membersWorkingtimeSummary,
                          element: <MembersWorktimeSummary />,
                        },
                        {
                          path: apiUrls.account.children.admin.children
                            .membersMonthlyTimesheets,
                          element: <MembersMonthlyTimesheets />,
                        },
                        {
                          path: apiUrls.account.children.admin.children
                            .pendingRequests,
                          element: <PendingMembersRequests/>
                        },
                        {
                          path: apiUrls.account.children.admin.children.users,
                          children: [
                            {
                              index: true,
                              element: <AdminUsers />,
                            },
                            {
                              path: apiUrls.account.children.admin.children
                                .memberYear,
                              element: <AdminMemberYear />,
                            },
                            {
                              path: apiUrls.account.children.admin.children
                                .memberMonth,
                              element: <AdminMemberMonth />,
                            },
                            {
                              path: apiUrls.account.children.admin.children
                                .memberDay,
                              element: <AdminMemberDay />,
                            },
                          ],
                        },
                        {
                          path: apiUrls.account.children.admin.children.tasks,
                          element: <AdminTasks />,
                        },
                        {
                          path: apiUrls.account.children.admin.children
                            .settings,
                          element: <AccountSettings />,
                        },
                        {
                          path: apiUrls.account.children.admin.children.help,
                          element: <AdminHelpPage />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        // Other
        {
          path: "/test",
          element: <Test />,
        },
        {
          path: "/*",
          element: <Lost />,
        },
      ],
    },
  ]

  // createRoutesFromElements(
  //   <Route path="/" element={<Layout/>}>
  //     <Route index element={<IndexRedirect />} />
  //     <Route path="login" element={<Login />} />
  //     <Route path="home" element={<Home />} />
  //     <Route path="unauthorized" element={<Unauthorized />} />

  //     {/* Admin */}
  //     <Route element={<RequireAuth allowedRoles={[AuthRoles.admin]}/>}>
  //       <Route path="admin" element={<AdminLayout />}>
  //         <Route index element={<UserDashboard />} />
  //         <Route path="member-day" element={<UserMemberDayRecords />} />
  //         <Route path="member-month" element={<UserMemberYear />} />
  //         <Route path="members" element={<UserMembers />} />
  //         <Route path="settings" element={<UserSettings />} />
  //         <Route path="tasks" element={<UserTasks />} />
  //       </Route>
  //     </Route>

  //     {/* User */}
  //     <Route element={<RequireAuth allowedRoles={[AuthRoles.member]}/>}>
  //       <Route path="user" element={<MemberLayout />}>
  //         <Route index element={<Dashboard />} />
  //         <Route path="day" element={<DayRecords />} />
  //         <Route path="month" element={<MonthRecords />} />
  //         <Route path="monthes" element={<Year />} />
  //         <Route path="settings" element={<Settings />} />
  //         <Route path="tasks" element={<Tasks />} />
  //       </Route>
  //     </Route>

  //     <Route path="test" element={<Test />} />
  //     {/* Catch all */}
  //     <Route path="/*" element={<Lost />} />
  //   </Route>
  // )
);
