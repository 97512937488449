import { useEffect, useRef } from "react";
import DayRecordsTable from "../../../DayRecordsTable";
import { useMonthRecords } from "../../context/MonthRecordsContext";
import "./DayModal.scss";

export function DayModal() {
const ref = useRef<null | HTMLDivElement>(null);
  const {month, modalStatus, changeModalDate} = useMonthRecords();
  useEffect(()=>{
    if(modalStatus.show) {
        ref.current?.parentElement?.parentElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [modalStatus])

return (
    <div className="DayModal" ref={ref}>
    {modalStatus.date && (
          <DayRecordsTable date={modalStatus.date} setDate={changeModalDate} onlyMonth={month} embeded={true}/>
          )}
    </div>
)
}