import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "react-bootstrap";
import {
  faCaretDown,
  faCaretLeft,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useTasksList } from "../../../../context/TasksListContext";
import { Category as CategoryType } from "../../../../../../../utils/Task";
import TooltipButton from "../../../../../../../components/TooltipButton";
import "./Category.scss";

type CategoryProps = {
    category: CategoryType;
    className?: string;
}
export default function Category({category, className}: CategoryProps) {
  const {
    allTasks,
    openTaskCreationModal,
    openTaskEditionModal,
    openCategoryEditionModal,
  } = useTasksList();
  return (
    <div className={`Category list-row-styled d-flex ${className}`}>
      <div
        className="d-flex align-items-center"
        style={{
          maxWidth: "calc(100% - 55px - 53px)",
          width: "100%",
        }}
      >
        <div>
          <FontAwesomeIcon icon={faCaretLeft} className="mx-2" rotation={90} />
        </div>
        <div className={`categoryName ${category.isPublic ? "disabled" : ""}`}>
          {category.name}
        </div>
        <div className="mx-2 count">
          <Badge bg="secondary" pill className="list-row-badge d-flex justify-content-center align-items-center">
            {category.tasks.length}
          </Badge>
        </div>
        <div className="ms-1 open">| tasks</div>
      </div>
      {category.isPublic && (
        <div
          className="public-info text-end"
          style={{
            maxWidth: "calc(55px + 53px)",
            width: "100%",
          }}
        >
          public
        </div>
      )}
      {!category.isPublic && (
        <>
          <div>
            <TooltipButton
              tooltipContent="Edit Category"
              buttonVariant="light"
              className="editCategory list-row-btn"
              onClick={(e) => {
                e.stopPropagation();
                openCategoryEditionModal(category.name);
              }}
            >
              <FontAwesomeIcon icon={faPencil} size="sm" className="mx-2" />
            </TooltipButton>
          </div>
          <div>
            <TooltipButton
              tooltipContent="Create Task"
              buttonVariant="light"
              className="createCategoryTask list-row-btn"
              onClick={(e) => {
                e.stopPropagation();
                openTaskCreationModal(category.name);
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" className="mx-2" />
            </TooltipButton>
          </div>
        </>
      )}
    </div>
  );
}
