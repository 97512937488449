import { Outlet } from "react-router";
import AdminPanelBase from "../../components/pageBases/AdminPanelBase";
import { RequireAuth } from "../RequireAuth/RequireAuth";
import { AuthRoles, getAuthRoleIndex } from "../../utils/AuthRoles";
import CookiesMenager from "../../utils/cookiesMenager";

export function AdminLayout() {
  return (
      <AdminPanelBase>
        <Outlet />
      </AdminPanelBase>
  );
}
