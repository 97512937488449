import { DateTime } from "./DateTime";
import { utcToZonedTime, format } from "date-fns-tz";
import {
  Day,
  Month,
  Year,
  Hours,
  Minutes,
  Seconds,
  isDay,
  isHours,
  isMinutes,
  isSeconds,
  isMonth,
  isYear,
  isWeekday,
  Weekday
} from "./TimeTypes";

// TO DO

const locale: string = "pl-PL";
const timeZone: string = "Europe/Warsaw";
const timeZoneUTC: string = "UTC";

export class DateTimeFormatter {
  //   static getLocalTime(dateTime: DateTime) {
  //     const formatter = Intl.DateTimeFormat("pl-PL", {
  //       year: "numeric",
  //       month: "2-digit",
  //       day: "2-digit",
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //       hour12: false, // setting 24 hour format
  //       timeZone: "Europe/Warsaw",
  //       // time/Zone:'Asia/Anadyr',
  //     });
  //     const today = formatter.format(new Date());
  //   }

  //   static getWarsawHours(dateTime: DateTime): number {
  //     const formatter = Intl.DateTimeFormat(locale, {
  //         hour: "2-digit",
  //         hour12: false,
  //         timeZone: timeZone,
  //       });
  //       const hours = formatter.format(dateTime.date);
  //       const parsedHours = parseInt(hours);
  //       if (isNaN(parsedHours)) throw new Error();;
  //       return parsedHours;
  //   }
  //   static getWarsawMinutes(dateTime: DateTime): number {
  //     const formatter = Intl.DateTimeFormat(locale, {
  //         minute: "2-digit",
  //         hour12: false,
  //         timeZone: timeZone,
  //       });
  //       const minute = formatter.format(dateTime.date);
  //       const parsedMinute = parseInt(minute);
  //       if (isNaN(parsedMinute)) throw new Error();;
  //       return parsedMinute;
  //   }
  //   static getWarsawSeconds(dateTime: DateTime): number {
  //     const formatter = Intl.DateTimeFormat(locale, {
  //         second: "2-digit",
  //         hour12: false,
  //         timeZone: timeZone,
  //       });
  //       const seconds = formatter.format(dateTime.date);
  //       const parsedSeconds = parseInt(seconds);
  //       if (isNaN(parsedSeconds)) throw new Error();;
  //       return parsedSeconds;
  //   }

  //   static getUTCHours(dateTime: DateTime): number {
  //     const formatter = Intl.DateTimeFormat(locale, {
  //         hour: "2-digit",
  //         hour12: false,
  //         timeZone: timeZoneUTC,
  //       });
  //       const hours = formatter.format(dateTime.date);
  //       const parsedHours = parseInt(hours);
  //       if (isNaN(parsedHours)) throw new Error();;
  //       return parsedHours;
  //   }
  //   static getUTCMinutes(dateTime: DateTime): number {
  //     const formatter = Intl.DateTimeFormat(locale, {
  //         minute: "2-digit",
  //         hour12: false,
  //         timeZone: timeZoneUTC,
  //       });
  //       const minute = formatter.format(dateTime.date);
  //       const parsedMinute = parseInt(minute);
  //       if (isNaN(parsedMinute)) throw new Error();;
  //       return parsedMinute;
  //   }
  //   static getUTCSeconds(dateTime: DateTime): number {
  //     const formatter = Intl.DateTimeFormat(locale, {
  //         second: "2-digit",
  //         hour12: false,
  //         timeZone: timeZoneUTC,
  //       });
  //       const seconds = formatter.format(dateTime.date);
  //       const parsedSeconds = parseInt(seconds);
  //       if (isNaN(parsedSeconds)) throw new Error();;
  //       return parsedSeconds;
  //   }

  //   static convertWarsawHoursToUTC(){}{

  //   }

  static getWarsawWeekday(date: Date): Weekday {
    const warsawDate = utcToZonedTime(date, "Europe/Warsaw");
    const warsawWeekday = format(warsawDate, "EEE");
    if (!isWeekday(warsawWeekday)) throw new Error();
    return warsawWeekday;
  }

  static getWarsawDay(date: Date): Day {
    const warsawDate = utcToZonedTime(date, "Europe/Warsaw");
    const warsawDay = parseInt(format(warsawDate, "dd"));
    if (!isDay(warsawDay)) throw new Error();
    return warsawDay;
  }

  static getWarsawMonth(date: Date): Month {
    const warsawDate = utcToZonedTime(date, "Europe/Warsaw");
    const warsawMonth = parseInt(format(warsawDate, "MM"));
    if (!isMonth(warsawMonth)) throw new Error();
    return warsawMonth;
  }

  static getWarsawMonthName(date: Date): string {
    const warsawDate = utcToZonedTime(date, "Europe/Warsaw");
    const warsawMonthName = format(warsawDate, "MMMM");
    return warsawMonthName;
  }

  static getWarsawYear(date: Date): Year {
    const warsawDate = utcToZonedTime(date, "Europe/Warsaw");
    const warsawYear = format(warsawDate, "yyyy");
    if (!isYear(warsawYear)) throw new Error();
    return warsawYear;
  }

  static getWarsawHour(date: Date): Hours {
    const warsawDate = utcToZonedTime(date, "Europe/Warsaw");
    const warsawHour = parseInt(format(warsawDate, "HH"));
    if (!isHours(warsawHour)) throw new Error();
    return warsawHour;
  }

  static getWarsawMinute(date: Date): Minutes {
    const warsawDate = utcToZonedTime(date, "Europe/Warsaw");
    const warsawMinute = parseInt(format(warsawDate, "mm"));
    if (!isMinutes(warsawMinute)) throw new Error();
    return warsawMinute;
  }

  static getWarsawSecond(date: Date): Seconds {
    const warsawDate = utcToZonedTime(date, "Europe/Warsaw");
    const warsawSecond = parseInt(format(warsawDate, "ss"));
    if (!isSeconds(warsawSecond)) throw new Error();
    return warsawSecond;
  }


  static convertWarsawHourToUTCHour(date: Date, warsawHour: Hours): Hours {
    const warsawDate = date;
    warsawDate.setHours(warsawHour);
    const utcDate = utcToZonedTime(warsawDate, "UTC");
    const utcHour = parseInt(format(utcDate, "HH"));
    if (!isHours(utcHour)) throw new Error();
    return utcHour;
  }

  static convertWarsawMinuteToUTCMinute(
    date: Date,
    warsawMinute: Minutes
  ): Minutes {
    const warsawDate = date;
    warsawDate.setMinutes(warsawMinute);
    const utcDate = utcToZonedTime(warsawDate, "UTC");
    const utcMinute = parseInt(format(utcDate, "mm"));
    if (!isMinutes(utcMinute)) throw new Error();
    return utcMinute;
  }

  static convertWarsawSecondToUTCSecond(
    date: Date,
    warsawSecond: Seconds
  ): Seconds {
    const warsawDate = date;
    warsawDate.setSeconds(warsawSecond);
    const utcDate = utcToZonedTime(warsawDate, "UTC");
    const utcSecond = parseInt(format(utcDate, "ss"));
    if (!isSeconds(utcSecond)) throw new Error();
    return utcSecond;
  }

  static convertWarsawDayToUTCDay(
    date: Date,
    warsawDay: Day
  ): Day {
    const warsawDate = date;
    warsawDate.setDate(warsawDay);
    const utcDate = utcToZonedTime(warsawDate, "UTC");
    const utcDay = parseInt(format(utcDate, "dd"));
    if (!isDay(utcDay)) throw new Error();
    return utcDay;
  }

  static convertWarsawMonthToUTCMonth(
    date: Date,
    warsawMonth: Month
  ): Month {
    const warsawDate = date;
    warsawDate.setMonth(warsawMonth-1);
    const utcDate = utcToZonedTime(warsawDate, "UTC");
    const utcMonth = parseInt(format(utcDate, "MM"));
    if (!isMonth(utcMonth)) throw new Error();
    return utcMonth as Month;
  }

  static convertWarsawYearToUTCYear(
    date: Date,
    warsawYear: Year
  ): Year {
    const warsawYearInt = parseInt(warsawYear);
    if(isNaN(warsawYearInt) || warsawYearInt < 1000 || warsawYearInt > 9999) {
      throw new Error();
    } 
    const warsawDate = date;
    warsawDate.setFullYear(warsawYearInt);
    const utcDate = utcToZonedTime(warsawDate, "UTC");
    const utcYear = format(utcDate, "yyyy");
    if (!isYear(utcYear)) throw new Error();
    return utcYear;
  }

}
