import { Navigate, useLocation } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import {
  AuthRoles,
  getAuthRoleByIndex,
  getAuthRoleIndex,
} from "../utils/AuthRoles";
import apiUrls from "../data/api.json";
import CookiesMenager from "../utils/cookiesMenager";
import { getIndexApiLinkForRole } from "../utils/defaultPathForRoles";

const IndexRedirect = () => {
  const { auth, roleIsAuthorized } = useAuth();
  const location = useLocation();

  if (auth) {
    if (auth.roles.length > 1) {
      const indexStr = CookiesMenager.getCookie("lastr");
      const index =
        indexStr && /^[0-9]+$/.test(indexStr) ? parseInt(indexStr) : undefined;
      const defaultRole =
        index !== undefined && index >= 0
          ? getAuthRoleByIndex(index)
          : undefined;
      if (defaultRole && roleIsAuthorized(defaultRole)) {
        CookiesMenager.setCookie(
          "lastr",
          getAuthRoleIndex(defaultRole).toString(),
          { ...CookiesMenager.defaultOptions, expires: 30 }
        );
        return (
          <Navigate
            to={((p) => p ? p : apiUrls.index)(getIndexApiLinkForRole(defaultRole))}
            state={{ from: location }}
            replace
          />
        );
      }
    }

    const roles = Object.values(AuthRoles);
    for (let role of roles) {
      if (roleIsAuthorized(role)) {
        CookiesMenager.setCookie("lastr", getAuthRoleIndex(role).toString(), {
          ...CookiesMenager.defaultOptions,
          expires: 30,
        });
        return (
          <Navigate
            to={((p) => p ? p : apiUrls.index)(getIndexApiLinkForRole(role))}
            state={{ from: location }}
            replace
          />
        );
      }
    }
  }
  return (
    // Other
    <Navigate to={apiUrls.login} state={{ from: location }} replace />
  );
  // return (
  //     // Admin
  //             // auth?.roles.find(role => role == AuthRoles.admin)
  //     roleIsAuthorized(AuthRoles.admin)
  //     ? <Navigate to={apiUrls.account.children.admin.route} state={{ from: location }} replace />

  //     // User
  //             // : auth?.roles.find(role => role == AuthRoles.member)
  //     : roleIsAuthorized(AuthRoles.member)
  //     ? <Navigate to={apiUrls.account.children.member.route} state={{ from: location }} replace />

  //     // Other
  //     : <Navigate to={apiUrls.login} state={{ from: location }} replace />
  // )
};

export default IndexRedirect;
