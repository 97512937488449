import { ReactNode, useEffect, useState } from "react";
import { TimerStatusType, useTimerStatus } from "../../hooks/useTimer";
// import { useFetchGet } from "../../hooks/useFetch";
import {
  ErrorResponce,
  fetchAll,
  // fetchGet,
  // fetchPost,
} from "../../utils/fetchFromEndpoint";
import { Task, Tasks, TasksJson } from "../../utils/Task";
import alertMessages from "../../data/alertMessages.json";
import { DateTime } from "../../utils";
import { logError, logResponseError } from "../../utils/logError";
import { DayStatus } from "../../utils/DayStatus";
import { fetchers } from "../../serverApi/fetchers";
import { useApp } from "../AppContext";
import { Formatter2 } from "../../widgets/member/DayRecordsTable/utils/Formatter2";
import { createContext } from "../../hooks/useCreateContext";
import Placeholder from "../../components/Placeholder";
import Base from "../../components/pageBases/Base";
import CookiesMenager from "../../utils/cookiesMenager";
import restrictions from "../../data/restrictions.json";
import _ from "lodash";

type TimerContextVal = {
  timerStatus: TimerStatusType;
  isLocked: boolean;
  isDisabled: boolean;
  startTimer: () => void;
  stopTimer: () => void;

  allTasks: Tasks;
  findTaskById: (taskId: string) => Task | null | undefined;
  selectedTask: Task | undefined;
  setSelectedTask: (newSelectedTask: Task) => void;
  childrenRefresh: boolean;
  timerDataRefresh: () => void;
  disableTimer: () => void;
  lockTimer: () => void;
};
const [TimerContext, useTimerContext] = createContext<TimerContextVal>();
export { useTimerContext };

type TimerProviderProps = {
  children: ReactNode;
};

type RespTimerStatusType =
  | {
      startedAt: string;
      task: {
        id: string;
        name: string;
        category: string;
      };
    }
  | {
      startedAt: null;
    };
type RespDayStatus = {
  status: string;
};

export function TimerProvider({ children }: TimerProviderProps) {
  const { showAlert } = useApp();
  // const {fetchGet, fetchPost} = useAccountFetch();
  const [childrenRefresh, setChildrenRefresh] = useState<boolean>(false);
  const [allTasks, setAllTasks] = useState<Tasks | undefined>(undefined);
  const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);
  const [timerStatus, setTimerStatus] = useTimerStatus(undefined);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [_isDisabled, setIsDisabled] = useState<boolean>(false);
  const timerDataRefresh = (allow_recursion: boolean = true) => {
    let dateStatusErrorCode: null | number = null;
    fetchAll(
      [
        fetchers.account.member.tasks.fetch({}),
        fetchers.account.member.timerStatus.fetch({}),
        fetchers.account.member.dateStatus.fetch(
          { date: new DateTime() },
          {
            fail: (data, status) => {
              dateStatusErrorCode = status;
            },
          }
        ),
      ],
      {
        success: ([tasks, timerStatus, dateStatus]) => {
          const allTasks = setAllTasks(new Tasks(tasks));
          // newIsDisabled = dateStatus.status !== DayStatus.editable;
          setIsLocked(dateStatus.status !== DayStatus.editable);
          // console.log(timerStatus.active)
          if (timerStatus.active) {
            setSelectedTask(timerStatus.active.task);
            if(!CookiesMenager.getCookie("lastCreatedTask")) {
              CookiesMenager.setCookie("lastCreatedTask", timerStatus.active.task.id);
            }
            setTimerStatus(timerStatus.active);
          } else {
            const existingTasks = tasks.filter(task => !task.isDeleted);
            setTimerStatus(null);
            const lastTimerTaskId = CookiesMenager.getCookie("lastCreatedTask");
            const lastTimerTask = lastTimerTaskId
            ? existingTasks.find((task) => task.id === lastTimerTaskId)
            : undefined;
            if (lastTimerTask) {
              setSelectedTask(lastTimerTask);
            } else {
              CookiesMenager.removeCookie("lastCreatedTask");
              const firstUserTask = existingTasks.find((task) => !task.isPublic);
              const firstPublicTask = existingTasks.find((task) => !!task.isPublic);
              const firstTask = firstUserTask
                ? firstUserTask
                : firstPublicTask
                ? firstPublicTask
                : undefined;
                if (firstTask){
                  CookiesMenager.setCookie("lastCreatedTask", firstTask.id);
                }
              setSelectedTask(
                firstTask
              );
            }
          }
        },
        fail: () => {
          if (
            dateStatusErrorCode !== null &&
            dateStatusErrorCode === 445 &&
            allow_recursion
          ) {
            setTimeout(() => timerDataRefresh(false), 1000);
            return;
          }
          showAlert(alertMessages.somethingWentWrong);
        },
        always: () => {
          setIsDisabled(false);
        },
        error: () => {
          showAlert(alertMessages.somethingWentWrong);
        },
      }
    );
  };

  useEffect(() => timerDataRefresh(), []);

  const findTaskById = (taskId: string): Task | null | undefined => {
    if (!allTasks) {
      showAlert(alertMessages.somethingWentWrong);
      return undefined;
    }
    const task = allTasks.tasks.filter((task) => task.id === taskId);
    if (!task || task.length < 1) {
      showAlert(alertMessages.somethingWentWrong);
      return null;
    }
    return task[0];
  };

  const changeActiveTaskStatus = () => {
    // if (timerStatus || !selectedTask) return;
    if (selectedTask === undefined) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const dataToSend = {
      taskId: selectedTask ? selectedTask.id : null,
    };
    fetchers.account.member.changeTimerStatus.fetch(dataToSend, {
      success: (data, status) => {
        // Started
        const newTimerStatus = data.active
          ? {
              startedAt: data.active.startedAt,
              task: data.active.task,
            }
          : null;
        setTimerStatus(newTimerStatus);
      },
      fail: (data, status) => {
        if (status === 444) {
          showAlert(alertMessages.timerDurationIsTooShort);
          setTimerStatus(null);
          return;
        }
        if (status === 445) {
          const startedAtMessage = timerStatus
            ? "Started at: " +
              Formatter2.formatDate(timerStatus.getStartedAt()) +
              " " +
              Formatter2.formatTime(timerStatus.getStartedAt()) +
              ", " +
              'task: "' +
              timerStatus.getRunningTask().category +
              ": " +
              timerStatus.getRunningTask().name +
              '".'
            : "";
          showAlert(
            (alertMessages.timerDurationIsLongerThan.replace("{{maxTimerDuration}}", restrictions.impossibleWorkingDurationHours + " hours")) + " " + startedAtMessage
            // +
            // " " +
            // alertMessages.editTimeRecord
          );
          setTimerStatus(null);
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
        timerDataRefresh();
      },
      always: () => {
        // timerDataRefresh();
        setChildrenRefresh(!childrenRefresh);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };
  const executeStartTimer = () => {
    if (selectedTask === undefined) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const dataToSend = {
      taskId: selectedTask.id,
    };
    fetchers.account.member.startTimer.fetch(dataToSend, {
      success: (data, status) => {
        // Started
        // const newTimerStatus = data.active
        //   ? {
        //       startedAt: data.active.startedAt,
        //       task: data.active.task,
        //     }
        //   : null;
        // setTimerStatus(newTimerStatus);
        CookiesMenager.setCookie("lastCreatedTask", dataToSend.taskId);
      },
      fail: (data, status) => {
        if (status === 450) {
          showAlert(alertMessages.timerAlreadyRunning);
          // setTimerStatus(null);
          // timerDataRefresh();
          return;
        }
        if (status === 444) {
          showAlert(alertMessages.timerDurationIsTooShort);
          // setTimerStatus(null);
          // timerDataRefresh();
          return;
        }
        if (status === 445) {
          const startedAtMessage = timerStatus
            ? "Started at: " +
              Formatter2.formatDate(timerStatus.getStartedAt()) +
              " " +
              Formatter2.formatTime(timerStatus.getStartedAt()) +
              ", " +
              'task: "' +
              timerStatus.getRunningTask().category +
              ": " +
              timerStatus.getRunningTask().name +
              '".'
            : "";
          showAlert(
            (alertMessages.timerDurationIsLongerThan.replace("{{maxTimerDuration}}", restrictions.impossibleWorkingDurationHours + " hours")) + " " + startedAtMessage
            // +
            // " " +
            // alertMessages.editTimeRecord
          );
          // setTimerStatus(null);
          // timerDataRefresh();
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
        // timerDataRefresh();
      },
      always: () => {
        timerDataRefresh();
        setChildrenRefresh(!childrenRefresh);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  const executeEndTimer = () => {
    fetchers.account.member.endTimer.fetch(
      {},
      {
        success: (data, status) => {
          // timerDataRefresh();
          if(timerStatus) {
            CookiesMenager.setCookie("lastCreatedTask", timerStatus.getRunningTask().id);
          }
        },
        fail: (data, status) => {
          if (status === 450) {
            showAlert(alertMessages.noTimerRunning);
            // setTimerStatus(null);
            // timerDataRefresh();
            return;
          }
          if (status === 444) {
            showAlert(alertMessages.timerDurationIsTooShort);
            return;
          }
          if (status === 445) {
            showAlert(
              (alertMessages.changesNotSaved,
              (alertMessages.timeRecordDurationIsLongerThan.replace("{{maxTimerecordDuration}}", restrictions.maxPossibleWorkingDurationPerDayHours + " hours")))
            );
            return;
          }
          showAlert(alertMessages.somethingWentWrong);
          // timerDataRefresh();
        },
        always: () => {
          timerDataRefresh();
          setChildrenRefresh(!childrenRefresh);
        },
        error: () => {
          showAlert(alertMessages.somethingWentWrong);
        },
      }
    );
  };

  const startTimer = () => {
    setIsDisabled(true);
    if (timerStatus) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    // changeActiveTaskStatus();

    executeStartTimer();
  };
  const stopTimer = () => {
    setIsDisabled(true);
    if (!timerStatus) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    executeEndTimer();
  };

  const disableTimer = () => {
    setIsDisabled(false)
  }
  const lockTimer = () => {
    setIsDisabled(false)
  }
  const isDisabled = _isDisabled || !selectedTask;
  return (
    <>
      {allTasks !== undefined &&
        // typeof selectedTask !== undefined &&
        typeof timerStatus !== undefined && (
          <TimerContext.Provider
            value={{
              timerStatus,
              isLocked,
              isDisabled,
              startTimer,
              stopTimer,
              allTasks,
              findTaskById,
              selectedTask,
              setSelectedTask,
              childrenRefresh,
              timerDataRefresh,
              disableTimer,
              lockTimer
            }}
          >
            {children}
          </TimerContext.Provider>
        )}
      {allTasks === undefined && (
        <Base>
          <Placeholder />
        </Base>
      )}
    </>
  );
}
