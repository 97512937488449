import { Button } from "react-bootstrap";
import { Tooltip } from "../Tooltip/Tooltip";
import React, { ReactNode } from "react";
import "./TooltipButton.scss"
type TooltipButtonProps = {
    children: ReactNode;
    className?: string,
    tooltipContent: ReactNode;
    delay?: {
        show: number,
        hide: number
    }
    buttonVariant?: string;
    onClick?: (event: any) => void;
    type?: 'button' | 'reset' | 'submit';
    size?: 'sm' | 'lg';
    style?: React.CSSProperties,
    disabled?: boolean;
    onAnimationEnd?: (e: React.AnimationEvent) => void;
};
export function TooltipButton({children, tooltipContent, delay, className, buttonVariant, onClick, type, size, style, disabled, onAnimationEnd}: TooltipButtonProps){
    return( 
        // <Tooltip tooltipContent={tooltipContent} delay={delay}>
              <Button variant={buttonVariant} className={"TooltipButton " + (className ? className : "")} type={type} onClick={onClick && (e => onClick(e))} size={size} style={style} disabled={disabled} onAnimationEnd={onAnimationEnd}>{children}</Button>
        // </Tooltip>
    )
}