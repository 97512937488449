import { Container } from "react-bootstrap";
import { useApp } from "../../../../../contexts/AppContext";
import { default as Options } from "../../../../../components/TextOptions";
import { default as Image } from "../../../../../components/TextImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faPlay,
  faStop,
  faCalendar,
  faEllipsisVertical,
  faEllipsis,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import startBtn from "../../../../../assets/images/help/start.png";
import stopBtn from "../../../../../assets/images/help/stop.png";
import addBtn from "../../../../../assets/images/help/add.png";
import addSimpleBtn from "../../../../../assets/images/help/addSimple.png";
import editBtn from "../../../../../assets/images/help/edit.png";
import editSimpleBtn from "../../../../../assets/images/help/editSimple.png";
import calendarBtn from "../../../../../assets/images/help/calendar.png";
import arrowsBtn from "../../../../../assets/images/help/arrows.png";
import navToSummaryBtn from "../../../../../assets/images/help/navToSummary.png";
import moveRecordDownBtn from "../../../../../assets/images/help/moveRecordDown.png";
import moveRecordUpBtn from "../../../../../assets/images/help/moveRecordUp.png";
import openBtn from "../../../../../assets/images/help/open.png";
import safariMenuBtn from "../../../../../assets/images/help/safariMenu2.png";
import TooltipButton from "../../../../../components/TooltipButton";

export function Content() {
  const { supportsPWA, triggerPWAInstall } = useApp();

  return (
    <div className="HelpContent">
      <Container fluid={true}>
        <div className="section">
          <div className="text">
            Welcome to Flytime! This guide will walk you through the steps to
            effectively use our time-tracking application to record and manage
            your working hours.
          </div>
        </div>
        <Options className="pb-1">
          <Options.Option
            header="1. Our Mobile and Desktop Applications"
            eventKey="11"
          >
            <div className="text">
              <div>
                <p>
                  For added convinience, we also offer you a Progressive Web
                  Application (PWA) that can be installed on your smartphone or
                  computer, providing seamless experience across devices. You
                  can find instructions on how to install our Flytime app below.
                </p>
                <p>
                  <span className="accent">Important</span>: Choosing which
                  browser to use for installing a Progressive Web Application
                  can notably influence the overall user experience, since
                  different browsers support varying levels of PWA features.
                </p>
                <p>
                  Using the{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Chrome browser
                  </span>{" "}
                  for installing our mobile/desktop application is optional, but{" "}
                  <span style={{ textDecoration: "underline" }}>
                    highly recommended
                  </span>{" "}
                  duo to its comprehensive feature support.
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <TooltipButton
                  tooltipContent="Download Timesheet"
                  buttonVariant="primary"
                  className={"installPWA mb-3" + (supportsPWA ? "" : " d-none")}
                  disabled={!supportsPWA}
                  onClick={triggerPWAInstall}
                >
                  {supportsPWA && <>{"Click to Install Flytime App"}</>}
                  {!supportsPWA && (
                    <>
                      <span>Use instructions to install our app manually.</span>
                    </>
                  )}
                </TooltipButton>
              </div>

              <div>
                <Options className="pb-2 inner-options">
                  <Options.Option
                    header="Mobile application for Android and iOS devices"
                    eventKey="111"
                  >
                    Follow the steps to install our mobile application from your
                    browser:
                    <ol>
                      <li>
                        Open the web browser of your choice on your smartphone
                        or tablet.
                      </li>
                      <li>
                        Go to the Flytime website at{" "}
                        <a className="link" href="https://time.whitefly.pl/">https://time.whitefly.pl/</a>.
                      </li>
                      <li>
                        Tap the <span className="accent">Menu</span> button
                        (Share button on Safari).
                        <Options className="hint">
                          <Options.Option
                            header="Show hint"
                            headerActive="Hide hint"
                            eventKey="1111"
                          >
                            <ul>
                              <li>
                                <span className="accent-light">Chrome</span>:{" "}
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  className="mx-2"
                                />{" "}
                                button in the top-right corner on Android or{" "}
                                <FontAwesomeIcon
                                  icon={faEllipsis}
                                  className="mx-2"
                                />{" "}
                                button in the bottom-right corner on iOS.
                              </li>
                              <li>
                                <span className="accent-light">Safari</span>:{" "}
                                <span className="accent">
                                  <Image src={safariMenuBtn} />
                                </span>{" "}
                                button in the bottom of the screen in the middle
                                of the toolbar.
                              </li>
                              <li>
                                <span className="accent-light">Firefox</span>:{" "}
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  className="mx-2"
                                />{" "}
                                button in the top-right corner on Android or{" "}
                                <FontAwesomeIcon
                                  icon={faBars}
                                  className="mx-2"
                                />{" "}
                                button in the bottom-right corner.
                              </li>
                              <li>
                                <span className="accent-light">Opera</span>:{" "}
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  className="mx-2"
                                />{" "}
                                button in the top-right corner on Android or{" "}
                                <FontAwesomeIcon
                                  icon={faBars}
                                  className="mx-2"
                                />{" "}
                                button in the bottom-right corner.
                              </li>
                              <li>
                                <span className="accent-light">Edge</span>:{" "}
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  className="mx-2"
                                />{" "}
                                button in the top-right corner on Android or{" "}
                                <FontAwesomeIcon
                                  icon={faEllipsis}
                                  className="mx-2"
                                />{" "}
                                button in the bottom-right corner on iOS.
                              </li>
                              <li>
                                <span className="accent-light">
                                  Samsung Internet
                                </span>
                                :{" "}
                                <FontAwesomeIcon
                                  icon={faBars}
                                  className="mx-2"
                                />{" "}
                                button in the top-right corner
                              </li>
                              <li>
                                <span className="accent-light">Brave</span>:{" "}
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  className="mx-2"
                                />{" "}
                                button in the top-right corner
                              </li>
                            </ul>
                          </Options.Option>
                        </Options>
                      </li>

                      <li>
                        Find an install option like "
                        <span className="accent">Add to Home Screen</span>
                        ", "<span className="accent">Add to phone</span>", "
                        <span className="accent">Install app</span>" or "
                        <span className="accent">Install Flytime</span>" and
                        select it.
                        <Options className="hint">
                          <Options.Option
                            header="Show hint"
                            headerActive="Hide hint"
                            eventKey="1112"
                          >
                            <ul>
                              <li>
                                <span className="accent-light">Chrome</span>:
                                Select "Add to Home screen" option.
                              </li>
                              <li>
                                <span className="accent-light">Safari</span>:
                                Select "Add to Home Screen" option.
                              </li>
                              <li>
                                <span className="accent-light">Firefox</span>:
                                Select "Add to Home screen" option.
                              </li>
                              <li>
                                <span className="accent-light">Opera</span>:
                                Select "Add to" option, and then "Home screen".
                              </li>
                              <li>
                                <span className="accent-light">Edge</span>:
                                Swipe menu options left to see more options,
                                then select "Add to phone" option.
                              </li>
                              <li>
                                <span className="accent-light">
                                  Samsung Internet
                                </span>
                                : Select "Add page to" option, and then "Home
                                screen".
                              </li>
                              <li>
                                <span className="accent-light">Brave</span>:
                                Select "Install app" option.
                              </li>
                            </ul>
                          </Options.Option>
                        </Options>
                      </li>
                      <li>
                        <span className="accent">Confirm</span> the
                        installation.
                      </li>
                    </ol>
                    <div>
                      If you need to uninstall the application, the process
                      would be similar to uninstalling any other mobile app:
                      long-press on an icon of the Flytime app and select
                      "Uninstall" option.
                    </div>
                  </Options.Option>
                  <Options.Option
                    header="Desktop application for Windows, Mac and Linux devices"
                    eventKey="112"
                  >
                    Here are the steps to install our desktop application from
                    your browser:
                    <ol>
                      <li>
                        Open the web browser of your choice on your computer.
                      </li>
                      <li>
                        Go to the Flytime website at{" "}
                        <a className="link" href="https://time.whitefly.pl/">https://time.whitefly.pl/</a>.
                      </li>
                      <li>
                        Look for an{" "}
                        <span className="accent">install button or option</span>{" "}
                        in the address bar or under the three-dot menu{" "}
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          className="mx-2"
                        />{" "}
                        .
                      </li>
                      <li>
                        <span className="accent">Confirm</span> the
                        installation.
                      </li>
                    </ol>
                    <div>
                      To uninstall the application, open your installed Flytime
                      app, click on the menu icon in the top-right corner of the
                      application, and then select "Uninstall" to remove it from
                      your device.
                    </div>
                  </Options.Option>
                </Options>

                <div>
                  <span className="accent">Please note</span> that on very rare
                  occasions you may be asked to{" "}
                  <span className="accent">reinstall</span> the application to
                  get the newest version.
                </div>
                <div className="mt-2">
                  If you encounter any issues while installing or uninstalling
                  our Flytime application, don't worry, we've got your back! Our
                  team is ready to assist you with any problems you may face.
                  Check out the{" "}
                  <span className="accent">Contacting Support</span> section to
                  see how to get in touch with us.
                </div>
              </div>
            </div>
          </Options.Option>
          <Options.Option header="2. Contacting Support" eventKey="12">
            <div className="text">
              If you have any questions, encounter issues, or need assistance
              while using our application, we're here to help! Here are a few
              options to get support:
              <ul>
                <li>
                  <div className="accent">Contact Us Directly:</div>
                  If you have technical issues, encounter bugs, or need
                  assistance that your supervisor cannot resolve, you can
                  contact our tech team directly (
                  <a className="link" href="mailto:time@whitefly.pl">
                    time@whitefly.pl
                  </a>
                  ). Our dedicated tech team is available to assist you and
                  ensure that your experience with our application is smooth and
                  hassle-free.{" "}
                </li>
              </ul>
              Remember, we're here to support you every step of the way. Don't
              hesitate to reach out if you need assistance or have any
              questions. Your feedback is valuable to us, and we're committed to
              providing you with the best possible support experience.
            </div>
          </Options.Option>
        </Options>

        <div className="section">
          <div className="text">
            That's it! You're now ready to efficiently track your working hours
            using Flytime. If you have any further questions or need assistance,
            don't hesitate to reach out to us.
            <div className="mt-3">Thank you for using Flytime!</div>
          </div>
        </div>
      </Container>
    </div>
  );
}
